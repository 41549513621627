import React from 'react';

function ExportButton(props) {
    const {
        onExport = () => {
            /* */
        },
        isLoading,
        ...rest
    } = props;
    return (
        <div className='margin-left-10'>
            <button type='button' className='btn btn-default btn-icon-only' onClick={onExport} {...rest}>
                <span
                    className={`rioglyph ${isLoading ? 'rioglyph-spinner spinning  ' : 'rioglyph-csv'}`}
                    style={{ fontSize: '2.5rem' }}
                />
            </button>
        </div>
    );
}

export default ExportButton;
