import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import FilePage from '@pages/FilePage';
import VehiclePage from '@pages/VehiclePage';
import RolloutPage from '@components/mapupdate/rollouts/overview/RolloutPage.jsx';
import ArchivePage from '@pages/ArchivePage';
import GroupPage from '@pages/GroupPage';
import NoMatch from '@pages/NoMatch';
import CampaignPage from '@components/mapupdate/rollouts/campaigns/CampaignPage.jsx';
import { useStores } from '@stores/context';
import { observer } from 'mobx-react-lite';
import { ServiceLabels } from '@stores/label';
import { Services } from '@stores/service.js';
import InstalledMaps from '@pages/InstalledMaps.jsx';

export default observer(() => {
    const { serviceStore, labelStore } = useStores();

    useEffect(() => {
        serviceStore.setCurrentService(Services.MapUpdate);
        labelStore.setCurrentLabels(ServiceLabels.MapUpdate);
    });

    return (
        <Routes>
            <Route path='/' element={<Navigate to={`${Services.MapUpdate}/vehicles`} />} />
            <Route path='vehicles' element={<VehiclePage />}>
                <Route path=':id' element={<VehiclePage />} />
            </Route>
            <Route path='groups' element={<GroupPage />} />
            <Route path='installed-maps' element={<InstalledMaps />}/>
            <Route path='files' element={<FilePage />}>
                <Route path=':id' element={<FilePage />} />
            </Route>
            <Route path='archive' element={<ArchivePage />} >
                <Route path=':id' element={<ArchivePage />} />
            </Route>
            <Route path='rollouts' element={<RolloutPage />}>
                <Route path=':id' element={<RolloutPage />} />
            </Route>
            <Route path='campaigns' element={<CampaignPage />}>
                <Route path=':id' element={<CampaignPage />} />
            </Route>
            <Route path='*' element={<NoMatch />} />
        </Routes>
    );
});
