import React from 'react';
import Badge from '@common/Badge/Badge.jsx';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { languageData } from '@helpers/constants.jsx';
import { useTranslation } from 'react-i18next';

export const featuresCounterRenderer = (_, row) => {
  const { t } = useTranslation();
  const activeFeatures = row?.assets[0]?.activations?.filter(feature => feature.is_active);
  const inactiveFeatures = row?.assets[0]?.activations?.filter(feature => !feature.is_active);

  const uniqueActiveFeaturesMap = activeFeatures.reduce((acc, feature) => {
    const { feature: { code, name}, feature_variant } = feature;
    if (!acc[code]) {
      acc[code] = {
      code,
      name,
      variants: [],
      };
    }
    acc[code].variants.push(feature_variant);
    return acc;
  }, {});
  
  const uniqueInactiveFeaturesMap = inactiveFeatures.reduce((acc, feature) => {
    const { feature: { code, name}, feature_variant } = feature;
    if (!acc[code]) {
      acc[code] = {
      code,
      name,
      variants: [],
      };
    }
    acc[code].variants.push(feature_variant);
    return acc;
  }, {});

  const uniqueActiveFeatures = Object.values(uniqueActiveFeaturesMap);
  const uniqueInactiveFeatures = Object.values(uniqueInactiveFeaturesMap);
  const uniqueFeaturesTotal = uniqueActiveFeatures.length + uniqueInactiveFeatures.length;

  return (
    <div className={'text-color-primary'}>
      <span data-testid={'serviceCounter'} className={'h3'}>
        {uniqueActiveFeatures?.length}
      </span>
      <span className={'h5'}>/</span>
      <span data-testid={'maxFeatureCounter'} className={'h5'}>
        {uniqueFeaturesTotal}
      </span>
      <span>{` ${t("fotaone.fd.serviceData.activeServices")}`}</span>
    </div>
  );
};

export const serviceDescriptionRenderer = (_, row) => {
  return (
    <div style={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px'
    }}>
      {_}
    </div>
  );
};

export const languageRenderer = (_, row) => {
  const { t } = useTranslation();
  const foundLanguageObj = languageData.find(lang => {
    const code = Object.keys(lang)[0];
    return _ === code;
  });
  const languageName = foundLanguageObj ? Object.values(foundLanguageObj)[0] : '';
  return (
    <div>
      <span
        className={'margin-right-5'}
        style={{position: 'relative', top: '2px'}}>
        {getUnicodeFlagIcon(_.slice(-2))}
      </span>
      {` ${t(languageName)}`}
    </div>
  );
}

export const serialNumberRenderer = (_, row) => {
  return (
      <div>
          <Badge 
                title={`${row.type}`}
                state={`${row.type?.toUpperCase()}`}
                color={
                  row.type?.toUpperCase() === "CM4"
                    ? 'badge-secondary margin-1'
                    : 'badge-default margin-1'
                }
            />
            {` ${row.serial_number}`}
      </div>
  );
};