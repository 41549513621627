import React, { useEffect } from 'react';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import ArchiveTable from '@components/Archive/ArchiveTable';
import ArchiveDetails from '@components/Archive/ArchiveDetails';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { PaginationStore } from '@stores/pagination';
import { FilterStore } from '@stores/filter.jsx';
import { useParams } from 'react-router-dom';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore();

export default observer(() => {
    const { id } = useParams();
    const { serviceStore } = useStores();

    useEffect(() => {
        // When unmounting the page:
        return () => {
            filterStore.resetToDefaults();
            paginationStore.resetToDefaults();
        };
    }, [serviceStore]);

    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Header>
                <FotaHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className='bg-muted'>
                <FotaSidebar files={true} />
            </ApplicationLayout.Sidebar>
            {id && (
                <ApplicationLayout.Sidebar className='right'>
                    <ArchiveDetails id={id} paginationStore={paginationStore} filterStore={filterStore} />
                </ApplicationLayout.Sidebar>
            )}
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer />
                {serviceStore.serviceMenuDialogOpen &&
                    <ServiceMenuDialog/>
                }
                <ArchiveTable
                    showSearch={true}
                    fetchFiles={true}
                    selectedRow={id}
                    paginationStore={paginationStore}
                    filterStore={filterStore}
                />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
});
