import React, { useEffect } from 'react';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStores } from '@stores/context.jsx';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';

export default observer(() => {
    const navigate = useNavigate();
    const { serviceStore } = useStores();
    const { t } = useTranslation();

    useEffect(() => {
    }, [serviceStore]);

    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Header>
                <FotaHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className='bg-muted'>
                <FotaSidebar />
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer />
                {serviceStore.serviceMenuDialogOpen &&
                    <ServiceMenuDialog/>
                }
                <h1 className='margin-bottom-20'>{`${t("fotaone.general.pageNotFound")}`}</h1>
                <button type='button' className='btn btn-default' onClick={() => navigate(-1)}>
                    {`${t("fotaone.general.goBack")}`}
                </button>
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
});
