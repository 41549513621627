import { configureAxiosClient } from '@api/api-utils.js';
import definition from '@api/schemas/featuredashboard-openapi.json';
import OpenAPIClientAxios from 'openapi-client-axios';
import config from '@src/config.js';

let client;

const init = () => {
  definition.servers = [{ url: config.FEATUREDASHBOARD_API_URL }];
  const api = new OpenAPIClientAxios({ definition });
  client = api.initSync();
  return configureAxiosClient(client);
};

export { client, init }