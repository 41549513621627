import React from 'react';
import OverlayLoader from '../Loader/OverlayLoader';
import { EBlurEffectStates } from './DetailsSideBar.constants';
import './DetailsSideBar.css';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';

const DetailsSideBar = ({ children, ...props }) => {
    const { blurEffect, ...sideBarProps } = props;
    return (
        <Sidebar
            bodyClassName={`${blurEffect === EBlurEffectStates.Show ? 'side-bar-body' : ''}`}
            titleClassName={`padding-left-10 position-relative ${
                blurEffect === EBlurEffectStates.Show ? 'overlay--blur' : ''
            }`}
            {...sideBarProps}
            resizable
            position={'right'}
            minWidth={600}
            maxWidth={1200}
            enableFullscreenToggle={true}
        >
            {blurEffect === EBlurEffectStates.Show && (
                <OverlayLoader className='width-100pct height-100pct z-index-10' />
            )}
            <div className={` ${blurEffect === EBlurEffectStates.Show ? 'overlay--blur' : ''}`}>
                <div className='padding-left-20 padding-right-20 margin-top-10 margin-bottom-10'>{children}</div>
            </div>
        </Sidebar>
    );
};

export default DetailsSideBar;
