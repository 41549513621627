import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Routes, Navigate } from 'react-router';
import NoMatch from '@pages/NoMatch';
import MapUpdate from './containers/MapUpdateContainer';
import Activation from './containers/OnlineTrafficContainer';
import FeatureDashboard from './containers/FeatureDashboardContainer';
import RemoteMessage from './containers/RemoteMessageContainer';
import AuthPage from '@pages/AuthPage';
import AuthHandle from './components/Auth/AuthHandle';
import AuthRenew from './components/Auth/AuthRenew';
import AuthLogout from './components/Auth/AuthLogout';
import UnauthorizedPage from '@pages/UnauthorizedPage';
import { StoreProvider, useStores } from './stores/context';
import * as mapUpdateClient from './api/mapupdate-client';
import * as onlinetrafficClient from './api/onlinetraffic-graphql';
import * as activationClient from './api/activation-client';
import * as featuredashboardClient from './api/featuredashboard-client';
import * as mapUpdateProxyClient from './api/mapupdate-proxy-client';
import * as onlineTrafficProxyClient from './api/onlinetraffic-proxy-client';
import * as featuredashboardProxyClient from './api/featuredashboard-proxy-client';
import * as mapUpdateGClient from './api/mapupdate-graphql';
import * as featureDashboardGClient from './api/featuredashboard-graphql';
import { observer } from 'mobx-react-lite';
import { Services } from './stores/service';
import FullPageLoader from './components/Common/Loader/FullPageLoader';
import './app.css';
import { INIT_URL_LINK } from './helpers/constants.jsx';
import i18n from './i18n';

const RouteWrapper = observer(() => {
    const [ready, setReady] = useState(false);
    const { authStore } = useStores();
    const init = useCallback(async () => {
        await authStore.init();
        mapUpdateClient.init();
        onlinetrafficClient.init();
        activationClient.init();
        mapUpdateGClient.init();
        mapUpdateProxyClient.init();
        onlineTrafficProxyClient.init();
        featureDashboardGClient.init();
        featuredashboardClient.init();
        featuredashboardProxyClient.init();
    }, [authStore]);

    useEffect(() => {
        // If link is provided before Login we set a SessionStorage Item that we will redirect to after Login.
        if (!authStore.isLoggedIn && !window.location.pathname.match(/(auth|^\/$)/i)) {
            sessionStorage.setItem(
                INIT_URL_LINK,
                window.location.pathname + (window.location.search ? window.location.search : ''),
            );
        }
        // let initURLprovided = window.location.href;
        init().then(() => setReady(true));
    }, [init]);
    // show full page loader while initialising
    if (!ready) return <FullPageLoader />;

    return !authStore.isLoggedIn ? (
        <BrowserRouter>
            <Routes>
                <Route path='/auth' element={<AuthPage />} />
                <Route path='/auth/logout' element={<AuthLogout />} />
                <Route path='/auth/handle' element={<AuthHandle />} />
                <Route path='/*' element={<Navigate to='/' />} />
                <Route exact path='/' element={<Navigate to='/auth' />} />
            </Routes>
        </BrowserRouter>
    ) : (
        <BrowserRouter>
            <Routes>
                {authStore.hasAccess(Services.MapUpdate) && (
                    <Route path='maps/*' element={<MapUpdate />} />
                )}
                {authStore.hasAccess(Services.OnlineTraffic) && (
                    <Route path='online-traffic/*' element={<Activation />} />
                )}
                {authStore.hasAccess(Services.FeatureDashboard) && (
                    <Route path='feature-dashboard/*' element={<FeatureDashboard />} />
                )}
                {authStore.hasAccess(Services.RemoteMessage) && (
                    <Route path='remote-message/*' element={<RemoteMessage />} />
                )}
                <Route exact path='/unauthorized' element={<UnauthorizedPage />} />
                <Route exact path='/auth/logout' element={<AuthLogout />} />
                <Route path='/auth/renew' element={<AuthRenew />} />
                <Route path='/auth/handle' element={<AuthHandle />} />
                <Route exact path='/' element={<Navigate to={authStore.getRootURIForUser()} />} />
                <Route element={<NoMatch />} />
            </Routes>
        </BrowserRouter>
    );
});
const App = () => {
    return (
        <StoreProvider>
            <React.Suspense fallback={<FullPageLoader />}>
                <RouteWrapper />
            </React.Suspense>
        </StoreProvider>
    );
};

export default App;
