import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import NoMatch from '@pages/NoMatch';
import { useStores } from '@stores/context';
import { observer } from 'mobx-react-lite';
import { Services } from '@stores/service';
import DeviceListPage from '@pages/DeviceListPage';
import AnalyticsPage from "@components/Onlinetraffic/Analytics/AnalyticsPage.jsx";

export default observer(() => {
    const { serviceStore } = useStores();

    useEffect(() => {
        serviceStore.setCurrentService(Services.OnlineTraffic);
    });

    return (
        <Routes>
            <Route path='/' element={<Navigate to={`${Services.OnlineTraffic}/vehicles`} />} />
            <Route path='analytics' element={<AnalyticsPage />} />
            <Route path='vehicles' element={<DeviceListPage />}>
                <Route path=':id' element={<DeviceListPage />} />
            </Route>
            <Route path='*' element={<NoMatch />} />
        </Routes>
    );
});
