import { useStores } from '@stores/context.jsx';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

const VehicleDetailsGeneral = observer(() => {
    const { assetStore } = useStores();
    const { t } = useTranslation();
    return (
        assetStore.assetDetails && (
            <>
                <h5>{`${t("fotaone.general.general")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.vehicle.one")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, ['vehicles', '0', 'name'])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.vehicleId")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, ['vehicles', '0', 'id'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.vinShort")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, ['vehicles', '0', 'vin'])}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    );
});

export default VehicleDetailsGeneral;
