import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import GroupTable from '@components/mapupdate/vehicles/groups/GroupTable';
import { useStores } from '@stores/context.jsx';
import { FilterStore } from '@stores/filter.jsx';
import { PaginationStore } from '@stores/pagination';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore();

export default observer(() => {
    const { serviceStore } = useStores();

    useEffect(() => {
        // When unmounting the page:
        return () => {
            filterStore.resetToDefaults();
            paginationStore.resetToDefaults();
        };
    }, [serviceStore]);
    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Header>
                <FotaHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className='bg-muted'>
                <FotaSidebar files={true} />
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer />
                {serviceStore.serviceMenuDialogOpen &&
                    <ServiceMenuDialog/>
                }
                <GroupTable paginationStore={paginationStore} filterStore={filterStore} />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
});
