import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import DetailsGeneral from '@components/RemoteMessage/Common/DetailsGeneral.jsx';
import DetailsTarget from '@components/RemoteMessage/Common/DetailsTarget.jsx';
import DetailsMessage from '@components/RemoteMessage/Common/DetailsMessage.jsx';
import DetailsProgress from '@components/RemoteMessage/Common/DetailsProgress.jsx';
import DetailsEvents from '@components/RemoteMessage/Common/DetailsEvents.jsx';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

const MyMessageDetail = (props) => {
  const { t } = useTranslation();
  const { remoteMessageStore } = useStores();
  const { messageId, setShowMyMessageConfirmationDialog, setConfirmationType } = props;
  const [targets, setTargets] = useState(get(remoteMessageStore.messageDetails, ['device_messages']));

  useEffect(() => {
    const fetchData = async () => {
      await remoteMessageStore.getMessageDetails(messageId);
      setTargets(get(remoteMessageStore.messageDetails, ['device_messages']));
    };
    fetchData();
  }, [messageId]);

  const ActionButton = ({ iconClass, buttonClass, label, onClick, disabled }) => (
    <button
      type='button'
      className={`btn ${buttonClass} margin-right-3`}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={`rioglyph ${iconClass}`} />
      {label}
    </button>
  );

  const renderActionButton = () => {
    switch (get(remoteMessageStore.messageDetails, ['status'])) {
      case 'DRAFT':
      case 'REJECTED':
        return (
          <>
            {get(remoteMessageStore.messageDetails, ['status']) === 'DRAFT' && (
              <ActionButton
                iconClass='rioglyph-send'
                buttonClass='btn-info'
                label={`${t("fotaone.rm.messageData.messageAction.requestApproval")}`}
                onClick={() => {
                  setConfirmationType('requestApproval');
                  remoteMessageStore.setEditMyMessage(remoteMessageStore.messageDetails);
                  setShowMyMessageConfirmationDialog(true);
                }}
                disabled={targets.length === 0}
              />
            )}
            <ActionButton
              iconClass='rioglyph-pencil'
              buttonClass='btn-primary'
              label={`${t("fotaone.rm.messageData.messageAction.editMessage")}`}
              onClick={() => {
                remoteMessageStore.setEditMyMessage(remoteMessageStore.messageDetails);
              }}
            />
            <ActionButton
              iconClass='rioglyph-trash'
              buttonClass='btn-danger'
              label={`${t("fotaone.rm.messageData.messageAction.deleteMessage")}`}
              onClick={() => {
                setConfirmationType('deleteMessage');
                remoteMessageStore.setEditMyMessage(remoteMessageStore.messageDetails);
                setShowMyMessageConfirmationDialog(true);
              }}
            />
          </>
        );
      case 'PENDING_APPROVAL':
        return (
          <ActionButton
            iconClass='rioglyph-remove'
            buttonClass='btn-secondary'
            label={`${t("fotaone.rm.messageData.messageAction.cancelApprovalRequest")}`}
            onClick={() => {
              setConfirmationType('cancelRequestApproval');
              remoteMessageStore.setEditMyMessage(remoteMessageStore.messageDetails);
              setShowMyMessageConfirmationDialog(true);
            }}
          />
        );
      case 'SENDING_FAILED':
        return (
          <ActionButton
            iconClass='rioglyph-refresh'
            buttonClass='btn-warning'
            label={`${t("fotaone.rm.messageData.messageAction.resendMessage")}`}
            onClick={() => null}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='message-details'>
      <div className='flex-1-1'>
        <div className='display-flex justify-content-end align-items-start margin-bottom-10'>
          {renderActionButton()}
        </div>
      </div>
      <div className='details'>
        <DetailsGeneral />
        <DetailsTarget />
        <DetailsProgress />
        <DetailsMessage />
        <DetailsEvents />
      </div>
    </div>
  );
};

export default observer(MyMessageDetail);