import React from 'react';
import { toJS } from 'mobx';
import { AssetStore } from './mapupdate/assets';
import { VehicleStore } from './vehicles';
import { MapRolloutStore } from './mapRollouts.jsx';
import { FilesStore } from './files.jsx';
import { UIStore } from './ui';
import { ServiceStore } from './service';
import { LabelStore } from './label';
import { ActivationStore } from './activations';
import { AuthStore } from './auth';
import { GroupStore } from './groups.jsx';
import { CampaignStore } from './campaigns';
import { PaginationStore } from './pagination';
import StateMachineStore from './stateMachine';
import { ExportsStore } from './exports';
import { FeatureDashboardStore } from '@stores/featureDashboard.jsx';
import { RemoteMessageStore } from '@stores/remoteMessage.jsx';

export const StoreContext = React.createContext();

export const init = () => {
    const assetStore = AssetStore.instance(new StateMachineStore(), new StateMachineStore());
    const vehicleStore = VehicleStore.instance(new StateMachineStore(), new StateMachineStore());
    const mapRolloutStore = MapRolloutStore.instance();
    const filesStore = FilesStore.instance(new StateMachineStore(), new StateMachineStore(), new StateMachineStore());
    const uiStore = UIStore.instance();
    const serviceStore = ServiceStore.instance();
    const labelStore = LabelStore.instance();
    const activationStore = ActivationStore.instance(new StateMachineStore());
    const authStore = AuthStore.instance();
    const groupStore = GroupStore.instance();
    const campaignStore = CampaignStore.instance(new StateMachineStore());
    const paginationStore = PaginationStore.instance();
    const exportsStore = ExportsStore.instance(new StateMachineStore(), vehicleStore, assetStore);
    const featureDashboardStore = FeatureDashboardStore.instance(new StateMachineStore());
    const remoteMessageStore = RemoteMessageStore.instance(new StateMachineStore());
    const stores = {
        assetStore,
        vehicleStore,
        mapRolloutStore,
        filesStore,
        uiStore,
        serviceStore,
        labelStore,
        activationStore,
        authStore,
        groupStore,
        campaignStore,
        paginationStore,
        exportsStore,
        featureDashboardStore,
        remoteMessageStore,
    };

    // expose the stores to the global object to aid debugging
    global.stores = stores;
    global.toJS = toJS;
    return stores;
};

export const useStores = () => {
    const store = React.useContext(StoreContext);
    if (!store) {
        throw new Error('useStore must be used within a StoreProvider.');
    }

    return store;
};

export const StoreProvider = ({ children }) => {
    const store = init();
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
