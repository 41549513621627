import { isNil } from 'lodash';

export const cloneObject = (obj) => {
    try {
        if (Array.isArray(obj)) {
            const result = obj.map((a) => {
                return { ...a };
            });
            return result;
        } else return JSON.stringify(JSON.parse(obj));
    } catch (err) {
        return obj;
    }
};

export const flattenObj = (value, currentKey, depth) => {
    let result = {};
    Object.keys(value).forEach((key) => {
        const tempKey = currentKey ? `${currentKey}.${key}` : key;
        if (typeof value[key] !== 'object' || isNil(value[key])) {
            result[tempKey] = value[key];
        } else {
            result = { ...result, ...flattenObj(value[key], tempKey) };
        }
    });
    return result;
};
