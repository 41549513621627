import React from 'react';
import { useTranslation } from 'react-i18next';

function FilterFooter(props) {
    const { t } = useTranslation();
    const {
        onConfirm = () => {
            /* */
        },
    } = props;
    return (
        <button type='button' className='btn btn-default' onClick={onConfirm}>
            {`${t("fotaone.general.apply")}`}
        </button>
    );
}

export default FilterFooter;
