import { useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import Pagination from '@common/Pagination/Pagination.jsx';
import Table from '@common/Table/Table.jsx';
import { setQueryParamToUrl } from '@components/mapupdate/mapupdateHelper/utils.jsx';
import { messagesTableData } from '@components/RemoteMessage/RemoteMessageHelper/constants.jsx';
import { useStores } from '@stores/context.jsx';

const MessagesTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceStore, remoteMessageStore } = useStores();
  const { paginationStore, filterStore } = props;
  const query = filterStore?.query;
  const activePage = paginationStore?.activePage;
  const limit = paginationStore?.limit;
  const offset = paginationStore?.offset;
  const sortBy = filterStore?.sortBy;
  const sortDir = filterStore?.sortDir;

  const getMessagesList = useCallback(
    debounce(async(params) => {
      await remoteMessageStore.getMessagesList(params);
      paginationStore.setItemsLength(remoteMessageStore.messagesListTotal);
    }, 200),[],
  );

  useEffect(() => {
    getMessagesList({
      query,
      sortBy,
      sortDir,
      limit,
      offset
    });
  }, [
    query,
    sortBy,
    sortDir,
    limit,
    activePage,
    offset
  ]);

  const rowClicked = (row) => {
    navigate(`${serviceStore.currentService}/messages/${row.id}${location.search}`);
  };
  const handleSearchValueChange = (search) => {
    setQueryParamToUrl('query', search);
    filterStore.setQuery(search);
    paginationStore.setActivePage(1);
  };

  const handleSortChange = (_sortBy, _sortDir) => {
    filterStore.setSortBy(_sortBy);
    filterStore.setSortDir(_sortDir);
  };

  return (
    <div className='message-table'>
      <Table
        onRowClick={rowClicked}
        rowKey='id'
        selectedRow={props.selectedRow}
        showSearch={true}
        handleSearchValueChange={handleSearchValueChange}
        andleSortChange={handleSortChange}
        searchValue={query}
        data={remoteMessageStore.messagesList || []}
        columnDetails={messagesTableData.columnDetails}
        defaultColumnOrder={messagesTableData.defaultColumnOrder}
        columns={messagesTableData.columns}
        columnOrder={messagesTableData.columnsOrder}
        hiddenColumns={messagesTableData.hiddenColumns}
        columnLabels={messagesTableData.columnLabels}
        disabledColumns={messagesTableData.disabledColumns}
        loading={remoteMessageStore.messagesListLoading}
      />
      <Pagination paginationStore={paginationStore} />
    </div>
  );
};

export default observer(MessagesTable);