import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';

const GroupDropdown = observer((props) => {
    const [selectedGroup, setSelectedGroup] = useState(props.groupId ? props.groupId : null);
    const { groupStore, serviceStore } = useStores();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onSelect = (item) => {
        if (item.id === 'all') {
            navigate(`${serviceStore.currentService}/vehicles/`);
        } else {
            navigate(`${serviceStore.currentService}/vehicles/?group=${item.label}`);
        }

        setSelectedGroup(item.label);
    };

    useEffect(() => {
        groupStore.getGroups();
    }, [groupStore]);

    const groups = groupStore.groupList
        ? groupStore.groupList.map((group, id) => ({
              id: id.toString(),
              label: group.name,
              selected: group.name === selectedGroup,
          }))
        : [];

    return (
        <div className='form-inline margin-bottom-15'>
            <Select
                placeholder={`${t("fotaone.mu.groupData.filterGroups")}`}
                options={[{ id: 'all', label: `${t("fotaone.general.table.selectAll")}` }, ...groups]}
                onChange={onSelect}
            />
        </div>
    );
});

export default GroupDropdown;
