export const createPaginationArray = (
    isSizeGreaterThanDisplayedAmount,
    showingNumbers,
    size,
    projectedLastItemIndex,
    active,
    step,
    startNumber,
) => {
    // If size greater than displayed amount of buttons
    // Then: Display the buttons starting in the first number after the initial button
    // Otherwise: Display the buttons starting for the first number (1) until the last number is less or equal than the size
    // Clear all the number less than 0
    if (isSizeGreaterThanDisplayedAmount) {
        return [...new Array(showingNumbers)]
            .map((_, i) => {
                if (size < projectedLastItemIndex) return size - showingNumbers + i;
                else if (active > step) return active - step + i;
                else return i + startNumber;
            })
            .filter((value) => value > 1);
    } else {
        return [...new Array(showingNumbers)]
            .map((_, i) => {
                return i + 1 <= size ? i + 1 : -1;
            })
            .filter((value) => value > 0);
    }
};
