import React, { useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useNavigate } from 'react-router-dom';
import { get, isEmpty} from 'lodash';
import prettyBytes from 'pretty-bytes';
import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants';
import { getRegion } from '../mapupdate/mapupdateHelper/region.jsx';
import { useTranslation } from 'react-i18next';
import './ArchiveDetails.css';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';

export const ArchiveDetails = observer((props) => {
    const { id } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { serviceStore, filesStore } = useStores();
    const approvalState = get(filesStore.currentFile, ['maps', '0', 'approval_state']);
    const releasedByEmail = get(filesStore.currentFile, ['map_lifecycle', '0', 'released_by_email']);

    useEffect(() => {
        filesStore.getFileDetails(id);
    }, [id, filesStore]);

    const onClose = () => {
        navigate(`${serviceStore.currentService}/archive`);
    };

    const renderDetails = () => {
        if (!filesStore.currentFile || isEmpty(filesStore.currentFile)) {
            return <NotFoundState headline={`${t("fotaone.general.table.noData")}`} />;
        }
        return (
            <>
                <h5>{`${t("fotaone.general.general")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.displayName")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'display_name'])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileName")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'file', 'filename'])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileSize")}`}</label>
                            <span className='text-primary'>
                                {prettyBytes(get(filesStore.currentFile, ['maps', '0', 'file', 'size_bytes'], 0))}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.version")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'version_number'])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.preVersion")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'previous_version_number'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label className='display-block'>{`${t("fotaone.mu.fileData.fileChecksum")}`}</label>
                        <span className='text-primary'>
                            <pre>{get(filesStore.currentFile, ['maps', '0', 'file', 'checksum_sha256'])}</pre>
                        </span>
                    </div>
                </div>
                <h5>{`${t("fotaone.mu.fileData.fileParams.interactions")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.uploadedBy")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['map_lifecycle', '0', 'uploaded_by_email'])}
                            </span>
                        </div>
                        {releasedByEmail === null ? (
                            <div />
                        ) : approvalState === 'RELEASED' ? (
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.releasedBy")}`}</label>
                                <span className='text-primary'>{releasedByEmail}</span>
                            </div>
                        ) : (
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.unreleasedBy")}`}</label>
                                <span className='text-primary'>{releasedByEmail}</span>
                            </div>
                        )}
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.uploadedAt")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['map_lifecycle', '0', 'uploaded_at'])}
                            </span>
                        </div>
                        {releasedByEmail === null ? (
                            <div />
                        ) : approvalState === 'RELEASED' ? (
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.releasedAt")}`}</label>
                                <span className='text-primary'>
                                    {get(filesStore.currentFile, ['map_lifecycle', '0', 'released_at'])}
                                </span>
                            </div>
                        ) : (
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.unreleasedAt")}`}</label>
                                <span className='text-primary'>
                                    {get(filesStore.currentFile, ['map_lifecycle', '0', 'released_at'])}
                                </span>
                            </div>
                        )}
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.archivedAt")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'archived_at'])}
                            </span>
                        </div>
                    </div>
                </div>
                <h5>{`${t("fotaone.mu.fileData.fileParams.parameters")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.regionData.region.one")}`}</label>
                            <span className='text-primary'>
                                {getRegion(get(filesStore.currentFile, ['maps', '0', 'map_region_enum']))}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.state.fileState")}`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'approval_state'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.updateMandatory")}?`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'mandatory_update']) ? 'Yes' : 'No'}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.fileData.fileParams.updateRecommended")}?`}</label>
                            <span className='text-primary'>
                                {get(filesStore.currentFile, ['maps', '0', 'update_recommended']) ? 'Yes' : 'No'}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <DetailsSideBar
            title={`${t("fotaone.mu.fileData.fileDetailsArchive")}`}
            onClose={onClose}
            width={600}
            blurEffect={
                filesStore?.fileDetailsState?.isLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide
            }
            fly
        >
            {renderDetails()}
        </DetailsSideBar>
    );
});

export default ArchiveDetails;
