import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { cloneObject } from '@helpers/object.js';
import FilterBody from './FilterBody';
import FilterFooter from './FilterFooter';
function Filter(props) {
    const { filters, title, showFilter, onCancel, onConfirm, onHide, id, dataTestId } = props;
    const [localShowFilter, setLocalShowFilter] = useState(showFilter);
    const [localFilters, setLocalFilters] = useState(cloneObject(filters));

    const syncFiltersWithParent = useCallback(() => {
        setLocalFilters(cloneObject(filters));
    }, [filters]);

    useEffect(() => {
        syncFiltersWithParent();
        setLocalShowFilter(showFilter);
    }, [filters, showFilter, syncFiltersWithParent]);

    const handleHide = () => {
        syncFiltersWithParent();
        onHide?.();
    };
    const handleConfirmation = () => {
        handleHardClose();
        onConfirm?.(localFilters);
    };
    const handleCancelation = () => {
        handleHardClose();
        onCancel?.();
    };
    const handleHardClose = () => {
        setLocalShowFilter(false);
        handleHide();
    };

    const handleSelectionChange = (filters) => {
        setLocalFilters(filters);
    };

    return (
        <Dialog
            show={localShowFilter}
            title={title}
            bsSize={Dialog.SIZE_SM}
            body={<FilterBody defaultFilters={localFilters} onChange={handleSelectionChange} />}
            footer={<FilterFooter onConfirm={handleConfirmation} onCancel={handleCancelation} />}
            className='multiple-rollout'
            id={id}
            showCloseButton={true}
            onHide={handleHide}
            data-testid={dataTestId}
        />
    );
}

Filter.defaultProps = {
    filters: [],
    showFilter: false,
    title: '',
};
export default Filter;
