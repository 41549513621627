import React, { useEffect, useState } from 'react';
import { useStores } from '@stores/context.jsx';
import FullPageLoader from '@common/Loader/FullPageLoader';
import { Navigate } from 'react-router';

const AuthHandle = () => {
    const [loading, setLoading] = useState(true);
    const { authStore } = useStores();
    useEffect(() => {
        authStore.completeLogin().then(() => setLoading(false));
    }, [authStore]);
    return loading ? <FullPageLoader /> : <Navigate to={authStore.getRootURIForUser()} />;
};

export default AuthHandle;
