import { action, computed, observable, makeObservable } from 'mobx';

export const STATES = {
    INITIAL: 'initial',
    LOADING: 'loading',
    ERROR: 'error',
    LOADED: 'loaded',
};
export default class StateMachineStore {
    state = STATES.INITIAL;

    constructor() {
        makeObservable(this, {
            state: observable,
            setActivationsState: action,
            setInitialState: action,
            setIsLoadingState: action,
            setLoadedState: action,
            setErrorState: action,
            isLoading: computed,
            hasError: computed,
            isLoaded: computed,
        });
    }

    get isLoading() {
        return this.state === STATES.LOADING;
    }
    get hasError() {
        return this.state === STATES.ERROR;
    }
    get isLoaded() {
        return this.state === STATES.LOADED;
    }
    /**
     *
     * @param {'initial' | 'loading' | 'error' | 'loaded'} state
     */

    setActivationsState(state) {
        this.state = state;
    }
    setInitialState() {
        this.setActivationsState(STATES.INITIAL);
    }
    setIsLoadingState() {
        this.setActivationsState(STATES.LOADING);
    }
    setLoadedState() {
        this.setActivationsState(STATES.LOADED);
    }
    setErrorState() {
        this.setActivationsState(STATES.ERROR);
    }
}
