import React from 'react';
import { useTranslation } from 'react-i18next';

function FilterButton(props) {
    const { t } = useTranslation();
    const {
        onShowFilter = () => {
            /* */
        },
        id = null,
    } = props;
    return (
        <div className='margin-left-10'>
            <button type='button' className='btn btn-default ' onClick={onShowFilter} id={id}>
                <span className='rioglyph rioglyph-filter' />
                {`${t("fotaone.general.table.tableFilter")}`}
            </button>
        </div>
    );
}

export default FilterButton;
