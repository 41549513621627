import React from 'react';
import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { useTranslation } from 'react-i18next';

function TableHead(props) {
    const { t } = useTranslation();
    const { columns, actionItemRenderer, handleSortClick, hiddenColumns, sortBy, sortDir, actionColumn } = props;
    const filteredColumns = columns.filter((col) => !hiddenColumns.includes(col.key));
    const renderSortArrow = (column) => {
        if ('sortable' in column && column.sortable) {
            if (sortBy === column.key) {
                return <SortArrows direction={sortDir} />;
            }
            return <SortArrows />;
        }
        return null;
    };

    return (
        <thead>
            <tr>
                {filteredColumns.map((column) => (
                    <th
                        key={`${t(column.title)}`}
                        className={'user-select-none sort-column'}
                        /*
                            Workaround for disabling sorting per column.
                            Columns that should not be able to be sorted by should have the 'sortable' property in the columns array.
                            ref: Vehicle.constants.jsx -> columns[]
                            Main reason for that Workaround is: We dont use the standard implementation of the UI Kit Tables
                            See: https://uikit.developers.rio.cloud/#components/tables -> TableCardsSorting -> selectOptions -> disabled
                        */
                        onClick={
                            'sortable' in column && column.sortable
                                ? handleSortClick
                                : () => {
                                      /* */
                                  }
                        }
                        data-field={column}
                        data-sortby={column.key}
                        title={`${t(column.title)}`}
                    >
                        <span className={'display-flex'}>
                            {renderSortArrow(column)}
                            <span className={'ellipsis-1'}>{`${t(column.title)}`} </span>
                            {column.tooltip && (
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip
                                            id='tooltip'
                                            textAlignment='left'
                                            className='top white-space-preline'
                                            width='auto'
                                        >
                                            {`${t(column.tooltip)}`}
                                        </Tooltip>
                                    }
                                >
                                    <span className='margin-left-5 align-self-center rioglyph rioglyph-info-sign' />
                                </OverlayTrigger>
                            )}
                        </span>
                    </th>
                ))}
                {actionItemRenderer && <th className={actionColumn || 'table-action'} />}
            </tr>
        </thead>
    );
}

export default TableHead;
