import Table from '@common/Table/Table';
import { messageTemplateTableData } from '@components/RemoteMessage/RemoteMessageHelper/constants';
import { observer } from 'mobx-react-lite';
import CreateMessageTemplateDialog
  from '@components/RemoteMessage/Messages/Template/Table/CreateMessageTemplateDialog';
import { useStores } from '@stores/context.jsx';
import React, { useCallback, useEffect, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { debounce } from 'lodash';

const MessageTemplateTable = (props) => {
  const { remoteMessageStore } = useStores();
  const [showDeleteTemplateConfirmationDialog, setShowDeleteTemplateConfirmationDialog] = useState(false);

  const getMessageTemplates = useCallback(
    debounce(async() => {
      await remoteMessageStore.getMessageTemplates();
    }, 200),[],
  );

  useEffect(() => {
    getMessageTemplates();
  }, []);

  const renderActions = (row) => (
    <div className='text-right'>
      <span
        className='margin-right-10'
        onClick={(e) => {
            e.stopPropagation();
            remoteMessageStore.setEditMessageTemplate(row);
            remoteMessageStore.setMessageTemplateDialogOpen(true);
        }}
      >
        <span className='rioglyph rioglyph-pencil text-size-16 text-color-primary' />
      </span>
      <span
        onClick={(e) => {
          e.stopPropagation();
          remoteMessageStore.setEditMessageTemplate(row);
          setShowDeleteTemplateConfirmationDialog(true);
        }}
      >
        <span className='rioglyph rioglyph rioglyph-trash text-size-16 text-color-danger' />
      </span>
    </div>
  );

  return (
      <>
        <div className='app-file-table'>
          <Table
            toolbarElement={<CreateMessageTemplateDialog />}
            showSearch={true}
            data={remoteMessageStore.messageTemplatesList}
            columnDetails={messageTemplateTableData.columnDetails}
            defaultColumnOrder={messageTemplateTableData.defaultColumnOrder}
            columns={messageTemplateTableData.columns}
            columnOrder={messageTemplateTableData.columnsOrder}
            hiddenColumns={messageTemplateTableData.hiddenColumns}
            columnLabels={messageTemplateTableData.columnLabels}
            disabledColumns={messageTemplateTableData.disabledColumns}
            rowKey='id'
            actionColumn='buttons'
            actionItemRenderer={renderActions}
          />
        </div>
        {
          remoteMessageStore.editMessageTemplate &&
            <ConfirmationDialog
              show={showDeleteTemplateConfirmationDialog}
              title={'Delete Message Template'}
              content={`Are you sure you want to delete the message template named ${remoteMessageStore.editMessageTemplate.title}?`}
              bsSize={Dialog.SIZE_SM}
              onClickConfirm={async () => {
                await remoteMessageStore.deleteMessageTemplate(remoteMessageStore.editMessageTemplate.id)
                setShowDeleteTemplateConfirmationDialog(false);
                remoteMessageStore.setEditMessageTemplate(null);
              }}
              onClickCancel={() => {
                  setShowDeleteTemplateConfirmationDialog(false);
                  remoteMessageStore.setEditMessageTemplate(null);
              }}
              cancelButtonText={'Cancel'}
              confirmButtonText={<>{remoteMessageStore.messageTemplatesLoading && <span className="rioglyph rioglyph-spinner spinning" />} Yes</>}
              useOverflow
            />
        }
      </>
  );
};

export default observer(MessageTemplateTable)