import React, { useMemo } from 'react';

import { formatDate } from '@helpers/date.js';
import { Services } from '@stores/service.js';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';

const VehicleDetailsRegionPopover = (props) => {
    const { region, vehicleId } = props;
    const { assetStore, authStore } = useStores();
    const { t } = useTranslation();

    /**
     *
     * @param {'activate' | 'deactivate' | 'reset'} action
     */
    const hasOverridePermission = (action) => {
        switch (action) {
            case 'activate':
            case 'deactivate':
            case 'reset':
                return !assetStore.detailsState.isLoading && authStore.hasWriteAccess(Services.MapUpdate);
            default:
                return false;
        }
    };

    /**
     *
     * @param {string} vehicleId
     * @param {string} regionEnum
     */
    const activate = async (vehicleId, regionEnum) => {
        const hasPermission = hasOverridePermission('activate');
        if (hasPermission) {
            await assetStore.activate(vehicleId, regionEnum);
        }
    };

    /**
     * @param {string} vehicleId
     * @param {string} regionEnum
     */
    const deactivate = async (vehicleId, regionEnum) => {
        const hasPermission = hasOverridePermission('deactivate');
        if (hasPermission) {
            await assetStore.deactivate(vehicleId, regionEnum);
        }
    };

    /**
     * @param {string} vehicleId
     * @param {string} regionEnum
     */
    const reset = async (vehicleId, regionEnum) => {
        const hasPermission = hasOverridePermission('reset');
        if (hasPermission) {
            await assetStore.reset(vehicleId, regionEnum);
        }
    };

    const state = useMemo(() => {
        const response = {
            allowReset: false,
            allowActivate: false,
            allowDeactivate: false,
        };

        if (!region.source_active && hasOverridePermission('activate')) {
            response.allowActivate = true;
        } else if (region.source_active && hasOverridePermission('deactivate')) {
            response.allowDeactivate = true;
        }

        if (region.override && hasOverridePermission('reset')) {
            response.allowReset = true;
        }
        return response;
    }, [vehicleId]);

    return (
        <>
            <div className={'padding-10'}>
                {region.overridden_at && (
                    <div className='item border-bottom-only'>
                        <label>{`${t("fotaone.mu.regionData.overriddenAt")}`}</label>&nbsp;
                        <span>{formatDate(region.overridden_at)}</span>
                    </div>
                )}
                <div>
                    <label>{`${t("fotaone.general.vehicleData.assetId")}`}</label>&nbsp;
                    <span>{region.asset_id ? region.asset_id : 'N/A'}</span>
                </div>
                <div>
                    <label>{`${t("fotaone.mu.regionData.activatedBy")}`}</label>&nbsp;
                    {region.activation_source ? (
                        <div
                            className={`label label-filled label-condensed
                            ${
                                region.activation_source === 'MAN_NOW'
                                    ? 'label-primary'
                                    : region.activation_source === 'fota-backend'
                                    ? 'label-warning'
                                    : 'label-danger'
                            }`}
                        >
                            {region.activation_source === 'MAN_NOW'
                                ? 'Man Now'
                                : region.activation_source === 'fota-backend'
                                ? 'Fota'
                                : region.activation_source}
                        </div>
                    ) : (
                        <span>N/A</span>
                    )}
                </div>
                <div>
                    <label>{`${t("fotaone.mu.regionData.activatedAt")}`}</label>&nbsp;
                    <span>{formatDate(region.activated_at)}</span>
                </div>
                <div>
                    <label>{`${t("fotaone.mu.regionData.deactivatedAt")}`}</label>&nbsp;
                    <span>{formatDate(region.deactivated_at)}</span>
                </div>
            </div>
            <hr className={'margin-0'} />
            <div className={'padding-10'}>
                <div className='btn-toolbar'>
                    <button
                        type='button'
                        className={'btn btn-success btn-outline'}
                        disabled={!state.allowActivate}
                        onClick={() => activate(vehicleId, region.short)}
                    >
                        <span className='rioglyph rioglyph-ok' aria-hidden='true' />
                        <span className='text-capitalize'>{`${t("fotaone.general.activate")}`}</span>
                    </button>
                    <button
                        type='button'
                        className={'btn btn-danger btn-outline'}
                        disabled={!state.allowDeactivate}
                        onClick={() => deactivate(vehicleId, region.short)}
                    >
                        <span className='rioglyph rioglyph-remove' aria-hidden='true' />
                        <span className='text-capitalize'>{`${t("fotaone.general.deactivate")}`}</span>
                    </button>
                    <button
                        type='button'
                        className={'btn btn-info btn-outline'}
                        disabled={!state.allowReset}
                        onClick={() => reset(vehicleId, region.short)}
                    >
                        <span className='rioglyph rioglyph-refresh' aria-hidden='true' />
                        <span className='text-capitalize'>{`${t("fotaone.general.reset")}`}</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default VehicleDetailsRegionPopover;
