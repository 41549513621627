import {
  statusMap,
  severityData,
  typeData,
} from '@components/RemoteMessage/RemoteMessageHelper/constants.jsx';
import i18n from 'i18next';
import Badge from '@common/Badge/Badge.jsx';
import { languageData } from '@helpers/constants.jsx';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@helpers/date.js';

export const typeRender = (_, row) => {
  const { t } = useTranslation();
  const foundType = typeData.find(obj => Object.prototype.hasOwnProperty.call(obj, _));
  return foundType ? t(`fotaone.rm.messageData.messageType.${_}`) : null;
};

export const severityRender = (_, row) => {
  const { t } = useTranslation();
  return (
    <div>
      <span
        className={_ === 'HIGH' ? 'rioglyph rioglyph-angle-double-up text-color-danger' : _ === 'LOW' ? 'rioglyph rioglyph-angle-double-down text-color-info' : 'rioglyph rioglyph-cards-list text-color-warning'}
      />
      {t(`fotaone.rm.severityState.${_}`)}
    </div>
  );
};

export const serialNumberRenderer = (_, row) => {
  return (
      <div>
          <Badge 
                title={`${row.type}`}
                state={`${row.type?.toUpperCase()}`}
                color={
                  row.type.toUpperCase() === "CM4"
                    ? 'badge-secondary margin-1'
                    : 'badge-default margin-1'
                }
            />
            {` ${row.serial_number}`}
      </div>
  );
};

export const messageCounterRenderer = (_, row) => {
  const { t } = useTranslation();
  return (
    <div className={'text-color-primary'}>
      <span data-testid={'messageCounter'} className={'h4'}>
          {row?.device_messages_aggregate?.aggregate?.count}
      </span>
      <span>{` ${t("fotaone.rm.messageData.message.many")}`}</span>
    </div>
  );
};

export const getDefaultType = () => {
  return typeData[0];
};
export const getDefaultTypeValue = () => {
  return Object.keys(getDefaultType())[0];
};
export const getDefaultTemplateSeverity = () => {
    return templateSeverityData[1];
};
export const getDefaultTemplateSeverityValue = () => {
    return Object.keys(getDefaultTemplateSeverity())[0];
};

export function renderType() {
  const { t } = useTranslation();
  return typeData.map((obj) => {
    return (
      <option value={Object.keys(obj)} key={Object.keys(obj)}>
        {t(`fotaone.rm.messageData.messageType.${Object.keys(obj)}`)}
      </option>
    );
  });
}

export const getDefaultSeverity = () => {
    return severityData[1];
};
export const getDefaultSeverityValue = () => {
    return Object.keys(getDefaultSeverity())[0];
};

export function renderSeverity() {
    const { t } = useTranslation();
    return severityData.map((obj) => {
        return (
            <option value={Object.keys(obj)} key={Object.keys(obj)}>
                {t(`fotaone.rm.severityState.${Object.keys(obj)}`)}
            </option>
        );
    });
}

export const getDefaultLanguage = () => {
    const foundLanguage = languageData.find(lang => {
        const code = Object.keys(lang)[0];
        return code.endsWith(i18n?.resolvedLanguage?.toUpperCase());
    });
    if (foundLanguage) {
        return foundLanguage;
    } else {
        return { 'en-GB': 'English' };
    }
};

export const getDefaultLanguageValue = () => {
    return Object.keys(getDefaultLanguage())[0];
};

export function renderLanguage() {
  const { t } = useTranslation();
    return languageData.map((obj) => {
        return (
            <option value={Object.keys(obj)} key={Object.keys(obj)}>
                {t(obj[Object.keys(obj)])}
            </option>
        );
    });
}

export const renderMessageStatus = (_) => {
  const { t } = useTranslation();
  const { color, icon } = statusMap[_] || { color: 'black', icon: '' };
  return (
    <>
      <i className={`margin-right-5 text-size-18 ${icon} ${color}`}/>
      <span className={color}>{`${t(`fotaone.rm.messageData.messageStatus.${_}`)}`}</span>
    </>
  );
}

export const renderSentAt = (_) => {
  return (
    <span>{formatDate(_)}</span>
  )
}