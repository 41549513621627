import CampaignTable from './table/CampaignTable.jsx';
import React, { useEffect } from 'react';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import FotaHeader from '@components/Navbar/Navbar.jsx';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import CampaignDetailsSideBar from './sidebar/CampaignDetailsSideBar.jsx';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { FilterStore } from '@stores/filter.jsx';
import { PaginationStore } from '@stores/pagination.js';
import { useParams } from 'react-router-dom';
import CampaignKpi from './kpi/CampaignKpi.jsx';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore();
export default observer(() => {
    const { id: campaignId } = useParams();
    const { serviceStore } = useStores();

    useEffect(() => {
        // When unmounting the page:
        return () => {
            filterStore.resetToDefaults();
            paginationStore.resetToDefaults();
        };
    }, [serviceStore]);

    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Header>
                <FotaHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className='bg-muted'>
                <FotaSidebar files={true} />
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Sidebar className='right'>
                {campaignId && <CampaignDetailsSideBar campaignId={campaignId} />}
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer />
                {serviceStore.serviceMenuDialogOpen &&
                    <ServiceMenuDialog/>
                }
                <CampaignKpi />
                <CampaignTable
                    showSearch={true}
                    fetchFiles={true}
                    actionButtons={true}
                    selectedRow={campaignId}
                    filterStore={filterStore}
                />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
});
