import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { get } from 'lodash';
import { toUtcDate } from '@helpers/date.js';
import { ProgressBar } from '@common/ProgressBar/ProgressBar.jsx';
import { de, pt} from 'date-fns/locale';
import  i18n  from 'i18next';

export const verifyChecksumInstruction =
    'How to verify the checksum: \r\n - Windows: CertUtil -hashfile filename MD5 / CertUtil -hashfile filename SHA256 \r\n - Linux: md5sum filename / sha256sum filename \r\n - MacOS: md5 filename / shasum -a 256 filename';
export const timestampInformation = 'Hover over the timestamp to see detailed time-information.';
export const filenameInformation = 'Hover over the displayed name to see the full filename.';

export const wordShortenerRender = (value) => {
    return <span className='ellipsis-1 display-block'>{value}</span>;
};
export const distanceTimeRenderer = (timestamp) => {
    let locale;
    switch (i18n.resolvedLanguage){
        case "en":
            locale=null;
            break;
        case "de":
            locale=de;
            break;
        case "pt":
            locale=pt;
            break;
    }
    return timestamp ? <span title={timestamp}>{formatDistanceToNow(toUtcDate(timestamp), {locale: locale})} {`${i18n.t("fotaone.general.timeData.ago")}`}</span> : 'N/A';
};
export const filenameRender = (all_names) => {
    try {
        const name_array = all_names.split(' - ');
        const fileName = name_array[0];
        const displayName = name_array[1];
        return <span title={fileName}>{displayName}</span>;
    } catch (err) {
        console.error('filenameRender', err);
    }
};
export const filenameFromAllNames = (all_names) => {
    try {
        const fileName = all_names.split(' - ')[0];
        return [fileName];
    } catch (err) {
        console.error('filenameFromAllNames', err);
    }
};
export const fileStatusRenderer = (_, row, withNewLine = true) => {
    if (row.approval_state) {
        return (
            <>
                {withNewLine && <br />}
                <span
                    className={
                        row.approval_state === 'RELEASED'
                            ? 'label label-success label-condensed margin-left-4 label-info'
                            : 'label label-default label-condensed margin-left-4 label-info'
                    }
                    title={`${i18n.t("fotaone.mu.fileData.state.fileState")}`}
                >
                    {row.approval_state}
                </span>
            </>
        );
    } else {
        return (
            <>
                <br />
                <span className='label label-danger label-condensed margin-left-4 label-info' title={`${i18n.t("fotaone.mu.fileData.state.fileState")}`}>
                    {`${i18n.t("fotaone.general.table.noData")}`}
                </span>
            </>
        );
    }
};
export const eventRenderer = (payload) => {
    let rendered = payload;
    try {
        // attempt to deserialize payload
        const parsed = JSON.parse(payload);
        rendered = JSON.stringify(parsed, null, 2);
    } catch (err) {}
    return (
        <pre className='overflow-auto max-height-100 text-size-12' title={rendered}>
            {rendered}
        </pre>
    );
};
export const subjectRenderer = (_, row) => {
    const { subject, payload } = row;
    let rendered = subject;
    try {
        // attempt to deserialize payload
        const parsed = JSON.parse(payload);
        const state = get(parsed, ['state', 'state']);
        const command = get(parsed, ['command']);
        if (state) {
            rendered = (
                <>
                    {subject} ({state})
                </>
            );
        }
        if (command) {
            rendered = (
                <>
                    {subject} ({command})
                </>
            );
        }
    } catch (err) {}
    return <span className='word-break'>{rendered}</span>;
};
export const rolloutTimeoutBadge = (_, row) => {
    let locale;
    switch (i18n.resolvedLanguage){
        case "en":
            locale=null;
            break;
        case "de":
            locale=de;
            break;
        case "pt":
            locale=pt;
            break;
    }
    if (row.rollout_state.timeout_at && new Date().getTime() > toUtcDate(row.rollout_state.timeout_at).getTime()) {
        return (
            <span
                className='rioglyph rioglyph-warning-sign text-size-16 margin-left-4 text-color-rating-3'
                title={`${i18n.t("fotaone.mu.rolloutData.rolloutTimeoutWarning")} ${formatDistanceToNow(toUtcDate(row.rollout_state.timeout_at), { locale: locale})}`}
            />
        );
    }
}; /*
 * this uses the old assumption before multiple assets.
 * Todo: it is currently only used in campaignAssetTableData,
 *  which probably is leading to not show the testBadge of a Vehicle
 */
export const testBadgeRenderer = (_, row) => {
    if (row.is_test_fleet || row.device?.is_test_fleet) {
        return (
            <span className='label label-condensed margin-left-4 label-info' title={`${i18n.t("fotaone.general.tagData.testfleet")}`}>
                {`${i18n.t("fotaone.general.tagData.testfleet")}`}
            </span>
        );
    }
};

export const rolloutStatusRenderer = (_, row) => {
    const campaignKpiStatus = {
        STARTED: 'started',
        QUEUED: 'queued',
        SUCCEEDED: 'succeeded',
        FAILED: 'failed',
        WARNING: 'warning',
        CANCELLED: 'cancelled',
    };

    if (!row.rolloutsStarted_aggregate) {
        return <div>N/A</div>;
    }

    const rollouts_kpi = [
        {
            name: campaignKpiStatus.STARTED,
            count: row.rolloutsStarted_aggregate.aggregate.count,
            color: 'primary',
            animated: true,
            test_id: 'campaign_details_rolloutsStarted',
        },
        {
            name: campaignKpiStatus.QUEUED,
            count: row.rolloutsQueued_aggregate.aggregate.count,
            color: 'secondary',
            test_id: 'campaign_details_rolloutsQueued',
        },
        {
            name: campaignKpiStatus.SUCCEEDED,
            count: row.rolloutsSucceeded_aggregate.aggregate.count,
            color: 'success',
            test_id: 'campaign_details_rolloutsSucceeded',
        },
        {
            name: campaignKpiStatus.WARNING,
            count: row.rolloutsWarning_aggregate.aggregate.count,
            color: 'warning',
            test_id: 'campaign_details_rolloutsWarning',
        },
        {
            name: campaignKpiStatus.FAILED,
            count: row.rolloutsFailed_aggregate.aggregate.count,
            color: 'danger',
            test_id: 'campaign_details_rolloutsFailed',
        },
        {
            name: campaignKpiStatus.CANCELLED,
            count: row.rolloutsCancelled_aggregate.aggregate.count,
            color: 'gray',
            test_id: 'campaign_details_rolloutsCancelled',
        },
    ];

    return (
        <ProgressBar
            title={`${i18n.t("fotaone.mu.rolloutData.rollout.many")}`}
            totalCount={row.rollouts_aggregate.aggregate.count}
            statuses={rollouts_kpi}
            showLabel={false}
            size={'small'}
            testId={'campaign-rollouts-kpi'}
        />
    );
};

export const removeParamFromIrl = (key) => {
    const url = window.location.toString();
    let rtn = url.split('?')[0];
    let param;
    let params_arr = [];
    const queryString = url.indexOf('?') !== -1 ? url.split('?')[1] : '';
    if (queryString !== '') {
        params_arr = queryString.split('&');
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split('=')[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = `${rtn}?${params_arr.join('&')}`;
    }
    window.history.replaceState({}, document.title, rtn);
};

export const setQueryParamToUrl = (name, value) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    params.set(name, value);
    url.search = params.toString();
    window.history.replaceState({}, document.title, url);
};
