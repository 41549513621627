import { observable, action, computed, makeObservable } from 'mobx';
import { gql } from '@apollo/client';
import { client as restApiClient } from '@api/mapupdate-client';
import { client as graphqlClient } from '@api/mapupdate-graphql';
import { isEqual, setWith } from 'lodash';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import  i18n  from 'i18next';

let instance; // singleton instance

export const GET_GROUPS = gql`
    query GetGroups($query: String = "%", $limit: Int, $orderBy: [mu_groups_order_by!] = {}, $offset: Int = 0) {
        mu {
            groups(limit: $limit, offset: $offset, where: { name: { _ilike: $query } }, order_by: $orderBy) {
                created_at
                description
                id
                name
                vehicles_groups {
                    group_id
                    vehicle_id
                    vehicle {
                        name
                        vin
                        assets {
                            asset_id
                        }
                        devices {
                            id
                            formatted_serial_no
                        }
                    }
                }
            }
            groups_aggregate(where: { name: { _ilike: $query } }) {
                aggregate {
                    count
                }
            }
        }
    }
`;

export class GroupStore {
    group = {}
    groupList = [];
    groupTotal = 0;
    groupsLoadingDeps = false;
    groupDeleteLoading = false;

    constructor() {
        makeObservable(this, {
            group: observable,
            groupList: observable,
            groupsLoadingDeps: observable,
            groupDeleteLoading: observable,
            groupsLoading: computed,
            setGroupsLoading: action,
            unsetGroupsLoading: action,
            getGroups: action,
            createGroup: action,
        });
    }
    resetGroups() {
        this.group = {};
        this.groupList = [];
        this.groupTotal = 0;
    }
    get groupsLoading() {
        return Boolean(this.groupsLoadingDeps);
    }
    setGroupsLoading(deps = {}) {
        this.groupsLoadingDeps = deps;
    }
    unsetGroupsLoading(deps) {
        if (!deps || isEqual(this.groupsLoadingDeps, deps)) {
            this.groupsLoadingDeps = false;
        }
    }

    async getGroups(params = {}) {
        const { query, offset, limit, sortDir, sortBy } = params;
        const requestId = Math.random();
        const variables = {
            query: query ? `%${query}%` : '%%',
            limit,
            offset,
        };
        if (sortBy) {
            variables.orderBy = {};
            setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
        }
        try {
            this.setGroupsLoading(requestId);
            const res = await graphqlClient.query({ query: GET_GROUPS, variables, fetchPolicy: 'no-cache' });
            const {
                groups,
                groups_aggregate: { aggregate: { count: total } },
            } = res.data.mu;

            this.groupTotal = total;
            this.groupList = groups;
            return this.groupList;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.groups.fetchGroups")}`);
            }
        } finally {
            this.unsetGroupsLoading(requestId);
        }
    }

    async createGroup(group) {
        try {
            const res = await restApiClient.createGroup(null, group);
            this.getGroups();
            Notification.success(`${i18n.t("fotaone.notification.success.groups.createGroup")} (${group.name})`);
            return res;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.groups.createGroup")} (${group.name})`);
            }
        }
    }

    async addToGroup(data) {
        try {
            const res = await restApiClient.addVehicleToGroup(data);
            Notification.success(`${i18n.t("fotaone.notification.success.groups.addedGroup")} (${data.vehicleId})`);
            return res;
        } catch (err) {
            Notification.error(`${i18n.t("fotaone.notification.error.groups.addedGroup")} (${data.groupId})`);
        }
    }
    async removeFromGroup(data) {
        try {
            const res = await restApiClient.removeVehicleFromGroup(data);
            Notification.success(`${i18n.t("fotaone.notification.success.groups.removedGroup")} (${data.vehicleId})`);
            return res;
        } catch (err) {
            Notification.error(`${i18n.t("fotaone.notification.error.groups.removedGroup")} (${data.groupId})`);
        }
    }

    async deleteGroup(data) {
        try {
            this.setGroupDeleteLoading(true);
            const res = await restApiClient.deleteGroup(data);
            this.getGroups();
            Notification.success(`${i18n.t("fotaone.notification.success.groups.deleteGroup")}`);
            this.setGroup({});
            return res;
        } catch (err) {
            Notification.error(`${i18n.t("fotaone.notification.error.groups.deleteGroup")}`);
        }finally{
            this.setGroupDeleteLoading(false);
        }
    }

    setGroup(group) {
        this.group = group;
    }

    setGroupDeleteLoading(state) {
        this.groupDeleteLoading = Boolean(state);
    }

    static instance() {
        if (!instance) {
            instance = new GroupStore();
        }
        return instance;
    }
}
