import React, { useEffect, useState } from 'react';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useNavigate } from 'react-router-dom';
import prettyBytes from 'pretty-bytes';
import { get, isEmpty } from 'lodash';
import Table from '../../../../Common/Table/Table.jsx';
import DetailsSideBar from '../../../../Common/DetailsSideBar/DetailsSideBar.jsx';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants.js';
import { getRegion } from '../../../mapupdateHelper/region.jsx';
import {
    campaignRolloutsData,
    campaignsAssetTableData,
    campaignStatus,
    campaignUpdatedVehiclesData,
} from '../Campaign.constants.jsx';
import { groupTableData, statusColorCode } from '../../../mapupdateHelper/constants.jsx';
import { ProgressBar } from '@common/ProgressBar/ProgressBar.jsx';
import { formatDistanceToNow } from 'date-fns';
import { de, pt} from 'date-fns/locale';
import { toUtcDate } from '@helpers/date.js';
import { useTranslation } from 'react-i18next';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

const CampaignDetailsSideBar = observer((props) => {
    // INIT
    const { serviceStore, campaignStore, authStore } = useStores();
    const { campaignId } = props;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [locale, setLocale] = useState();
    const [showStartCampaignConfirmDialog, setShowStartCampaignConfirmDialog] = useState(false);
    const [showStopCampaignConfirmDialog, setShowStopCampaignConfirmDialog] = useState(false);
    const [showPauseCampaignConfirmDialog, setShowPauseCampaignConfirmDialog] = useState(false);
    const [showResumeCampaignConfirmDialog, setShowResumeCampaignConfirmDialog] = useState(false);
    const [showDeleteCampaignConfirmDialog, setShowDeleteCampaignConfirmDialog] = useState(false);
    const campaignIsCreated = campaignStore.currentCampaign.status === campaignStatus.CREATED;
    const campaignIsStarted = campaignStore.currentCampaign.status === campaignStatus.STARTED;
    const campaignIsPaused = campaignStore.currentCampaign.status === campaignStatus.PAUSED;
    const campaignIsStopped = campaignStore.currentCampaign.status === campaignStatus.STOPPED;
    const campaignCanBeDeleted =
        authStore.roles.includes('PO') &&
        (campaignStore.currentCampaign.status === campaignStatus.CREATED ||
            campaignStore.currentCampaign.status === campaignStatus.STOPPED);

    const getLanguage=(browserLanguage)=>{
        switch (browserLanguage){
            case "en":
                setLocale();
                break;
            case "de":
                setLocale(de);
                break;
            case "pt":
                setLocale(pt);
                break;
        }
    }
    // USE EFFECTS
    useEffect(() => {
        campaignStore.getCampaignDetails(campaignId);
        getLanguage(i18n.resolvedLanguage);
    }, [campaignId, campaignStore, i18n.resolvedLanguage]);

    // HANLDERS
    const onClose = () => {
        navigate(`${serviceStore.currentService}/campaigns`);
    };

    const onStartCampaignConfirmation = async () => {
        setShowStartCampaignConfirmDialog(false);
        await campaignStore.startCampaign();
    };

    const onDeleteCampaignConfirmation = async () => {
        setShowDeleteCampaignConfirmDialog(false);
        await campaignStore.deleteCampaign();
        onClose();
    };

    const onStopCampaignConfirmation = async () => {
        setShowStopCampaignConfirmDialog(false);
        await campaignStore.stopCampaign();
    };

    const onPauseCampaignConfirmation = async () => {
        setShowPauseCampaignConfirmDialog(false);
        await campaignStore.pauseCampaign();
    };

    const onResumeCampaignConfirmation = async () => {
        setShowResumeCampaignConfirmDialog(false);
        await campaignStore.resumeCampaign();
    };

    const onVehicleClick = (row) => {
        navigate(`${serviceStore.currentService}/assets/${row.asset_id}`);
    };

    const setStatusColor = (status) => {
        return statusColorCode[status] || statusColorCode['UNKNOWN'];
    };

    // RENDERS
    const renderDetails = () => {
        if (!campaignStore.currentCampaign || isEmpty(campaignStore.currentCampaign)) {
            return <NotFoundState headline={`${t("fotaone.general.table.noData")}`} />;
        }
        return (
            <>
                <ConfirmationDialog
                    show={showStartCampaignConfirmDialog}
                    title={`${t("fotaone.mu.campaignData.startCampaign")}`}
                    content={`${t("fotaone.mu.campaignData.startCampaignInfo")} ${campaignStore.currentCampaign.id}?`}
                    bsSize={Dialog.SIZE_SM}
                    onClickConfirm={onStartCampaignConfirmation}
                    onClickCancel={() => setShowStartCampaignConfirmDialog(false)}
                    cancelButtonText={`${t("fotaone.general.abort")}`}
                    confirmButtonText={`${t("fotaone.general.confirm")}`}
                    useOverflow
                />
                <ConfirmationDialog
                    show={showPauseCampaignConfirmDialog}
                    title={`${t("fotaone.mu.campaignData.pauseCampaign")}`}
                    content={`${t("fotaone.mu.campaignData.pauseCampaignInfo")} ${campaignStore.currentCampaign.id}?`}
                    bsSize={Dialog.SIZE_SM}
                    onClickConfirm={onPauseCampaignConfirmation}
                    onClickCancel={() => setShowPauseCampaignConfirmDialog(false)}
                    cancelButtonText={`${t("fotaone.general.abort")}`}
                    confirmButtonText={`${t("fotaone.general.confirm")}`}
                    useOverflow
                />
                <ConfirmationDialog
                    show={showResumeCampaignConfirmDialog}
                    title={`${t("fotaone.mu.campaignData.resumeCampaign")}`}
                    content={`${t("fotaone.mu.campaignData.resumeCampaignInfo")} ${campaignStore.currentCampaign.id}?`}
                    bsSize={Dialog.SIZE_SM}
                    onClickConfirm={onResumeCampaignConfirmation}
                    onClickCancel={() => setShowResumeCampaignConfirmDialog(false)}
                    cancelButtonText={`${t("fotaone.general.abort")}`}
                    confirmButtonText={`${t("fotaone.general.confirm")}`}
                    useOverflow
                />
                <ConfirmationDialog
                    show={showStopCampaignConfirmDialog}
                    title={`${t("fotaone.mu.campaignData.stopCampaign")}`}
                    content={`${t("fotaone.mu.campaignData.stopCampaignInfo")} ${campaignStore.currentCampaign.id}?`}
                    bsSize={Dialog.SIZE_SM}
                    onClickConfirm={onStopCampaignConfirmation}
                    onClickCancel={() => setShowStopCampaignConfirmDialog(false)}
                    cancelButtonText={`${t("fotaone.general.abort")}`}
                    confirmButtonText={`${t("fotaone.general.confirm")}`}
                    useOverflow
                />
                <ConfirmationDialog
                    show={showDeleteCampaignConfirmDialog}
                    title={`${t("fotaone.mu.campaignData.deleteCampaign")}`}
                    content={`${t("fotaone.mu.campaignData.deleteCampaignInfo")} ${campaignStore.currentCampaign.id}?`}
                    bsSize={Dialog.SIZE_SM}
                    onClickConfirm={onDeleteCampaignConfirmation}
                    onClickCancel={() => setShowDeleteCampaignConfirmDialog(false)}
                    cancelButtonText={`${t("fotaone.general.abort")}`}
                    confirmButtonText={`${t("fotaone.general.confirm")}`}
                    useOverflow
                />
                <div>
                    <button
                        type='button'
                        className='btn btn-primary margin-bottom-10 margin-right-10'
                        disabled={campaignIsStarted || campaignIsPaused}
                        onClick={() => setShowStartCampaignConfirmDialog(true)}
                    >
                        <span className='rioglyph rioglyph-play' aria-hidden='true' />
                            {`${t("fotaone.mu.start")}`}
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary margin-bottom-10 margin-right-10'
                        disabled={campaignIsCreated || campaignIsStopped}
                        onClick={() =>
                            campaignIsPaused
                                ? setShowResumeCampaignConfirmDialog(true)
                                : setShowPauseCampaignConfirmDialog(true)
                        }
                        data-testid='pauseResumeCampaignButton'
                    >
                        <span
                            className={
                                campaignIsPaused ? 'rioglyph rioglyph-angle-double-right' : 'rioglyph rioglyph-pause'
                            }
                            aria-hidden='true'
                        />
                        {campaignIsPaused ? `${t("fotaone.mu.resume")}` : `${t("fotaone.mu.pause")}`}
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary margin-bottom-10 margin-right-10'
                        disabled={!campaignIsStarted}
                        onClick={() => setShowStopCampaignConfirmDialog(true)}
                    >
                        <span className='rioglyph rioglyph-remove' aria-hidden='true' />
                        {`${t("fotaone.mu.stop")}`}
                    </button>
                    {campaignCanBeDeleted && (
                        <button
                            data-testid='deleteCampaignButton'
                            type='button'
                            className='btn btn-danger margin-bottom-10 margin-right-10'
                            onClick={() => setShowDeleteCampaignConfirmDialog(true)}
                        >
                            <span className='rioglyph rioglyph-trash' aria-hidden='true' />
                            {`${t("fotaone.mu.delete")}`}
                        </button>
                    )}
                    <button
                        type='button'
                        className='btn btn-primary pull-right margin-bottom-10 margin-right-10'
                        onClick={() => {
                            campaignStore.getCampaignDetails(campaignId);
                        }}
                    >
                        <span className='rioglyph rioglyph-refresh' />
                        {`${t("fotaone.mu.refreshData")}`}
                    </button>
                </div>
                <h6>{`${t("fotaone.mu.campaignData.campaign.one")}`}</h6>
                <div className='breakline' />
                <table className='table table-condensed margin-bottom-20'>
                    <colgroup>
                        <col className='width-25pct' />
                    </colgroup>
                    <tbody>
                        <tr className={'border-bottom-0 cursor-default'}>
                            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.general.name")}`}</td>
                            <td className={'border-top-0'}>{campaignStore.currentCampaign.campaign_name}</td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray'}>ID:</td>
                            <td data-testid='campaignId'>{campaignStore.currentCampaign.id}</td>
                        </tr> 
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray'}>{`${t("fotaone.general.status")}`}</td>
                            <td className={setStatusColor(campaignStore.currentCampaign.status)}>
                                {`${t(`fotaone.mu.campaignData.state.${campaignStore.currentCampaign.status.toLowerCase()}`).toUpperCase()}`}
                            </td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.mu.createdAt")}`}</td>
                            <td>{formatDistanceToNow(toUtcDate(campaignStore.currentCampaign.created_at), { locale: locale})} {`${t("fotaone.general.timeData.ago")}`}</td>
                        </tr>
                    </tbody>
                </table>
                <h6>{`${t("fotaone.mu.fileData.map.one")}`}</h6>
                <div className='breakline' />
                <table className='table table-condensed margin-bottom-20'>
                    <colgroup>
                        <col className='width-25pct' />
                    </colgroup>
                    <tbody>
                        <tr className={'border-bottom-0 cursor-default'}>
                            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.mu.regionData.region.one")}`}</td>
                            <td className={'border-top-0'}>
                                {getRegion(get(campaignStore.currentCampaign, 'map.map_region_enum'))}
                            </td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.mu.fileData.fileName")}`}</td>
                            <td>{get(campaignStore.currentCampaign, 'map.file.filename')}</td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.mu.fileData.displayName")}`}</td>
                            <td>{get(campaignStore.currentCampaign, 'map.display_name')}</td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.mu.fileData.version")}`}</td>
                            <td>{get(campaignStore.currentCampaign, 'map.version_number')}</td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.mu.fileData.preVersion")}`}</td>
                            <td>{get(campaignStore.currentCampaign, 'map.previous_version_number')}</td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.general.status")}`}</td>
                            <td>
                                <span
                                    className={
                                        get(campaignStore.currentCampaign, 'map.approval_state') === 'RELEASED'
                                            ? 'label label-success label-condensed margin-left-4 label-info'
                                            : 'label label-default label-condensed margin-left-4 label-info'
                                    }
                                    title='State'
                                >
                                    {get(campaignStore.currentCampaign, 'map.approval_state')}
                                </span>
                            </td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.mu.fileData.fileSize")}`}</td>
                            <td>{prettyBytes(get(campaignStore.currentCampaign, 'map.file.size_bytes', 0))}</td>
                        </tr>
                    </tbody> 
                </table>
                <h6>{`${t("fotaone.mu.groupData.group.one")}`} / {`${t("fotaone.general.vehicleData.vehicle.many")}`}</h6>
                <div className='breakline' />
                <table className='table table-condensed margin-bottom-20'>
                    <colgroup>
                        <col className='width-25pct' />
                    </colgroup>
                    <tbody>
                        <tr className={'border-bottom-0 cursor-default'}>
                            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.mu.groupData.groupName")}`}</td>
                            <td className={'border-top-0'}>{campaignStore.currentCampaign.group_name}</td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.general.vehicleData.vehiclesSelected.amount")}`}</td>
                            <td>{campaignStore.currentCampaign.total_vehicles_whitelisted}</td>
                        </tr>
                        <tr className={'cursor-default'}>
                            <td className={'align-top text-color-gray '}>{`${t("fotaone.mu.groupData.groupsSelected.amount")}`}</td>
                            <td>{campaignStore.currentCampaign.total_groups_whitelisted}</td>
                        </tr>
                    </tbody>
                </table>
                <h6>{`${t("fotaone.mu.rolloutData.rolloutStatus")}`}</h6>
                <div className='breakline margin-bottom-10' />
                <ProgressBar
                    title={`${t("fotaone.mu.rolloutData.rollout.many")}`}
                    totalCount={campaignStore.currentCampaign.rollouts_count}
                    statuses={campaignStore.currentCampaign.rollouts_kpi}
                    showLabel={true}
                    size={'large'}
                    testId={'campaign-details-rollouts-kpi'}
                    currentService={serviceStore.currentService}
                />
                {renderVehicles()}
            </>
        );
    };

    const renderVehicles = () => {
        const updated_vehicles = campaignStore.currentCampaign.updated_vehicles || [];
        return (
            <div>
                <ExpanderPanel
                    title={<h6>{`${t("fotaone.general.vehicleData.eligibleVehicles")}`}</h6>}
                    headerClassName={'padding-0'}
                    bodyClassName={'padding-0'}
                    onToggle={() => {
                        campaignStore.getCampaignEligibleVehicles();
                    }}
                    open={false}
                >
                    <div className='breakline margin-bottom-20' />
                    {campaignStore.campaignEligibleVehiclesLoading ? (
                      <Spinner show text='Loading' />
                    ) : (
                      <Table
                        showSearch={false}
                        data={campaignStore.campaignEligibleVehicles}
                        columnDetails={campaignsAssetTableData.columnDetails}
                        defaultColumnOrder={campaignsAssetTableData.defaultColumnOrder}
                        columns={campaignsAssetTableData.columns}
                        columnsOrder={campaignsAssetTableData.columnsOrder}
                        rowKey='vin'
                        />
                    )}
                </ExpanderPanel>
                <ExpanderPanel
                    title={<h6>{`${t("fotaone.general.vehicleData.whitelistedVehicles")}`}</h6>}
                    headerClassName={'padding-0'}
                    bodyClassName={'padding-0'}
                    onToggle={() => {
                        campaignStore.getCampaignWhitelistedVehicles();
                    }}
                    open={false}
                >
                    <div className='breakline margin-bottom-20' />
                    {campaignStore.campaignWhitelistedVehiclesLoading ? (
                      <Spinner show text='Loading' />
                    ) : (
                      <Table
                        showSearch={false}
                        data={campaignStore.campaignWhitelistedVehicles}
                        columnDetails={campaignsAssetTableData.columnDetails}
                        defaultColumnOrder={campaignsAssetTableData.defaultColumnOrder}
                        columns={campaignsAssetTableData.columns}
                        columnsOrder={campaignsAssetTableData.columnsOrder}
                        rowKey='vin'
                      />
                    )}

                </ExpanderPanel>
                <ExpanderPanel
                    title={<h6>{`${t("fotaone.mu.groupData.whitelistedGroups")}`}</h6>}
                    headerClassName={'padding-0'}
                    bodyClassName={'padding-0'}
                    onToggle={() => {
                        campaignStore.getCampaignFromGroupsFilter();
                    }}
                    open={false}
                >
                    <div className='breakline margin-bottom-20' />
                    {campaignStore.campaignFromGroupsFilterLoading ? (
                      <Spinner show text='Loading' />
                    ) : (
                      <Table
                        showSearch={false}
                        data={campaignStore.campaignFromGroupsFilter}
                        columnDetails={groupTableData.columnDetails}
                        defaultColumnOrder={groupTableData.defaultColumnOrder}
                        columns={groupTableData.columns}
                        columnsOrder={groupTableData.columnsOrder}
                        rowKey='vehicle_id'
                      />
                    )}
                </ExpanderPanel>
                <ExpanderPanel
                    title={<h6>{`${t("fotaone.mu.rolloutData.rollout.many")}`}</h6>}
                    headerClassName={'padding-0'}
                    bodyClassName={'padding-0'}
                    onToggle={() => {
                        campaignStore.getCampaignRollouts();
                    }}
                    open={false}
                >
                    <div className='breakline margin-bottom-20' />
                    {campaignStore.campaignRolloutsLoading ? (
                      <Spinner show text='Loading' />
                    ) : (
                      <Table
                        showSearch={false}
                        data={campaignStore.campaignRollouts}
                        columnDetails={campaignRolloutsData.columnDetails}
                        defaultColumnOrder={campaignRolloutsData.defaultColumnOrder}
                        columns={campaignRolloutsData.columns}
                        columnsOrder={campaignRolloutsData.columnsOrder}
                      />
                    )}
                </ExpanderPanel>
                <ExpanderPanel
                    title={<h6>{`${t("fotaone.general.vehicleData.vehicleStatus")}`}</h6>}
                    headerClassName={'padding-0'}
                    bodyClassName={'padding-0'}
                    onToggle={() => {
                        campaignStore.getCampaignUpdatedVehicles();
                    }}
                    open={false}
                >
                    <div className='breakline margin-bottom-20' />
                    {campaignStore.campaignUpdatedVehiclesLoading ? (
                      <Spinner show text='Loading' />
                    ) : (
                      <Table
                        showSearch={false}
                        data={campaignStore.campaignUpdatedVehicles}
                        onRowClick={onVehicleClick}
                        columnDetails={campaignUpdatedVehiclesData.columnDetails}
                        defaultColumnOrder={campaignUpdatedVehiclesData.defaultColumnOrder}
                        columns={campaignUpdatedVehiclesData.columns}
                        columnsOrder={campaignUpdatedVehiclesData.columnsOrder}
                        rowKey='vin'
                      />
                    )}
                </ExpanderPanel>
            </div>
        );
    };

    return (
        <DetailsSideBar
            title={`${t("fotaone.mu.campaignData.campaignDetails")}`}
            onClose={onClose}
            width={600}
            blurEffect={campaignStore.campaignDetailsLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide}
            fly
        >
            {renderDetails()}
        </DetailsSideBar>
    );
});

export default CampaignDetailsSideBar;
