import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const ServicesMenu = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='text-size-h2 text-thin'>
                {t("fotaone.fd.serviceData.service.many")} {t("fotaone.general.overview")}
            </div>
            <Divider />
            <div className='text-size-h5'>
                <span className="rioglyph rioglyph-route-view margin-right-10"/>
                <strong>Map Update (MU)</strong>
            </div>
            <p>
                {t("fotaone.general.serviceMenu.mapUpdateSection.serviceDesc")}
            </p>
            <div className='text-size-h5'>
                <span className="rioglyph rioglyph-drive-history margin-right-10"/>
                <strong>Online Traffic (OT)</strong>
            </div>
            <p>
                {t("fotaone.general.serviceMenu.onlineTrafficSection.serviceDesc")}
            </p>
            <div className='text-size-h5'>
                <span className="rioglyph rioglyph-filled-chart margin-right-10"/>
                <strong>Feature Dashboard (FD)</strong>
            </div>
            <p>
                {t("fotaone.general.serviceMenu.featureDashboardSection.serviceDesc")}
            </p>
            <div className='text-size-h5'>
                <span className="rioglyph rioglyph-comment margin-right-10"/>
                <strong>Remote Message (RM)</strong>
            </div>
            <p>
                {t("fotaone.general.serviceMenu.remoteMessageSection.serviceDesc")}
            </p>
        </>
    );
};

export default observer(ServicesMenu);