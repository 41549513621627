import { observable, action, computed, makeObservable } from 'mobx';
import { gql } from '@apollo/client';
import { Services, ServiceStore } from './service';
import { client as mapClient } from '@api/mapupdate-client';
import { client as mapUpdateGraphqlClient } from '@api/mapupdate-graphql';
import { setWith } from 'lodash';
import { getRegion } from '@components/mapupdate/mapupdateHelper/region.jsx';
import { v4 as uuidv4 } from 'uuid';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import  i18n  from 'i18next';

const serviceStore = ServiceStore.instance();
let instance; // singleton instance
const MAP_DETAILS_QUERY = gql`
    query MapDetailsQuery($_map_id: uuid = "") {
        mu {
            maps(where: { map_id: { _eq: $_map_id } }) {
                map_id
                display_name
                mandatory_update
                required_storage_size
                update_recommended
                version_number
                previous_version_number
                approval_state
                file {
                    filename
                    checksum_sha256
                    size_bytes
                    file_id
                }
                map_region_enum
                archived_at
            }
            map_lifecycle(where: { map_id: { _eq: $_map_id } }) {
                uploaded_at
                released_at
                released_by_email
                uploaded_by_email
            }
        }
    }
`;

export const MAP_FILES_QUERY = gql`
    query GetMapFiles(
        $query: String = "%"
        $limit: Int
        $offset: Int = 0
        $orderBy: [mu_maps_view_order_by!] = { created_at: desc }
    ) {
        mu {
            maps_view(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _or: [
                        { display_name: { _ilike: $query } }
                        { file_metadata: { filename: { _ilike: $query } } }
                        { map_region_enum: { _ilike: $query } }
                        { version_number: { _ilike: $query } }
                        { previous_version_number: { _ilike: $query } }
                    ]
                    is_archived: { _eq: false }
                }
            ) {
                id: map_id
                display_name
                version_number
                previous_version_number
                file_metadata {
                    file_id
                    filename
                    size_bytes
                    checksum_sha256
                }
                map_region_enum
                created_at
                archived_at
                is_rolled_out
                approval_state
                can_be_archived
                can_be_deleted
            }
            maps_view_aggregate(
                where: {
                    _or: [
                        { display_name: { _ilike: $query } }
                        { file_metadata: { filename: { _ilike: $query } } }
                        { map_region_enum: { _ilike: $query } }
                    ]
                    is_archived: { _eq: false }
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

export const MAP_ARCHIVE_QUERY = gql`
    query GetMapFiles(
        $query: String = "%"
        $limit: Int
        $offset: Int = 0
        $orderBy: [mu_maps_view_order_by!] = { archived_at: desc }
    ) {
        mu {
            maps_view(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _or: [
                        { display_name: { _ilike: $query } }
                        { file_metadata: { filename: { _ilike: $query } } }
                        { map_region_enum: { _ilike: $query } }
                        { version_number: { _ilike: $query } }
                        { previous_version_number: { _ilike: $query } }
                    ]
                    is_archived: { _eq: true }
                }
            ) {
                id: map_id
                display_name
                version_number
                previous_version_number
                file_metadata {
                    file_id
                    filename
                    size_bytes
                    checksum_sha256
                }
                map_region_enum
                archived_at
                created_at
                approval_state
            }
            maps_view_aggregate(
                where: {
                    _or: [
                        { display_name: { _ilike: $query } }
                        { file_metadata: { filename: { _ilike: $query } } }
                        { map_region_enum: { _ilike: $query } }
                    ]
                    is_archived: { _eq: true }
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

export class FilesStore {
    uploading = false;
    uploadPercentage = null;
    file = {};
    fileList = [];
    archiveList = [];
    shouldReload = 0;

    currentFile = {};
    editFile = null;
    currentFileLoading = false;
    fileUploadDialogOpen = false;
    storedPage;

    fileState = {};

    fileDetailsState = {};

    archiveState = {};

    get isLoading() {
        if (this.archiveState || this.fileState || this.fileDetailsState) {
            return this.archiveState?.isLoading() || this.fileState?.isLoading() || this.fileDetailsState?.isLoading();
        }
    }
    get isLoaded() {
        if (this.archiveState || this.fileState || this.fileDetailsState) {
            return this.archiveState?.isLoaded() || this.fileState?.isLoaded() || this.fileDetailsState?.isLoaded();
        }
    }

    // todo: Use Enum provided in the maps (maps_view) table.
    get archiveListToTable() {
        if (this.archiveList && Array.isArray(this.archiveList)) {
            return this.archiveList?.map((d) => {
                const obj = {
                    ...d,
                    all_names: `${d.file_metadata.filename} - ${d.display_name}`,
                    map_name: getRegion(d.map_region_enum),
                    map_checksum: d.file_metadata.checksum_sha256,
                    map_size: d.file_metadata.size_bytes,
                    disabled: false,
                };
                return obj;
            });
        } else {
            return [];
        }
    }

    get listToTable() {
        if (this.fileList && Array.isArray(this.fileList)) {
            return this.fileList?.map((d) => {
                const obj = {
                    ...d,
                    all_names: `${d.file_metadata.filename} - ${d.display_name}`,
                    map_name: getRegion(d.map_region_enum),
                    map_checksum: d.file_metadata.checksum_sha256,
                    map_size: d.file_metadata.size_bytes,
                };
                return obj;
            });
        } else {
            return [];
        }
    }

    get client() {
        if (serviceStore.currentService === Services.MapUpdate) {
            return mapClient;
        }
        return null;
    }

    get graphqlClient() {
        if (serviceStore.currentService === Services.MapUpdate) {
            return mapUpdateGraphqlClient;
        }
        return null;
    }

    setFileDialogOpen(state) {
        this.fileUploadDialogOpen = Boolean(state);
    }

    setEditFile(data) {
        this.editFile = data;
    }

    setFileList(data) {
        this.fileList = data;
    }

    setUploading(state) {
        this.uploading = Boolean(state);
    }

    /**
     *
     * @param {StateMachineStore} fileState
     * @param {StateMachineStore} archiveState
     * @param {StateMachineStore} fileDetailsState
     */
    constructor(fileState, archiveState, fileDetailsState) {
        makeObservable(this, {
            fileUploadDialogOpen: observable,
            editFile: observable,
            uploading: observable,
            file: observable,
            fileList: observable,
            archiveList: observable,
            uploadPercentage: observable,
            fileState: observable,
            archiveState: observable,
            fileDetailsState: observable,

            listToTable: computed,
            archiveListToTable: computed,
            isLoaded: computed,
            isLoading: computed,

            setFileDialogOpen: action,
            setEditFile: action,
            setUploading: action,
            getFile: action,
            uploadFile: action,
            getFileDetails: action,
        });

        this.fileState = fileState;
        this.archiveState = archiveState;
        this.fileDetailsState = fileDetailsState;
    }

    triggerReload() {
        this.shouldReload = uuidv4();
    }

    async getFilesGraphql(searchParams = {}) {
        const { query, sortBy, sortDir, limit, offset } = searchParams;
        try {
            const variables = {
                query: `%${query}%`,
                limit,
                offset,
            };
            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }
            this.fileState.setIsLoadingState();
            const res = await mapUpdateGraphqlClient.query({
                query: MAP_FILES_QUERY,
                variables,
                fetchPolicy: 'no-cache',
            });
            const {
                maps_view,
                maps_view_aggregate: { aggregate: { count: total } },
            } = res.data.mu;
            this.fileList = maps_view;
            this.filesTotal = total;
            this.fileState.setLoadedState();
        } catch (err) {
            Notification.error(`${i18n.t("fotaone.notification.error.files.fetchFiles")}`);
            this.fileState.setErrorState();
        }
    }

    async getFilesArchiveGraphql(searchParams) {
        const { query, sortBy, sortDir, limit, offset } = searchParams;
        this.archiveState.setIsLoadingState();
        try {
            const variables = {
                query: `%${query}%`,
                limit,
                offset,
            };
            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }
            const res = await mapUpdateGraphqlClient.query({
                query: MAP_ARCHIVE_QUERY,
                variables,
                fetchPolicy: 'no-cache',
            });
            const {
                maps_view,
                maps_view_aggregate: { aggregate: { count: total } },
            } = res.data.mu;
            this.archiveList = maps_view;
            this.archiveTotal = total;
            this.archiveState.setLoadedState();
            return this.archiveList;
        } catch (err) {
            this.archiveState.setErrorState();
            Notification.error(`${i18n.t("fotaone.notification.error.files.fetchArchivedFiles")}`);
        }
    }

    async getFileDetails(id) {
        this.fileDetailsState.setIsLoadingState();
        const query = serviceStore.currentService === Services.MapUpdate ? MAP_DETAILS_QUERY : '';
        const variables = {
            _map_id: id,
        };
        try {
            const res = await this.graphqlClient.query({ query, variables, fetchPolicy: 'no-cache' });
            this.currentFile = res.data.mu;
            this.fileDetailsState.setLoadedState();
            return this.currentFile;
        } catch (err) {
            this.fileDetailsState.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.files.fetchArchivedFiles")}`);
            }
        }
    }

    async deleteFile(id) {
        try {
            this.fileState.setIsLoadingState();
            if (serviceStore.currentService === Services.MapUpdate) {
                await mapClient.deleteMap(id);
            }
            this.fileState.setLoadedState();
            this.triggerReload();
            Notification.success(`${i18n.t("fotaone.notification.success.files.deleteFile")}`);
        } catch (err) {
            this.fileState.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.files.deleteFile")}`);
            }
        }
    }

    async archiveFile(id) {
        try {
            this.archiveState.setIsLoadingState();
            if (serviceStore.currentService === Services.MapUpdate) {
                await mapClient.archiveMap(id);
            }
            this.archiveState.setLoadedState();

            this.triggerReload();
            Notification.success(`${i18n.t("fotaone.notification.success.files.archiveFile")}`);
        } catch (err) {
            this.archiveState.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.files.archiveFile")}`);
            }
        }
    }

    async updateApprovalState(id, newApprovalState) {
        try {
            this.fileDetailsState.setIsLoadingState();
            await mapClient.updateMapApprovalState(id, { approvalState: newApprovalState });
            this.fileDetailsState.setLoadedState();

            this.triggerReload();

            Notification.success(`${i18n.t("fotaone.notification.success.files.stateUpdate")}`);
        } catch (err) {
            this.fileDetailsState.setErrorState();
            if (err.response) {
                Notification.error(
                    `${err.response.data.detail} ( ${err.response.data.message.toUpperCase()} ${err.response.status} )`,
                );
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.files.stateUpdate")}`);
            }
        }
    }

    async uploadFile(data) {
        try {
            this.fileState.setIsLoadingState();
            this.setUploading(true);
            const { formFile, ...metadata } = data;
            const request = {
                metadata: {
                    ...metadata,
                    filename: formFile[0].name,
                },
            };
            const formData = new FormData();
            formData.append('file', data.formFile[0]);
            formData.append(
                'mapRequest',
                new Blob([JSON.stringify(request)], {
                    type: 'application/json',
                }),
            );
            let res = null;
            if (serviceStore.currentService === Services.MapUpdate) {
                res = await mapClient.uploadMap(null, formData, { onUploadProgress: this.updateProgres });
                Notification.success(`${i18n.t("fotaone.notification.success.files.uploadFile")}`);
            }
            this.fileState.setLoadedState();
            this.setUploading(false);

            this.triggerReload();

            return res;
        } catch (err) {
            this.fileState.setErrorState();
            if (err.respone) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.files.uploadFile")}`);
            }
        } finally {
            this.setUploading(false);
        }
    }

    async updateFile(data) {
        try {
            this.fileState.setIsLoadingState();
            let res = null;
            if (serviceStore.currentService === Services.MapUpdate) {
                res = await mapClient.updateMap(this.editFile.id, data);
                Notification.success(`${i18n.t("fotaone.notification.success.files.editFile")}`);
            }
            this.fileState.setLoadedState();

            this.triggerReload();

            return res;
        } catch (err) {
            this.fileState.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.files.editFile")}`);
            }
        } finally {
            this.setUploading(false);
        }
    }

    updateProgres = (progress) => {
        const { loaded, total } = progress;
        this.uploadPercentage = (loaded / total) * 100;
    };

    async getFile(fileId) {
        try {
            this.fileDetailsState.setIsLoadingState();
            const res = await this.client.getFile(fileId);
            this.fileDetailsState.setLoadedState();
            return res.data.mu;
        } catch (err) {
            this.fileDetailsState.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.files.failedDownload")}`);
            }
        }
    }

    setFile(file) {
        this.file = file;
    }

    static instance(fileState, archiveState, fileDetailsState) {
        if (!instance) {
            instance = new FilesStore(fileState, archiveState, fileDetailsState);
        }
        return instance;
    }
}
