import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import FeedbackRating from '@rio-cloud/rio-uikit/FeedbackRating';
import RadioButton from '@rio-cloud/rio-uikit/lib/es/RadioButton';
import FeedbackReceived from '@components/Dialogs/SupportMenu/SupportMenuContent/FeedbackReceived.jsx';
import { useStores } from '@stores/context.jsx';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const FeedbackMenu = (props) => {
  const { remoteMessageStore } = useStores();
  const { t } = useTranslation();
  const [feedbackState, setFeedbackState] = useState({ rating: null, text: '', contactable: null });
  const [showFeedbackReceived, setShowFeedbackReceived] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const handleRatingChanged = () => {
    return null;
  };
  const totalWordCount = 3000;
  const textAreaRows = 4;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (remoteMessageStore.sendFeedbackLoading) return null;

    if (feedbackState.rating === null || feedbackState.contactable === null) {
      setShowError(true);
      return null;
    }
    const res = await remoteMessageStore.sendFeedback({
      rating: feedbackState.rating,
      details: feedbackState.text,
      isToContact: feedbackState.contactable
    });

    if(res) {
      setShowFeedbackReceived(true);
    }
  };

  const handleTextChange = (event) => {
    const value = event.target.value;
    const currentWordCount = value.length;
    setFeedbackState((prevFeedbackState) => ({
      ...prevFeedbackState,
      text: value,
    }));
    setWordCount(currentWordCount);
  };

  const handleContact = (contactable) => {
    setFeedbackState((prevFeedbackState) => ({
      ...prevFeedbackState,
      contactable,
    }));
  };

  function handleRating(rating) {
    setFeedbackState((prevFeedbackState) => ({
      ...prevFeedbackState,
      rating,
    }));
  }

  if (showFeedbackReceived) return <FeedbackReceived />;

  return (
    <div className={`${remoteMessageStore.sendFeedbackLoading ? 'opacity-50' : ''}`}>
      <div className='text-size-h2 text-thin'>
        {t('fotaone.general.serviceMenu.feedbackSection.headline')}
      </div>
      <Divider />
      <Trans i18nKey={'fotaone.general.serviceMenu.feedbackSection.appreciate'} />
      <form onSubmit={!remoteMessageStore.sendFeedbackLoading ? handleFormSubmit : undefined} id="feedbackForm">
        <ol className='margin-bottom-0 padding-15'>
          <li className='form-group has-feedback'>
            <div className={'margin-bottom-15'}>
              <Trans i18nKey={'fotaone.general.serviceMenu.feedbackSection.overall'} />
              {showError && feedbackState.rating === null && (
                <i className="text-color-danger">
                  <span className="margin-right-5 margin-left-5 rioglyph rioglyph-warning-sign" />
                  {t('fotaone.general.serviceMenu.feedbackSection.mandatoryField')}
                </i>
              )}
            </div>
            <FeedbackRating
              count={10}
              leftLabel={t('fotaone.general.serviceMenu.feedbackSection.dissatisfied')}
              rightLabel={t('fotaone.general.serviceMenu.feedbackSection.satisfied')}
              circleButtons
              onRatingChanged={handleRating}
            />
          </li>
          <li className='form-group has-feedback'>
            <div className={'margin-bottom-10'}>
              <Trans i18nKey={'fotaone.general.serviceMenu.feedbackSection.otherFeedback'} />
              <i className={'margin-left-5'}>
                {t('fotaone.general.serviceMenu.feedbackSection.optional')}
              </i>
            </div>
            <textarea
              className={'form-control max-height-200'}
              value={feedbackState.text}
              rows={textAreaRows}
              maxLength={totalWordCount}
              onChange={handleTextChange.bind(null)}
            />
            <div className={`text-right text-thin text-size-11 ${wordCount === totalWordCount ? 'text-color-danger' : ''}`}>
              {wordCount}/{totalWordCount}
            </div>
          </li>
          <li className="form-group has-feedback">
            <div className={'margin-bottom-10'}>
              {t('fotaone.general.serviceMenu.feedbackSection.contact')}
              {showError && feedbackState.contactable === null && (
                <i className="text-color-danger">
                  <span className="margin-right-5 margin-left-5 rioglyph rioglyph-warning-sign" />
                  {t('fotaone.general.serviceMenu.feedbackSection.mandatoryField')}
                </i>
              )}
            </div>
            <RadioButton
              name="ContactRadios"
              onClick={handleContact.bind(null, true)}
              inline={true}
              label={t('fotaone.general.yes')}
              checked={!!feedbackState.contactable}
            />
            <RadioButton
              name="ContactRadios"
              onClick={handleContact.bind(null, false)}
              inline={true}
              label={t('fotaone.general.no')}
              checked={feedbackState.contactable === false}
            />
          </li>
        </ol>
        <button
            className={`btn btn-primary float-right ${remoteMessageStore.sendFeedbackLoading ? 'btn-loading-overlay' : ''}`}
            form="feedbackForm"
            type="submit"
        >
          {t('fotaone.general.serviceMenu.feedbackSection.sendFeedback')}
        </button>
      </form>
    </div>
  );
};

export default observer(FeedbackMenu);