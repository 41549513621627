import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import Table from '@common/Table/Table';
import { useStores } from '@stores/context.jsx';
import Pagination from '@common/Pagination/Pagination';
import { mapFileTableData } from '../mapupdate/mapupdateHelper/constants.jsx';
import { setQueryParamToUrl } from '../mapupdate/mapupdateHelper/utils.jsx';

export default observer((props) => {
    const { filesStore, serviceStore } = useStores();
    const navigate = useNavigate();
    const { paginationStore, filterStore, showSearch } = props;
    const queryParams = new URLSearchParams(location.search);
    const limit = paginationStore?.limit;
    const activePage = paginationStore?.activePage;
    const offset = paginationStore?.offset;
    const query = filterStore?.query;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;
    const searchQueryParam = queryParams.get('query');

    const searchFiles = useCallback(
        debounce(async (params) => {
            await filesStore.getFilesArchiveGraphql(params);
            paginationStore.setItemsLength(filesStore.archiveTotal);
        }, 200),
        [],
    );

    useEffect(() => {
        if (searchQueryParam) {
            filterStore.setQuery(searchQueryParam);
        }

        searchFiles({
            query,
            sortBy,
            sortDir,
            limit,
            offset,
        });
    }, [query, sortBy, sortDir, limit, activePage, offset, searchFiles, searchQueryParam]);

    const handleSearchValueChange = (search) => {
        setQueryParamToUrl('query', search);
        filterStore.setQuery(search);
        paginationStore.setActivePage(1);
    };

    const handleSortChange = (sortBy, sortDir) => {
        //TODO Sort here is not possible because the displayed data has concatenetd fields, must think in a solution after
        // filterStore.setSortBy(sortBy);
        // filterStore.setSortDir(sortDir);
    };

    const handleRowClick = (row) => {
        if (props.isStep) {
            props.onChange({
                selectedMap: row,
            });
            return;
        }
        navigate(`${serviceStore.currentService}/archive/${row.id}`);
    };

    return (
        <div className='map-archive-table'>
            <Table
                showSearch={showSearch || props.isStep}
                handleSearchValueChange={handleSearchValueChange}
                handleSortChange={handleSortChange}
                searchValue={query}
                data={filesStore?.archiveListToTable}
                columnDetails={mapFileTableData.columnDetails}
                defaultColumnOrder={mapFileTableData.defaultColumnOrder}
                columns={mapFileTableData.columns}
                columnOrder={mapFileTableData.columnsOrder}
                hiddenColumns={mapFileTableData.hiddenColumns}
                columnLabels={mapFileTableData.columnLabels}
                disabledColumns={mapFileTableData.disabledColumns}
                loading={filesStore?.archiveState?.isLoading}
                onRowClick={handleRowClick}
                selectedRow={props.isStep && props.data.selectedMap ? props.data.selectedMap.id : props.selectedRow}
                rowKey='id'
            />
            <Pagination paginationStore={paginationStore} />
        </div>
    );
});
