import React from 'react';
import {
    distanceTimeRenderer,
    rolloutStatusRenderer,
    testBadgeRenderer,
    timestampInformation,
} from '../../mapupdateHelper/utils.jsx';
import { rolloutStatusMap } from '../../mapupdateHelper/constants.jsx';

const fixStepIconAlignStyle = { margin: '5px 0 0 -2px' };
export const stepperCreateCampaignsLabel = [
    {
        icon: <i className='rioglyph rioglyph-files' style={fixStepIconAlignStyle} />,
        label: 'fotaone.mu.fileData.file.one',
    },
    {
        icon: <i className='rioglyph rioglyph-truck-baseline' style={fixStepIconAlignStyle} />,
        label: 'fotaone.general.vehicleData.vehicle.many',
    },
    {
        icon: <i className='rioglyph rioglyph-ok' style={fixStepIconAlignStyle} />,
        label: 'fotaone.general.preview',
    },
];
export const campaignsAssetTableData = {
    defaultColumnOrder: ['name', 'vin', 'account_id'],
    columnsOrder: ['name', 'vin', 'account_id'],
    columnLabels: {
        name: 'fotaone.general.vehicleData.shortname',
        vin: 'fotaone.general.vehicleData.vinShort',
        account_id: 'fotaone.general.vehicleData.fleetId',
    },
    columnDetails: {
        name: {},
        vin: {},
        account_id: {},
    },
    columns: [
        {
            title: 'fotaone.general.vehicleData.shortname',
            key: 'name',
        },
        {
            title: 'fotaone.general.vehicleData.vinShort',
            key: 'vin',
            badge: testBadgeRenderer,
        },
        {
            title: 'fotaone.general.vehicleData.fleetId',
            key: 'account_id',
        },
    ],
    disabledColumns: [],
    hiddenColumns: [],
};
export const campaignStatus = {
    CREATED: 'CREATED',
    STARTED: 'STARTED',
    PAUSED: 'PAUSED',
    STOPPED: 'STOPPED',
};

export const campaignKpiStatus = {
    STARTED: 'started',
    QUEUED: 'queued',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    WARNING: 'warning',
    CANCELLED: 'cancelled',
};
export const stepperCreateCampaignsInitState = {
    selectedMap: null,
    selectedVehicleIds: [], // if the filters grow, use a dedicated object like filterBy
    selectedGroupIds: [],
    customName: '',
};
export const campaignTableData = {
    defaultColumnOrder: ['campaign_name', 'map.display_name', 'status', 'rollout_status', 'created_at'],
    columnsOrder: ['campaign_name', 'map.display_name', 'status', 'rollout_status', 'created_at'],

    columnLabels: {
        id: 'fotaone.mu.campaignData.campaignName',
        created_at: 'fotaone.mu.createdAt',
        status: 'fotaone.mu.campaignData.campaignStatus',
        'map.display_name': 'fotaone.mu.fileData.displayName',
        rollout_status: 'fotaone.mu.rolloutData.rolloutStatus',
    },
    columnDetails: {
        'campaign_name': {},
        created_at: {},
        status: {},
        'map.display_name': {},
        rollout_status: {},
    },
    columns: [
        {
            title: 'fotaone.mu.campaignData.campaignName',
            key: 'campaign_name',
            sortable: true,
        },
        {
            title: 'fotaone.mu.fileData.displayName',
            key: 'map.display_name',
            sortable: true,
        },
        {
            title: 'fotaone.mu.campaignData.campaignStatus',
            key: 'status',
            statusColor: true,
            sortable: true,
        },
        {
            title: 'fotaone.mu.rolloutData.rolloutStatus',
            key: 'rollout_status',
            statusColor: true,
            renderer: rolloutStatusRenderer,
        },
        {
            title: 'fotaone.mu.createdAt',
            key: 'created_at',
            renderer: distanceTimeRenderer,
            tooltip: timestampInformation,
            sortable: true,
        },
    ],
    disabledColumns: [],
    hiddenColumns: [],
};
export const campaignRolloutsData = {
    defaultColumnOrder: ['vehicle.name', 'vehicle.vin', 'rollout_state.status'],
    columnsOrder: ['vehicle.name', 'vehicle.vin', 'rollout_state.status'],
    columnLabels: {
        'vehicle.name': 'fotaone.general.vehicleData.shortname',
        'vehicle.vin': 'fotaone.general.vehicleData.vinShort',
        'rollout_state.status': 'fotaone.mu.rolloutData.rolloutStatus',
    },
    columnDetails: {
        'vehicle.name': {},
        'vehicle.vin': {},
        'rollout_state.status': {},
    },
    columns: [
        {
            title: 'fotaone.general.vehicleData.shortname',
            key: 'vehicle.name',
        },
        {
            title: 'fotaone.general.vehicleData.vinShort',
            key: 'vehicle.vin',
        },
        {
            title: 'fotaone.mu.rolloutData.rolloutStatus',
            key: 'rollout_state.status',
            statusColor: true,
            renderer: (status) => (rolloutStatusMap[status] ? rolloutStatusMap[status] : 'UNKNOWN'),
        },
    ],
    disabledColumns: [],
    hiddenColumns: [],
};
export const campaignUpdatedVehiclesData = {
    defaultColumnOrder: ['name', 'vin', 'status'],
    columnsOrder: ['name', 'vin', 'status'],
    columnLabels: {
        name: 'fotaone.general.vehicleData.shortname',
        vin: 'fotaone.general.vehicleData.vinShort',
        status: 'fotaone.general.status',
    },
    columnDetails: {
        name: {},
        vin: {},
        status: {},
    },
    columns: [
        {
            title: 'fotaone.general.vehicleData.shortname',
            key: 'name',
        },
        {
            title: 'fotaone.general.vehicleData.vinShort',
            key: 'vin',
        },
        {
            title: 'fotaone.general.status',
            key: 'status',
            statusColor: true,
        },
    ],
    disabledColumns: [],
    hiddenColumns: [],
};
