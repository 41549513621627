import { useEffect, useState } from 'react';
import FilePicker from '@rio-cloud/rio-uikit/lib/es/FilePicker';
import StatusBar from '@rio-cloud/rio-uikit/lib/es/StatusBar';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { getDefaultRegionValue, renderRegions } from '../mapupdate/mapupdateHelper/region.jsx';
import { useStores } from '@stores/context.jsx';
import './UploadDialog.css';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

export default observer((props) => {
    const [formFile, setFormFile] = useState([]);
    const [displayName, setDisplayName] = useState('');
    const [versionNumber, setVersionNumber] = useState('');
    const [previousVersionNumber, setPreviousVersionNumber] = useState('');
    const [mandatoryUpdate, setMandatoryUpdate] = useState(true);
    const [updateRecommended, setUpdateRecommended] = useState(true);
    const [updateRegion, setUpdateRegion] = useState(getDefaultRegionValue());
    const { filesStore } = useStores();
    const { t } = useTranslation();

    const renderFileUploadFormDialog = () => {
        const onHide = () => {
            filesStore.setFileDialogOpen(false);
            setInitialState();
            clearFileData();
        };
        return (
            <Dialog
                show={filesStore.fileUploadDialogOpen}
                title={
                    filesStore.editFile
                    ? `${t("fotaone.mu.fileData.editFile")}`
                    : `${t("fotaone.mu.fileData.uploadFile")}`}
                body={form()}
                onHide={onHide}
                className='fileUploadForm'
                showCloseButton={true}
            />
        );
    };

    useEffect(() => {
        setInitialState();
    }, [filesStore.editFile]);

    const onSubmit = async (e) => {
        e.preventDefault();
        let uploaded = null;
        if (filesStore.editFile) {
            uploaded = await filesStore.updateFile({
                displayName,
                versionNumber,
                // previousVersionNumber,
                mandatoryUpdate,
                updateRecommended,
                updateRegion,
            });
        } else {
            uploaded = await filesStore.uploadFile({
                formFile,
                displayName,
                versionNumber,
                previousVersionNumber,
                mandatoryUpdate,
                updateRecommended,
                updateRegion,
            });
        }

        if (uploaded) {
            filesStore.setFileDialogOpen(false);
            filesStore.setEditFile(null);
            setInitialState();
        }
    };

    const clearFileData = () => {
        filesStore.setEditFile(null);
    };

    const setInitialState = () => {
        if (filesStore.editFile) {
            setDisplayName(filesStore.editFile.display_name);
            setVersionNumber(filesStore.editFile.version_number);
            // setPreviousVersionNumber(filesStore.editFile.previous_version_number);
            setUpdateRegion(filesStore.editFile.map_region_enum);
        } else {
            setDisplayName('');
            setVersionNumber('');
            setPreviousVersionNumber('');
            setFormFile([]);
        }
    };

    const versionValidation = /^\d{4}\ v\d+[.]?\d*$/;
    const versionIsValid = versionValidation.test(versionNumber);
    const preVersionIsValid = versionValidation.test(previousVersionNumber);

    const form = () => {
        return (
            <div className='row equal-height'>
                <div className='col-xs-12 col-md-12 margin-bottom-15'>
                    <form onSubmit={onSubmit}>
                        <fieldset>
                            <div
                                className={
                                    displayName
                                        ? 'form-group has-success has-feedback'
                                        : 'form-group has-error has-feedback'
                                }
                            >
                                <label htmlFor='inputDisplayName'>{`${t("fotaone.mu.fileData.displayName")}`}</label>
                                <input
                                    className='form-control'
                                    id='inputDisplayName'
                                    placeholder={`${t("fotaone.mu.fileData.displayName")}`}
                                    type='text'
                                    value={displayName}
                                    onChange={(event) => setDisplayName(event.target.value)}
                                />
                                <span
                                    className={
                                        displayName
                                            ? 'form-control-feedback rioglyph rioglyph-ok'
                                            : 'form-control-feedback rioglyph rioglyph-error-sign'
                                    }
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='regionSelect'>{`${t("fotaone.mu.regionData.region.one")}`}</label>
                                <select
                                    className='form-control'
                                    id='regionSelect'
                                    defaultValue={`${t(updateRegion)}`}
                                    value={`${t(updateRegion)}`}
                                    onChange={(event) => setUpdateRegion(event.target.value)}
                                >
                                    {renderRegions()}
                                </select>
                            </div>
                            <div
                                className={
                                    versionIsValid
                                        ? 'form-group has-success has-feedback'
                                        : 'form-group has-error has-feedback'
                                }
                            >
                                <label htmlFor='inputVersion'>
                                    {`${t("fotaone.mu.fileData.version")} (${t("fotaone.mu.fileData.versionWarning")})`}
                                </label>
                                <input
                                    className='form-control'
                                    id='inputVersion'
                                    placeholder={`${t("fotaone.mu.fileData.version")}`}
                                    type='text'
                                    value={versionNumber}
                                    onChange={(event) => setVersionNumber(event.target.value)}
                                />
                                <span
                                    className={
                                        versionIsValid
                                            ? 'form-control-feedback rioglyph rioglyph-ok'
                                            : 'form-control-feedback rioglyph rioglyph-error-sign'
                                    }
                                />
                            </div>
                            {!filesStore.editFile && (
                                <div
                                    className={
                                        preVersionIsValid
                                            ? 'form-group has-success has-feedback'
                                            : 'form-group has-error has-feedback'
                                    }
                                >
                                    <label htmlFor='inputPrevVersion'>
                                    {`${t("fotaone.mu.fileData.preVersion")} (${t("fotaone.mu.fileData.versionWarning")})`}
                                    </label>
                                    <input
                                        className='form-control'
                                        id='inputPrevVersion'
                                        placeholder={`${t("fotaone.mu.fileData.preVersion")}`}
                                        type='text'
                                        value={previousVersionNumber}
                                        onChange={(event) => setPreviousVersionNumber(event.target.value)}
                                    />
                                    <span
                                        className={
                                            preVersionIsValid
                                                ? 'form-control-feedback rioglyph rioglyph-ok'
                                                : 'form-control-feedback rioglyph rioglyph-error-sign'
                                        }
                                    />
                                </div>
                            )}
                            <div className='form-group'>
                                <div className='checkbox'>
                                    <label>
                                        <input
                                            id='mandatoryCheck'
                                            type='checkbox'
                                            size='large'
                                            defaultChecked={mandatoryUpdate}
                                            onChange={(event) => setMandatoryUpdate(event.target.checked)}
                                        />
                                        <span className='checkbox-text'>{`${t("fotaone.mu.fileData.fileParams.updateMandatory")}?`}</span>
                                    </label>
                                </div>
                                <div className='checkbox'>
                                    <label>
                                        <input
                                            id='recommendedCheck'
                                            type='checkbox'
                                            size='large'
                                            defaultChecked={updateRecommended}
                                            onChange={(event) => setUpdateRecommended(event.target.checked)}
                                        />
                                        <span className='checkbox-text'>{`${t("fotaone.mu.fileData.fileParams.updateRecommended")}?`}</span>
                                    </label>
                                </div>
                            </div>
                            {!filesStore.editFile && (
                                <div className='form-group file-upload'>
                                    <label>{`${t("fotaone.mu.fileData.selectFile")}`}</label>
                                    <div className='file-upload'>
                                        <FilePicker
                                            label={`${t("fotaone.mu.fileData.selectFile")}`}
                                            multiple
                                            accept='' //MIME type
                                            onPick={setFormFile}
                                        />
                                        {formFile[0] && (
                                            <div className='has-success'>
                                                <span
                                                    className='form-control-feedback only-glyph rioglyph rioglyph-ok col-lg-4'
                                                    aria-hidden
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {formFile[0] && <span>{formFile[0].name}</span>}
                                </div>
                            )}
                            {!filesStore.editFile ? (
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={
                                        !displayName ||
                                        !versionIsValid ||
                                        !preVersionIsValid ||
                                        !formFile ||
                                        !formFile[0] ||
                                        filesStore.uploading
                                    }
                                >
                                    {`${t("fotaone.mu.fileData.uploadFile")}`}
                                </button>
                            ) : (
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={!displayName || !versionIsValid || filesStore.uploading}
                                >
                                    {`${t("fotaone.mu.fileData.editFile")}`}
                                </button>
                            )}
                        </fieldset>
                        {filesStore.uploading && (
                            <>
                                <StatusBar
                                    label={{
                                        value: `${Math.round(filesStore.uploadPercentage)}% uploaded`,
                                        alignment: 'top',
                                        color: 'text-color-status-driving',
                                        weight: 'light',
                                    }}
                                    progress={[
                                        {
                                            percentage: Math.round(filesStore.uploadPercentage),
                                            color: 'text-color-status-driving',
                                            tooltip: <span>{`${Math.round(filesStore.uploadPercentage)}%`}</span>,
                                        },
                                    ]}
                                    className='MyProgress'
                                />
                                {Math.round(filesStore.uploadPercentage) >= '99' ? (
                                    <i className='rioglyph rioglyph-spinner spinning center' />
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </form>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <div className='table-toolbar-column pull-right'>
                <button type='button' className='btn btn-primary' onClick={() => filesStore.setFileDialogOpen(true)}>
                    {filesStore.uploading ? `${t("fotaone.general.loading")}` : `${t("fotaone.mu.fileData.uploadFile")}`}
                </button>
                {renderFileUploadFormDialog()}
            </div>
        );
    };

    return render();
});
