import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import FotaHeader from '@components/Navbar/Navbar.jsx';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import { PaginationStore } from '@stores/pagination.js';
import { FilterStore } from '@stores/filter.jsx';
import MyMessagesTable from '@components/RemoteMessage/MyMessages/Overview/Table/MyMessagesTable.jsx';
import MyMessageDetailSidebar from '@components/RemoteMessage/MyMessages/Overview/Sidebar/MyMessageDetailSidebar.jsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useStores } from '@stores/context.jsx';
import { debounce } from 'lodash';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
  query: '',
});

export default observer(() => {
  const { id: messageId } = useParams();
  const { remoteMessageStore, authStore, serviceStore } = useStores();
  const query = filterStore?.query;
  const limit = paginationStore?.limit;
  const offset = paginationStore?.offset;
  const sortBy = filterStore?.sortBy;
  const sortDir = filterStore?.sortDir;
  const activePage = paginationStore?.activePage;
  const [showMyMessageConfirmationDialog, setShowMyMessageConfirmationDialog] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');
  const profileEmail = authStore.user?.profile.email;

  const searchMyMessages = useCallback(debounce(async (params) => {
    await remoteMessageStore.getMyMessagesList(params);
    paginationStore.setItemsLength(remoteMessageStore.myMessagesListTotal);
  }, 200), []);

  useEffect(() => {
    searchMyMessages({
      profileEmail, query, sortBy, sortDir, limit, offset,
    });
  }, [profileEmail, query, sortBy, sortDir, limit, activePage, offset, remoteMessageStore.messageDialogOpen, serviceStore]);

  function getConfirmationDialogTitle(type) {
    switch (type) {
      case 'deleteMessage':
        return 'Delete My Message';
      case 'requestApproval':
        return 'Confirm Approval Request';
      case 'cancelRequestApproval':
        return 'Cancel Approval Request';
      default:
        return '';
    }
  }

  function getConfirmationDialogContent(type) {
    switch (type) {
      case 'deleteMessage':
        return `Are you sure you want to delete this message named "${remoteMessageStore.editMyMessage.title}"?`;
      case 'requestApproval':
        return `Are you sure you want to send an approval request for "${remoteMessageStore.editMyMessage.title}"? Please confirm that all the information is correct and that you wish to proceed. Once submitted, this action cannot be undone.`;
      case 'cancelRequestApproval':
        return `Are you sure you want to cancel approval request this message named "${remoteMessageStore.editMyMessage.title}"?`;
      default:
        return '';
    }
  }


  return (<ApplicationLayout className={'MyServiceLayout'}>
      <ApplicationLayout.Header>
        <FotaHeader />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar className='bg-muted'>
        <FotaSidebar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Sidebar className='right'>
        {
          messageId &&
          <MyMessageDetailSidebar
            messageId={messageId}
            setShowMyMessageConfirmationDialog={setShowMyMessageConfirmationDialog}
            setConfirmationType={setConfirmationType}
          />
        }
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className='responsive'>
        <NotificationsContainer />
        {serviceStore.serviceMenuDialogOpen &&
          <ServiceMenuDialog/>
        }
        <MyMessagesTable
          selectedRow={messageId}
          paginationStore={paginationStore}
          filterStore={filterStore}
          query={query}
          setShowMyMessageConfirmationDialog={setShowMyMessageConfirmationDialog}
          setConfirmationType={setConfirmationType}
        />
        {remoteMessageStore.editMyMessage && <ConfirmationDialog
          show={showMyMessageConfirmationDialog}
          title={getConfirmationDialogTitle(confirmationType)}
          content={getConfirmationDialogContent(confirmationType)}
          bsSize={Dialog.SIZE_SM}
          onClickConfirm={async () => {
            if (confirmationType === 'deleteMessage') {
              await remoteMessageStore.deleteMyMessage({
                messageId: remoteMessageStore.editMyMessage.id, profileEmail, query, sortBy, sortDir, limit, offset,
              });
            }

            if (confirmationType === 'requestApproval') {
              await remoteMessageStore.requestApproval({
                messageId: remoteMessageStore.editMyMessage.id, profileEmail, query, sortBy, sortDir, limit, offset,
              });
            }

            if (confirmationType === 'cancelRequestApproval') {
              await remoteMessageStore.cancelRequestApproval({
                messageId: remoteMessageStore.editMyMessage.id, profileEmail, query, sortBy, sortDir, limit, offset,
              });
            }

            setShowMyMessageConfirmationDialog(false);
            remoteMessageStore.setEditMyMessage(null);
          }}
          onClickCancel={() => {
            setConfirmationType('');
            setShowMyMessageConfirmationDialog(false);
            remoteMessageStore.setEditMyMessage(null);
          }}
          cancelButtonText={'Cancel'}
          confirmButtonText={<>{remoteMessageStore.deleteMyMessageLoading &&
            <span className='rioglyph rioglyph-spinner spinning' />} Yes</>}
          useOverflow
        />}
      </ApplicationLayout.Body>
    </ApplicationLayout>);
});