import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { PaginationStore } from '@stores/pagination.js';
import { FilterStore } from '@stores/filter.jsx';
import { useStores } from '@stores/context.jsx';
import { debounce } from 'lodash';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import FotaHeader from '@components/Navbar/Navbar.jsx';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import VehiclesMessageTable from '@components/RemoteMessage/Vehicles/VehiclesMessageTable.jsx';
import VehicleDetailSidebar from '@components/RemoteMessage/Vehicles/Sidebar/VehicleDetailSidebar.jsx';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
  query: '',
});

export default observer(() => {
  const { id: deviceId } = useParams();
  const { remoteMessageStore, serviceStore } = useStores();
  const query = filterStore?.query;
  const activePage = paginationStore?.activePage;
  const limit = paginationStore?.limit;
  const offset = paginationStore?.offset;
  const sortBy = filterStore?.sortBy;
  const sortDir = filterStore?.sortDir;

  const searchVehicles = useCallback(debounce(async (params) => {
    await remoteMessageStore.getVehicles(params);
    paginationStore.setItemsLength(remoteMessageStore.vehicleTotal);
  }, 200), []);

  useEffect(() => {
    searchVehicles({
      query, sortBy, sortDir, limit, offset,
    });
  }, [query, sortBy, sortDir, limit, activePage, offset, serviceStore]);


  return (
    <ApplicationLayout className={'MyServiceLayout'}>
      <ApplicationLayout.Header>
        <FotaHeader />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar className='bg-muted'>
        <FotaSidebar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Sidebar className='right'>
        {deviceId && <VehicleDetailSidebar deviceId={deviceId} />}
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className='responsive'>
        <NotificationsContainer />
        {serviceStore.serviceMenuDialogOpen &&
          <ServiceMenuDialog/>
        }
        <VehiclesMessageTable
          selectedRow={deviceId}
          paginationStore={paginationStore}
          filterStore={filterStore}
          query={query}
        />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
});