import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';

const VehicleDetailsDevice = observer(() => {
    const { assetStore } = useStores();
    const { t } = useTranslation();
    return (
        get(assetStore.assetDetails, ['vehicles', '0', 'devices'], []).length > 0 && (
            <>
                <h5>{`${t("fotaone.general.vehicleData.device")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.deviceId")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, ['vehicles', '0', 'devices', '0', 'device_id'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.serialnumber")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, ['vehicles', '0', 'devices', '0', 'formatted_serial_no'])}
                            </span>
                            &nbsp;
                            <span className='margin-left-10 label label-muted label-filled label-condensed'>
                                <a
                                    className='text-color-info'
                                    href={get(assetStore.assetDetails, ['vehicles', '0', 'devices', '0', 'type'])==="CM4"
                                    ?
                                        `https://ota.admin-eu.rio.cloud/#devices?serial-number-prefix=cm4_${get(
                                        assetStore.assetDetails,
                                        ['vehicles', '0', 'devices', '0', 'serial_number'],
                                        )}&deviceSelected=true`
                                    :
                                        `https://ota.admin-eu.rio.cloud/#devices?serial-number-prefix=${get(
                                        assetStore.assetDetails,
                                        ['vehicles', '0', 'devices', '0', 'serial_number'],
                                        )}&deviceSelected=true`
                                    }
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {`${t("fotaone.general.vehicleData.mdmDevice")}`}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    );
});

export default VehicleDetailsDevice;
