import React, { useEffect, useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import FileCheckTable from '../../../../Files/FileCheckTable.jsx';
import TargetSelection from '../../../../Dialogs/common/TargetSelection.jsx';
import { observer } from 'mobx-react-lite';
import prettyBytes from 'pretty-bytes';
import './MultipleRollout.css';
import { find } from 'lodash';
import { PaginationStore } from '@stores/pagination.js';
import { FilterStore } from '@stores/filter.jsx';
import { useStores } from '@stores/context.jsx';
import { rolloutLabels } from '../../../mapupdateHelper/constants.jsx';
import { useTranslation } from 'react-i18next';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore();

//Multi File Rollout - triggered from RolloutTable
const Preview = observer((props) => {
    const { mapRolloutStore, serviceStore } = useStores();
    const [warningLabel, setWarningLabel] = useState('');
    const { t } = useTranslation();

    // const getStore = useCallback(() => {
    //     if (serviceStore.currentService === Services.MapUpdate) {
    //         return mapRolloutStore;
    //     }
    // }, [mapRolloutStore, serviceStore.currentService]);

    const renderPreviewFilesInfo = (filesList, activeIds) => {
        let selectedFilesList = [];

        activeIds.forEach((id) => {
            selectedFilesList = [...selectedFilesList, find(filesList, { id }, filesList.approval_state)];
        });

        return selectedFilesList.map(({ id, display_name, file_metadata, approval_state }) => (
            <React.Fragment key={id}>
                <li key={id}>
                    <strong className='displayname'>{display_name}</strong>
                    {', '}
                    <span className='filename'>{file_metadata.filename}</span>{' '}
                    <span className='filesize'>({prettyBytes(file_metadata.size_bytes)})</span>
                    <span
                        className={
                            approval_state === 'RELEASED'
                                ? 'label label-success label-condensed margin-left-4 label-info'
                                : 'label label-default label-condensed margin-left-4 label-info'
                        }
                        title='State'
                    >
                        {approval_state}
                    </span>
                </li>
                {approval_state === 'UNRELEASED' ? (
                    warningLabel === '' ? (
                        ''
                    ) : (
                        <div className='text-color-warning warning-wrapper'>
                            <span className='rioglyph rioglyph-warning-sign warning-sign' />
                            {warningLabel}
                        </div>
                    )
                ) : (
                    ''
                )}
            </React.Fragment>
        ));
    };

    const checkForUnreleasedFiles = () => {
        const { data } = props;
        const filesList = data.filesList;
        const filesListLength = data.filesList.length;
        let i;
        let fileCounter = 0;
        for (i = 0; i < filesListLength; i++) {
            if (filesList[i].approval_state === 'UNRELEASED') {
                fileCounter++;
            }
        }
        if (fileCounter >= 1) {
            // There is a UNRELEASED file in selection
            setWarningLabel(`${t("fotaone.mu.rolloutData.unreleasedFileWarning")}`);
        }
    };

    useEffect(() => {
        checkForUnreleasedFiles();
        if (props.data.vehicleIds.length === 0) {
            return;
        }
        mapRolloutStore.getAssetsWithActiveRollouts(props.data.vehicleIds);
    }, [props.data.vehicleIds]);

    const renderActiveRollouts = () => {
        return mapRolloutStore.activeVehicles.length ? (
            <div className='has-warning active-rollouts-wrapper'>
                <div className='text-color-warning warning-wrapper'>
                    <span className='rioglyph rioglyph-warning-sign warning-sign' />
                    {`${t("fotaone.mu.rolloutData.activeRolloutWarning")} ${mapRolloutStore.activeVehicles.length}`}
                </div>
            </div>
        ) : (
            <div> </div>
        );
    };

    const render = () => {
        const { data } = props;
        return (
            <>
                <div className='rollout-summary'>
                    <h4>{`${t("fotaone.mu.rolloutData.rolloutPreview")}`}</h4>
                    <p>
                        {`${t("fotaone.mu.rolloutData.rolloutTarget")} `}
                        <a
                            className='text-color-highlight'
                            target='_blank'
                            rel='noopener noreferrer'
                            href={
                                data.group
                                    ? `${serviceStore.currentService}/vehicles/?group=${data.group.name}`
                                    : `${serviceStore.currentService}/vehicles/${data.vehicleIds[0]}`
                            }
                        >
                            {' '}
                            <span title={data.vehicleIds.join(',\n')}>
                                <span className='vehicle-count'>{`${data.vehicleIds.length} `}</span>
                                {
                                    data.vehicleIds.length>=1 ?
                                    `${t("fotaone.general.vehicleData.vehicle.one")}`
                                    :
                                    `${t("fotaone.general.vehicleData.vehicle.many")}`
                                }
                            </span>
                            <span className='rioglyph rioglyph-link' />
                        </a>
                        {data.group && (
                            <span>
                                {` ${t("fotaone.mu.groupData.inGroup")} `} <strong className='groupname'>{data.group.name}</strong>
                            </span>
                        )}{' '}
                        :
                    </p>
                    <ul className='files-list margin-bottom-20'>
                        {renderPreviewFilesInfo(data.filesList, data.activeIds)}
                    </ul>
                </div>

                {renderActiveRollouts()}

                <div className='rollout-debug'>
                    <div className='checkbox'>
                        <label>
                            <input
                                type='checkbox'
                                size='large'
                                checked={mapRolloutStore.multipleRolloutDialogData.interactiveMode}
                                onChange={(event) => {
                                    mapRolloutStore.setMultipleRolloutData({
                                        interactiveMode: event.target.checked,
                                        cancelRetry: false,
                                    });
                                }}
                            />
                            <span className='checkbox-text'>{`${t("fotaone.mu.rolloutData.startInteractiveMode")}`}</span>
                        </label>
                    </div>
                    <div className='checkbox'>
                        <label className={'display-flex'}>
                            <input
                                type='checkbox'
                                size='large'
                                checked={mapRolloutStore.multipleRolloutDialogData.cancelRetry}
                                onChange={(event) =>
                                    mapRolloutStore.setMultipleRolloutData({ cancelRetry: event.target.checked })
                                }
                                disabled={mapRolloutStore.multipleRolloutDialogData.interactiveMode}
                            />
                            <span className='checkbox-text'>{`${t("fotaone.mu.rolloutData.disableRetriable")}`}</span>
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip
                                        id='tooltip'
                                        textAlignment='left'
                                        className='top white-space-preline'
                                        width='auto'
                                    >
                                        {`${t("fotaone.general.tooltip.retrymode")}`}
                                    </Tooltip>
                                }
                            >
                                <span className='margin-left-5 align-self-center rioglyph rioglyph-info-sign' />
                            </OverlayTrigger>
                        </label>
                    </div>
                    <div className='checkbox'>
                        <label className={'display-flex'}>
                            <input
                                type='checkbox'
                                size='large'
                                checked={mapRolloutStore.multipleRolloutDialogData.distinctMapRollouts}
                                onChange={(event) =>
                                    mapRolloutStore.setMultipleRolloutData({
                                        distinctMapRollouts: event.target.checked,
                                    })
                                }
                            />
                            <span className='checkbox-text'>{`${t("fotaone.mu.rolloutData.loopOuter")}`}</span>
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip
                                        id='tooltip'
                                        textAlignment='left'
                                        className='top white-space-preline'
                                        width='auto'
                                    >
                                        {`${t("fotaone.general.tooltip.loopOuter")}`}
                                    </Tooltip>
                                }
                            >
                                <span className='margin-left-5 align-self-center rioglyph rioglyph-info-sign' />
                            </OverlayTrigger>
                        </label>
                    </div>
                </div>
            </>
        );
    };
    return render();
});

export default observer(() => {
    const [step, setStep] = useState(0);
    const { t } = useTranslation();
    const labelsProgressBar = rolloutLabels;
    const steps = [FileCheckTable, TargetSelection, Preview];
    const { uiStore, mapRolloutStore } = useStores();
    labelsProgressBar.forEach((element) => {
        element.label = `${t(element.label)}`;
    });

    const onUpdateData = (d) => {
        mapRolloutStore.setMultipleRolloutData(d);
    };

    const onNextStep = () => {
        let update_step = step + 1;
        if (update_step > steps.length) {
            update_step = steps.length;
        }
        setStep(update_step);
    };

    const onPreviousStep = () => {
        let update_step = step - 1;
        if (update_step < 0) {
            update_step = 0;
        }
        setStep(update_step);
    };

    const onSelectedChanged = (s) => {
        setStep(s);
    };

    const onShow = () => {
        setStep(0);
        uiStore.setShowMultipleRolloutDialog(true);
    };

    const onHide = () => {
        mapRolloutStore.setMultipleDefaultRolloutData();
        uiStore.setShowMultipleRolloutDialog(false);
    };

    const startRollout = (startNow) => {
        return mapRolloutStore.createRollout({ isMultipleRollout: true, startImmediately: startNow });
    };

    const onFinish = ({ startNow }) => {
        startRollout(startNow);
        onHide();
    };

    const render = () => {
        const prev_button = (
            <button type='button' className='btn btn-primary' onClick={onPreviousStep}>
                {`${t("fotaone.general.previous")}`}
            </button>
        );

        let next_button_disabled = false;
        if (
            step === 0 &&
            (!mapRolloutStore.multipleRolloutDialogData.filesList ||
                !mapRolloutStore.multipleRolloutDialogData.filesList.length)
        ) {
            next_button_disabled = true;
        }
        if (
            step === 1 &&
            !mapRolloutStore.multipleRolloutDialogData.vehicle &&
            !mapRolloutStore.multipleRolloutDialogData.group
        ) {
            next_button_disabled = true;
        }

        const next_button = (
            <button type='button' className='btn btn-primary' onClick={onNextStep} disabled={next_button_disabled}>
                {`${t("fotaone.general.next")}`}
            </button>
        );
        const finish_button = (
            <>
                <button type='button' className={'btn btn-warning'} onClick={onFinish}>
                    {`${t("fotaone.mu.rolloutData.startImmediately")}`}
                </button>
                <button type='button' className='btn btn-primary' onClick={() => onFinish({ startNow: false })}>
                    {`${t("fotaone.mu.rolloutData.queue")}`}
                </button>
            </>
        );

        const footer = (
            <div className='pull-right btn-toolbar'>
                {step > 0 && prev_button}
                {step < steps.length - 1 && next_button}
                {step === steps.length - 1 && finish_button}
            </div>
        );

        const CurrentStep = steps[step];

        const body = (
            <div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <SteppedProgressBar
                            selectedStepNumber={step}
                            onSelectedChanged={onSelectedChanged}
                            labels={labelsProgressBar}
                        />
                    </div>
                </div>
                <div className='row equal-height'>
                    <div className='col-xs-12 col-md-12 margin-bottom-15'>
                        <div className='panel panel-default panel-body height-100pct'>
                            <CurrentStep
                                fetchFiles={true}
                                data={mapRolloutStore.multipleRolloutDialogData || []}
                                onChange={onUpdateData}
                                multipleRollout={true}
                                paginationStore={paginationStore}
                                filterStore={filterStore}
                                className={'table-wrapper'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div>
                <div className='text-right table-toolbar-column'>
                    <button type='button' className='btn btn-primary' onClick={onShow}>
                        {`${t("fotaone.mu.rolloutData.startRollout")}`}
                    </button>
                </div>
                <Dialog
                    show={uiStore.showMultipleRolloutDialog}
                    title={`${t("fotaone.mu.rolloutData.startRollout")}`}
                    body={body}
                    footer={footer}
                    onHide={onHide}
                    className='multiple-rollout'
                    showCloseButton={true}
                />
            </div>
        );
    };
    return render();
});
