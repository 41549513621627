import { observer } from 'mobx-react-lite';
import {
  servicesDescriptionsTableData,
} from '@components/FeatureDashboard/FeatureDashboardHelper/constants.jsx';
import Table from '@common/Table/Table.jsx';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { debounce } from 'lodash';
import { useStores } from '@stores/context.jsx';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ExpanderPanelHeader from '@components/FeatureDashboard/DigitalServices/Services/Sidebar/ExpanderPanelHeader.jsx';

export default observer((props) => {
  const navigate = useNavigate();
  const { featureDashboardStore, serviceStore } = useStores();

  const getDigitalServices = useCallback(
    debounce(async() => {
      await featureDashboardStore.getFeatureDescriptions();
    }, 200),[],
  );

  useEffect(() => {
    getDigitalServices();
  }, []);

  const handleRowClick = (row) => {
    navigate(`${serviceStore.currentService}/services/${row.id}`);
  };

  return (
    featureDashboardStore.featureDescriptionsList.map((service) => {
      return (
        <ExpanderPanel
          key={service.code}
          title={
            <ExpanderPanelHeader
                name={service.name}
                category={service.category}
                icon={service.image}
                code={service.code}
            />
          }
          bsStyle={'default'}
          bodyClassName={'padding-20'}
          className={'margin-0'}
        >
          <>
            <div className='app-file-table'>
              <Table
                onRowClick={handleRowClick}
                data={service.feature_descriptions}
                columnDetails={servicesDescriptionsTableData.columnDetails}
                defaultColumnOrder={servicesDescriptionsTableData.defaultColumnOrder}
                columns={servicesDescriptionsTableData.columns}
                columnOrder={servicesDescriptionsTableData.columnsOrder}
                hiddenColumns={servicesDescriptionsTableData.hiddenColumns}
                columnLabels={servicesDescriptionsTableData.columnLabels}
                disabledColumns={servicesDescriptionsTableData.disabledColumns}
                loading={featureDashboardStore.featureLoading}
                rowKey='id'
                selectedRow={props.selectedRow}
              />
            </div>
          </>
        </ExpanderPanel>
      );
    })
  );
});