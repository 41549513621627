import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import VehicleDetailSidebar from '@components/FeatureDashboard/DigitalServices/VehiclesActivations/Sidebar/VehicleFeatureSidebar.jsx';
import MainTable from '@components/FeatureDashboard/DigitalServices/VehiclesActivations/Table/VehiclesActivationsTable.jsx';
import { useStores } from '@stores/context.jsx';
import { FilterStore } from '@stores/filter.jsx';
import { PaginationStore } from '@stores/pagination';

const sortField = {
  VIN: 'vin',
  ASSETID: 'asset_id',
  DEVICEID: 'device_id',
};

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
    query: '',
    sortBy: sortField.VIN,
    sortDir: SortDirection.DESCENDING,
});

export default observer(() => {
  const { id: deviceId } = useParams();
  const { serviceStore } = useStores();
  const location = useLocation();
  const vin = location?.state?.vin;
  const queryParams = new URLSearchParams(location.search);
  const searchQueryParam = queryParams.get('query');

  useEffect(() => {
    if (searchQueryParam) {
        filterStore.setQuery(searchQueryParam);
    }
    return () => {
      filterStore.resetToDefaults();
      paginationStore.resetToDefaults();
    };
  }, [searchQueryParam, serviceStore]);

  return (
    <ApplicationLayout className={'MyServiceLayout'}>
      <ApplicationLayout.Header>
        <FotaHeader />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar className='bg-muted'>
        <FotaSidebar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Sidebar className='right'>
        {deviceId && <VehicleDetailSidebar deviceId={deviceId} vin={vin} />}
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className='responsive'>
        <NotificationsContainer />
        {serviceStore.serviceMenuDialogOpen &&
          <ServiceMenuDialog/>
        }
        <MainTable
          selectedRow={deviceId}
          paginationStore={paginationStore}
          filterStore={filterStore}
        />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
});