import { observer } from 'mobx-react-lite';
import StatsWidgets from '@rio-cloud/rio-uikit/lib/es/StatsWidgets';
import StatsWidget from '@rio-cloud/rio-uikit/lib/es/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/lib/es/StatsWidgetNumber';
import StatsWidgetSpacer from '@rio-cloud/rio-uikit/lib/es/StatsWidgetSpacer';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useStores } from '@stores/context.jsx';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { campaignKpiStatus } from '../Campaign.constants.jsx';
import { useTranslation } from 'react-i18next';

export default observer(() => {
    const { campaignStore, serviceStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [kpiObject, setKpiObject] = useState({
        campaignKPI: {
            started: 0,
            stopped: 0,
            created: 0,
        },
        rolloutsKPI: {
            total: 0,
            started: 0,
            queued: 0,
            succeeded: 0,
            failed: 0,
            warning: 0,
            cancelled: 0,
        },
    });

    const searchKpiMetrics = useCallback(
        debounce(async () => {
            const kpiObject = await campaignStore.getCampaignMetrics();
            setKpiObject(kpiObject);
            return kpiObject;
        }, 200),
        [],
    );

    useEffect(() => {
        searchKpiMetrics();
    }, [campaignStore, campaignStore.shouldReload]);

    return (
        <div className='display-grid gap-20'>
            <div className='display-grid gap-20 grid-cols-2 margin-bottom-20'>
                <StatsWidgets data-testid='campaignKpi' className=''>
                    <StatsWidget>
                        <StatsWidget.Header>
                            <div className={'text-bold text-size-h4'}>{`${t("fotaone.mu.campaignData.campaign.many")}`}</div>
                        </StatsWidget.Header>
                        <StatsWidget.Body>
                            {campaignStore.kpiState.isLoading ? (
                                <Spinner text={`${t("fotaone.general.loading")}`} />
                            ) : (
                                <>
                                    <StatsWidgetNumber
                                        data-testid='campaignsCreated'
                                        className='text-color-primary'
                                        value={kpiObject.campaignKPI.created}
                                        label={`${t("fotaone.mu.campaignData.state.created")}`}
                                    />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber
                                        data-testid='campaignsStarted'
                                        className='text-color-success'
                                        value={kpiObject.campaignKPI.started}
                                        label={`${t("fotaone.mu.campaignData.state.started")}`}
                                    />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber
                                        data-testid='campaignsStopped'
                                        className='text-color-warning'
                                        value={kpiObject.campaignKPI.stopped}
                                        label={`${t("fotaone.mu.campaignData.state.stopped")}`}
                                    />
                                </>
                            )}
                        </StatsWidget.Body>
                    </StatsWidget>
                </StatsWidgets>
                <StatsWidgets data-testid='rolloutKpi' className={'margin-top-0'}>
                    <StatsWidget>
                        <StatsWidget.Header>
                            <div className={'text-bold text-size-h4'}>{`${t("fotaone.mu.rolloutData.rollout.many")}`}</div>
                        </StatsWidget.Header>
                        <StatsWidget.Body>
                            {campaignStore.kpiState.isLoading ? (
                                <Spinner text='Loading' />
                            ) : (
                                <>
                                    <StatsWidgetNumber
                                        data-testid='rolloutsTotal'
                                        className='text-color-rating-5 cursor-pointer underline-on-hover'
                                        value={kpiObject.rolloutsKPI.total}
                                        label={`${t("fotaone.mu.rolloutData.state.total")}`}
                                        onClick={() => navigate(`${serviceStore.currentService}/rollouts`)}
                                    />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber
                                        data-testid='rolloutsStarted'
                                        className='text-color-primary cursor-pointer underline-on-hover'
                                        value={kpiObject.rolloutsKPI.started}
                                        label={`${t("fotaone.mu.rolloutData.state.started")}`}
                                        onClick={() =>
                                            navigate(
                                                `${serviceStore.currentService}/rollouts?hasCampaign=true&rolloutState=${campaignKpiStatus.STARTED}`,
                                            )
                                        }
                                    />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber
                                        data-testid='rolloutsQueued'
                                        className='text-color-secondary cursor-pointer underline-on-hover'
                                        value={kpiObject.rolloutsKPI.queued}
                                        label={`${t("fotaone.mu.rolloutData.state.queued")}`}
                                        onClick={() =>
                                            navigate(
                                                `${serviceStore.currentService}/rollouts?hasCampaign=true&rolloutState=${campaignKpiStatus.QUEUED}`,
                                            )
                                        }
                                    />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber
                                        data-testid='rolloutsSucceeded'
                                        className='text-color-success cursor-pointer underline-on-hover'
                                        value={kpiObject.rolloutsKPI.succeeded}
                                        label={`${t("fotaone.mu.rolloutData.state.succeeded")}`}
                                        onClick={() =>
                                            navigate(
                                                `${serviceStore.currentService}/rollouts?hasCampaign=true&rolloutState=${campaignKpiStatus.SUCCEEDED}`,
                                            )
                                        }
                                    />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber
                                        data-testid='rolloutsFailed'
                                        className='text-color-danger cursor-pointer underline-on-hover'
                                        value={kpiObject.rolloutsKPI.failed}
                                        label={`${t("fotaone.mu.rolloutData.state.failed")}`}
                                        onClick={() =>
                                            navigate(
                                                `${serviceStore.currentService}/rollouts?hasCampaign=true&rolloutState=${campaignKpiStatus.FAILED}`,
                                            )
                                        }
                                    />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber
                                        data-testid='rolloutsWarning'
                                        className='text-color-warning cursor-pointer underline-on-hover'
                                        value={kpiObject.rolloutsKPI.warning}
                                        label={`${t("fotaone.mu.rolloutData.state.warning")}`}
                                        onClick={() =>
                                            navigate(
                                                `${serviceStore.currentService}/rollouts?hasCampaign=true&rolloutState=${campaignKpiStatus.WARNING}`,
                                            )
                                        }
                                    />
                                    <StatsWidgetSpacer />
                                    <StatsWidgetNumber
                                        data-testid='rolloutsCancelled'
                                        className='text-color-gray cursor-pointer underline-on-hover'
                                        value={kpiObject.rolloutsKPI.cancelled}
                                        label={`${t("fotaone.mu.rolloutData.state.cancelled")}`}
                                        onClick={() =>
                                            navigate(
                                                `${serviceStore.currentService}/rollouts?hasCampaign=true&rolloutState=${campaignKpiStatus.CANCELLED}`,
                                            )
                                        }
                                    />
                                </>
                            )}
                        </StatsWidget.Body>
                    </StatsWidget>
                </StatsWidgets>
            </div>
        </div>
    );
});
