import { formatDistanceToNow } from 'date-fns';
import { get } from 'lodash';
import { toUtcDate } from '@helpers/date.js';
import { activationsManNowStatus } from '@helpers/constants.jsx';
import { devicesType, overrideStatus, deactivationReasonEnum } from './Vehicle.constants';
import moment from 'moment';
import i18n from 'i18next';
import { de, pt } from 'date-fns/locale';

//TODO: Create tests for this
export const getVehicleStatus = (row) => {
    let vehicleState = activationsManNowStatus.Error;
    const { assets = [] } = row ?? {};

    if (assets.some((asset) => asset.activations.length > 0)) {
        if (
            assets.some((asset) => asset.activations.some((activation) => activation.source_active === true)) &&
            assets.some((asset) => asset.activations.some((activation) => activation.source_active === false))
        ) {
            vehicleState = activationsManNowStatus.Conflict;
        } else if (assets.some((asset) => asset.activations.some((activation) => activation.source_active === true))) {
            vehicleState = activationsManNowStatus.Active;
        } else if (assets.some((asset) => asset.activations.some((activation) => activation.source_active === false))) {
            vehicleState = activationsManNowStatus.Inactive;
        }
    } else {
        vehicleState = activationsManNowStatus.Never;
    }

    return vehicleState;
};

//TODO: Create tests for this
export const getManNowStatus = (deviceState) => {
    const response = {
        color: null,
        state: null,
        title: null,
    };

    switch (deviceState) {
        case activationsManNowStatus.Active:
            response.color = 'badge-success';
            response.state = `${i18n.t('fotaone.general.vehicleData.state.active')}`;
            response.title = `${i18n.t('fotaone.general.vehicleData.state.active')}`;
            break;
        case activationsManNowStatus.Inactive:
            response.color = 'badge-danger';
            response.state = `${i18n.t('fotaone.general.vehicleData.state.inactive')}`;
            response.title = `${i18n.t('fotaone.general.vehicleData.state.inactive')}`;
            break;
        case activationsManNowStatus.Never:
            response.color = 'badge-default';
            response.state = `${i18n.t('fotaone.general.vehicleData.state.neverActivated')}`;
            response.title = `${i18n.t('fotaone.general.vehicleData.state.neverActivated')}`;
            break;
        case activationsManNowStatus.Conflict:
            response.color = 'badge-warning';
            response.state = `${i18n.t('fotaone.general.vehicleData.state.conflict')}`;
            response.title = `${i18n.t('fotaone.general.vehicleData.state.conflict')}`;
            break;
        default:
            response.color = 'badge-warning';
            response.state = `${i18n.t('fotaone.general.vehicleData.state.error')}`;
            response.title = `${i18n.t('fotaone.general.vehicleData.state.error')}`;
            break;
    }
    return response;
};

//TODO: Create tests for this
export const getFotaStatus = (activations_override) => {
    const response = { color: '', state: '', title: '' };

    if (activations_override.length > 0) {
        if (activations_override.some((activation) => activation?.status === overrideStatus.Active)) {
            response.color = 'bg-success';
            response.state = `${i18n.t('fotaone.general.vehicleData.state.active')}`;
            response.title = `${i18n.t('fotaone.general.vehicleData.state.active')}`;
        } else if (activations_override.some((activation) => activation?.status === overrideStatus.Inactive)) {
            response.color = 'bg-danger';
            response.state = `${i18n.t('fotaone.general.vehicleData.state.inactive')}`;
            response.title = `${i18n.t('fotaone.general.vehicleData.state.inactive')}`;
        } else {
            response.color = 'bg-warning';
            response.state = `${i18n.t('fotaone.general.vehicleData.state.error')}`;
            response.title = `${i18n.t('fotaone.general.vehicleData.state.error')}`;
        }
    }
    return response;
};

export const formatActivationDate = (activation_date) => {
    let locale;
    switch (i18n.resolvedLanguage) {
        case 'en':
            locale = null;
            break;
        case 'de':
            locale = de;
            break;
        case 'pt':
            locale = pt;
            break;
    }
    return activation_date
        ? `${formatDistanceToNow(toUtcDate(activation_date), { locale: locale })} ${i18n.t(
              'fotaone.general.timeData.ago'
          )}`
        : '';
};
export const formatDate = (_, row, key) => {
    const response = { state: '' };
    const date = get(row, key);
    if (date) response.state = date;
    return response;
};

export const getVcmInformation = (devices) => {
    const response = {
        serial: null,
        type: null,
    };
    const vcm = devices.find((device) => device?.type === devicesType.Vcm);
    if (vcm) {
        response.serial = vcm?.serial_number ?? 'ERROR';
        response.type = vcm?.type ?? 'ERROR';
    }
    return response;
};
export const getAccountIdListFromAsset = (assets = []) => {
    return assets.map((asset) => asset.account_id);
};

export const getActivationDate = (_, row, key) => {
    let activationDate = null;
    if (key === 'man_now_change_date') {
        activationDate = getActivationsNewDate(row);
    } else if (key === 'fota_override_change_date') {
        activationDate = getActivationsOverrideDate(row);
    } else if (key === 'first_activated_at') {
        activationDate = getFirstActivatedAt(row);
    } else if (key === 'first_coverage_request_at') {
        activationDate = getFirstCoverageRequestAt(row);
    }
    return activationDate;
};

//TODO: Create tests for this
export const getDeactivationReason = (row) => {
    const { assets } = row;
    let lastDeactivationDate = null;
    let deactivationReason = null;

    const isDeactivationDateValid = (date) => moment(date).isValid();

    if (getVehicleStatus(row) === activationsManNowStatus.Inactive) {
        // Find the latest deactivation reason
        assets.forEach((asset) => {
            if (asset.activations.length > 0) {
                asset.activations.forEach((activation) => {
                    const { deactivated_at, deactivation_reason } = activation;

                    if (deactivated_at != null && isDeactivationDateValid(deactivated_at)) {
                        const currentDeactivationDate = moment(deactivated_at);

                        if (lastDeactivationDate === null || currentDeactivationDate.isAfter(lastDeactivationDate)) {
                            lastDeactivationDate = currentDeactivationDate;
                            deactivationReason = deactivation_reason;
                        }
                    }
                });
            }
        });
    }

    return deactivationReasonEnum[deactivationReason];
};

export const getDeactivationReasonProps = (deactivationReason) => {
    return {
        color: 'bg-danger',
        state: `${i18n.t(deactivationReason)}`,
        title: `${i18n.t(deactivationReason)}`,
    };
};

export const getActivationsNewDate = (row) => {
    const { assets } = row;
    let lastChangeDate = '';

    /*
     * Todo: this approach doesn't handle the situation if we have multiple assets associated (renter, rental-company)
     *  that have both conflicting activation states; They way it is not
     * Multiple assets-Case:
     * 1. Active, 2. inactive:
     * Show activated_at
     *
     * 1. inactive, 2. inactive
     * show recent deactivated at.
     *
     * 1. active, 2 active,
     * show recent activated_at
     *
     */
    assets.forEach((asset) => {
        const { date } = getActivationDateFromAsset(asset);
        lastChangeDate = date;
    });

    return lastChangeDate;
};
export const getActivationsOverrideDate = (row) => {
    const { activations_override } = row;

    return activations_override.length > 0 ? activations_override[0].created_at : null;
};

export const getFirstActivatedAt = (row) => {
    const { assets } = row;
    let lastChangeDate = null;
    assets.forEach((asset) => {
        if (asset.activations.length > 0) {
            if (asset.activations[0]?.first_activated_at !== null) {
                const firstActivatedAt = moment(asset.activations[0].first_activated_at);
                if (moment(lastChangeDate).isValid() && firstActivatedAt.isBefore(moment(lastChangeDate))) {
                    lastChangeDate = asset.activations[0].first_activated_at;
                }
                if (!moment(lastChangeDate).isValid()) {
                    lastChangeDate = asset.activations[0].first_activated_at;
                }
            }
        }
    });
    return lastChangeDate;
};

export const getFirstCoverageRequestAt = (row) => {
    const { assets } = row;
    let lastChangeDate = null;
    assets.forEach((asset) => {
        if (asset.activations.length > 0) {
            if (asset.activations[0]?.first_coverage_request_at !== null) {
                const firstCoverageRequestAt = moment(asset.activations[0].first_coverage_request_at);
                if (moment(lastChangeDate).isValid() && firstCoverageRequestAt.isBefore(moment(lastChangeDate))) {
                    lastChangeDate = asset.activations[0].first_coverage_request_at;
                }
                if (!moment(lastChangeDate).isValid()) {
                    lastChangeDate = asset.activations[0].first_coverage_request_at;
                }
            }
        }
    });
    return lastChangeDate;
};

//Sidebar

export const getActivationDateFromAsset = (asset) => {
    const response = {
        type: '',
        date: '',
    };
    if (asset.activations.length > 0) {
        const activationActive = asset.activations.find((activation) => activation.source_active === true);
        const activationInactive = asset.activations.find((activation) => activation.source_active === false);
        if (activationActive) {
            response.date = activationActive.activated_at;
            response.type = `${i18n.t('fotaone.mu.regionData.activatedAt')}`;
        } else if (activationInactive) {
            response.date = activationInactive.deactivated_at;
            response.type = `${i18n.t('fotaone.mu.regionData.deactivatedAt')}`;
        }
    }
    return response;
};

export const getAssetStatus = (asset) => {
    let vehicleState = activationsManNowStatus.Error;

    if (asset.activations.length > 0) {
        if (asset.activations.some((activation) => activation.source_active === true)) {
            vehicleState = activationsManNowStatus.Active;
        } else if (asset.activations.some((activation) => activation.source_active === false)) {
            vehicleState = activationsManNowStatus.Inactive;
        }
    } else {
        vehicleState = activationsManNowStatus.Never;
    }

    return vehicleState;
};
