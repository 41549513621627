import { AuthStore } from '@stores/auth';

export const configureAxiosClient = (client) => {
    const authStore = AuthStore.instance();
    client.interceptors.request.use((request) => {
        // add auth header
        const authorization = localStorage.getItem('Authorization');
        if (authorization) {
            request.headers = {
                ...request.headers,
                "Authorization": authorization,
            };
        }
        return request;
    });
    client.interceptors.response.use(
        (response) => response,
        (error) => {
            // auto-logout on auth failure
            if (error.response.status === 401) {
                authStore.logout();
            }
            return Promise.reject(error);
        },
    );
    return client;
};
