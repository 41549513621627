import { debounce } from 'lodash';
import { Observer, observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import {filterDataKeys, vehicleMainTable} from '../Vehicle.constants';
import { useStores } from '@stores/context.jsx';
import Table from '@common/Table/Table';
import Pagination from '@common/Pagination/Pagination';
import Filter from '@common/Filter/Filter';
import FilterButton from '@common/Buttons/FilterButton';
import { cloneObject } from '@helpers/object.js';
import ExportButton from '@common/Buttons/ExportButton';
import { SupportedFileTypes } from '@stores/exports.js';
import { useNavigate, useLocation } from 'react-router-dom';
import {removeParamFromIrl, setQueryParamToUrl} from "../../../mapupdate/mapupdateHelper/utils.jsx";
import { useTranslation } from 'react-i18next';

const { columnDetails, defaultColumnOrder, columns, columnOrder, hiddenColumns } = vehicleMainTable;

const VehicleTable = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { exportsStore, vehicleStore, serviceStore } = useStores();
    const [showFilter, setShowFilter] = useState(false);
    const { t } = useTranslation();

    const { paginationStore, filterStore } = props;

    const limit = paginationStore?.limit;
    const activePage = paginationStore?.activePage;
    const offset = paginationStore?.offset;

    const query = filterStore?.query;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;
    const filters = filterStore?.filters;

    const searchDevices = useCallback(
        debounce(async (params) => {
            const { list, total, vehicleDeviceEnvironment } = await vehicleStore.searchVehicles(params);
            vehicleStore.setVehicleList(list);
            vehicleStore.setVehicleTotal(total);
            paginationStore.setItemsLength(vehicleStore.vehicleTotal);
        }, 200),
        [],
    );

    useEffect(() => {
        searchDevices({ query, sortBy, sortDir, limit, filters, offset });
    }, [query, sortBy, sortDir, limit, offset, filters, activePage, searchDevices]);

    const rowClicked = (row) => {
        navigate(`${serviceStore.currentService}/vehicles/${row.vin}${location.search}`);
    };

    const handleSearchValueChange = (search) => {
        setQueryParamToUrl('query', search);
        filterStore.setQuery(search);
        paginationStore.setActivePage(1);
    };

    const handleSortChange = (_sortBy, _sortDir) => {
        filterStore.setSortBy(_sortBy);
        filterStore.setSortDir(_sortDir);
    };

    const handleFilterState = (state) => {
        setShowFilter(state);
    };
    const handleFilterConfirmation = (newData) => {
        newData.forEach((item) => {
            if (item.key === filterDataKeys.ManNow) {
                if (item.result.length > 0) {
                    setQueryParamToUrl('manNow', item.result.join(','));
                } else {
                    removeParamFromIrl('manNow');
                }
            }
            if (item.key === filterDataKeys.FotaStatus) {
                if (item.result.length > 0) {
                    setQueryParamToUrl('fotaStatus', item.result.join(','));
                } else {
                    removeParamFromIrl('fotaStatus');
                }
            }
            if (item.key === filterDataKeys.TestFleet) {
                if (item.result.length > 0) {
                    setQueryParamToUrl('tags', item.result.join(','));
                } else {
                    removeParamFromIrl('tags');
                }
            }
            if (item.key === filterDataKeys.Environment) {
                if (item.result.length > 0) {
                    setQueryParamToUrl('environment', item.result.join(','));
                } else {
                    removeParamFromIrl('environment');
                }
            }
        });
        filterStore.setFilters(cloneObject(newData));
    };
    const handleExportButton = async () => {
        const params = {
            type: SupportedFileTypes.CSV,
            feature: serviceStore.currentService,
            headers: cloneObject(columns),
            query,
            filters,
            sortBy,
            sortDir,
        };
        await exportsStore.exportAsFile(params);
    };

    return (
        <div>
            <Observer>
                {() => (
                    <Filter
                        filters={filters}
                        title={`${t("fotaone.mu.filterVehicles")}`}
                        showFilter={showFilter}
                        onHide={() => handleFilterState(false)}
                        onConfirm={(data) => handleFilterConfirmation(data)}
                        id={'onlinetraffic-filter-modal'}
                    />
                )}
            </Observer>
            <Table
                showSearch={true}
                toolbarElementLeft={
                    <FilterButton id={'filter-online-traffic'} onShowFilter={() => handleFilterState(true)} />
                }
                toolbarElement={
                    <Observer>
                        {() => (
                            <ExportButton
                                isLoading={exportsStore?.stateMachine?.isLoading}
                                disabled={exportsStore?.stateMachine?.isLoading}
                                id={'export-online-traffic'}
                                onExport={() => handleExportButton()}
                            />
                        )}
                    </Observer>
                }
                onRowClick={rowClicked}
                rowKey='vin'
                selectedRow={props.selectedRow}
                handleSearchValueChange={handleSearchValueChange}
                handleSortChange={handleSortChange}
                searchValue={query}
                data={vehicleStore.vehicleList || []}
                columnDetails={columnDetails}
                defaultColumnOrder={defaultColumnOrder}
                columns={columns}
                columnOrder={columnOrder}
                actionColumn='buttons'
                loading={vehicleStore?.stateMachine?.isLoading}
                filterStore={filterStore}
                hiddenColumns={hiddenColumns}
            />
            <Pagination paginationStore={paginationStore} />
        </div>
    );
};

export default observer(VehicleTable);
