import React from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import './Loader.css';

const Loader = ({ center, right, className }) => {
    const classes = ['loader'];
    if (center) {
        classes.push('loader-center');
    }
    if (right) {
        classes.push('pull-right');
    }
    if (className) {
        classes.push(className);
    }
    return (
        <div className={classes.join(' ')}>
            <Spinner isDoubleSized />
        </div>
    );
};

export default Loader;
