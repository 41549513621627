import { observer } from 'mobx-react-lite';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import React, { useEffect } from 'react';
import ServicesKpi from '@components/FeatureDashboard/DigitalServices/Services/KPI/ServicesKpi.jsx';
import ServicesTable from '@components/FeatureDashboard/DigitalServices/Services/Table/ServicesTable.jsx';
import { useParams } from 'react-router-dom';
import { useStores } from '@stores/context.jsx';
import { FilterStore } from '@stores/filter.jsx';
import ServiceSidebar from '@components/FeatureDashboard/DigitalServices/Services/Sidebar/ServiceSidebar.jsx';

const filterStore = new FilterStore();
const ServicesPage = () => {
  const { id: featureId } = useParams();
  const { serviceStore } = useStores();

  useEffect(() => {
    // When unmounting the page:
    return () => {
      filterStore.resetToDefaults();
    };
  }, [serviceStore]);
  return (
    <ApplicationLayout className={'MyServiceLayout'}>
      <ApplicationLayout.Header>
        <FotaHeader />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar className='bg-muted'>
        <FotaSidebar files={true} />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Sidebar className='right'>
        {featureId && <ServiceSidebar featureId={featureId} />}
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className='responsive'>
        <NotificationsContainer />
        {serviceStore.serviceMenuDialogOpen &&
          <ServiceMenuDialog/>
        }
        <ServicesKpi />
        <ServicesTable
          howSearch={true}
          fetchFiles={true}
          actionButtons={true}
          selectedRow={featureId}
          filterStore={filterStore}
        />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
};

export default observer(ServicesPage);