import React, { useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';

export default observer(() => {
    const { t } = useTranslation();
    const { groupStore } = useStores();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);

    const onCreateGroup = () => {
        groupStore.createGroup({ name, description });
        setShowCreateGroupDialog(false);
    };

    const renderCreateGroupDialog = () => {
        const onHide = () => setShowCreateGroupDialog(false);
        return (
            <Dialog
                show={showCreateGroupDialog}
                title={`${t("fotaone.mu.groupData.createGroup")}`}
                body={renderBody()}
                onHide={onHide}
                className='fileUploadForm'
                showCloseButton={true}
            />
        );
    };

    const renderBody = () => {
        return (
            <div className='row equal-height'>
                <div className='col-xs-12 col-md-12'>
                    <form>
                        <fieldset>
                            <div
                                className={
                                    name ? 'form-group has-success has-feedback' : 'form-group has-error has-feedback'
                                }
                            >
                                <label htmlFor='inputName'>{`${t("fotaone.general.name")}`}</label>
                                <input
                                    className='form-control'
                                    id='inputName'
                                    type='text'
                                    placeholder={`${t("fotaone.mu.groupData.groupName")}`}
                                    onChange={(event) => setName(event.target.value)}
                                />
                                <span
                                    className={
                                        name
                                            ? 'form-control-feedback rioglyph rioglyph-ok'
                                            : 'form-control-feedback rioglyph rioglyph-error-sign'
                                    }
                                />
                            </div>
                            <div
                                className={
                                    description
                                        ? 'form-group has-success has-feedback'
                                        : 'form-group has-error has-feedback'
                                }
                            >
                                <label htmlFor='inputDesc'>{`${t("fotaone.general.description")}`}</label>
                                <input
                                    className='form-control'
                                    id='inputDesc'
                                    placeholder={`${t("fotaone.mu.groupData.groupDesc")}`}
                                    type='text'
                                    onChange={(event) => setDescription(event.target.value)}
                                />
                                <span
                                    className={
                                        description
                                            ? 'form-control-feedback rioglyph rioglyph-ok'
                                            : 'form-control-feedback rioglyph rioglyph-error-sign'
                                    }
                                />
                            </div>

                            <button
                                type='button'
                                className='btn btn-primary'
                                onClick={onCreateGroup}
                                disabled={!name || !description}
                            >
                                {`${t("fotaone.mu.groupData.createGroup")}`}
                            </button>
                        </fieldset>
                    </form>
                </div>
            </div>
        );
    };

    const render = () => {
        return (
            <div className='table-toolbar-column pull-right'>
                <button type='button' className='btn btn-primary' onClick={() => setShowCreateGroupDialog(true)}>
                    {`${t("fotaone.mu.groupData.createGroup")}`}
                </button>
                {renderCreateGroupDialog()}
            </div>
        );
    };

    return render();
});
