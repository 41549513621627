import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';

const VehicleDetailsDevice = observer(() => {
    const { remoteMessageStore } = useStores();
    const { t } = useTranslation();
    return (
        remoteMessageStore.vehicleDetails && (
            <>
                <h5>{`${t("fotaone.general.vehicleData.device")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.deviceId")}`}</label>
                            <span className='text-primary'>
                                {get(remoteMessageStore.vehicleDetails, ['id'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.serialnumber")}`}</label>
                            <span className='text-primary'>
                                {`${get(remoteMessageStore.vehicleDetails,['type'])?.toUpperCase()}:${get(remoteMessageStore.vehicleDetails,['serial_number'])}`}
                            </span>
                            &nbsp;
                            <span className='margin-left-10 label label-muted label-filled label-condensed'>
                                <a
                                    className='text-color-info'
                                    href={get(remoteMessageStore.vehicleDetails, ['type'])==="CM4"
                                    ?   
                                    `https://ota.admin-eu.rio.cloud/#devices?serial-number-prefix=cm4_${get(
                                            remoteMessageStore.vehicleDetails,
                                            ['serial_number'],
                                        )}&deviceSelected=true`
                                    :
                                    `https://ota.admin-eu.rio.cloud/#devices?serial-number-prefix=${get(
                                            remoteMessageStore.vehicleDetails,
                                            ['serial_number'],
                                        )}&deviceSelected=true`
                                    }
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {`${t("fotaone.general.vehicleData.mdmDevice")}`}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    );
});

export default VehicleDetailsDevice;
