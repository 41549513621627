import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const RemoteMessageMenu = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='text-size-h2 text-thin'>
                Remote Message
            </div>
            <Divider />
            <p>
                {t("fotaone.general.serviceMenu.remoteMessageSection.serviceDesc")}
            </p>
            <div className='text-size-h5 margin-bottom-5'>
                <span className='rioglyph rioglyph-truck margin-right-10' />
                <strong>{t('fotaone.general.vehicleData.vehicle.many')}</strong>
            </div>
            <ul>
                <li>
                    <p>
                        <strong>{t('fotaone.general.overview')}</strong>: {' '}{t('fotaone.general.serviceMenu.remoteMessageSection.vehiclesDesc')}
                    </p>

                </li>
            </ul>
            <div className='text-size-h5 margin-bottom-5'>
                <span className='rioglyph rioglyph-comment margin-right-10' />
                <strong>{t('fotaone.rm.messageData.message.many')}</strong>
            </div>
            <ul>
                <li>
                    <p>
                        <strong>{t('fotaone.general.overview')}</strong>: {' '}{t('fotaone.general.serviceMenu.remoteMessageSection.messagesDesc')}
                    </p>

                </li>
                <li>
                    <p>
                        <strong>{t('fotaone.rm.templateData.template.many')}</strong>: {' '}{t('fotaone.general.serviceMenu.remoteMessageSection.templateDesc')}
                    </p>

                </li>
            </ul>
            <div className='text-size-h5 margin-bottom-5'>
                <span className='rioglyph rioglyph-comment margin-right-10' />
                <strong>{t('fotaone.rm.messageData.myMessages')}</strong>
            </div>
            <ul>
                <li>
                    <p>
                        <strong>{t('fotaone.general.overview')}</strong>: {' '}{t('fotaone.general.serviceMenu.remoteMessageSection.myMessagesDesc')}
                    </p>

                </li>
                <li>
                    <p>
                        <strong>{t('fotaone.rm.messageData.toApprove')}</strong>: {' '}{t('fotaone.general.serviceMenu.remoteMessageSection.toApproveDesc')}
                    </p>

                </li>
            </ul>
        </>
    );
};

export default observer(RemoteMessageMenu);