import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants.js';
import Details from './Details.jsx';
import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar.jsx';

const MessageDetailSidebar = observer((props) => {
    const { serviceStore, remoteMessageStore } = useStores();
    const { messageId } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const close = async () => {
        navigate(`${serviceStore.currentService}/messages${location.search}`);
    };
    return (
        <DetailsSideBar
            title={`${t("fotaone.rm.messageData.messageDetails")}`}
            onClose={close}
            width={600}
            blurEffect={remoteMessageStore.messageDetailsLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide}
            fly
        >
            <Details messageId={messageId} />
        </DetailsSideBar>
    );
});

export default MessageDetailSidebar;