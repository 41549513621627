import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';
import Pagination from '@common/Pagination/Pagination';
import Table from '@common/Table/Table.jsx';
import { vehicleDetailsMessagesTableData } from '@components/RemoteMessage/RemoteMessageHelper/constants.jsx';

const VehicleDetailsMessages = observer(() => {
    const { serviceStore, remoteMessageStore, paginationStore, filterStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const query = filterStore?.query;
    const filters = filterStore?.filters;
    const activePage = paginationStore?.activePage;
    const limit = paginationStore?.limit;
    const offset = paginationStore?.offset;

    paginationStore.setItemsLength(remoteMessageStore.vehicleDetails?.device_messages_aggregate?.aggregate?.count);
    
    const handleSearchValueChange = (search) => {
    setQueryParamToUrl('query', search);
    filterStore.setQuery(search);
    paginationStore.setActivePage(1);
    };

    const handleSortChange = (_sortBy, _sortDir) => {
        filterStore.setSortBy(_sortBy);
        filterStore.setSortDir(_sortDir);
    };

    const handleRowClick = (row) => {
        navigate(`${serviceStore.currentService}/messages/${row.message_id}${location.search}`);
    };

    return (
        remoteMessageStore.vehicleDetails && (
            <>
                <h5>{`${t("fotaone.rm.messageData.message.many")}`}</h5>
                <div className="breakline margin-bottom-20" />
                <div className="row">
                    <div className="col-md-12">
                        <div className="margin-bottom-10">
                        <div>
                            <Table
                                data = {remoteMessageStore.vehicleDetails?.device_messages}
                                searchValue={query}
                                columns={vehicleDetailsMessagesTableData.columns}
                                columnDetails={vehicleDetailsMessagesTableData.columnDetails}
                                columnLabels={vehicleDetailsMessagesTableData.columnLabels}
                                onRowClick={handleRowClick}
                                handleSearchValueChange={handleSearchValueChange}
                                handleSortChange={handleSortChange}
                                loading={remoteMessageStore?.vehicleDetailsLoading}
                                withoutAlerts
                            />
                            <Pagination paginationStore={paginationStore} />
                        </div>
                        </div>
                    </div>
                </div>
            </>
        )
    );
});

export default VehicleDetailsMessages;
