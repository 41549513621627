import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { useStores } from '@stores/context.jsx';
import Table from '@common/Table/Table.jsx';
import Pagination from '@common/Pagination/Pagination.jsx';
import { setQueryParamToUrl } from '@components/mapupdate/mapupdateHelper/utils.jsx';
import { myMessagesTableData } from '@components/RemoteMessage/RemoteMessageHelper/constants.jsx';
import CreateMessageDialog from '@components/Dialogs/RemoteMessage/CreateMessageDialog.jsx';
import { useTranslation } from 'react-i18next';

const MyMessagesTable = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { serviceStore, remoteMessageStore } = useStores();
  const {
    paginationStore, filterStore, query, setShowMyMessageConfirmationDialog, setConfirmationType,
  } = props;

  const rowClicked = (row) => {
    navigate(`${serviceStore.currentService}/myMessages/${row.id}${location.search}`);
  };

  const handleSearchValueChange = (search) => {
    setQueryParamToUrl('query', search);
    filterStore.setQuery(search);
    paginationStore.setActivePage(1);
  };

  const handleSortChange = (_sortBy, _sortDir) => {
    filterStore.setSortBy(_sortBy);
    filterStore.setSortDir(_sortDir);
  };

  const stopPropagation = (e) => e.stopPropagation();

  const ActionButton = ({ iconClass, tooltipText, onClick, notAllowed }) => (
    <OverlayTrigger
        placement='bottom'
        overlay={
          <Tooltip
              id='tooltip'
              allowOnTouch
          >
            {notAllowed? t("fotaone.notification.warning.devices.noTargetSelected") :tooltipText}
          </Tooltip>
        }
    >
      <span className={`margin-right-10 ${notAllowed}`} onClick={notAllowed?'':onClick}> 
        <span className={iconClass} />
      </span>
    </OverlayTrigger>);

  const renderActions = (row) => {
    switch (row.status) {
      case 'DRAFT':
      case 'REJECTED':
        return (<div className='text-right'>
          {row.status === 'DRAFT' && (<ActionButton
            iconClass='rioglyph-send text-size-16 text-color-info'
            tooltipText={`${t("fotaone.rm.messageData.messageAction.requestApproval")}`}
            onClick={(e) => {
              stopPropagation(e);
              setConfirmationType('requestApproval');
              remoteMessageStore.setEditMyMessage(row);
              setShowMyMessageConfirmationDialog(true);
            }}
            notAllowed={row.device_messages.length === 0? 'cursor-not-allowed' : null}
          />)}
          <ActionButton
            iconClass='rioglyph-pencil text-size-16 text-color-primary'
            tooltipText={`${t("fotaone.rm.messageData.messageAction.editMessage")}`}
            onClick={(e) => {
              stopPropagation(e);
              remoteMessageStore.setEditMyMessage(row);
              remoteMessageStore.setMessageDialogOpen(true)
            }}
          />
          <ActionButton
            iconClass='rioglyph-trash text-size-16 text-color-danger'
            tooltipText={`${t("fotaone.rm.messageData.messageAction.deleteMessage")}`}
            onClick={(e) => {
              stopPropagation(e);
              setConfirmationType('deleteMessage');
              remoteMessageStore.setEditMyMessage(row);
              setShowMyMessageConfirmationDialog(true);
            }}
          />
        </div>);
      case 'PENDING_APPROVAL':
        return (<div className='text-right'>
          <ActionButton
            iconClass='rioglyph-remove text-size-16 text-color-gray'
            tooltipText={`${t("fotaone.rm.messageData.messageAction.cancelApprovalRequest")}`}
            onClick={(e) => {
              stopPropagation(e);
              setConfirmationType('cancelRequestApproval');
              remoteMessageStore.setEditMyMessage(row);
              setShowMyMessageConfirmationDialog(true);
            }}
          />
        </div>);
      case 'SENDING_FAILED':
        return (<div className='text-right'>
          <ActionButton
            iconClass='rioglyph-refresh text-size-16 text-color-light'
            tooltipText={`${t("fotaone.rm.messageData.messageAction.resendMessage")}`}
            onClick={stopPropagation}
          />
        </div>);
      default:
        return null;
    }
  };

  return (<div className='message-table'>
    <Table
      toolbarElement={<CreateMessageDialog />}
      onRowClick={rowClicked}
      rowKey='id'
      selectedRow={props.selectedRow}
      showSearch={true}
      handleSearchValueChange={handleSearchValueChange}
      andleSortChange={handleSortChange}
      searchValue={query}
      data={remoteMessageStore.myMessagesList || []}
      columnDetails={myMessagesTableData.columnDetails}
      defaultColumnOrder={myMessagesTableData.defaultColumnOrder}
      columns={myMessagesTableData.columns}
      columnOrder={myMessagesTableData.columnsOrder}
      hiddenColumns={myMessagesTableData.hiddenColumns}
      columnLabels={myMessagesTableData.columnLabels}
      disabledColumns={myMessagesTableData.disabledColumns}
      actionColumn='buttons'
      actionItemRenderer={renderActions}
      loading={remoteMessageStore.myMessagesListLoading}
    />
    <Pagination paginationStore={paginationStore} />
  </div>);
};

export default observer(MyMessagesTable);