import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import { PaginationStore } from '@stores/pagination.js';
import { FilterStore } from '@stores/filter.jsx';
import { useStores } from '@stores/context.jsx';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import FotaHeader from '@components/Navbar/Navbar.jsx';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import ToApproveDetailSidebar from '@components/RemoteMessage/MyMessages/ToApprove /Sidebar/ToApproveDetailSidebar.jsx';
import ToApproveTable from '@components/RemoteMessage/MyMessages/ToApprove /Table/ToApproveTable.jsx';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
  query: '',
});

export default observer(() => {
  const { t } = useTranslation();
  const { id: messageId } = useParams();
  const { remoteMessageStore, serviceStore } = useStores();
  const query = filterStore?.query;
  const limit = paginationStore?.limit;
  const offset = paginationStore?.offset;
  const sortBy = filterStore?.sortBy;
  const sortDir = filterStore?.sortDir;
  const activePage = paginationStore?.activePage;

  const [showDecisionConfirmationDialog, setShowDecisionConfirmationDialog] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');
  const [rejectReason, setRejectReason] = useState('');

  const fetchMessagesToApprove = useCallback(debounce(async (params) => {
    await remoteMessageStore.getMessagesToApprove(params);
    paginationStore.setItemsLength(remoteMessageStore.myMessagesListTotal);
  }, 200), []);

  useEffect(() => {
    fetchMessagesToApprove({
      query, sortBy, sortDir, limit, offset,
    });
  }, [query, sortBy, sortDir, limit, activePage, offset, remoteMessageStore.messageDialogOpen, serviceStore]);


  return (
    <ApplicationLayout className={'MyServiceLayout'}>
      <ApplicationLayout.Header>
        <FotaHeader />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar className='bg-muted'>
        <FotaSidebar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Sidebar className='right'>
        {
          messageId && 
          <ToApproveDetailSidebar 
            messageId={messageId}
            setShowDecisionConfirmationDialog={setShowDecisionConfirmationDialog}
            setConfirmationType={setConfirmationType}
          />
        }
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className='responsive'>
        <NotificationsContainer />
        {serviceStore.serviceMenuDialogOpen &&
          <ServiceMenuDialog/>
        }
        <ToApproveTable
          selectedRow={messageId}
          paginationStore={paginationStore}
          filterStore={filterStore}
          query={query}
          setShowDecisionConfirmationDialog={setShowDecisionConfirmationDialog}
          setConfirmationType={setConfirmationType}
        />
        {remoteMessageStore.handleMessageDecision && <ConfirmationDialog
          show={showDecisionConfirmationDialog}
          title={confirmationType === 'approval' ? 
            <div className="label label-success">
                {`${t("fotaone.rm.messageData.messageAction.confirmApproval")}`}
            </div>
            : confirmationType === 'rejection' ?
            <div className="label label-danger">
                {`${t("fotaone.rm.messageData.messageAction.confirmRejection")}`}
            </div> : ''}
          content={
            confirmationType === 'approval' ? 
            <>
              {`${t("fotaone.notification.warning.messages.approveMessage")} ${remoteMessageStore.handleMessageDecision.title}?`}
              <br/>
              <br/>
              {`${t("fotaone.notification.warning.actions.cantUndo")}`}
            </>
            : confirmationType === 'rejection' ? 
            <>
              {`${t("fotaone.notification.warning.messages.rejectMessage")} ${remoteMessageStore.handleMessageDecision.title}?`}
              <br/>
              <br/>
              {`${t("fotaone.notification.warning.actions.cantUndo")}`}
              <br/>
              <br/>
              Reject Reason:
              <div className={`form-group ${rejectReason ? 'has-success has-feedback' : 'has-error has-feedback'}`}>
                <ClearableInput
                    placeholder='Reject Reason'
                    id='inputRejectReason'
                    type='text'
                    maxLength={1000}
                    value={rejectReason}
                    onChange={event => setRejectReason(event)}
                />
                <span className={`form-control-feedback rioglyph ${rejectReason ? 'rioglyph-ok' : 'rioglyph-error-sign'}`}/>
                <span className="help-block input-group-addon input-group-addon-label">{`${rejectReason.length}/1000`}</span>
              </div>
            </>
          : ''}
          bsSize={Dialog.SIZE_SM}
          onClickConfirm={async () => {
            if (confirmationType === 'approval') {
              await remoteMessageStore.approveMessage({
                messageId: remoteMessageStore.handleMessageDecision.id, query, sortBy, sortDir, limit, offset,
              });
            }

            if (confirmationType === 'rejection') {
              await remoteMessageStore.rejectMessage({
                messageId: remoteMessageStore.handleMessageDecision.id, rejectReason, query, sortBy, sortDir, limit, offset,
              });
              setRejectReason('');
            }

            setShowDecisionConfirmationDialog(false);
            remoteMessageStore.setHandleMessageDecision(null);
          }}
          onClickCancel={() => {
            setConfirmationType('');
            setRejectReason('');
            setShowDecisionConfirmationDialog(false);
            remoteMessageStore.setHandleMessageDecision(null);
          }}
          cancelButtonText={`${t("fotaone.general.abort")}`}
          disableConfirm={confirmationType === 'rejection' ? !rejectReason : rejectReason}
          confirmButtonText={
            <>
              {remoteMessageStore.handleMessageDecisionLoading &&
                <span className='rioglyph rioglyph-spinner spinning' />
              }
              {`${t("fotaone.general.confirm")}`}
            </>
          }
          useOverflow
        />}
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
});