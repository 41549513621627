import OpenAPIClientAxios from 'openapi-client-axios';
import definition from './schemas/mapupdate-openapi.json';
import config from '@src/config';
import { configureAxiosClient } from './api-utils';

let client;

const init = () => {
    definition.servers = [{ url: config.MAPUPDATE_API_URL }];
    const api = new OpenAPIClientAxios({ definition });
    client = api.initSync();
    return configureAxiosClient(client);
};

export { client, init };
