import React from 'react';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
    const { t } = useTranslation();
    return (
        <div className='text-center'>
            <h1> {`${t("fotaone.auth.permissionDenied")}`} </h1>
            <h3> {`${t("fotaone.auth.accessDenied")}`} </h3>
        </div>
    );
};

export default Unauthorized;
