import React from 'react';
import { regionSelectData } from '../../../mapupdateHelper/constants.jsx';
import { useStores } from '@stores/context.jsx';
import * as _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import VehicleDetailsRegionPopover from './VehicleDetailsRegionPopover.jsx';
import { useTranslation } from 'react-i18next';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';

const VehicleDetailsRegions = (props) => {
    const ref = React.createRef();
    const { vehicleId } = props;
    const { assetStore } = useStores();
    const [regionsSelection, setRegionsSelection] = useState([]);
    const { t } = useTranslation();
    const assets = _.get(assetStore.assetDetails, ['vehicles', '0', 'assets']);
    const devices = _.get(assetStore.assetDetails, ['vehicles', '0', 'devices']);
    const activationsOverride = _.get(assetStore.assetDetails, ['vehicles', '0', 'activations_override']);

    useEffect(() => {
        const regions = regionSelectData.map((region) => {
            return {
                ...region,
                short: Object.keys(region)[0],
                description: region[Object.keys(region)[0]],
            };
        });

        assets?.map((asset) => {
            asset.activations.map((activation) => {
                _.find(regions, activation.feature_context.region)['source_active'] = activation.source_active;
                _.find(regions, activation.feature_context.region)['asset_id'] = asset.asset_id;
                _.find(regions, activation.feature_context.region)['activated_at'] = activation.activated_at;
                _.find(regions, activation.feature_context.region)['activation_source'] = activation.activation_source;
                if (!activation.source_active) {
                    _.find(regions, activation.feature_context.region)['deactivated_at'] = activation.deactivated_at;
                }
            });
        });

        activationsOverride?.map((override) => {
            _.find(regions, override.feature_context.region)['override'] = true;
            _.find(regions, override.feature_context.region)['source_active'] = override.status === 'ACTIVE';
            _.find(regions, override.feature_context.region)['overridden_at'] = override.created_at;
        });

        // Each vehicle only has one device
        devices?.[0].maps_installed.map((map) => {
            _.find(regions, map.map_region_enum)['map_version'] = map.map_version;
        });

        setRegionsSelection(regions);
    }, [vehicleId, assetStore.assetDetails]);

    return (
        <>
            <h5>{`${t("fotaone.mu.regionData.region.many")}`}</h5>
            <div className='breakline margin-bottom-20' />
            <div className='row'>
                <div data-testid='regions-activation' className={'col-md-12 display-flex'} style={{ flexFlow: 'wrap' }}>
                    {regionsSelection.map((region) => (
                        <OverlayTrigger
                            key={`${t(region.short)}`}
                            trigger={'click'}
                            placement={'top'}
                            overlay={
                                <Popover
                                    ref={ref}
                                    id={`popover-extended-${region.short}`}
                                    title={
                                        <>
                                            <div className={'text-bold'}>
                                                {`${t(region.description)}`}
                                                <br />
                                                {region.map_version ? (
                                                    <div
                                                        className={
                                                            'label label-success label-filled label-condensed margin-top-5'
                                                        }
                                                    >
                                                        {region.map_version}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {region.overridden_at ? (
                                                    <>
                                                        <div
                                                            className={
                                                                'label label-warning label-filled label-condensed margin-top-5'
                                                            }
                                                        >
                                                            {' '}
                                                            {`${t("fotaone.general.tagData.overrideActive")}`}
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </>
                                    }
                                    titleClassName='bg-lightest'
                                    contentClassName='padding-0'
                                    style={{ maxWidth: 330 }}
                                    data-testid={`region-popover-${region.short}`}
                                >
                                    <VehicleDetailsRegionPopover region={region} vehicleId={vehicleId} />
                                </Popover>
                            }
                        >
                            <div className='indicated-button margin-right-20 margin-bottom-20'>
                                <button
                                    type='button'
                                    className={`btn
                                ${
                                    region.source_active === true
                                        ? 'btn-success btn-outline'
                                        : region.source_active === false
                                        ? 'btn-danger btn-outline'
                                        : 'label-default'
                                }`}
                                >
                                    {region.short}
                                </button>
                                {region.override && (
                                    <span className='badge badge-warning badge-indicator badge-indicator-border'>
                                        <span className='rioglyph rioglyph-wrench' />
                                    </span>
                                )}
                            </div>
                        </OverlayTrigger>
                    ))}
                </div>
            </div>
        </>
    );
};

export default observer(VehicleDetailsRegions);
