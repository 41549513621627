import { useStores } from '@stores/context.jsx';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {severityRender, renderMessageStatus} from '@components/RemoteMessage/RemoteMessageHelper/utils.jsx';

const MessageDetailsGeneral = observer(() => {
    const { remoteMessageStore } = useStores();
    const { t } = useTranslation();
    const severity = get(remoteMessageStore.messageDetails, ['severity']);
    const type = get(remoteMessageStore.messageDetails, ['type']);
    const status = get(remoteMessageStore.messageDetails, ['status']);
    const device_message_status = get(remoteMessageStore.messageDetails, ['device_messages','0','status']);
    return (
        remoteMessageStore.messageDetails && (
            <>
                <h5>
                    <span className="rioglyph rioglyph-envelope text-size-20 margin-right-10"/>
                    {`${t("fotaone.general.general")}`}
                </h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.rm.messageData.messageId")}`}</label>
                            <span className='text-primary'>
                                {get(remoteMessageStore.messageDetails, ['id'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.rm.severityState.severity")}`}</label>
                            <span className='text-primary'>
                                {severityRender(severity)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.rm.messageData.messageType.type")}`}</label>
                            <span className='text-primary'>
                                {`${t(`fotaone.rm.messageData.messageType.${type}`)}`}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.rm.messageData.messageStatus.status")}`}</label>
                            <span className='text-primary'>
                                {status==='APPROVED' ? renderMessageStatus(device_message_status) : renderMessageStatus(status) }
                            </span>
                        </div>
                    </div>
                </div>
                {type === "RECALL" && (
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.rm.kbaNumber")}`}</label>
                                <span className='text-primary'>
                                    <a href="https://www.kba-online.de/gpsg/startServlet?adress=gpsg" target="_blank" rel="noreferrer">
                                        {get(remoteMessageStore.messageDetails, ['kba_number'])}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    );
});

export default MessageDetailsGeneral;
