import { observer } from 'mobx-react-lite';
import { messagesApprovalTableData } from '@components/RemoteMessage/RemoteMessageHelper/constants.jsx';
import { useStores } from '@stores/context.jsx';
import { Services } from '@stores/service.js';
import { useNavigate } from 'react-router-dom';
import { setQueryParamToUrl } from '@components/mapupdate/mapupdateHelper/utils.jsx';
import Pagination from '@common/Pagination/Pagination.jsx';
import Table from '@common/Table/Table.jsx';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { useTranslation } from 'react-i18next';
import '../../../../Common/Table/Table.css';

const ToApproveTable = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { serviceStore, authStore, remoteMessageStore } = useStores();
  const {paginationStore, filterStore, query, setShowDecisionConfirmationDialog, setConfirmationType} = props;

  const rowClicked = (row) => {
    navigate(`${serviceStore.currentService}/toApprove/${row.id}${location.search}`);
  };

  const handleSearchValueChange = (search) => {
    setQueryParamToUrl('query', search);
    filterStore.setQuery(search);
    paginationStore.setActivePage(1);
  };

  const handleSortChange = (_sortBy, _sortDir) => {
    filterStore.setSortBy(_sortBy);
    filterStore.setSortDir(_sortDir);
  };

  const stopPropagation = (e) => e.stopPropagation();

  const ActionButton = ({ iconClass, buttonClass, label, onClick }) => (
    <Button
      type='button'
      className={`btn ${buttonClass} margin-right-3`}
      onClick={onClick}
    >
      <span className={`rioglyph ${iconClass} `}/>
      <span>{label}</span>
    </Button>
  );

  const renderActions = (row) => (
    authStore.hasWriteAccess(Services.RemoteMessage)?
    (
      <div className='text-right'>
        <div className='row margin-right-3'>
          <ActionButton
            iconClass='rioglyph-ok'
            buttonClass='btn-outline btn-success'
            label={`${t("fotaone.rm.messageData.messageAction.approveMessage")}`}
            onClick={(e) => {
              stopPropagation(e);
              setConfirmationType('approval');
              remoteMessageStore.setHandleMessageDecision(row);
              setShowDecisionConfirmationDialog(true);
            }}
          />
          <ActionButton
            iconClass='rioglyph-remove'
            buttonClass='btn-outline btn-danger'
            label={`${t("fotaone.rm.messageData.messageAction.rejectMessage")}`}
            onClick={(e) => {
              stopPropagation(e);
              setConfirmationType('rejection');
              remoteMessageStore.setHandleMessageDecision(row);
              setShowDecisionConfirmationDialog(true);
            }}
          />
        </div>
      </div>
    ):(<></>)
  );

  return (
    <div className='approve-message-table'>
      <Table
        onRowClick={rowClicked}
        rowKey='id'
        selectedRow={props.selectedRow}
        showSearch={true}
        handleSearchValueChange={handleSearchValueChange}
        andleSortChange={handleSortChange}
        searchValue={query}
        data={remoteMessageStore.messagesApprovalList || []}
        columnDetails={messagesApprovalTableData.columnDetails}
        defaultColumnOrder={messagesApprovalTableData.defaultColumnOrder}
        columns={messagesApprovalTableData.columns}
        columnOrder={messagesApprovalTableData.columnsOrder}
        hiddenColumns={messagesApprovalTableData.hiddenColumns}
        columnLabels={messagesApprovalTableData.columnLabels}
        disabledColumns={messagesApprovalTableData.disabledColumns}
        actionColumn='buttons width-250'
        actionItemRenderer={renderActions}
        loading={remoteMessageStore.messagesApprovalListLoading}
      />
      <Pagination paginationStore={paginationStore} />
    </div>
  );
};

export default observer(ToApproveTable);