import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CreateGroupDialog from './CreateGroupDialog.jsx';
import Table from '@common/Table/Table.jsx';
import { useStores } from '@stores/context.jsx';
import { useNavigate } from 'react-router-dom';
import { Services } from '@stores/service.js';
import Pagination from '@common/Pagination/Pagination.jsx';
import { debounce } from 'lodash';
import { groupTableData } from '../../mapupdateHelper/constants.jsx';
import { setQueryParamToUrl } from '../../mapupdateHelper/utils.jsx';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

export default observer((props) => {
    const { groupStore, serviceStore, uiStore, appRolloutStore, mapRolloutStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [rolloutGroups] = useState(props.rolloutGroups);
    const [selectedRow, setSelectedRow] = useState();
    const [showDeleteGroupDialog, setShowDeleteGroupDialog] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const { paginationStore, filterStore, className = 'group-table table-wrapper' } = props;
    const limit = paginationStore?.limit;
    const activePage = paginationStore?.activePage;
    const offset = paginationStore?.offset;
    const query = filterStore?.query;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;
    const searchQueryParam = queryParams.get('query');

    const searchGroups = useCallback(
        debounce(async (params) => {
            const response = await groupStore.getGroups(params);
            paginationStore.setItemsLength(groupStore.groupTotal);
            return response;
        }, 300),
        [],
    );
    useEffect(() => {
        if (searchQueryParam) {
            filterStore.setQuery(searchQueryParam);
        }

        searchGroups({
            query,
            sortBy,
            sortDir,
            limit,
            offset,
        });
        return () => {
            uiStore.setShowRolloutDialog(false);
        };
    }, [query, sortBy, sortDir, limit, activePage, uiStore, searchGroups, offset, searchQueryParam, groupStore]);

    const handleSearchValueChange = (search) => {
        setQueryParamToUrl('query', search);
        filterStore.setQuery(search);
        paginationStore.setActivePage(1);
    };
    const handleSortChange = (sortBy, sortDir) => {
        filterStore.setSortBy(sortBy);
        filterStore.setSortDir(sortDir);
    };

    const rowClicked = (row) => {
        if (rolloutGroups) {
            props.rowClicked(row);
            setSelectedRow(row.id);
        } else {
            navigate(`${serviceStore.currentService}/vehicles/?group=${row.name}`);
        }
    };

    const getStore = () => {
        if (serviceStore.currentService === Services.MapUpdate) {
            return mapRolloutStore;
        }
    };

    const renderActions = (row) => (
        <div className='text-right'>
            <button
                type='button'
                className='btn btn-primary margin-right-5'
                onClick={(e) => {
                    e.stopPropagation();
                    getStore().setMultipleRolloutData({ group: row });
                    uiStore.setShowMultipleRolloutDialog(true);
                    navigate(`${serviceStore.currentService}/rollouts`);
                }}
            >
                <span>{`${t("fotaone.mu.rolloutData.startRollout")}`}</span>
            </button>
            <button
                type='button'
                className='btn btn-danger margin-left-5'
                onClick={async (e) => {
                    e.stopPropagation();
                    groupStore.setGroup(row);
                    setShowDeleteGroupDialog(true);
                }}
            >
                <span>{`${t("fotaone.mu.delete")}`}</span>
            </button>
        </div>
    );

    return (
        <div>
            <Table
                toolbarElement={<CreateGroupDialog />}
                showSearch={true}
                searchValue={query}
                handleSortChange={handleSortChange}
                handleSearchValueChange={handleSearchValueChange}
                onRowClick={rowClicked}
                data={groupStore.groupList}
                columnDetails={groupTableData.columnDetails}
                defaultColumnOrder={groupTableData.defaultColumnOrder}
                columns={groupTableData.columns}
                columnOrder={groupTableData.columnsOrder}
                actionItemRenderer={!rolloutGroups ? renderActions : ''}
                actionColumn='buttons'
                loading={groupStore.groupsLoading}
                rowKey='id'
                selectedRow={selectedRow}
            />
            <Pagination paginationStore={paginationStore} />
            {
                groupStore.group &&
                    <ConfirmationDialog
                        show={showDeleteGroupDialog}
                        title={`${t("fotaone.mu.groupData.deleteGroup")}`}
                        content={`${t("fotaone.mu.groupData.deleteGroupInfo")} ${groupStore.group.name}?`}
                        bsSize={Dialog.SIZE_SM}
                        onClickConfirm={async () => {
                            await groupStore.deleteGroup({ groupId: groupStore.group.id });
                            setShowDeleteGroupDialog(false);
                        }}
                        onClickCancel={() => {
                            setShowDeleteGroupDialog(false);
                        }}
                        cancelButtonText={`${t("fotaone.general.abort")}`}
                        confirmButtonText={`${t("fotaone.general.yes")}`}
                        useOverflow
                    />
            }
        </div>
    );
});
