import React, { useEffect, useState } from 'react';
import StepLayout from '../../../../Dialogs/common/StepLayout.jsx';
import VehicleSearchTable from '../../../vehicles/overview/table/VehicleSearchTable.jsx';
import GroupTable from '../../../vehicles/groups/GroupTable.jsx';
import { PaginationStore } from '../../../../../stores/pagination.js';
import { FilterStore } from '../../../../../stores/filter.jsx';
import { useTranslation } from 'react-i18next';

const CampaignTargetSelection = (props) => {
    const [vehicleActive, setVehicleActive] = useState(false);
    const [groupActive, setGroupActive] = useState(true);
    const [selectedCount, setSelectedCount] = useState(
        props.data.selectedVehicleIds.length || props.data.selectedGroupIds.length || '',
    );
    const [isTestFleet, setIsTestFleet] = useState(false);
    const { t } = useTranslation();

    const onUpdateVehicles = (updatedData) => {
        setSelectedCount([updatedData.id].length);
        props.onChange({
            selectedVehicleIds: [updatedData.id],
            selectedGroupIds: [],
        });
    };

    const onUpdateGroup = (updatedData) => {
        setSelectedCount(1); // for now only able to select one group
        props.onChange({
            selectedVehicleIds: [],
            selectedGroupIds: [updatedData.id],
        });
    };

    const onSetVehicleActive = () => {
        setVehicleActive(true);
        setGroupActive(false);
    };

    const onSetGroupActive = () => {
        setVehicleActive(false);
        setGroupActive(true);
    };

    useEffect(() => {
        if (props.data.selectedMap.approval_state === 'UNRELEASED') {
            setIsTestFleet(true);
        }
    }, [vehicleActive, groupActive]);

    return (
        <StepLayout title={`${t("fotaone.mu.rolloutData.selectTarget")}`}>
            {!!selectedCount && (
                <p className='text-color-success'>
                    {selectedCount}
                    {props.data.selectedVehicleIds.length > 0
                        ? props.data.selectedVehicleIds.length === 1 ? ` ${t("fotaone.general.vehicleData.vehiclesSelected.one")}` : ` ${t("fotaone.general.vehicleData.vehiclesSelected.many")}`
                        : props.data.selectedGroupIds.length === 1 ? ` ${t("fotaone.mu.groupData.groupsSelected.one")}` : ` ${t("fotaone.mu.groupData.groupsSelected.many")}`
                    }
                </p>
            )}
            <div className='row'>
                <ul className='nav nav-tabs' id='myTab' role='tablist'>
                    <li className={groupActive ? 'nav-item active' : 'nav-item'}>
                        <a className='nav-link' id='group' data-toggle='tab' role='tab' onClick={onSetGroupActive}>
                            {`${t("fotaone.mu.groupData.group.many")}`}
                        </a>
                    </li>
                    <li className={vehicleActive ? 'nav-item active' : 'nav-item'}>
                        <a className='nav-link' id='vehicle' data-toggle='tab' role='tab' onClick={onSetVehicleActive}>
                            {`${t("fotaone.general.vehicleData.vehicle.many")}`}
                        </a>
                    </li>
                </ul>
            </div>
            <div className='tab-content' id='myTabContent'>
                {groupActive && (
                    <div id='group' role='tabpanel' aria-labelledby='group'>
                        <GroupTable
                            className={`${props.className} group-table`}
                            paginationStore={new PaginationStore()}
                            filterStore={new FilterStore()}
                            rowClicked={onUpdateGroup}
                            rolloutGroups={true}
                            selectedRow={props.data.selectedGroupIds.length > 0 && props.data.selectedGroupIds[0]} // to be migrated to a multi selection table, for now we only have one
                        />
                    </div>
                )}
                {vehicleActive && (
                    <div id='vehicle' role='tabpanel' aria-labelledby='vehicle'>
                        <VehicleSearchTable
                            rowClicked={onUpdateVehicles}
                            testFleetOnly={isTestFleet}
                            paginationStore={new PaginationStore()}
                            filterStore={new FilterStore()}
                            className={`${props.className} vehicle-table`}
                        />
                    </div>
                )}
            </div>
        </StepLayout>
    );
};

export default CampaignTargetSelection;
