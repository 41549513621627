import React, { useEffect } from 'react';
import Table from '@common/Table/Table.jsx';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { get } from 'lodash';
import { vehicleTableSidebar } from './Sidebar.constants';
import DeviceDetailsHeader from './DetailsHeader';
import { formatActivationDate } from '../Vehicle.utils';
import { useTranslation } from 'react-i18next';


const VehicleDetails = observer((props) => {
    const { vin } = props;
    const { vehicleStore } = useStores();
    const { t } = useTranslation();
    const deviceDetails = vehicleStore.vehicleDetails;
    useEffect(() => {
        const fetchData = async () => {
            vehicleStore.resetDetails();
            await vehicleStore.getVehicleDetailsByVin(vin);
        };

        fetchData();
    }, [vehicleStore, vin]);
    const { name, id, devices, activations_override } = deviceDetails ?? {};
    const { device_id, formatted_serial_no, type, serial_number } = devices?.[0] ?? {};
    const { created_at, status } = activations_override?.[0] ?? {};
    return (
        <div className='device-details'>
            <DeviceDetailsHeader row={deviceDetails} vin={vin} />

            <h5>{`${t("fotaone.general.general")}`}</h5>
            <div className='breakline margin-bottom-20' />
            <div className='row'>
                <div className='col-md-6'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>{`${t("fotaone.general.vehicleData.vehicle.one")}`}</label>
                        <span className='text-primary'>{name}</span>
                    </div>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>{`${t("fotaone.general.vehicleData.vehicleId")}`}</label>
                        <span className='text-primary'>{id}</span>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>{`${t("fotaone.general.vehicleData.vinShort")}`}</label>
                        <span className='text-primary'>{vin}</span>
                    </div>
                </div>
            </div>

            <h5>{`${t("fotaone.general.vehicleData.device")}`}</h5>
            <div className='breakline margin-bottom-20' />
            <div className='row'>
                <div className='col-md-6'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>{`${t("fotaone.general.vehicleData.deviceId")}`}</label>
                        <span className='text-primary'>{device_id}</span>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>{`${t("fotaone.general.vehicleData.serialnumber")}`}</label>
                        <span className='text-primary'>{formatted_serial_no}</span>
                        &nbsp;
                        <span className='margin-left-10 label label-muted label-filled label-condensed'>
                            <a
                                className='text-color-info'
                                href={type==="CM4"
                                ?
                                    `https://ota.admin-eu.rio.cloud/#devices?serial-number-prefix=cm4_${serial_number}&deviceSelected=true`
                                :
                                    `https://ota.admin-eu.rio.cloud/#devices?serial-number-prefix=${serial_number}&deviceSelected=true`
                                }
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {`${t("fotaone.general.vehicleData.mdmDevice")}`}
                            </a>
                        </span>
                    </div>
                </div>
            </div>

            {status && (
                <>
                    <h5>{`${t("fotaone.ot.activationData.activationOverrideFota")}`}</h5>
                    <div className='breakline margin-bottom-20' />

                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.general.status")}`}</label>
                                <span className='text-primary'>{status}</span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.createdAt")}`}</label>
                                <span className='text-primary'>{formatActivationDate(created_at)}</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <h5>{`${t("fotaone.general.vehicleData.asset.many")}`}</h5>

            <div className='breakline margin-bottom-20' />
            <Table
                data={get(deviceDetails, ['assets'])}
                columnDetails={vehicleTableSidebar.columnDetails}
                columns={vehicleTableSidebar.columns}
                columnOrder={vehicleTableSidebar.columnsOrder}
                defaultColumnOrder={vehicleTableSidebar.defaultColumnOrder}
            />
        </div>
    );
});

export default VehicleDetails;
