import {observer} from 'mobx-react-lite';
import SessionCountsByDateRangeKpi from "./components/SessionCountsByDateRangeKpi.jsx";

export default observer(() => {
  return (
    <div className='display-grid gap-20'>
      <div className='display-grid gap-20 grid-cols-1'>
        <SessionCountsByDateRangeKpi />
      </div>
    </div>
  );

});