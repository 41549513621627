import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { connectivityBoxStatusMap } from '../Vehicle.constants.jsx';
import { get } from 'lodash';
import { formatDate } from '@helpers/date.js';
import { useTranslation } from 'react-i18next';

const VehicleDetailsConnectivity = observer(() => {
    const { assetStore } = useStores();
    const { t } = useTranslation();

    return (
        assetStore.assetDetails &&
        get(assetStore.assetDetails, ['vehicles', '0', 'connectivity_box_state'], []).length > 0 && (
            <>
                <h5>{`${t("fotaone.mu.connectBox.connectivityBox")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.connectBox.boxState")}`}</label>
                            <span className='text-primary'>
                                {
                                    connectivityBoxStatusMap[
                                        get(assetStore.assetDetails, [
                                            'vehicles',
                                            '0',
                                            'connectivity_box_state',
                                            '0',
                                            'status_code',
                                        ])
                                    ]
                                }
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.connectBox.downloadId")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, [
                                    'vehicles',
                                    '0',
                                    'connectivity_box_state',
                                    '0',
                                    'download_id',
                                ])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.connectBox.requestId")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, [
                                    'vehicles',
                                    '0',
                                    'connectivity_box_state',
                                    '0',
                                    'request_id',
                                ])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.updatedAt")}`}</label>
                            <span className='text-primary'>
                                {formatDate(
                                    get(assetStore.assetDetails, [
                                        'vehicles',
                                        '0',
                                        'connectivity_box_state',
                                        '0',
                                        'timestamp',
                                    ]),
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    );
});

export default VehicleDetailsConnectivity;
