import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { activationsManNowStatus } from '@helpers/constants.jsx';
import { useStores } from '@stores/context.jsx';
import { Services } from '@stores/service.js';
import { getVehicleStatus } from '../Vehicle.utils';
import { useTranslation } from 'react-i18next';

const OverrideActions = (props) => {
    const { vehicleStore, activationStore, authStore } = useStores();
    const { t } = useTranslation();
    const { vin, row, ...layoutProps } = props;
    const fetchDetails = useCallback(
        debounce(async (vin) => {
            await vehicleStore.getVehicleDetailsByVin(vin);
            const { list, total } = await vehicleStore.searchVehicles(vehicleStore.vehicleParams);
            vehicleStore.setVehicleList(list);
            vehicleStore.setVehicleTotal(total);
        }, 200),
        [],
    );

    const refreshDevices = async (vin) => {
        return fetchDetails(vin);
    };
    /**
     *
     * @param {'activate' | 'deactivate' | 'reset'} action
     */
    const hasActivationWritePermission = (action) => {
        switch (action) {
            case 'activate':
            case 'deactivate':
            case 'reset':
                return !activationStore.stateMachine.isLoading && authStore.hasWriteAccess(Services.OnlineTraffic);
            default:
                return false;
        }
    };
    /**
     *
     * @param {string} vehicleId
     */
    const activate = async (vehicleId) => {
        const hasPermission = hasActivationWritePermission('activate');
        if (hasPermission) {
            await activationStore.activate(vehicleId);
            await refreshDevices(vin);
        }
    };
    /**
     *
     * @param {string} vehicleId
     */
    const deactivate = async (vehicleId) => {
        const hasPermission = hasActivationWritePermission('deactivate');
        if (hasPermission) {
            await activationStore.deactivate(vehicleId);
            await refreshDevices(vin);
        }
    };

    const reset = async (vehicleId) => {
        const hasPermission = hasActivationWritePermission('reset');
        if (hasPermission) {
            await activationStore.reset(vehicleId);
            await refreshDevices(vin);
        }
    };

    const state = useMemo(() => {
        let response = 'error';
        const deviceStatus = getVehicleStatus(row);
        const hasActivationOverride = row?.activations_override?.length > 0;

        if (hasActivationOverride) {
            response = 'reset';
        } else if (deviceStatus === activationsManNowStatus.Active) {
            response = 'deactivate';
        } else if (
            deviceStatus === activationsManNowStatus.Inactive ||
            deviceStatus === activationsManNowStatus.Never
        ) {
            response = 'activate';
        }
        return response;
    }, [row]);

    return (
        state && (
            <div {...layoutProps}>
                {state === 'activate' && (
                    <button
                        type='button'
                        className='btn btn-success  btn-outline margin-left-5 margin-right-5'
                        disabled={!hasActivationWritePermission('activate')}
                        onClick={() => activate(row.id)}
                        id='activation-button'
                    >
                        <span className='rioglyph rioglyph-ok-sign' aria-hidden='true' />
                        <span>{`${t("fotaone.general.activate")}`}</span>
                    </button>
                )}
                {state === 'deactivate' && (
                    <button
                        type='button'
                        className='btn btn-danger  btn-outline margin-left-5 margin-right-5'
                        onClick={() => deactivate(row.id)}
                        disabled={!hasActivationWritePermission('deactivate')}
                        id='activation-button'
                    >
                        <span className='rioglyph rioglyph-remove-sign' aria-hidden='true' />
                        <span>{`${t("fotaone.general.deactivate")}`}</span>
                    </button>
                )}
                {state === 'reset' && (
                    <button
                        type='button'
                        className='btn btn-info btn-outline  btn-outline margin-left-5 margin-right-5'
                        disabled={!hasActivationWritePermission('reset')}
                        onClick={() => reset(row.id)}
                        id='activation-button'
                    >
                        <span className='rioglyph rioglyph-repeat' aria-hidden='true' />
                        <span>{`${t("fotaone.general.reset")}`}</span>
                    </button>
                )}
                {state === 'error' && (
                    <button
                        type='button'
                        className='btn btn-danger  btn-action text-size-14'
                        id='activation-button-error'
                    >
                        <span className='rioglyph rioglyph-error-sign' aria-hidden='true' />

                        <span>{`${t("fotaone.general.vehicleData.state.error")}`}</span>
                    </button>
                )}
            </div>
        )
    );
};

export default observer(OverrideActions);
