import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const OnlineTrafficMenu = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='text-size-h2 text-thin'>
                Online Traffic
            </div>
            <Divider />
            <p>
                {t("fotaone.general.serviceMenu.onlineTrafficSection.serviceDesc")}
            </p>
            <div className='text-size-h5 margin-bottom-5'>
                <span className='rioglyph rioglyph-truck margin-right-10' />
                <strong>{t("fotaone.ot.activationData.activation.many")}</strong>
            </div>
            <ul>
                <li>
                    <p>
                        <strong>{t('fotaone.general.vehicleData.vehicle.many')}</strong>: {' '}{t('fotaone.general.serviceMenu.onlineTrafficSection.vehiclesDesc')}
                    </p>
                </li>
                <li>
                    <p>
                        <strong>{t('fotaone.ot.analyticData.analytics')}</strong>: {' '}{t('fotaone.general.serviceMenu.onlineTrafficSection.analyticDesc')}
                    </p>
                </li>
            </ul>
        </>
    );
};

export default observer(OnlineTrafficMenu);