import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const SupportMenu = (props) => {
    const { t } = useTranslation();

    return (<>
        <div className='text-size-h2 text-thin'>
            FOTA Admin {t('fotaone.general.support')}
        </div>
        <Divider />
        <div className={'margin-bottom-5'}>
            {t('fotaone.general.serviceMenu.supportSection.introductionText')}
        </div>
        <div className={'margin-bottom-15'}>
            <strong>Email</strong>: {' '}{t('fotaone.general.serviceMenu.supportSection.emailText')}
        </div>
        <div className='text-size-h5'>
            <strong>{t('fotaone.auth.account.roles')} & {t('fotaone.auth.account.permissions')}</strong>
        </div>
        <p>{t('fotaone.general.serviceMenu.supportSection.checkRolesText')}</p>
        <p>{t('fotaone.general.serviceMenu.supportSection.requestRolesText')}</p>
    </>);
};

export default observer(SupportMenu);