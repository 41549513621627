import React from 'react';

function TableColumnGroup(props) {
    const { columns, actionItemRenderer, actionColumn, columnDetails, hiddenColumns } = props;

    const filteredColumns = columns.filter((col) => !hiddenColumns.includes(col.key));

    const renderTableCaption = (colDetails, idx) => {
        const style = {};
        if (colDetails?.width) {
            style.minWidth = colDetails?.width;
            style.width = colDetails?.width;
        }

        return <col key={idx} style={style} />;
    };

    return (
        <colgroup>
            {filteredColumns.map((column, idx) => renderTableCaption(columnDetails[column.key], idx))}
            {actionItemRenderer && <col className={actionColumn || 'table-action'} />}
        </colgroup>
    );
}

export default TableColumnGroup;
