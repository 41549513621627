import React, { Component } from 'react';

export default class StepLayout extends Component {
    render() {
        const { title, children } = this.props;
        return (
            <div>
                <div>
                    <h4>{title}</h4>
                </div>
                <div>{children}</div>
            </div>
        );
    }
}
