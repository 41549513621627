import React, { useState } from 'react';
import './Table.css';
import Loader from '../Loader/Loader';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { getSortDir } from './Table.utils';
import TableColumnGroup from './TableColumnGroup';
import TableToolbarComponent from './TableToolbarComponent';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';

const Table = (props) => {
    const { data, loading, children } = props;
    const { t } = useTranslation();

    if (loading) {
        return <Loader center key='loader' />;
    }

    if (!data || !data.length) {
        return (
            <div className='row' key='noresults'>
                <div className='col-md-12'>
                    <NotFoundState headline={`${t("fotaone.general.table.noData")}`} message='' />
                </div>
            </div>
        );
    }

    return (
        <table
            key='table'
            className={'table table-layout-fixed table-bordered table-sticky table-head-filled table-hover'}
        >
            {children}
        </table>
    );
};
export default observer((props) => {
    const {
        filterStore,
        data,
        showSearch,
        loading,
        actionColumn,
        handleSortChange,
        onRowClick,
        toolbarElement,
        toolbarElementLeft,
        showTableSettings,
        searchValue,
        ...rest
    } = props;

    const [sortBy, setSortBy] = useState(filterStore?.sortBy || '');
    const [sortDir, setSortDir] = useState(filterStore?.sortDir || SortDirection.ASCENDING);
    const [columnOrder, setColumnOrder] = useState(rest.columnOrder);
    const [hiddenColumns, setHiddenColumns] = useState(rest.hiddenColumns ? rest.hiddenColumns : []);
    const [columnDetails, setColumnDetails] = useState(rest.columnDetails);

    const handleSortClick = (event) => {
        const newSortDir = getSortDir(event.currentTarget.getAttribute('data-sortby'), sortBy, sortDir);
        const newSortBy = event.currentTarget.getAttribute('data-sortby');
        setSortDir(newSortDir);
        setSortBy(newSortBy);
        if (handleSortChange) {
            handleSortChange(newSortBy, newSortDir);
        }
    };

    return (
        <>
            <TableToolbarComponent
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                hiddenColumns={hiddenColumns}
                setHiddenColumns={setHiddenColumns}
                columnDetails={columnDetails}
                setColumnDetails={setColumnDetails}
                showSearch={showSearch}
                toolbarElement={toolbarElement}
                toolbarElementLeft={toolbarElementLeft}
                showTableSettings={showTableSettings}
                searchValue={searchValue}
                {...rest}
            />
            <Table data={data} loading={loading}>
                <TableColumnGroup
                    {...rest}
                    actionColumn={actionColumn}
                    columnDetails={columnDetails}
                    hiddenColumns={hiddenColumns}
                />
                <TableHead
                    {...rest}
                    hiddenColumns={hiddenColumns}
                    handleSortClick={handleSortClick}
                    sortBy={sortBy}
                    sortDir={sortDir}
                    actionColumn={actionColumn}
                />
                <TableBody
                    {...rest}
                    hiddenColumns={hiddenColumns}
                    actionColumn={actionColumn}
                    rows={data}
                    onRowClick={onRowClick}
                />
            </Table>
        </>
    );
});
