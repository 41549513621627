import React from 'react';
import { isEmpty } from 'lodash';
import { formatActivationDate, getActivationDateFromAsset, getAssetStatus, getManNowStatus } from '../Vehicle.utils';
import Badge from '@common/Badge/Badge';
import { useTranslation } from 'react-i18next';

export const lastActivationDateRenderer = (_, asset) => {
    const { date, type } = getActivationDateFromAsset(asset);
    const formattedDate = formatActivationDate(date);
    if (isEmpty(date)) return date;
    else return <span title={`${type}: ${date}`}>{formattedDate}</span>;
};

export const activationBadgeRenderer = (_, asset) => {
    const assetStatus = getAssetStatus(asset);
    const props = getManNowStatus(assetStatus);
    return <Badge {...props} />;
};
export const assetBadgeRenderer = (_, asset) => {
    const { t } = useTranslation();
    const { testfleet } = asset;
    if (testfleet) {
        return <Badge title={`${t("fotaone.general.tagData.testfleet")}`} state={`${t("fotaone.general.tagData.testfleet")}`} color={'badge-info margin-left-4'} />;
    }
};
