import React, { useEffect } from 'react';
import Auth from '@components/Auth/Auth';
import { useStores } from '@stores/context';
import { useNavigate } from 'react-router-dom';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';

export default () => {
    const { authStore, serviceStore } = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        if (authStore.isLoggedIn) {
            navigate(serviceStore.currentService);
        }
    });

    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Body className='auth'>
                <NotificationsContainer />
                <Auth />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};
