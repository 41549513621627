import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { useStores } from '@stores/context.jsx';
import {
    getDefaultLanguageValue,
    getDefaultSeverityValue,
    getDefaultTypeValue,
    renderLanguage,
    renderSeverity,
    renderType,
} from '@components/RemoteMessage/RemoteMessageHelper/utils.jsx';
import { useTranslation } from 'react-i18next';

const CreateMessageTemplateDialog = () => {
  const { remoteMessageStore } = useStores();
  const [templateTitle, setTemplateTitle] = useState(remoteMessageStore.editMessageTemplate ? remoteMessageStore.editMessageTemplate.title : '');
  const [templateType, setTemplateType] = useState(getDefaultTypeValue());
  const [templateSeverity, setTemplateSeverity] = useState(getDefaultSeverityValue());
  const [templateLanguage, setTemplateLanguage] = useState(getDefaultLanguageValue());
  const [templateContent, setTemplateContent] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (remoteMessageStore.editMessageTemplate) {
      setTemplateTitle(remoteMessageStore.editMessageTemplate.title);
      setTemplateType(remoteMessageStore.editMessageTemplate.type);
      setTemplateSeverity(remoteMessageStore.editMessageTemplate.severity);
      setTemplateLanguage(remoteMessageStore.editMessageTemplate.language_code);
      setTemplateContent(remoteMessageStore.editMessageTemplate.content);
    }
  }, [remoteMessageStore.editMessageTemplate]);
  const onHide = () => {
    remoteMessageStore.setEditMessageTemplate(null);
    remoteMessageStore.setMessageTemplateDialogOpen(false);
    setTemplateTitle('');
    setTemplateType(getDefaultTypeValue());
    setTemplateSeverity(getDefaultSeverityValue());
    setTemplateLanguage(getDefaultLanguageValue());
    setTemplateContent('');
  };
  const renderCreateMessageTemplateDialog = () => {
    return (
      <Dialog
        show={remoteMessageStore.messageTemplateDialogOpen}
        title={remoteMessageStore.editMessageTemplate ?
          t('fotaone.rm.templateData.createTemplate.editMessageTemplate') :
          t('fotaone.rm.templateData.createTemplate.createMessageTemplate')
        }
        body={renderBody()}
        onHide={onHide}
        showCloseButton={true}
      />
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let response = null;
    if (remoteMessageStore.editMessageTemplate) {
        response = await remoteMessageStore.updateMessageTemplate({
          id: remoteMessageStore.editMessageTemplate.id,
          title: templateTitle,
          type: templateType,
          severity: templateSeverity,
          language_code: templateLanguage,
          content: templateContent
        });
    } else {
      response = await remoteMessageStore.createMessageTemplate({
        title: templateTitle,
        type: templateType,
        severity: templateSeverity,
        language_code: templateLanguage,
        content: templateContent
      });
    }

    if(response) {
      onHide();
    }
  };
  const renderBody = () => {
    return (
      <div className='row equal-height'>
        <div className='col-xs-12 col-md-12 margin-bottom-15'>
          <form onSubmit={onSubmit}>
            <fieldset>
              <div className={`form-group ${templateTitle ? 'has-success has-feedback' : 'has-error has-feedback'}`}>
                <label htmlFor='inputTemplateTitle'>{t('fotaone.rm.templateData.createTemplate.templateName')}</label>
                <input
                  className='form-control'
                  id='inputTemplateTitle'
                  placeholder={t('fotaone.rm.templateData.createTemplate.templateTitle')}
                  type='text'
                  value={templateTitle}
                  onChange={(event) => setTemplateTitle(event.target.value)}
                />
                <span
                    className={`form-control-feedback rioglyph ${templateTitle ? 'rioglyph-ok' : 'rioglyph-error-sign'}`}
                />
              </div>
              <div className='row'>
                <div className='form-group col-xs-4 col-md-4'>
                  <label htmlFor='templateTypeSelect'>{t('fotaone.rm.templateData.createTemplate.type')}</label>
                  <select
                    className='form-control'
                    id='templateTypeSelect'
                    defaultValue={templateType}
                    value={templateType}
                    onChange={(event) => setTemplateType(event.target.value)}
                  >
                    {renderType()}
                  </select>
                </div>
                <div className='form-group col-xs-4 col-md-4'>
                  <label htmlFor='templateSeveritySelect'>{t('fotaone.rm.severityState.severity')}</label>
                    <select
                        className='form-control'
                        id='templateSeveritySelect'
                        defaultValue={templateSeverity}
                        value={templateSeverity}
                        onChange={(event) => setTemplateSeverity(event.target.value)}
                    >
                        {renderSeverity()}
                    </select>
                </div>
                <div className='form-group col-xs-4 col-md-4'>
                  <label htmlFor='templateLanguageSelect'>{t('fotaone.rm.templateData.createTemplate.language')}</label>
                    <select
                        className='form-control'
                        id='templateSeveritySelect'
                        defaultValue={`${t(templateLanguage)}`}
                        value={`${t(templateLanguage)}`}
                        disabled={!!remoteMessageStore.editMessageTemplate}
                        onChange={(event) => setTemplateLanguage(event.target.value)}
                    >
                        {renderLanguage()}
                    </select>
                </div>
              </div>
              <div className={`form-group ${templateContent ? 'has-success has-feedback' : 'has-error has-feedback'}`}>
                <label htmlFor='areaTemplateContent'>{t('fotaone.rm.templateData.createTemplate.templateContent')}</label>
                <textarea
                    className='form-control'
                    rows='3'
                    id='areaTemplateContent'
                    value={templateContent}
                    onChange={(event) => setTemplateContent(event.target.value)}
                >
                </textarea>
                <span
                    className={`form-control-feedback rioglyph ${templateContent ? 'rioglyph-ok' : 'rioglyph-error-sign'}`}
                />
              </div>
              <div className='breakline' />
              <div className='flex-1-1'>
                <div className="display-flex justify-content-end align-items-start margin-top-10">
                    <Button
                        type='submit'
                        className='btn btn-primary'
                        disabled={
                          !templateTitle ||
                          !templateType ||
                          !templateSeverity ||
                          !templateLanguage ||
                          !templateContent
                        }
                    >
                      {remoteMessageStore.messageTemplatesLoading && <span className="rioglyph rioglyph-spinner spinning" />}
                      {remoteMessageStore.editMessageTemplate ? t('fotaone.rm.templateData.createTemplate.edit') : t('fotaone.rm.templateData.createTemplate.create')}
                    </Button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className='table-toolbar-column pull-right'>
      <button type='button' className='btn btn-primary' onClick={() => remoteMessageStore.setMessageTemplateDialogOpen(true)}>
        {t('fotaone.rm.templateData.createTemplate.createTemplateLabel')}
      </button>
      {renderCreateMessageTemplateDialog()}
    </div>
  );
};

export default observer(CreateMessageTemplateDialog);