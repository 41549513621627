import React, { useState } from 'react';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import PropTypes from 'prop-types';

export const defaultOptions = [25, 50, 100, 200];
export const defaultLimitValue = 50;
/**
 *
 * @typedef TableLengthProps
 * @type {object}
 * @property {()=>any} onSelect
 * @property {number} defaultLimit
 * @property {Array<number>} options
 */

/**
 *
 * @param {TableLengthProps} props
 * @returns
 */
export default function TableLength({ onSelect, defaultLimit = defaultLimitValue, options = defaultOptions }) {
    const [limit, setLimit] = useState(defaultLimit);
    const items = options.map((option) => ({
        value: option,
        onSelect: () => {
            setLimit(option);
            onSelect?.(option);
        },
    }));

    return (
        <div className='text-left' data-testid='div-dropdown'>
            <ButtonDropdown
                id='table-length'
                data-testid='table-length'
                className='page-size'
                title={limit}
                items={items}
                dropup
                splitButton
            />
        </div>
    );
}

TableLength.propTypes = {
    onSelect: PropTypes.func,
    defaultLimit: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.number),
};
