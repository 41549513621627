import {
    assetParser,
    testFleetParser,
    latestStatusParser,
    deploymentEnvironmentParser,
    environments,
} from '@helpers/constants.jsx';
import { regionSelectData, rolloutStatusMap } from '../../mapupdateHelper/constants.jsx';
import { getRegion } from '../../mapupdateHelper/region.jsx';
import { distanceTimeRenderer } from '../../mapupdateHelper/utils.jsx';
import {
    regionCounter,
    tagsRenderer,
    regionExtensionColumnBuilder,
    mapsVersionRenderer,
    serialNumberRenderer,
    availableStorageRenderer,
} from './Vehicle.utils.jsx';
import { filterOptionsTypes } from '@common/Filter/Filter.constants.js';

export const assetTableData = {
    defaultColumnOrder: [
        'name',
        'vin',
        'devices.0.formatted_serial_no',
        'latest_rollout_state',
        'taglist'
    ],
    columnsOrder: [
        'name',
        'vin',
        'devices.0.formatted_serial_no',
        'latest_rollout_state',
        'taglist'
    ],
    columnLabels: { 
        name: 'fotaone.general.vehicleData.shortname',
        vin: 'fotaone.general.vehicleData.vinShort',
        'devices.0.formatted_serial_no': 'fotaone.general.vehicleData.serialnumber',
        latest_rollout_state: 'fotaone.mu.latestStatus',
        taglist: 'fotaone.general.tagData.tags',
    },
    columnDetails: {
        vehicleName: {},
        vin: {},
        'devices.0.formatted_serial_no': {},
        latest_rollout_state: {},
        taglist: {},
    },
    columns: [
        {
            title: "fotaone.general.vehicleData.shortname",
            key: 'name',
            sortable: true,
        },
        {
            title: 'fotaone.general.vehicleData.vinShort',
            key: 'vin',
            sortable: true,
        },
        {
            title: 'fotaone.general.vehicleData.serialnumber',
            key: 'devices.0.formatted_serial_no',
            renderer: serialNumberRenderer,
        },
        {
            title: 'fotaone.mu.latestStatus',
            alternateExportTitle: 'Latest Rollout State',
            key: 'latest_rollout_state',
            statusColor: true,
            renderer: (status) => (rolloutStatusMap[status] ? rolloutStatusMap[status] : 'N/A'),
            parser: latestStatusParser,
            sortable: true,
        },
        {
            title: 'fotaone.mu.regionData.region.many',
            key: 'region',
            renderer: regionCounter,
            extensionColumns: regionExtensionColumnBuilder,
            sortable: false,
        },
        {
            title: 'fotaone.general.tagData.tags',
            key: 'tags',
            exportable: false,
            renderer: tagsRenderer,
        },
        {
            title: 'fotaone.general.vehicleData.asset.many',
            key: 'assets',
            parser: assetParser,
        },
        {
            title: 'fotaone.general.tagData.testfleet',
            key: 'test-fleet',
            parser: testFleetParser,
        },
        {
            title: 'fotaone.general.environmentData.deployEnvironment',
            key: 'environment',
            parser: deploymentEnvironmentParser,
        }
    ],
    disabledColumns: [],
    hiddenColumns: ['assets', 'test-fleet', 'environment'],
};
export const connectivityBoxStatusMap = {
    42: 'STATE_VCM_WAITING_FOR_DOWNLOAD',
    43: 'STATE_VCM_DOWNLOADING',
    44: 'STATE_VCM_SERVING',
    45: 'STATE_VCM_IDLE',
    46: 'STATE_VCM_DOWNLOAD_STARTED',
    47: 'STATE_VCM_DOWNLOAD_FINISHED',
    48: 'STATE_VCM_SERVED_FILE_SUCCESFUL',
    49: 'VCM_SIGNATURE_SUCCESFUL',
    50: 'VCM_SIGNATURE_FAILED',
    51: 'VCM_INSTALLATION_SUCCESFUL',
    52: 'VCM_INSTALLATION_FAILED',
    53: 'VCM_UNINSTALLATION_SUCCESFUL',
    54: 'VCM_UNINSTALLATION_FAILED',
    '-1': 'STATE_UNKNOWN',
};
export const assetMapStateUpdatesData = [
    {
        title: 'fotaone.mu.regionData.region.one',
        key: 'map.mapUpdateRegion',
        renderer: getRegion,
    },
    {
        title: 'fotaone.mu.fileData.version',
        key: 'map.versionNumber',
    },
];
export const assetRolloutsData = {
    columnDetails: {
        filename: { width: 150 },
    },
    columns: [
        {
            title: 'fotaone.mu.fileData.fileName',
            key: 'filename',
        },
        {
            title: 'fotaone.general.status',
            key: 'status',
            statusColor: true,
            renderer: (status) => (rolloutStatusMap[status] ? rolloutStatusMap[status] : 'UNKNOWN'),
        },
        {
            title: 'fotaone.general.updatedAt',
            key: 'rollout_state.updated_at',
            renderer: distanceTimeRenderer,
            sortable: true,
        },
    ],
};

export const muFilterDataKeys = {
    FotaRegionFilter: 'man-region-filter',
    FotaTestFleetFilter: 'man-test-fleet-filter',
    Environment: 'environment',
};

export const filterData = [
    {
        key: muFilterDataKeys.FotaRegionFilter,
        label: `${"fotaone.mu.regionData.regionSelection"}`,
        databaseKey: 'region_Selection',
        type: filterOptionsTypes.CHECKBOX,
        options: regionSelectData.map((region) => {
            return {
                key: Object.keys(region)[0],
                label: region[Object.keys(region)[0]],
                value: true,
            };
        }),
        result: [],
    },
    {
        key: muFilterDataKeys.FotaTestFleetFilter,
        label: 'fotaone.general.tagData.tags',
        databaseKey: 'test_Fleet_Selection',
        type: filterOptionsTypes.CHECKBOX,
        options: [
            {
                key: 'testfleet',
                label: 'fotaone.general.tagData.testfleet',
                value: true,
            },
        ],
        result: [],
    },
    {
        key: muFilterDataKeys.Environment,
        label: 'fotaone.general.tagData.environment',
        type: filterOptionsTypes.CHECKBOX,
        options: [
            {
                key: environments.Dev,
                label: 'fotaone.general.environmentData.dev'
            },
            {
                key: environments.Canary,
                label: 'fotaone.general.environmentData.canary'
            },
            {
                key: environments.Prod,
                label: 'fotaone.general.environmentData.prod'
            }
        ],
        result: [],
    },
];

export const installedMapsTableData = {
    defaultColumnOrder: [
        'VIN',
        'availableStorage',
        ...regionSelectData.map(item => Object.keys(item)[0]),
    ],
    columnsOrder: [
        'VIN',
        'availableStorage',
        ...regionSelectData.map(item => Object.keys(item)[0]),
    ],
    columnLabels:
      regionSelectData.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            acc[key] = key;
            return acc;
        },
        { VIN: 'VIN' },
        { 'availableStorage': 'fotaone.mu.mapState.availableStorage' }
    ),
    columnDetails: regionSelectData.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            acc[key] = {};
            return acc;
        },
        {vin: {width: 100}},
        {'availableStorage': {width: 100}}
    ),
    columns: [
        {
            title: 'VIN',
            key: 'vin',
            sortable: true,
        },
        {
            title: 'fotaone.mu.mapState.availableStorage',
            key: 'availableStorage',
            sortable: false,
            renderer: availableStorageRenderer
        },
        ...regionSelectData.map(item => {
            const key = Object.keys(item)[0];
            return {
                title: key,
                key: key,
                sortable: true,
                renderer: mapsVersionRenderer
            };
        })
    ],
    disabledColumns: [],
    hiddenColumns: [],
};
