import React from 'react';
import Badge from '@common/Badge/Badge.jsx';
import { useStores } from '@stores/context.jsx';
import NoData from '@rio-cloud/rio-uikit/NoData';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { regionSelectData } from '../../mapupdateHelper/constants.jsx';
import { testBadgeRendererMultipleAssets } from '@helpers/constants.jsx';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';

export const regionCounter = (_, row) => {
    const region = [];
    const { assets = [], activations_override = [] } = row;

    assets.map((asset) => {
        asset.activations.map((activation) => {
            if (activation.source_active === true) region[activation.feature_context.region] = '';
        });
    });

    activations_override.map((override) => {
        if (override.status === 'ACTIVE') {
            region[override.feature_context.region] = '';
        } else if (override.status === 'INACTIVE') {
            delete region[override.feature_context.region];
        } else {
            console.error('Wrong Override Status', row);
        }
    });

    return (
        <div className={'text-color-primary'}>
            <span data-testid={'regionCounter'} className={'h4'}>
                {Object.keys(region).length}
            </span>
            <span>/</span>
            <span data-testid={'maxRegionCounter'}>{regionSelectData.length}</span>
        </div>
    );
};

export const regionExtensionColumnBuilder = (_, row) => {
    const result = Object.fromEntries(
        regionSelectData.map((region) => {
            const regionKey = Object.keys(region);
            return [
                regionKey,
                {
                    key: `${regionKey}`,
                    title: `${regionKey}`,
                    content: 'NOT ACTIVE', // Default value
                },
            ];
        }),
    );

    const { assets = [] } = row;

    assets.forEach((asset) =>
        asset.activations.forEach((activation) => {
            const region = activation.feature_context.region;
            result[region].content = !activation.source_active && result[region].content !== 'INST' ? 'EXP' : 'INST';
        }),
    );

    return result;
};

export const tagsRenderer = (_, row) => {
    const { t } = useTranslation();
    const { assets, activations_override } = row;
    const deploymentEnvironment = row.environment ? row.environment : null
    return (
        <>
            {assets.length > 1 && <Badge title={`${t("fotaone.general.tagData.multipleAssets")}`} state={`${t("fotaone.general.tagData.multipleAssets")}`} color={'badge-primary'} style={{ marginRight: '5px' }} />}
            {testBadgeRendererMultipleAssets(_, row)}
            {activations_override.length > 0 && (
                <Badge title={`${t("fotaone.general.tagData.overrideActive")}`} state={`${t("fotaone.general.tagData.override")}`} color={'badge-warning margin-1'} />
            )}
           {deploymentEnvironment &&  <Badge title={`${t("fotaone.general.tagData.environment")}`} state={deploymentEnvironment[0].toUpperCase() + deploymentEnvironment.slice(1)} color={'badge-info'} />}
        </>
    );
};

export const serialNumberRenderer = (_, row) => {
    const { devices } = row;
    return (
        <div>
            <Badge 
                title={`${devices[0]?.type}`}
                state={`${devices[0]?.type?.toUpperCase()}`}
                color={
                    devices[0]?.type?.toUpperCase() === "CM4"
                    ? 'badge-secondary margin-1'
                    : 'badge-default margin-1'
                }
            />
            {` ${devices[0]?.serial_number}`}
        </div>
    );
};

export const mapsVersionRenderer = (_, row, key) => {
    const { t } = useTranslation();
    const { assetStore } = useStores();
    function checkMapVersionAndGetColorRating (mapVersion, listOfMaps) {
        if (mapVersion === undefined) {
            return null;
        }
        const index = listOfMaps.indexOf(mapVersion);
        if (index === 0) {
            return 'text-color-success';
        } else if (index === 1 || index === 2) {
            return 'text-color-warning';
        } else {
            return 'text-color-danger';
        }
    }
    function checkRegionActiveState(key){
        const keyActive=`${key}_active`;
        if(keyActive in row){
            if(row[keyActive] === true){
                return 'rioglyph rioglyph-beacon margin-left-5';
            }
        }
    }
    return (
    <div>
        <span className={checkMapVersionAndGetColorRating(_, assetStore.lastMapsVersions)}>
            <b>{_ ? _ : <NoData text={`${t("fotaone.general.table.noData")}`} />}</b>
        </span>
        <OverlayTrigger
            key={key}
            placement={"bottom"}
            overlay={
                <Tooltip id='tooltip' allowOnTouch>
                    {`${t("fotaone.mu.installedMapsPage.booked")}: `}{key}
                </Tooltip>
            }
        >
            <span className={checkRegionActiveState(key)}/>
        </OverlayTrigger>
        
    </div>
    )
};

export const availableStorageRenderer = (_, row) => {
    return typeof (row.availableStorage) === 'number' ?
      prettyBytes(
        Number(row.availableStorage * 1000000),{ locale: 'de' },
      ) : (
        <NoData text={row.availableStorage} />
      );
};