import { useStores } from '@stores/context.jsx';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { assetRolloutsData } from '../Vehicle.constants.jsx';
import Table from '@common/Table/Table.jsx';
import { useTranslation } from 'react-i18next';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';

const VehicleDetailsRollouts = ({handleSortChange}) => {
    const { assetStore, mapRolloutStore, serviceStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleRolloutRowClick = (row) => {
        mapRolloutStore.setRolloutsQuery(row.vehicle_id);
        navigate(`${serviceStore.currentService}/rollouts/${row.rollout_id}`);
    };
    const handleRolloutsClick = () => {
        mapRolloutStore.setRolloutsQuery(get(assetStore.assetDetails, ['vehicles', '0', 'id']));
        navigate(`${serviceStore.currentService}/rollouts`);
    };
    const transformRolloutData = (data) => {
        return data.map((d) => {
            return {
                ...d,
                filename: d.rollout_packages[0].file_metadata?.file.filename,
                status: d.rollout_state.status,
            };
        });
    };
    return (
        get(assetStore.assetDetails, 'rollouts', []).length > 0 && (
            <ExpanderPanel
                title={
                    <>
                        <h5 className='display-inline-block'>{`${t("fotaone.mu.rolloutData.rollout.many")}`}</h5>
                        <span className='margin-left-10 label label-muted label-filled label-condensed'>
                            <a className='text-color-info' onClick={handleRolloutsClick}>
                                {`${t("fotaone.general.details")}`}
                            </a>
                        </span>
                    </>
                }
                headerClassName={'padding-0'}
                bodyClassName={'padding-0'}
                open={true}
            >
                <div className='breakline margin-bottom-20' />
                <Table
                    data={transformRolloutData(get(assetStore.assetDetails, 'rollouts'))}
                    columns={assetRolloutsData.columns}
                    columnDetails={assetRolloutsData.columnDetails}
                    actionButtons={false}
                    onRowClick={handleRolloutRowClick}
                    handleSortChange={handleSortChange}
                    withoutAlerts
                />
            </ExpanderPanel>
        )
    );
};

export default VehicleDetailsRollouts;
