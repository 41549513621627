import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';
import Collapse from '@rio-cloud/rio-uikit/lib/es/Collapse';
import ReleaseNotes from '@rio-cloud/rio-uikit/lib/es/ReleaseNotes';

const MessageDetailsEvents = observer(() => {
    const { remoteMessageStore } = useStores();
    const [events, setEvents] = useState([]);
    const [showCollapse, setShowCollapse] = useState(false);
    const { t } = useTranslation();

    const eventsList =[];

    useEffect(() => {
        setEvents(get(remoteMessageStore.messageDetails, ['device_message_events'], []))
    }, [remoteMessageStore.messageDetails]);

    const renderEventList = () => {
        for (let i = 0; i < events?.length; i++) {
            const createdAt = events[i].created_at;
            const createdBy = events[i].created_by;
            const operation = events[i].operation!=null ? events[i].operation : "Unknown Operation";
            const event = {
                [operation]: {
                    date: createdAt.split('T')[0],
                    content:(
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='margin-bottom-10'>
                                    <label className='display-block'>{`${t("fotaone.rm.eventData.eventExecutedBy")}`}</label>
                                    <span className='rioglyph rioglyph-user margin-right-5 text-primary'/>
                                    {createdBy}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='margin-bottom-10'>
                                    <label className='display-block'>{`${t("fotaone.rm.eventData.eventCreatedAt")}`}</label>
                                    <span className='rioglyph rioglyph-time-alt margin-right-5 text-primary'/>
                                    {createdAt.split('T')[1].substr(0, 5)}
                                    <span className='rioglyph rioglyph-calendar margin-left-10 margin-right-5 text-primary'/>
                                    {createdAt.split('T')[0]}
                                </div>
                            </div>
                        </div>
                    ),
                },
            }
            eventsList.push(
                <div className='row' key={`key-${i}`} id={`event-${i}`}>
                    <div className='col-md-12 margin-bottom-2'>
                        <ReleaseNotes releaseNotes={event} />
                    </div>
                </div>
            )
        }

        return(
            <>
            {get(remoteMessageStore.messageDetails, ['device_message_events'], []).length > 0 ? (
                <div className='row'>
                    <div className='col-md-12'>
                        {eventsList}
                    </div>
                </div>
            ):(<></>)}
            </>
        )
    }

    return (
        remoteMessageStore.messageDetails && (
            <div data-testid="message-details-events-section">
                <h5
                    className="cursor-pointer"
                    onClick={() => setShowCollapse(state => !state)}
                >
                    <span className="rioglyph rioglyph-time-alt text-size-20 margin-right-10"/>
                    <span className="margin-right-5">{`${t("fotaone.rm.eventData.event.many")}`}</span>
                    <span
                        className={
                            showCollapse
                            ? "rioglyph rioglyph-chevron-down"
                            : "rioglyph rioglyph-chevron-right"
                        }
                    />
                </h5>
                <div className="breakline margin-bottom-20" />

                <Collapse open={showCollapse} unmountOnExit>
                    <div>
                        {renderEventList()}
                    </div>
                </Collapse>
            </div>
        )
    );
});

export default MessageDetailsEvents;
