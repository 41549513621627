import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar.jsx';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants.js';
import { isEmpty } from 'lodash';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useTranslation } from 'react-i18next';
import NoData from '@rio-cloud/rio-uikit/NoData';

export default observer((props) => {
  const { featureDashboardStore, serviceStore } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { featureId } = props;

  useEffect(() => {
    featureDashboardStore.getFeatureDetails(featureId);
  }, [featureId, featureDashboardStore]);

  const onClose = () => {
    navigate(`${serviceStore.currentService}/services`);
  };

  function getCategoryClass(category) {
    switch (category) {
      case 'Efficiency':
        return 'text-color-primary';
      case 'Comfort':
        return 'text-color-secondary';
      case 'Safety':
        return 'text-color-success';
      default:
        return 'text-color-danger';
    }
  }

  const renderDetails = () => {
    if (!featureDashboardStore.currentFeatureDetails || isEmpty(featureDashboardStore.currentFeatureDetails)) {
      return <NotFoundState headline={`${t("fotaone.general.table.noData")}`} />;
    }

    return (
      <>
        <div className='breakline margin-bottom-15' />
        <div className='margin-bottom-25'>
          <div className='display-flex'>
            <div className='margin-right-15'>
              {featureDashboardStore.currentFeatureDetails.icon ? (
                <div style={{ background: '#303c49', width: 70, height: 70}}>
                  <img
                    src={featureDashboardStore.currentFeatureDetails.icon}
                    alt={featureDashboardStore.currentFeatureDetails.name}
                    style={{ width: 70, height: 70}}
                  />
                </div>
              ) : (
                  <div className="width-70 aspect-ratio-1 position-relative shorthand-style" >
                    <div
                      className="inset-0 display-flex justify-content-center align-items-center rounded"
                      style={{backgroundColor: '#303c49', color: 'white'}} >
                      {`${t("fotaone.general.icon")}`}
                    </div>
                  </div>
              )}
            </div>
            <div className='FeatureHeaderDetails'>
              <div className='display-flex'>
                <h5>{featureDashboardStore.currentFeatureDetails.name}</h5>
                <span
                  className={'margin-left-5'}
                  style={{fontSize: 30}}
                >
                    {getUnicodeFlagIcon(featureDashboardStore.currentFeatureDetails.local.slice(-2))}
                </span>
              </div>
              <div>
                <Tag size='small' className={getCategoryClass(featureDashboardStore.currentFeatureDetails.category)}>
                  {`${t(`fotaone.fd.featureData.category.${featureDashboardStore.currentFeatureDetails.category.toLowerCase()}`)}`}
                </Tag>
              </div>
            </div>
          </div>
        </div>
        <h6>{`${t("fotaone.general.general")}`}</h6>
        <div className='breakline' />
        <table className='table table-condensed margin-bottom-25'>
          <colgroup>
            <col className='width-25pct' />
          </colgroup>
          <tbody>
            <tr className={'border-bottom-0 cursor-default'}>
              <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.fd.featureData.featureCode")}`}</td>
              <td className={'border-top-0'}>{featureDashboardStore.currentFeatureDetails.feature_code}</td>
            </tr>
            <tr className={'border-bottom-0 cursor-default'}>
              <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.language.languageCode")}`}</td>
              <td className={'border-top-0'}>{featureDashboardStore.currentFeatureDetails.local}</td>
            </tr>
            <tr className={'border-bottom-0 cursor-default'}>
              <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.fd.featureData.featureDescId")}`}</td>
              <td className={'border-top-0'}>{featureDashboardStore.currentFeatureDetails.description_id}</td>
            </tr>
          </tbody>
        </table>
        <h6>{`${t("fotaone.general.description")}`}</h6>
        <div className='breakline' />
        <table className='table table-condensed margin-bottom-25'>
          <colgroup>
            <col className='width-25pct' />
          </colgroup>
          <tbody>
          <tr className={'border-bottom-0 cursor-default'}>
            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.general.name")}`}</td>
            <td className={'border-top-0'}>
              {featureDashboardStore.currentFeatureDetails.name ?
                featureDashboardStore.currentFeatureDetails.name : <NoData text={`${t("fotaone.general.table.noData")}`} />
              }
            </td>
          </tr>
          <tr className={'border-bottom-0 cursor-default'}>
            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.fd.featureData.featureSubtitle")}`}</td>
            <td className={'border-top-0'}>
              {featureDashboardStore.currentFeatureDetails.subtitle ?
                featureDashboardStore.currentFeatureDetails.subtitle: <NoData text={`${t("fotaone.general.table.noData")}`} />
              }
            </td>
          </tr>
          <tr className={'border-bottom-0 cursor-default'}>
            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.fd.featureData.featureShortDesc")}`}</td>
            <td className={'border-top-0'}>
              {featureDashboardStore.currentFeatureDetails.short_description ?
                featureDashboardStore.currentFeatureDetails.short_description : <NoData text={`${t("fotaone.general.table.noData")}`} />
              }
            </td>
          </tr>
          <tr className={'border-bottom-0 cursor-default'}>
            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.fd.featureData.featureFullDesc")}`}</td>
            <td className={'border-top-0'}>
              {featureDashboardStore.currentFeatureDetails.description ?
                featureDashboardStore.currentFeatureDetails.description : <NoData text={`${t("fotaone.general.table.noData")}`} />
              }
            </td>
          </tr>
          <tr className={'border-bottom-0 cursor-default'}>
            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.fd.featureData.featureBenefits")}`}</td>
            <td className={'border-top-0'}>
              <ul>
              {featureDashboardStore.currentFeatureDetails.benefits ?
                featureDashboardStore.currentFeatureDetails.benefits.map((item, index) => (
                  <li key={`${item.substring(0, 10)}-${index}`}>{item}</li>
                )) : <NoData text={`${t("fotaone.general.table.noData")}`} />
              }
              </ul>
            </td>
          </tr>
          <tr className={'border-bottom-0 cursor-default'}>
            <td className={'align-top text-color-gray border-top-0'}>URL</td>
            <td className={'border-top-0'}>
              {featureDashboardStore.currentFeatureDetails.url ?
                featureDashboardStore.currentFeatureDetails.url : <NoData text={`${t("fotaone.general.table.noData")}`} />
              }
            </td>
          </tr>
          <tr className={'border-bottom-0 cursor-default'}>
            <td className={'align-top text-color-gray border-top-0'}>{`${t("fotaone.fd.featureData.featureNote")}`}</td>
            <td className={'border-top-0'}>
              {featureDashboardStore.currentFeatureDetails.note ?
                featureDashboardStore.currentFeatureDetails.note : <NoData text={`${t("fotaone.general.table.noData")}`} />
              }
            </td>
          </tr>
          </tbody>
        </table>
      </>
    );
  };

  return (
    <DetailsSideBar
      title={`${t("fotaone.fd.featureData.featureDetails")}`}
      onClose={onClose}
      width={600}
      blurEffect={featureDashboardStore.featureDetailsLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide}
      fly
    >
      {renderDetails()}
    </DetailsSideBar>
  );
});