import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';

import Table from '../../../../Common/Table/Table.jsx';

import { getTestFleetStatusFromAssets } from '../../../../../helpers/constants.jsx';
import { useStores } from '../../../../../stores/context.jsx';

import Pagination from '../../../../Common/Pagination/Pagination.jsx';
import { assetTableData } from '../Vehicle.constants.jsx';

export default observer((props) => {
    const { assetStore } = useStores();

    const { paginationStore, filterStore, testFleetOnly } = props;
    const [selectedRow, setSelectedRow] = useState();

    const limit = paginationStore?.limit;
    const activePage = paginationStore?.activePage;
    const offset = paginationStore?.offset;

    const query = filterStore?.query;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;

    const searchAssets = useCallback(
        debounce(async (params) => {
            const assets = await assetStore.getAssetsGraphql(params);
            paginationStore.setItemsLength(assetStore.assetTotal);
            return assets;
        }, 200),
        [],
    );

    useEffect(() => {
        searchAssets({
            query,
            sortBy,
            sortDir,
            limit,
            offset,
        });
    }, [query, sortBy, sortDir, limit, activePage, searchAssets, offset]);

    const rowClicked = (row) => {
        props.rowClicked(row);
        setSelectedRow(row.id);
    };

    const handleSearchValueChange = (search) => {
        filterStore.setQuery(search.trim());
        paginationStore.setActivePage(1);
    };

    const handleSortChange = (sortBy, sortDir) => {
        filterStore.setSortBy(sortBy);
        filterStore.setSortDir(sortDir);
    };

    let transformedData = [];
    if (testFleetOnly === false) {
        transformedData = assetStore.assetList?.map((d) => {
            return {
                ...d,
                disabled: false,
            };
        });
    } else {
        transformedData =
            assetStore.assetList?.map((d) => {
                const { isTestFleet } = getTestFleetStatusFromAssets(d.assets);
                return {
                    ...d,
                    disabled: !isTestFleet,
                };
            });
    }

    return (
        <div className={props.className}>
            <Table
                onRowClick={rowClicked}
                showSearch={true}
                handleSearchValueChange={handleSearchValueChange}
                handleSortChange={handleSortChange}
                searchValue={query}
                data={transformedData || []}
                columnDetails={assetTableData.columnDetails}
                defaultColumnOrder={assetTableData.defaultColumnOrder}
                columns={assetTableData.columns}
                columnOrder={assetTableData.columnsOrder}
                hiddenColumns={assetTableData.hiddenColumns}
                columnLabels={assetTableData.columnLabels}
                disabledColumns={assetTableData.disabledColumns}
                actionColumn='buttons'
                loading={assetStore.assetsState.isLoading}
                rowKey='id'
                selectedRow={selectedRow}
            />
            <Pagination paginationStore={paginationStore} />
        </div>
    );
});
