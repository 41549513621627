import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import InstalledMapsTable from '@components/mapupdate/vehicles/installedMaps/InstalledMapsTable.jsx';
import { useStores } from '@stores/context.jsx';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';

export default observer((props) => {
  const { serviceStore } = useStores();

  useEffect(() => {
  }, [serviceStore]);
  return (
    <ApplicationLayout className='MyServiceLayout'>
      <ApplicationLayout.Header>
        <FotaHeader />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar className='bg-muted'>
        <FotaSidebar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className='responsive'>
        <NotificationsContainer />
        {serviceStore.serviceMenuDialogOpen &&
          <ServiceMenuDialog/>
        }
        <InstalledMapsTable />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
});