import React from 'react';
import { isEmpty } from 'lodash';
import {
    formatActivationDate,
    getActivationDate,
    getVehicleStatus,
    getFotaStatus,
    getManNowStatus,
    getDeactivationReason,
    getDeactivationReasonProps,
} from '../Vehicle.utils';
import Badge from '@components/Common/Badge/Badge.jsx';

export const activationManNowStatusRenderer = (_, row) => {
    const vehicleState = getVehicleStatus(row);
    const props = getManNowStatus(vehicleState);
    return <Badge {...props} />;
};

export const activationFotaStatusRenderer = (_, row) => {
    const { activations_override } = row;
    const props = getFotaStatus(activations_override);

    return <Badge {...props} />;
};

export const formatActivationDateRenderer = (_, row, key) => {
    const activationDate = getActivationDate(_, row, key);
    const formattedActivationDate = formatActivationDate(activationDate);

    if (isEmpty(formattedActivationDate)) return formattedActivationDate;
    else return <span title={activationDate}>{formattedActivationDate}</span>;
};

export const deactivationReasonRenderer = (_, row) => {
    const deactivationReason = getDeactivationReason(row);
    const props = getDeactivationReasonProps(deactivationReason);

    return deactivationReason != null ? <Badge {...props} /> : null;
};

export const serialNumberRenderer = (_, row) => {
    const { devices } = row;
    return (
        <div>
            <Badge 
                title={`${devices[0]?.type}`}
                state={`${devices[0]?.type}`}
                color={
                    devices[0]?.type?.toUpperCase() === "CM4"
                    ? 'badge-secondary margin-1'
                    : 'badge-default margin-1'
                }
            />
            {` ${devices[0]?.serial_number}`}
        </div>
    );
};
