import { observable, action, makeObservable } from 'mobx';

let instance; // singleton instance

export const ServiceLabels = {
    MapUpdate: {
        sidebarFiles: 'Maps',
        uploadDialogTitle: 'Upload a New Map File',
        uploadDialogBtn: 'Upload Map File',
        uploadRegion: true,
    },
};

export class LabelStore {
    currentLabels = ServiceLabels.MapUpdate;

    constructor() {
        makeObservable(this, {
            currentLabels: observable,
            setCurrentLabels: action,
        });
    }

    setCurrentLabels(labels) {
        this.currentLabels = labels;
    }

    static instance() {
        if (!instance) {
            instance = new LabelStore();
        }
        return instance;
    }
}
