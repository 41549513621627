import { observable, action, computed, makeObservable } from 'mobx';
import { gql } from '@apollo/client';
import { client as mapClient } from '@api/mapupdate-client';
import { client as mapUpdateGraphqlClient } from '@api/mapupdate-graphql';
import { client as mapUpdateProxyAxiosClient } from '@api/mapupdate-proxy-client.js';
import { setWith } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { environments } from '@helpers/constants.jsx';
import  i18n  from 'i18next';

// eslint-disable-next-line no-unused-vars
import StateMachineStore from '../stateMachine'; //NOSONAR
import { muFilterDataKeys } from '@components/mapupdate/vehicles/overview/Vehicle.constants.jsx';
import { ForbiddenError } from '@helpers/errors/error.forbidden.js';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import {
    enrichVehicleDataWithDeploymentEnvironment,
    generateDeviceEnvironmentMap, getMUDevicesDeploymentEnvironment} from '../storeHelpers/utils.jsx';
import { regionSelectData } from '@components/mapupdate/mapupdateHelper/constants.jsx';

let instance; // singleton instance

//todo: search is somehow not working.

const SEARCH_ASSETS_QUERY = gql`
    query searchVehicles(
        $limit: Int
        $offset: Int = 0
        $query: String = "%"
        $orderBy: [mu_vehicles_order_by!] = {}
        $filter: [mu_vehicles_bool_exp!] = {}
    ) {
        mu {
            vehicles(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _and: [
                        { vin: { _is_null: false } }
                        { assets: { asset_id: { _is_null: false } } }
                        {
                            _or: [
                                { id_text: { _ilike: $query } }
                                { vin: { _ilike: $query } }
                                { name: { _ilike: $query } }
                                { assets: { account_id: { _ilike: $query } } }
                                { assets: { asset_id_text: { _ilike: $query } } }
                                { devices: { serial_number: { _ilike: $query } } }
                                { devices: { formatted_serial_no: { _ilike: $query } } }
                                { head_unit_version: { _ilike: $query } }
                            ]
                            _and: $filter
                        }
                    ]
                }
            ) {
                name
                id
                status
                vin
                assets {
                    asset_id
                    asset_id_text
                    testfleet {
                        account_id
                    }
                    account_id
                    activations {
                        source_active
                        first_activated_at
                        activation_source
                        deactivated_at
                        activated_at
                        feature_context
                    }
                }
                devices {
                    device_id
                    formatted_serial_no
                    type
                    serial_number
                    updated_at
                }
                latest_rollout_state
                activations_override {  
                    feature_context
                    status
                    created_at
                }
            }
            vehicles_aggregate(
                where: {
                    _and: [
                        { vin: { _is_null: false } }
                        { assets: { asset_id: { _is_null: false } } }
                        {
                            _or: [
                                { vin: { _ilike: $query } }
                                { name: { _ilike: $query } }
                                { assets: { account_id: { _ilike: $query } } }
                                { assets: { asset_id_text: { _ilike: $query } } }
                                { devices: { serial_number: { _ilike: $query } } }
                                { devices: { formatted_serial_no: { _ilike: $query } } }
                                { head_unit_version: { _ilike: $query } }
                            ]
                            _and: $filter
                        }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const MAP_ASSET_DETAILS_QUERY = gql`
    query GetMapVehicleDetails($vehicle_id: uuid!, $sort_dir: order_by) {
        mu {
            vehicles(where: { id: { _eq: $vehicle_id } }) {
                id
                id_text
                name
                vin
                devices {
                    device_id
                    serial_number
                    formatted_serial_no
                    type
                    maps_installed {
                        map_version
                        map_region_enum
                    }
                }
                assets {
                    account_id
                    asset_id
                    asset_id_text
                    created_at
                    testfleet {
                        account_id
                    }
                    activations {
                        activated_at
                        activation_source
                        deactivated_at
                        first_activated_at
                        source_active
                        feature_context
                    }
                }
                activations_override {
                    feature_context
                    status
                    created_at
                }
                groups {
                    id
                    name
                    description
                }
                status_request_progress(order_by: { requested_at: desc }, limit: 1) {
                    update_status
                    updated_at
                    requested_at
                }
                map_state(order_by: { created_at: desc }, limit: 1) {
                    available_storage_size_megabytes: payload(path: "disk_free_mb")
                    region: payload(path: "region")
                    software_version_head_unit: payload(path: "software_version_head_unit")
                    software_version_nav_core: payload(path: "software_version_nav_core")
                    updates: payload(path: "updates")
                }
                connectivity_box_state(order_by: { created_at: desc }, limit: 1) {
                    download_id
                    request_id
                    status_code
                    timestamp: sent_from_device_at
                }
            }
            rollouts(where: { vehicle_id: { _eq: $vehicle_id }}, order_by: {rollout_state: {updated_at: $sort_dir}}) {
                created_at
                rollout_id: id
                vehicle_id
                rollout_packages(order_by: { map_file: { file: { created_at: desc } } }) {
                    file_metadata: map_file {
                        file {
                            filename
                            created_at
                        }
                    }
                }
                rollout_state {
                    updated_at
                    status: payload(path: "['@type']")
                }
            }
        }
    }
`;

const SEARCH_VEHICLES_INSTALLED_MAPS_QUERY = gql`
    query searchVehiclesInstalledMaps(
        $limit: Int
        $offset: Int = 0
        $query: String = "%"
        $orderBy: [mu_vehicles_order_by!] = {}
        $filter: [mu_vehicles_bool_exp!] = {}
    ) {
        mu {
            vehicles(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _and: [
                        { vin: { _is_null: false } }
                        { status: { _eq: "ACTIVE" } }
                        { assets: { asset_id: { _is_null: false } } }
                        { assets: { activations: { source_active: { _eq: true } } } }
                        {
                            _or: [
                                { vin: { _ilike: $query } }
                                { devices: { maps_installed: { map_version: { _ilike: $query } } } }
                            ]
                            _and: $filter
                        }
                    ]
                    _not: {
                        assets: {
                            testfleet: {account_id: {_is_null: false}}
                        }
                    }
                }
            ) {
            vin
            id
            status
            devices {
                maps_installed {
                    map_version
                    map_region_enum
                }
            }
            assets {
                activations {
                    source_active
                    feature_context
                }
            }
            map_state {
                available_storage_size_megabytes: payload(path: "disk_free_mb")
            }    
            }
            vehicles_aggregate(
                where: {
                    _and: [
                        { vin: { _is_null: false } }
                        { status: { _eq: "ACTIVE" } }
                        { assets: { asset_id: { _is_null: false } } }
                        { assets: { activations: { source_active: { _eq: true } } } }
                        {
                            _or: [
                                { vin: { _ilike: $query } }
                                { devices: { maps_installed: { map_version: { _ilike: $query } } } }
                            ]
                            _and: $filter
                        }
                    ]
                    _not: {
                        assets: {
                            testfleet: {account_id: {_is_null: false}}
                        }
                    }
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const LAST_MAPS_VERSION = gql`
    query getLastMapsVersions {
        mu {
            maps(
                order_by: {version_number: desc}, 
                distinct_on: version_number, 
                limit: 5, 
                where: {approval_state: {_eq: "RELEASED"}}
            ) {
                version_number
            }
        }
    }
`;

export class AssetStore {
    assetList = null;
    assetTotal = 0;
    assetDetails = {};
    shouldReload = 0;
    vehicleDeviceEnvironment = {};

    /**
     *
     * @type {StateMachineStore}
     */
    detailsState = {};

    /**
     *
     * @type {StateMachineStore}
     */
    assetsState = {};
    installedMapsList = {};
    installedMapsTotal = {};
    lastMapsVersions = [];

    get assetsListToTable() {
        if (this.assetList && Array.isArray(this.assetList)) {
            return this.assetList.map((d) => {
                const obj = {
                    ...d,
                    disabled: false,
                };
                return obj;
            });
        } else {
            return [];
        }
    }

    get client() {
        //default to mapClient
        return mapClient;
    }

    get graphqlClient() {
        //default to mapClient graphQL
        return mapUpdateGraphqlClient;
    }

    // rome-ignore lint: reason
    get assetDetails() {
        return this.assetDetails;
    }

    get mapUpdateProxyClient(){
        return mapUpdateProxyAxiosClient;
    }

    /**
     *
     * @param {StateMachineStore} detailsState
     * @param {StateMachineStore} assetsState
     */
    constructor(detailsState, assetsState) {
        makeObservable(this, {
            assetList: observable,
            assetTotal: observable,
            assetDetails: observable,
            vehicleDeviceEnvironment: observable,
            assetsState: observable,
            detailsState: observable,
            shouldReload: observable,
            lastMapsVersions: observable,

            assetsListToTable: computed,

            getDetailsById: action,
            getAssetsGraphql: action,
            refreshAssetInfo: action,
            setAssetList: action,
        });

        this.assetsState = assetsState;
        this.detailsState = detailsState;
    }

    triggerReload() {
        // randomized value to trigger a useEffect in a component.
        this.shouldReload = uuidv4();
    }

    async activate(vehicleId, regionEnum) {
        try {
            this.detailsState.setIsLoadingState();
            await mapClient.put(`/activations/activate/${vehicleId}`, { region: regionEnum });
            Notification.success(vehicleId ? `${i18n.t('fotaone.notification.success.assets.activationRequest')} ${i18n.t('fotaone.general.vehicleData.vehicleId')}: ${vehicleId}` : `${i18n.t('fotaone.notification.success.assets.activationRequest')}`);
            await this.getDetailsById(vehicleId);
            this.triggerReload();
            this.detailsState.setLoadedState();
        } catch (err) {
            this.handleError(err, vehicleId);
            this.detailsState.setLoadedState();
        }
    }

    async deactivate(vehicleId, regionEnum) {
        try {
            this.detailsState.setIsLoadingState();
            await mapClient.put(`/activations/deactivate/${vehicleId}`, { region: regionEnum });
            Notification.success(vehicleId ? `${i18n.t('fotaone.notification.success.assets.deactivationRequest')} ${i18n.t('fotaone.general.vehicleData.vehicleId')}: ${vehicleId}` : `${i18n.t('fotaone.notification.success.assets.deactivationRequest')}`);
            await this.getDetailsById(vehicleId);
            this.triggerReload();
            this.detailsState.setLoadedState();
        } catch (err) {
            this.handleError(err, vehicleId);
            this.detailsState.setLoadedState();
        }
    }
    async reset(vehicleId, regionEnum) {
        try {
            this.detailsState.setIsLoadingState();
            await mapClient.put(`/activations/reset/${vehicleId}`, { region: regionEnum });
            Notification.success(vehicleId ? `${i18n.t('fotaone.notification.success.assets.resetRequest')} ${i18n.t('fotaone.general.vehicleData.vehicleId')}: ${vehicleId}` :  `${i18n.t('fotaone.notification.success.assets.resetRequest')}`);
            await this.getDetailsById(vehicleId);
            this.triggerReload();
            this.detailsState.setLoadedState();
        } catch (err) {
            this.handleError(err, vehicleId);
            this.detailsState.setLoadedState();
        }
    }

    /**
     *
     * @param {Error} error
     * @param {string} vehicleId
     */
    handleError(error, vehicleId) {
        if (error instanceof ForbiddenError) {
            Notification.error(`${i18n.t('fotaone.notification.error.assets.overrideRequest')} ${i18n.t('fotaone.general.vehicleData.vehicleId')}: ${vehicleId}`);
        } else {
            if (error.response) {
                Notification.error(`${error.response.statusText.toUpperCase()} - ${error}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.assets.overrideRequest')} ${i18n.t('fotaone.general.vehicleData.vehicleId')}: ${vehicleId}`);
            }
        }
    }

    resetDetails() {
        this.assetDetails = {};
    }

    setAssetList(param = []) {
        this.assetList = param;
        this.assetTotal = param.length;
    }

    setVehicleDeviceEnvironment(value) {
        this.vehicleDeviceEnvironment = value;
    }

    async getAssetsGraphql(searchParams = {}) {
        this.assetsState.setIsLoadingState();
        const { query, sortBy, sortDir, limit, offset, groupNameFilter, groupIdFilter, filters } = searchParams;

        try {
            const variables = {
                query: `%${query === undefined ? '' : query}%`,
                limit,
                offset,
                filter: {},
            };

            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }

            const vehicleDeviceEnvironmentMap =  generateDeviceEnvironmentMap(await getMUDevicesDeploymentEnvironment(true))
            this.setVehicleDeviceEnvironment(vehicleDeviceEnvironmentMap);

            if (groupNameFilter && groupNameFilter !== 'Select All') {
                variables.filter = {
                    ...variables.filter,
                    groups: {
                        name: {
                            _eq: groupNameFilter,
                        },
                    },
                };
            }

            if (groupIdFilter) {
                variables.filter = {
                    ...variables.filter,
                    groups: {
                        id: {
                            _eq: groupIdFilter,
                        },
                    },
                };
            }

            const regionFilter = this.getVariablesFilters(filters);

            variables.filter = {
                ...variables.filter,
                _and: regionFilter._and
            };

            const res = await this.graphqlClient.query({
                query: SEARCH_ASSETS_QUERY,
                variables,
                fetchPolicy: 'no-cache',
            });

            res.data = enrichVehicleDataWithDeploymentEnvironment(res.data.mu, vehicleDeviceEnvironmentMap);

            const {
                vehicles,
                vehicles_aggregate: { aggregate: { count: total } },
            } = res.data;

            this.assetList = vehicles;
            this.assetTotal = total;
            this.assetsState.setLoadedState();
            return this.assetList;
        } catch (err) {
            this.assetsState.setErrorState();
            Notification.error(`${i18n.t('fotaone.notification.error.assets.fetchVehicles')}`);
        }
    }

    /**
     *
     * @param {*} filters
     * @returns
     */
    getVariablesFilters(filters = []) {
        const filterSetForQuery = { _and: [] };
        let filterCounter = 0;
        filters.forEach((filter) => {
            const filtersSetByUser = filter.result;

            switch (filter.key) {
                case muFilterDataKeys.FotaRegionFilter:
                    filterSetForQuery._and.push({_or: []});
                    regionSelectData.forEach((region) =>{
                        if (observable(filtersSetByUser).includes(Object.keys(region)[0])) {
                            filterSetForQuery._and[filterCounter]._or.push(
                              { assets: { activations: { feature_context: { _contains: { region:  Object.keys(region)[0]} } } } },
                              { activations_override: { feature_context: { _contains: { region: Object.keys(region)[0]} } } }
                            )
                        }
                    });
                    if(filterSetForQuery?._and[filterCounter]?._or.length === 0){
                        filterSetForQuery._and[filterCounter]._or.push({ assets: {} } );
                    }
                    filterCounter++;
                    break;
                case muFilterDataKeys.FotaTestFleetFilter:
                    filterSetForQuery._and.push({_or: []});
                    if (observable(filtersSetByUser).includes('testfleet')) {
                        filterSetForQuery._and[filterCounter]._or.push({ assets: { testfleet: { id: { _is_null: false } } } });
                    } else {
                        filterSetForQuery._and[filterCounter]._or.push({ assets: {} });
                    }
                    filterCounter++;
                    break;
                case muFilterDataKeys.Environment: {
                    filterSetForQuery._and.push({_or: []});
                    for (const environment of Object.values(environments)) {
                        const prodDevices = [];
                        if (filtersSetByUser.some((elem) => elem === environment)) {
                            for (const key of this.vehicleDeviceEnvironment.keys()) {
                                if (environment === environments.Prod) {
                                    prodDevices.push(key)
                                } else if(environment === this.vehicleDeviceEnvironment.get(key)) {
                                    filterSetForQuery._and[filterCounter]._or.push( { devices: { device_id: {_eq: key}} } );
                                }
                            }
                            if(prodDevices.length > 0){
                                filterSetForQuery._and[filterCounter]._or.push( { devices: { device_id: {_nin: prodDevices}} });
                            }
                        }
                    }
                    if(filterSetForQuery?._and[filterCounter]?._or.length === 0) {
                        filterSetForQuery._and[filterCounter]._or.push({ devices: {} });
                    }
                    filterCounter++;
                    break;
                }
                default:
                    break;
            }
        });
        return filterSetForQuery
    }

    async getDetailsById(vehicleId, sortDir ) {
        try {
            this.detailsState.setIsLoadingState();
            const variables = { vehicle_id: vehicleId, sort_dir: sortDir };
            const query = MAP_ASSET_DETAILS_QUERY;
            const res = await this.graphqlClient.query({
                query,
                variables,
                fetchPolicy: 'no-cache',
            });
            const vehicleDeviceEnvironmentMap =  this.vehicleDeviceEnvironment.length > 0
              ? this.vehicleDeviceEnvironment
              : generateDeviceEnvironmentMap(await getMUDevicesDeploymentEnvironment())
            res.data = enrichVehicleDataWithDeploymentEnvironment(res.data.mu, vehicleDeviceEnvironmentMap);
            this.assetDetails = res.data;
            this.detailsState.setLoadedState();
            return this.assetDetails;
        } catch (err) {
            this.detailsState.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.assets.fetchVehicleDetails')}`);
            }
        }
    }

    async refreshAssetInfo(vehicleId) {
        try {
            await this.client.triggerVehicleStateUpdate(vehicleId);
            setWith(this.assetDetails, '[vehicles][0][status_request_progress][0][update_status]', 'REQUESTED', Object);
        } catch (err) {
            Notification.error(`${i18n.t('fotaone.notification.error.refreshData')} ${i18n.t('fotaone.general.vehicleData.vehicleId')}: ${vehicleId}`);
        }
    }

    /**
     *
     * @param {StateMachineStore} detailsState
     * @param {StateMachineStore} assetsState
     */
    static instance(detailsState, assetsState) {
        if (!instance) {
            instance = new AssetStore(detailsState, assetsState);
        }
        return instance;
    }

    async getVehiclesInstalledMapsGraphql(searchParams = {}) {
        this.assetsState.setIsLoadingState();
        const { query, sortBy, sortDir, limit, offset } = searchParams;
        try {
            const variables = {
                query: `%${query === undefined ? '' : query}%`,
                limit,
                offset,
            };

            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }

            const res = await this.graphqlClient.query({
                query: SEARCH_VEHICLES_INSTALLED_MAPS_QUERY,
                variables,
                fetchPolicy: 'no-cache',
            });

            const {
                vehicles,
                vehicles_aggregate: { aggregate: { count: total } },
            } = res.data.mu;
            this.installedMapsList = vehicles.map(vehicle => {
                const transformed = {
                    vin: vehicle.vin,
                    id: vehicle.id,
                    availableStorage: vehicle.map_state[0] ? vehicle.map_state[0].available_storage_size_megabytes : 'N/A'
                };
                vehicle.devices.forEach(device => {
                    device.maps_installed.forEach(map => {
                        transformed[map.map_region_enum] = map.map_version;
                    });
                });
                vehicle.assets.forEach(asset => {
                    asset.activations.forEach(activation => {
                        transformed[`${activation.feature_context.region}_active`] = activation.source_active;
                    });
                });
                return transformed;
            });
            this.installedMapsTotal = total;
            this.assetsState.setLoadedState();
            return this.installedMapsList;
        } catch (err) {
            this.assetsState.setErrorState();
            Notification.error(`${i18n.t('fotaone.notification.error.assets.fetchInstalledMaps')}`);
        }
    }

    async getLastMapsVersions() {
        try {
            this.assetsState.setIsLoadingState();
            const res = await this.graphqlClient.query({
                query: LAST_MAPS_VERSION,
                fetchPolicy: 'no-cache',
            });
            const mapVersions = [];
            res.data.mu.maps.forEach(mapVersion => {
                mapVersions.push(mapVersion.version_number);
            })
            this.lastMapsVersions = mapVersions;
            this.assetsState.setLoadedState();
            return this.lastMapsVersions;
        } catch (err) {
            this.assetsState.setErrorState();
            Notification.error(`${i18n.t('fotaone.notification.error.assets.fetchLastMaps')}`);
        }
    }
}
