import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import Table from '@common/Table/Table.jsx';
import { vehiclesActivationsTableData } from '@components/FeatureDashboard/FeatureDashboardHelper/constants.jsx';
import { useStores } from '@stores/context.jsx';
import Pagination from '../../../../Common/Pagination/Pagination.jsx';
import { setQueryParamToUrl } from '../../../../mapupdate/mapupdateHelper/utils.jsx';

const VehiclesActivationsTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceStore, featureDashboardStore } = useStores();
  const { paginationStore, filterStore } = props;
  const limit = paginationStore?.limit;
  const activePage = paginationStore?.activePage;
  const offset = paginationStore?.offset;
  const query = filterStore?.query;
  const filters = filterStore?.filters;

  const getVehicles = useCallback(
    debounce(async (params) => {
      await featureDashboardStore.getVehicles(params);
      paginationStore.setItemsLength(featureDashboardStore.vehicleTotal);
    }, 200),
    [],
  );
  useEffect(() => {
    getVehicles({
        query,
        limit,
        offset,
        filters,
    });
  }, [
      query,
      limit,
      activePage,
      location.search,
      getVehicles,
      offset,
      filters,
  ]);

  const rowClicked = (row) => {
    navigate(`${serviceStore.currentService}/vehicles/${row.id}${location.search}`, { state: { id: row.id, vin: row.vin } } );
  };

  const handleSearchValueChange = (search) => {
    setQueryParamToUrl('query', search);
    filterStore.setQuery(search);
    paginationStore.setActivePage(1);
  };

  const handleSortChange = (_sortBy, _sortDir) => {
    filterStore.setSortBy(_sortBy);
    filterStore.setSortDir(_sortDir);
  };

  return (
    <div className='app-file-table'>
      <Table
          onRowClick={rowClicked}
          rowKey='id'
          selectedRow={props.selectedRow}
          showSearch={true}
          handleSearchValueChange={handleSearchValueChange}
          handleSortChange={handleSortChange}
          searchValue={query}
          data={featureDashboardStore.vehicleList || []}
          columnDetails={vehiclesActivationsTableData.columnDetails}
          defaultColumnOrder={vehiclesActivationsTableData.defaultColumnOrder}
          columns={vehiclesActivationsTableData.columns}
          columnOrder={vehiclesActivationsTableData.columnsOrder}
          hiddenColumns={vehiclesActivationsTableData.hiddenColumns}
          columnLabels={vehiclesActivationsTableData.columnLabels}
          disabledColumns={vehiclesActivationsTableData.disabledColumns}
          actionColumn='buttons'
          loading={featureDashboardStore?.vehiclesLoading}
        />
        <Pagination paginationStore={paginationStore} />
    </div>
  );
};

export default observer(VehiclesActivationsTable);