import {
  featuresCounterRenderer, languageRenderer,
  serviceDescriptionRenderer, serialNumberRenderer,
} from '@components/FeatureDashboard/FeatureDashboardHelper/utils.jsx';

export const vehiclesActivationsTableData= {
  defaultColumnOrder: [
    'device.name',
    'device.vin',
    'id',
  ],
  columnsOrder: [
    'device.name',
    'device.vin',
    'id',
  ],
  columnDetails: {
    'device.name': {},
    'device.vin': {},
    'id': {},
  },
  columnLabels: {
    'device.name': 'Shortname',
    'device.vin': 'VIN',
    'id': 'Device ID',
  },
  columns: [
    {
      title: 'fotaone.general.vehicleData.shortname',
      key: 'assets.0.name',
      sortable: true,
    },
    {
      title: 'fotaone.general.vehicleData.vinShort',
      key: 'vin',
      sortable: true,
    },
    {
      title: 'fotaone.general.vehicleData.deviceId',
      key: 'serial_number',
      renderer: serialNumberRenderer,
      sortable: true,
    },
    {
      title: "fotaone.fd.serviceData.service.many",
      key: 'assets.0.activations_aggregate.aggregate.count',
      renderer: featuresCounterRenderer,
      sortable: true,
    },
  ],
  disabledColumns: [],
  hiddenColumns: [],
};

export const servicesDescriptionsTableData = {
  defaultColumnOrder: [
    'locale',
    'subtitle',
    'description',
    'short_description',
    'benefits',
    'url',
  ],
  columnsOrder: [
    'locale',
    'subtitle',
    'description',
    'short_description',
    'benefits',
    'url',
  ],
  columnDetails: {
    'locale': {width: 150},
    'subtitle': {},
    'description': {},
    'short_description': {},
    'benefits': {},
    'url': {},
  },
  columnLabels: {
    'locale': 'Language',
    'subtitle': 'Subtitle',
    'description': 'Description',
    'short_description': 'Short description',
    'benefits': 'Benefits',
    'url': 'Url',
  },
  columns: [
    {
      title: 'fotaone.language.languages',
      key: 'locale',
      renderer: languageRenderer,
      sortable: true,
    },
    {
      title: 'fotaone.fd.featureData.featureSubtitle',
      key: 'subtitle',
      renderer: serviceDescriptionRenderer,
      sortable: false,
    },
    {
      title: 'fotaone.fd.featureData.featureFullDesc',
      key: 'description',
      renderer: serviceDescriptionRenderer,
      sortable: false,
    },
    {
      title: 'fotaone.fd.featureData.featureShortDesc',
      key: 'short_description',
      renderer: serviceDescriptionRenderer,
      sortable: false,
    },
    {
      title: 'fotaone.fd.featureData.featureBenefits',
      key: 'benefits',
      renderer: serviceDescriptionRenderer,
      sortable: true,
    },
    {
      title: 'URL',
      key: 'url',
      renderer: serviceDescriptionRenderer,
      sortable: true,
    }
  ],
  disabledColumns: [],
  hiddenColumns: [],
};