// used for render the data for file table

import { isNil } from 'lodash';
import React from 'react';
import Badge from '@common/Badge/Badge';
import { rolloutStatusMap } from '@components/mapupdate/mapupdateHelper/constants';
import { useTranslation } from 'react-i18next';

export const getTestFleetStatusFromAssets = (assets) => {
    /*
     * vehicles => assets => 0 > testfleet;
     */
    let isTestFleet = false;
    const testFleetIds = assets.filter((asset) => !isNil(asset.testfleet)).map((asset) => asset.testfleet.account_id);
    if (testFleetIds.length > 0) isTestFleet = true;
    return { isTestFleet, testFleetIds };
};
/*
 * TestBadgeRenderer with multiple assets eligibility.
 */
export const testBadgeRendererMultipleAssets = (_, row) => {
    const { t } = useTranslation();
    const { assets } = row;
    const { isTestFleet, testFleetIds } = getTestFleetStatusFromAssets(assets);
    const titleString =  `${t("fotaone.general.vehicleData.vehicleIsTestfleet")}\n${testFleetIds.join(',\n')}`;

    if (isTestFleet) {
        return <Badge state={`${t("fotaone.general.tagData.testfleet")}`} title={titleString} color={'badge-info'} style={{ marginRight: '5px'}} />;
    }
};

export const getAssetIdListFromAsset = (assets = []) => {
    return assets.map((asset) => asset.asset_id);
};

export const assetParser = (_, row) => {
    const { assets } = row;
    const response = { state: '' };
    const asset_ids = getAssetIdListFromAsset(assets);
    response.state = asset_ids.join(', ');
    return response;
};

export const testFleetParser = (_, row) => {
    const { assets } = row;
    const { isTestFleet } = getTestFleetStatusFromAssets(assets);
    return { state: isTestFleet ? 'Yes' : 'No' };
};

export const deploymentEnvironmentParser = (_, row) => {
    return { state: row.environment ? row.environment : '' };
};

export const multipleAssetsParser = (_, row) => {
    const { assets } = row;
    let isMultiple = false;
    if (assets.length > 1) {
        isMultiple = true;
    }
    return { state: isMultiple ? 'Yes' : 'No' };
};

export const latestStatusParser = (_, row) => {
    const { latest_rollout_state: lrs } = row;
    const stateValue = rolloutStatusMap[lrs] != null ? rolloutStatusMap[lrs] : 'N/A';
    return { state: stateValue };
};

export const tagsRenderer = (_, row) => {
    const { t } = useTranslation();
    const { assets } = row;

    let multiple = false;
    if (assets.length > 1) {
        multiple = true;
    }

    const deploymentEnvironment = row.environment ? row.environment : null
    return (
        <>
            {multiple && <Badge title={`${t("fotaone.general.tagData.multipleAssets")}`} state={`${t("fotaone.general.tagData.multipleAssets")}`} color={'badge-primary'} style={{ marginRight: '5px' }} />}
            {testBadgeRendererMultipleAssets(_, row)}
            {deploymentEnvironment &&  <Badge title={`${t("fotaone.general.tagData.evironment")}`} state={deploymentEnvironment.charAt(0).toUpperCase() + deploymentEnvironment.slice(1)} color={'badge-info'} />}
        </>
    );
};

export const ServiceRoles = {
    MapUpdate: 'MU_RW',
    OnlineTraffic: 'OT_RW',
    FeatureDashboard: 'FD_RW',
    RemoteMessage: 'FD_RW',
    MapUpdateRead: 'MU_RO',
    OnlineTrafficRead: 'OT_RO',
    FeatureDashboardRead: 'FD_RO',
    RemoteMessageRead: 'FD_RO',
    ProductOwner: 'PO',
};

export const ServiceRoleDescription = {
    MU_RW: 'MapUpdate Read/Write',
    OT_RW: 'OnlineTraffic Read/Write',
    FD_RW: 'FeatureDashboard Read/Write',
    MU_RO: 'MapUpdate Read',
    OT_RO: 'OnlineTraffic Read',
    FD_RO: 'FeatureDashboard Read',
    PO: 'Product Owner',
};

export const rolloutStatusFinal = {
    MapUpdatesRejectedState: rolloutStatusMap.MapUpdatesRejectedState,
    RolloutDoneState: rolloutStatusMap.RolloutDoneState,
    RolloutCanceledState: rolloutStatusMap.RolloutCanceledState,
    RolloutFailedState: rolloutStatusMap.RolloutFailedState,
};

export const initialRolloutStatusFilter = [
    'SUSPENDED',
    'APP_STATE_UPDATE_INITIATED',
    'COMPLETED',
    'MAP_UPDATES_REJECTED',
    'MAP_UPDATES_PATCHED',
    'MAP_UPDATES_PATCHING',
    'DOWNLOADS_COMPLETED',
    'DOWNLOAD_IN_PROGRESS',
    'MDM_DOWNLOAD_COMPLETED',
    'DOWNLOAD_INITIATED',
    'MAP_UPDATES_OFFERED',
    'MAP_STATE_UPDATE_INITIATED',
    'QUEUED',
];

export const activationsManNowStatus = {
    Active: 'active',
    Inactive: 'inactive',
    Never: 'never',
    Conflict: 'conflict',
    Error: 'error',
};
export const activationsFotaStatus = {
    Active: 'active',
    Inactive: 'inactive',
    Never: 'never',
};

export const environments = {
    Dev: 'dev',
    Canary: 'canary',
    Prod: 'prod'
};

export const INIT_URL_LINK = 'initUrlLink';
export const languageData = [
    { "bg-BG": 'fotaone.language.locales.bg-BG' },
    { "cs-CZ": 'fotaone.language.locales.cs-CZ' },
    { "da-DK": 'fotaone.language.locales.da-DK' },
    { "de-DE": 'fotaone.language.locales.de-DE' },
    { "el-GR": 'fotaone.language.locales.el-GR' },
    { "en-GB": 'fotaone.language.locales.en-GB' },
    { "es-ES": 'fotaone.language.locales.es-ES' },
    { "et-EE": 'fotaone.language.locales.et-EE' },
    { "fi-FI": 'fotaone.language.locales.fi-FI' },
    { "fr-FR": 'fotaone.language.locales.fr-FR' },
    { "hr-HR": 'fotaone.language.locales.hr-HR' },
    { "hu-HU": 'fotaone.language.locales.hu-HU' },
    { "it-IT": 'fotaone.language.locales.it-IT' },
    { "lt-LT": 'fotaone.language.locales.lt-LT' },
    { "lv-LV": 'fotaone.language.locales.lv-LV' },
    { "nb-NO": 'fotaone.language.locales.nb-NO' },
    { "nl-NL": 'fotaone.language.locales.nl-NL' },
    { "pl-PL": 'fotaone.language.locales.pl-PL' },
    { "pt-PT": 'fotaone.language.locales.pt-PT' },
    { "ro-RO": 'fotaone.language.locales.ro-RO' },
    { "sk-SK": 'fotaone.language.locales.sk-SK' },
    { "sl-SI": 'fotaone.language.locales.sl-SI' },
    { "sv-SE": 'fotaone.language.locales.sv-SE' },
];