import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { getTestFleetStatusFromAssets } from '@helpers/constants.jsx';
import { get } from 'lodash';
import Badge from '@common/Badge/Badge.jsx';
import VehicleDetailsGeneral from './VehicleDetailsGeneral.jsx';
import VehicleDetailsDevice from './VehicleDetailsDevice.jsx';
import VehicleDetailsGroups from './VehicleDetailsGroups.jsx';
import VehicleDetailsConnectivity from './VehicleDetailsConnectivity.jsx';
import VehicleDetailsMapState from './VehicleDetailsMapState.jsx';
import VehicleDetailsRollouts from './VehicleDetailsRollouts.jsx';
import VehicleDetailsAssets from './VehicleDetailsAssets.jsx';
import VehicleDetailsRegions from './VehicleDetailsRegions.jsx';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { useTranslation } from 'react-i18next';

const VehicleDetails = (props) => {
    const { vehicleId } = props;
    const { assetStore } = useStores();
    const [sortDir, setSortDir] = useState("desc");
    const deviceDetails = get(assetStore.assetDetails, ['vehicles', '0']);
    const isMultipleAssets = useMemo(() => deviceDetails?.assets?.length > 1, [deviceDetails]);
    const { isTestFleet } = getTestFleetStatusFromAssets(deviceDetails?.assets || []);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const  deploymentEnvironment  = deviceDetails?.environment ? deviceDetails?.environment : null;

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            await assetStore.getDetailsById(vehicleId, sortDir);
        };
        fetchData().then(() => {
            setIsLoading(false);
        });
    }, [vehicleId, sortDir, assetStore]);

    const handleSortChange = () => {
        sortDir==="desc"?setSortDir("asc"):setSortDir("desc");
    };

    return (
        <div className='vehicle-details'>
            <div className='display-flex align-items-center'>
                <div className='margin-right-auto'>
                    <button
                        type='button'
                        className='btn btn-primary margin-bottom-10 margin-right-10'
                        onClick={async () => {
                            await assetStore.getDetailsById(vehicleId, sortDir);
                        }}
                        disabled={assetStore.detailsState.isLoading}
                    >
                        <span className='rioglyph rioglyph-refresh' />
                            {`${t("fotaone.mu.refreshData")}`}
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary margin-bottom-10'
                        onClick={async () => {
                            await assetStore.refreshAssetInfo(vehicleId);
                            Notification.success(`${t("fotaone.notification.success.assets.requestUpdate")} ${vehicleId}`);
                        }}
                        disabled={assetStore.detailsState.isLoading}
                    >
                        <span className='rioglyph rioglyph-compare' />
                            {`${t("fotaone.mu.requestUpdate")}`}
                    </button>
                </div>

                <div className='display-flex justify-content-around align-items-center'>
                    {isMultipleAssets && (
                        <Badge title={`${t("fotaone.general.tagData.multipleAssets")}`}
                               state={`${t("fotaone.general.tagData.multipleAssets")}`}
                               color={'badge-default'}
                               style={{ marginRight: '5px' }}/>
                    )}
                    {isTestFleet && <Badge
                      state={`${t("fotaone.general.tagData.testfleet")}`}
                      title={`${t("fotaone.general.tagData.testfleet")}`}
                      color={'badge-info'}
                      style={{ marginRight: '5px' }} />}
                    {deploymentEnvironment &&
                      <Badge title={`${t("fotaone.general.tagData.environment")}`}
                             state={deploymentEnvironment[0].toUpperCase() + deploymentEnvironment.slice(1)}
                             color={'badge-info'} />
                    }
                </div>
            </div>
            <VehicleDetailsGeneral />
            <VehicleDetailsDevice />
            {!isLoading && <VehicleDetailsGroups vehicleId={vehicleId} />}
            {assetStore.detailsState.isLoaded && <VehicleDetailsRegions vehicleId={vehicleId} />}
            <VehicleDetailsConnectivity />
            <VehicleDetailsMapState />
            <VehicleDetailsRollouts handleSortChange={handleSortChange}/>
            <VehicleDetailsAssets />
        </div>
    );
};

export default observer(VehicleDetails);
