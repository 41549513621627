import { muFilterDataKeys } from '@components/mapupdate/vehicles/overview/Vehicle.constants.jsx';
import { environments } from './constants.jsx';

export const validateEnvironmentLabelIfDev = (filteredData) => {
  if (window.location.href.indexOf('services-dev') !== -1) {
    filteredData.find((entry) => entry.key === muFilterDataKeys.Environment)
      .options.find((entry) => entry.key === environments.Prod).label
      = "Prod (On DEV there are no available tags for environment, all vehicles default to Prod)";
  }
  return filteredData;
};
