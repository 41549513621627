import { observer, Observer } from 'mobx-react-lite';
import { cloneDeep, difference, get, isNil, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';

const VehicleDetailsGroups = observer((props) => {
    const { vehicleId } = props;
    const { assetStore, groupStore } = useStores();
    const [groupsList, setGroupsList] = useState([]);
    const [vehicleGroups, setVehicleGroups] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        groupStore.getGroups().then(() => {
            const initVehicleGroups = get(assetStore.assetDetails, ['vehicles', '0', 'groups'])?.map(
                (group) => group.id,
            );
            setVehicleGroups(initVehicleGroups);
            const initGroup = groupStore.groupList?.map((group) => ({
                id: group.id,
                label: group.name,
                selected: some(get(assetStore.assetDetails, ['vehicles', '0', 'groups']), { id: group.id }),
            }));
            setGroupsList(initGroup);
        });
    }, [vehicleId]);

    const onGroupChange = (_groups) => {
        if (vehicleGroups.length > _groups.length) {
            const data = {
                groupId: difference(vehicleGroups, _groups)[0],
                vehicleId: get(assetStore.assetDetails, ['vehicles', '0', 'id']),
            };
            groupStore.removeFromGroup(data);
        } else {
            const data = {
                groupId: _groups[_groups.length - 1],
                vehicleId: get(assetStore.assetDetails, ['vehicles', '0', 'id']),
            };
            groupStore.addToGroup(data);
        }
        const newTagList = groupStore.groupList.map((group) => ({
            id: group.id,
            label: group.name,
            selected: _groups.includes(group.id),
        }));
        setGroupsList(cloneDeep(newTagList));
        setVehicleGroups(cloneDeep(_groups));
    };

    return (
        <>
            <h5>{`${t("fotaone.mu.groupData.group.many")}`}</h5>
            <div className='breakline margin-bottom-20' />
            <div className='group-select margin-bottom-20'>
                <Observer>
                    {() => (
                        <TagList
                            className='margin-top-10'
                            inline={groupsList.filter((item) => item.selected === true).length !== 0}
                        >
                            {!isNil(groupsList) && groupsList.filter((item) => item.selected === true).length === 0 ? (
                                <span className='text-muted text-size-16'>
                                    {`${t("fotaone.mu.groupData.noGroupsAssigned")}`}
                                </span>
                            ) : (
                                groupsList
                                    .filter((item) => item.selected === true)
                                    .map((item) => <Tag key={item.label}>{item.label}</Tag>)
                            )}
                        </TagList>
                    )}
                </Observer>

                <div className='margin-bottom-5' />
                <Multiselect
                    placeholder={`${t("fotaone.mu.groupData.groupSelect")}`}
                    options={groupsList}
                    counterMessage={{ one: `${t("fotaone.mu.groupData.groupsSelected.one")}`, many: `${t("fotaone.mu.groupData.groupsSelected.many")}` }}
                    onChange={onGroupChange}
                />
            </div>
        </>
    );
});

export default VehicleDetailsGroups;
