import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const FeatureDashboardMenu = (props) => {
  const { t } = useTranslation();

  return (
      <>
        <div className='text-size-h2 text-thin'>
          Feature Dashboard
        </div>
        <Divider />
        <p>
          {t('fotaone.general.serviceMenu.featureDashboardSection.serviceDesc')}
        </p>
        <div className='text-size-h5 margin-bottom-5'>
          <span className='rioglyph rioglyph-truck margin-right-10' />
          <strong>{t('fotaone.fd.serviceData.digitalServices')}</strong>
        </div>
        <ul>
          <li>
            <p>
              <strong>{t('fotaone.general.vehicleData.vehicle.many')}</strong>: {' '}{t('fotaone.general.serviceMenu.featureDashboardSection.vehiclesDesc')}
            </p>
          </li>
          <li>
            <p>
              <strong>{t('fotaone.fd.serviceData.service.many')}</strong>: {' '}{t('fotaone.general.serviceMenu.featureDashboardSection.servicesDesc')}
            </p>
          </li>
        </ul>
    </>
  );
};

export default observer(FeatureDashboardMenu);