import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar.jsx';
import { useTranslation } from 'react-i18next';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants.js';
import { useNavigate } from 'react-router-dom';
import MyMessageDetails from '@components/RemoteMessage/MyMessages/Overview/Sidebar/MyMessageDetail.jsx';

const MyMessageDetailSidebar = (props) => {
  const { t } = useTranslation();
  const { serviceStore, remoteMessageStore } = useStores();
  const { messageId, setShowMyMessageConfirmationDialog, setConfirmationType } = props;
  const navigate = useNavigate();
  const close = async () => {
    navigate(`${serviceStore.currentService}/myMessages`);
  };

  return (
      <DetailsSideBar
          title={`${t("fotaone.rm.messageData.messageDetails")}`}
          onClose={close}
          width={600}
          blurEffect={remoteMessageStore.messageDetailsLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide}
          fly
      >
       <MyMessageDetails
         messageId={messageId}
         setShowMyMessageConfirmationDialog={setShowMyMessageConfirmationDialog}
         setConfirmationType={setConfirmationType}
       />
      </DetailsSideBar>
  );
};

export default observer(MyMessageDetailSidebar);