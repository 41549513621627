import { observer } from 'mobx-react-lite';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';
import { useTranslation } from 'react-i18next';
import React, { useRef, useState } from 'react';
import { useStores } from '@stores/context.jsx';

export default observer((props) => {
  const {name, category, icon, code} = props;
  const { t } = useTranslation();
  const [imagePreview, setImagePreview] = useState('');
  const fileInputRef = useRef();
  const { featureDashboardStore } = useStores();
  function getClass(category) {
    switch (category) {
      case 'Efficiency':
        return 'text-color-primary';
      case 'Comfort':
        return 'text-color-secondary';
      case 'Safety':
        return 'text-color-success';
      default:
        return 'text-color-danger';
    }
  }

  const handleIconChange = async (e) => {
    const file = e.target.files[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = reader.result;
        setImagePreview(base64);
        await featureDashboardStore.uploadIcon({
          featureCode: code,
          iconData: {icon: base64}
        });
      };
      reader.onerror = (error) => {
        console.error('Error reading Icon:', error);
      };
  }

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <div className={'display-flex'}>
        <input
            type='file'
            accept='image/*'
            onChange={handleIconChange}
            style={{ display: 'none' }}
            ref={fileInputRef}
        />
      <div className={'margin-right-10'} onClick={handleClick}>
          {
              imagePreview || icon ? (
                <div
                    style={{ background: '#303c49', width: 40, height: 40, borderRadius: 5, cursor: 'copy'}}

                >
                    <img
                        src={icon}
                        alt={name}
                        style={{ width: 40, height: 40}}

                    />
                </div>
            ) : (
                <div
                    className="width-40 aspect-ratio-1 position-relative shorthand-style"
                >
                    <div
                        className="inset-0 display-flex justify-content-center align-items-center rounded"
                        style={{backgroundColor: '#303c49', color: 'white'}} >
                        {`${t("fotaone.general.icon")}`}
                    </div>
                </div>
            )
          }
      </div>
      <div className='margin-right-10 display-flex align-items-center'><b>{name}</b></div>
      <div className={'display-flex align-items-center'}>
        <Tag size='small' className={getClass(category)}>
          {`${t(`fotaone.fd.featureData.category.${category.toLowerCase()}`)}`}
        </Tag>
      </div>
    </div>
  );
});