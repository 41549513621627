import React, { useEffect, useState } from 'react';
import { useStores } from '@stores/context.jsx';
import FullPageLoader from '@common/Loader/FullPageLoader';
import { Navigate } from 'react-router';

const AuthLogout = () => {
    const [loading, setLoading] = useState(true);
    const { authStore } = useStores();
    useEffect(() => {
        authStore.completeLogout();
        setTimeout(() => setLoading(false), 1000);
    }, [authStore]);
    return loading ? <FullPageLoader /> : <Navigate to={'/auth'} />;
};

export default AuthLogout;
