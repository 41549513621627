import { zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';

// this normalizes a timestamp without timezone to UTC time
export const toUtcDate = (utcTimestamp) => {
    return zonedTimeToUtc(utcTimestamp, 'UTC');
};
export const getDateAndHour = (date) => {
    const checkedDate = !date ? new Date() : date;
    return moment(checkedDate).format('YYYY_MM_DD_h');
};

export function formatDate(dateToBeParsed) {
    return dateToBeParsed ? moment(dateToBeParsed).format('yyyy-MM-DD hh:mm:ss') : 'N/A';
}
