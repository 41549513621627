import React from 'react';
import { observer } from 'mobx-react-lite';
import TableLength from './TableLength';
import Pages from './Pages';
import { PaginationStore } from '@stores/pagination.js';

/**
 *
 * @typedef Props
 * @type {object}
 * @property {import('./../../../stores/pagination').PaginationStore} paginationStore
 * @property {import('./TableLength').TableLengthProps} tableLengthProps
 * @property {import('./Pages.jsx').PagesProps} pagesProps
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export default observer((props) => {
    const { tableLengthProps, pagesProps, paginationStore = new PaginationStore() } = props;

    const size = paginationStore?.size;
    const activePage = paginationStore?.activePage;
    const step = 3;

    const handlePaginationChange = (page) => {
        paginationStore.setActivePage(page);
    };
    const handleLengthChange = (value) => {
        paginationStore.setLimit(value);
        paginationStore.setActivePage(1);
    };

    return (
        <div className='display-flex margin-top-10 margin-bottom-10'>
            <TableLength onSelect={handleLengthChange} {...tableLengthProps} />
            <Pages
                onPageChange={handlePaginationChange}
                step={step}
                size={size}
                activePage={activePage}
                {...pagesProps}
            />
        </div>
    );
});
