import { gql } from '@apollo/client';
import { setWith } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
import { client } from '@api/onlinetraffic-graphql';
import { filterDataKeys } from '@components/Onlinetraffic/Vehicle/Vehicle.constants';
// eslint-disable-next-line no-unused-vars
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { environments } from '@helpers/constants.jsx';
import {
    enrichVehicleDataWithDeploymentEnvironment,
    generateDeviceEnvironmentMap,
    getOTDevicesDeploymentEnvironment,
} from './storeHelpers/utils.jsx';
import i18n from 'i18next';

let instance; // singleton instance

export const VehicleSearchFilter = {
    status: {
        ANY: 'any',
        ACTIVE: 'active',
        DEACTIVATED: 'deactivated',
        INACTIVE: 'inactive',
    },
    source: {
        ANY: 'any',
        MAN_NOW: 'man-now',
        FOTA_ADMIN: 'fota-admin',
        NOT_SUPPORTED: 'not-supported',
    },
};
export const VEHICLE_STATES = {
    INITIAL: 'initial',
    LOADING: 'loading',
    ERROR: 'error',
    LOADED: 'loaded',
};

const SEARCH_VEHICLES = gql`
    query SearchVehicles(
        $query: String = "%"
        $limit: Int = 50
        $offset: Int = 0
        $orderBy: [ot_vehicles_order_by!] = {}
        $filter: [ot_vehicles_bool_exp!] = {}
    ) {
        ot {
            vehicles(
                limit: $limit
                offset: $offset
                order_by: $orderBy
                where: {
                    _and: [
                        { vin: { _is_null: false } }
                        {
                            _or: [
                                { devices: { device_id: { _ilike: $query } } }
                                { id_text: { _ilike: $query } }
                                { name: { _ilike: $query } }
                                { assets: { account_id: { _ilike: $query } } }
                                { assets: { asset_id_text: { _ilike: $query } } }
                                { vin: { _ilike: $query } }
                                { devices: { serial_number: { _ilike: $query } } }
                                { devices: { formatted_serial_no: { _ilike: $query } } }
                            ]
                            _and: $filter
                        }
                    ]
                }
            ) {
                name
                id
                id_text
                vin
                assets {
                    account_id
                    asset_id
                    asset_id_text
                    created_at
                    testfleet {
                        account_id
                    }
                    activations {
                        activated_at
                        activation_source
                        deactivated_at
                        first_activated_at
                        first_coverage_request_at
                        source_active
                        deactivation_reason
                    }
                }
                devices {
                    device_id
                    serial_number
                    formatted_serial_no
                    type
                }
                activations_override {
                    status
                    created_at
                    vehicle_id
                }
            }
            vehicles_aggregate(
                where: {
                    _and: [
                        { vin: { _is_null: false } }
                        {
                            _or: [
                                { devices: { device_id: { _ilike: $query } } }
                                { id_text: { _ilike: $query } }
                                { name: { _ilike: $query } }
                                { assets: { account_id: { _ilike: $query } } }
                                { assets: { asset_id_text: { _ilike: $query } } }
                                { vin: { _ilike: $query } }
                                { devices: { serial_number: { _ilike: $query } } }
                                { devices: { formatted_serial_no: { _ilike: $query } } }
                            ]
                            _and: $filter
                        }
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const SEARCH_VEHICLE_DETAILS = gql`
    query SearchVehicleDetails($vin: String!) {
        ot {
            vehicles(where: { vin: { _eq: $vin } }) {
                name
                id
                id_text
                vin
                assets {
                    account_id
                    asset_id
                    asset_id_text
                    created_at
                    testfleet {
                        account_id
                    }
                    activations {
                        activated_at
                        activation_source
                        deactivated_at
                        first_activated_at
                        first_coverage_request_at
                        source_active
                    }
                }
                devices {
                    device_id
                    serial_number
                    formatted_serial_no
                    type
                }
                activations_override {
                    status
                    created_at
                    vehicle_id
                }
            }
        }
    }
`;

const SESSION_COUNTS_PER_COUNTRY = gql`
    query GetSessionCountsPerCountry($startDate: date, $endDate: date) {
        ot {
            get_session_counts__by_date_range(args: { start_date: $startDate, end_date: $endDate }) {
                activated_sessions_count
                deactivated_sessions_count
                start_country
            }
        }
    }
`;

export class VehicleStore {
    vehicleList = null;
    vehicleTotal = 0;
    vehicleDetails = {};
    vehicleParams = {};
    vehicleDeviceEnvironment = [];
    /**
     *
     * @type {StateMachineStore}
     */
    stateMachine = {};

    /**
     *
     * @type {StateMachineStore}
     */
    detailsState = {};

    /**
     *
     * @param {StateMachineStore} stateMachine
     * @param {StateMachineStore} detailsState
     */
    constructor(stateMachine, detailsState) {
        makeObservable(this, {
            stateMachine: observable,
            detailsState: observable,
            vehicleList: observable,
            vehicleTotal: observable,
            vehicleDetails: observable,
            vehicleParams: observable,
            vehicleDeviceEnvironment: observable,

            setVehicleList: action,
            setVehicleTotal: action,
            setVehicleDeviceEnvironment: action,
            searchVehicles: action,
            getVehicleDetailsByVin: action,
        });

        this.stateMachine = stateMachine;
        this.detailsState = detailsState;
    }

    resetDetails() {
        this.vehicleDetails = {};
    }

    setVehicleList(value) {
        this.vehicleList = value;
    }

    setVehicleTotal(value) {
        this.vehicleTotal = value;
    }

    setVehicleDetails(value) {
        this.vehicleDetails = value;
    }
    setVehicleParams(value) {
        this.vehicleParams = value;
    }
    setVehicleDeviceEnvironment(value) {
        this.vehicleDeviceEnvironment = value;
    }

    async searchVehicles(searchParams = {}) {
        const { query, sortBy, sortDir, limit, offset, filters } = searchParams;
        this.setVehicleParams(searchParams);
        const response = {
            list: null,
            total: null,
            vehicleDeviceEnvironment: null,
        };
        this.stateMachine.setIsLoadingState();
        try {
            const variables = {
                query: `%${query}%`,
                limit,
                offset,
                filter: {},
            };

            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
                if (!('vin' in variables.orderBy)) {
                    variables.orderBy['vin'] = 'asc';
                }
            }

            const vehicleDeviceEnvironmentMap = generateDeviceEnvironmentMap(
                await getOTDevicesDeploymentEnvironment(true)
            );

            this.setVehicleDeviceEnvironment(vehicleDeviceEnvironmentMap);

            variables.filter = this.getVariablesFilters(filters);

            const res = await client.query({
                query: SEARCH_VEHICLES,
                variables,
                fetchPolicy: 'no-cache',
            });

            res.data = enrichVehicleDataWithDeploymentEnvironment(res.data.ot, vehicleDeviceEnvironmentMap);

            const {
                vehicles,
                vehicles_aggregate: {
                    aggregate: { count: total },
                },
            } = res.data;

            response.list = vehicles;
            response.total = total;
            response.vehicleDeviceEnvironment = vehicleDeviceEnvironmentMap;

            this.stateMachine.setLoadedState();
        } catch (err) {
            this.stateMachine.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.devices.fetchDevices')}`);
            }
        }
        return response;
    }
    /**
     * Queries data used to display in the VehicleDetailSidebar with the VIN provided by the VehicleListPage.
     * @param {*} vin
     * @returns {Object} vehicleDetails
     */
    async getVehicleDetailsByVin(vin) {
        try {
            this.detailsState.setIsLoadingState();
            const variables = { vin: vin };
            const query = SEARCH_VEHICLE_DETAILS;
            const res = await client.query({
                query,
                variables,
                fetchPolicy: 'no-cache',
            });
            const vehicleDeviceEnvironmentMap =
                this.vehicleDeviceEnvironment.length > 0
                    ? this.vehicleDeviceEnvironment
                    : generateDeviceEnvironmentMap(await getOTDevicesDeploymentEnvironment());
            res.data = enrichVehicleDataWithDeploymentEnvironment(res.data.ot, vehicleDeviceEnvironmentMap);
            const { vehicles } = res.data ?? {};
            this.setVehicleDetails(vehicles?.[0]);
            this.detailsState.setLoadedState();
            return this.vehicleDetails;
        } catch (err) {
            this.detailsState.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.devices.findDeviceByVin')} (${vin})`);
            }
        }
    }
    /**
     *
     * @param {*} filters
     * @returns
     */
    getVariablesFilters(filters = []) {
        const filterSetForQuery = {
            _and: [
                {
                    _or: [],
                },
                {
                    _or: [],
                },
                {
                    _or: [],
                },
                {
                    _or: [],
                },
            ],
        };
        filters.forEach((filter) => {
            const filtersSetByUser = filter.result;
            switch (filter.key) {
                case filterDataKeys.ManNow:
                    if (filtersSetByUser.some((elem) => elem === 'active'))
                        filterSetForQuery._and[0]._or.push({
                            assets: {
                                activations: {
                                    source_active: {
                                        _eq: true,
                                    },
                                },
                            },
                        });
                    if (filtersSetByUser.some((elem) => elem === 'inactive'))
                        filterSetForQuery._and[0]._or.push({
                            assets: {
                                activations: {
                                    source_active: {
                                        _eq: false,
                                    },
                                },
                            },
                        });
                    if (filtersSetByUser.some((elem) => elem === 'never'))
                        filterSetForQuery._and[0]._or.push({
                            _not: {
                                assets: {
                                    activations: {},
                                },
                            },
                        });
                    if (filterSetForQuery._and[0]._or.length === 0) filterSetForQuery._and[0]._or.push({ assets: {} });
                    break;
                case filterDataKeys.FotaStatus:
                    if (filtersSetByUser.some((elem) => elem === 'active'))
                        filterSetForQuery._and[1]._or.push({
                            activations_override: {
                                status: {
                                    _eq: 'ACTIVE',
                                },
                            },
                        });
                    if (filtersSetByUser.some((elem) => elem === 'inactive'))
                        filterSetForQuery._and[1]._or.push({
                            activations_override: {
                                status: {
                                    _eq: 'INACTIVE',
                                },
                            },
                        });
                    if (filtersSetByUser.some((elem) => elem === 'never'))
                        filterSetForQuery._and[1]._or.push({
                            _not: {
                                activations_override: {
                                    status: {},
                                },
                            },
                        });
                    if (filterSetForQuery._and[1]._or.length === 0) filterSetForQuery._and[1]._or.push({ assets: {} });
                    break;
                case filterDataKeys.TestFleet:
                    if (filtersSetByUser.some((elem) => elem === 'testfleet')) {
                        filterSetForQuery._and[2]._or.push({
                            assets: {
                                testfleet: {
                                    id: {
                                        _is_null: false,
                                    },
                                },
                            },
                        });
                    } else {
                        filterSetForQuery._and[2]._or.push({ assets: {} });
                    }
                    break;
                case filterDataKeys.Environment: {
                    const prodDevices = [];
                    for (const environment of Object.values(environments)) {
                        if (filtersSetByUser.some((elem) => elem === environment)) {
                            for (const key of this.vehicleDeviceEnvironment.keys()) {
                                if (environment === environments.Prod) {
                                    prodDevices.push(key);
                                } else if (environment === this.vehicleDeviceEnvironment.get(key)) {
                                    filterSetForQuery._and[3]._or.push({ devices: { device_id: { _eq: key } } });
                                }
                            }
                            if (prodDevices.length > 0) {
                                filterSetForQuery._and[3]._or.push({ devices: { device_id: { _nin: prodDevices } } });
                            }
                        }
                    }
                    if (filterSetForQuery._and[3]._or.length === 0) {
                        filterSetForQuery._and[3]._or.push({ devices: {} });
                    }
                    break;
                }
                default:
                    break;
            }
        });
        return filterSetForQuery;
    }

    /**
     *
     * @param {StateMachineStore} stateMachine
     * @param {StateMachineStore} detailsState
     */
    static instance(stateMachine, detailsState) {
        if (!instance) {
            instance = new VehicleStore(stateMachine, detailsState);
        }
        return instance;
    }

    async getSessionCountsPerCountry(startDate, endDate) {
        try {
            const variables = {
                startDate,
                endDate,
            };
            const res = await client.query({
                query: SESSION_COUNTS_PER_COUNTRY,
                variables,
                fetchPolicy: 'no-cache',
            });
            return res.data.ot.get_session_counts__by_date_range
                .filter((item) => item.start_country !== '')
                .map((item) => {
                    const newItem = {
                        ...item,
                        start_country: item.start_country === 'DE' ? 'SimTruck' : item.start_country,
                        Activated: item.activated_sessions_count,
                        Deactivated: item.deactivated_sessions_count,
                    };
                    newItem.activated_sessions_count = undefined;
                    newItem.deactivated_sessions_count = undefined;
                    return newItem;
                });
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t('fotaone.notification.error.devices.fetchSessions')}`);
            }
        } finally {
        }
    }
}
