import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';

const VehicleDetailsDevice = observer(() => {
    const { featureDashboardStore } = useStores();
    const [language, setLanguage] = useState("");
    const [activeFeatures, setActiveFeatures] = useState([]);
    const [inactiveFeatures, setInactiveFeatures] = useState([]);
    const { t, i18n } = useTranslation();

    const activeList =[];
    const inactiveList =[];

    useEffect(() => {
        setLanguage(i18n.resolvedLanguage ? i18n.resolvedLanguage : "en");
        getFeatures()
    }, [i18n.resolvedLanguage, featureDashboardStore.deviceDetails]);

    function getClass(category) {
        switch (category) {
        case 'Efficiency':
            return 'text-color-primary';
        case 'Comfort':
            return 'text-color-secondary';
        case 'Safety':
            return 'text-color-success';
        default:
            return 'text-color-danger';
        }
    }

    const getFeatures = () => {
        const allFeatures = get(featureDashboardStore.deviceDetails, ['assets','0','activations'], []);
        const uniqueActiveFeatures = getUniqueActiveFeatures(allFeatures);
        const uniqueInactiveFeatures = getUniqueInactiveFeatures(allFeatures, featureDashboardStore.bookingRequestList);
        setActiveFeatures(uniqueActiveFeatures);
        setInactiveFeatures(uniqueInactiveFeatures);
    }

    function getUniqueActiveFeatures(features) {
        const activeFeatures = features.filter(feature => feature.is_active);
        const uniqueFeaturesMap = activeFeatures.reduce((acc, feature) => {
            const { feature: { code, name, category, feature_descriptions }, feature_variant, id, subscription_end_date, subscription_start_date, type  } = feature;
            if (!acc[code]) {
                acc[code] = {
                code,
                name,
                category,
                descriptions: feature_descriptions,
                variants: [],
                id: id,
                type: type,
                subscription_end_date: subscription_end_date,
                subscription_start_date: subscription_start_date,
                };
            }
            acc[code].variants.push(feature_variant);
            return acc;
        }, {});
            return Object.values(uniqueFeaturesMap);
    }

    function getUniqueInactiveFeatures(features, bookingRequests) {
        const inactiveFeatures = features.filter(feature => !feature.is_active);
        const uniqueFeaturesMap = inactiveFeatures.reduce((acc, feature) => {
            const { feature: { code, name, category, feature_descriptions }, feature_variant, id, subscription_end_date, subscription_start_date, type } = feature;
            if (!acc[code]) {
                acc[code] = {
                    code,
                    name,
                    category,
                    descriptions: feature_descriptions,
                    variants: [],
                    id: id,
                    type: type,
                    subscription_end_date: subscription_end_date,
                    subscription_start_date: subscription_start_date,
                    booking_requests: []
                };
            }
            acc[code].variants.push(feature_variant);
            return acc;
        }, {});

        bookingRequests.forEach(request => {
            if (uniqueFeaturesMap[request.feature_code]) {
                uniqueFeaturesMap[request.feature_code].booking_requests.push(request);
            }
        });

        return Object.values(uniqueFeaturesMap);
    }

    const renderFeatureCount = () => {
        return(
            <div className='row'>
                <div className='col-md-6'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>{`${t("fotaone.fd.serviceData.amountActiveServices")}`}</label>
                        {activeFeatures.length > 0 ? (
                            <span className='text-primary text-size-18'>
                                {activeFeatures.length}
                            </span>
                        ):(
                            <span className='text-primary'>
                                <div className="label label-default">{`${t("fotaone.fd.serviceData.noActiveServices")}`}</div>
                            </span>
                        )}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>{`${t("fotaone.fd.serviceData.amountSupportedServices")}`}</label>
                        <span className='text-primary text-size-18'>
                            {activeFeatures.length + inactiveFeatures.length}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    const renderActiveFeatureList = () => {
        for (let i = 0; i < activeFeatures?.length; i++) {
            const endDate = activeFeatures[i].subscription_end_date;
            const startDate = activeFeatures[i].subscription_start_date;
            const name = activeFeatures[i].name;
            const code = activeFeatures[i].code;
            const type = activeFeatures[i].type;
            const category = activeFeatures[i].category;
            let variants = [];
            for (let v = 0; v < activeFeatures[i].variants.length; v++) {
                if(activeFeatures[i].variants[v] !== "N/A"){
                    variants.push(
                        <Tag key={`variant_${v}`}>
                            {activeFeatures[i].variants[v]}
                        </Tag>
                    )
                }
            }
            let shortDescription="";
            let description="";
            for (const element of activeFeatures[i].descriptions) {
                if(element.locale.includes(language)){
                    shortDescription = element.short_description;
                    description = element.description;
                }
            }
            activeList.push(
                <div className='row' key={`key-${code}`} id={code}>
                    <div className='col-md-12 margin-bottom-2'>
                        <ExpanderPanel 
                            title={`${name} (${code})`}
                            bsStyle='default'
                            headerClassName='text-bold bg-lightest text-center'
                            className='border border-left-only border-color-success hover-border-color-rating-5 border-width-3'
                        >
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='margin-bottom-10'>
                                        <label className='display-block'>{`${t("fotaone.fd.serviceData.category")}`}</label>
                                        <Tag size='small' className={getClass(category)}>{`${t(`fotaone.fd.featureData.category.${category.toLowerCase()}`)}`}</Tag>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='margin-bottom-10'>
                                        <label className='display-block'>{`${t("fotaone.fd.serviceData.bookedSince")}`}</label>
                                        {startDate ? startDate.split('T')[0] : `${t("fotaone.general.table.noData")}`}
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='margin-bottom-10'>
                                        <label className='display-block'>{`${t("fotaone.fd.serviceData.bookedUntil")}`}</label>
                                        {endDate ? endDate.split('T')[0] : `${t("fotaone.general.table.noData")}`}
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='margin-bottom-10'>
                                        <label className='display-block'>{`${t("fotaone.fd.serviceData.type")}`}</label>
                                        {type || `${t("fotaone.general.table.noData")}`}
                                    </div>
                                </div>
                            </div>
                            <div className='breakline margin-bottom-10' />
                            <div className='display-flex justify-content-center'>
                                <div className='width-50 flex-1-0 text-size-20'>
                                    <span className='rioglyph rioglyph-detail-view-info text-size-200pct text-color-gray' />
                                </div>
                                <div className='text-color-darker'>{shortDescription}</div>
                            </div>
                            <div className='row'>
                                <ExpanderPanel 
                                    title={`${t("fotaone.fd.featureData.featureFullDesc")}`}
                                    bsStyle='separator'
                                    iconLeft
                                >
                                    <div className='display-flex justify-content-center'>
                                        <div className='text-color-darker'>{description}</div>
                                    </div>
                                </ExpanderPanel>
                            </div>
                            {variants.length > 0 ? (
                                <div className='row'>
                                    <ExpanderPanel 
                                        title={`${t("fotaone.fd.featureData.variant.many")} (${variants.length})`}
                                        bsStyle='separator'
                                        iconLeft
                                    >
                                        <div>
                                            {variants}
                                        </div>
                                        <br/>
                                    </ExpanderPanel>
                                </div>
                            ):(
                                <></>
                            )}
                        </ExpanderPanel>
                    </div>
                </div>
            )
            variants = [];
        }

        return(
            <>
            {activeList.length > 0 ? (
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.fd.serviceData.activeServices")}:`}</label>
                        </div>
                        {activeList}
                    </div>
                </div>
            ):(<></>)}
            <br/>
            </>
        )
    }

    const renderInactiveFeatureList = () => {
        for (let i = 0; i < inactiveFeatures?.length; i++) {
            const { name, code, category, variants, descriptions, booking_requests } = inactiveFeatures[i];
            const variantTags = [];
            for (let v = 0; v < variants.length; v++) {
                if (variants[v] !== "N/A") {
                    variantTags.push(
                        <Tag key={`variant_${v}`}>
                            {variants[v]}
                        </Tag>
                    );
                }
            }
    
            let shortDescription = "";
            let description = "";
            for (const element of descriptions) {
                if (element.locale.includes(language)) {
                    shortDescription = element.short_description;
                    description = element.description;
                }
            }
            const isRequested = booking_requests && booking_requests.length > 0;
            const requestId = isRequested ? booking_requests[0].id : '';
            inactiveList.push(
                <div className='row' key={`key-${code}`} id={code}>
                    <div className='col-md-12'>
                        <ExpanderPanel
                            title={
                                <div className='text-right'>
                                    <span className={isRequested ? 'rioglyph rioglyph-notification pulsing text-size-h5' : ''} />
                                    {` ${name} (${code}) ${isRequested ? ' - ' : ''}`}
                                    {isRequested ? ` (${t("fotaone.general.requested").toUpperCase()})` : ''}
                                </div>}
                            bsStyle='default'
                            headerClassName="text-bold bg-lightest"
                            className={`border border-left-only border-width-3 ${isRequested ? 'border-color-warning' : 'border-color-default hover-border-color-gray'}`}
                        >
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='margin-bottom-10'>
                                        <label className='display-block'>{t("fotaone.fd.serviceData.category")}</label>
                                        <Tag size='small' className={getClass(category)}>{t(`fotaone.fd.featureData.category.${category.toLowerCase()}`)}</Tag>
                                    </div>
                                </div>
                                <div className='col-md-5' />
                                <div className='col-md-4'>
                                    <div className='margin-bottom-10'>
                                        <label className='display-block'>{t("fotaone.fd.serviceData.digitalServices")} {t("fotaone.general.overview")}</label>
                                        <a href="https://rio.cloud/en/marketplace/man-digital-services/man-now" target="_blank" rel="noreferrer">
                                            <div className="label label-primary hover-bg-lightest">
                                                MAN Now
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='breakline margin-bottom-10' />
                            <div className='display-flex justify-content-center'>
                                <div className='width-50 flex-1-0 text-size-20'>
                                    <span className='rioglyph rioglyph-detail-view-info text-size-200pct text-color-gray' />
                                </div>
                                <div className='text-color-darker'>{shortDescription}</div>
                            </div>
                            {isRequested ?
                                <ExpanderPanel
                                    title={t("fotaone.notification.success.assets.activationRequest")}
                                    bsStyle='separator'
                                    iconLeft
                                    open
                                    className='margin-bottom-10'
                                >
                                    <div className='display-flex justify-content-right'>
                                        <div className='width-50 flex-1-0 text-size-20'>
                                            <span className='rioglyph rioglyph-notification text-color-gray text-size-200pct' />
                                        </div>
                                        <div className='text-color-darker'>
                                            {t("fotaone.fd.serviceData.requestedByDriverDesc")}
                                            <br/>
                                            <div class="label label-muted label-filled label-condensed margin-top-5">
                                                ID: {requestId}
                                            </div>
                                        </div>
                                    </div>
                                </ExpanderPanel> : null
                            }
                            <ExpanderPanel
                                title={t("fotaone.fd.featureData.featureFullDesc")}
                                bsStyle='separator'
                                iconLeft
                                className='margin-bottom-10'
                            >
                                <div className='display-flex justify-content-center'>
                                    <div className='text-color-darker'>{description}</div>
                                </div>
                            </ExpanderPanel>
                            {variantTags.length > 0 ? (
                                <ExpanderPanel
                                    title={`${t("fotaone.fd.featureData.variant.many")} (${variantTags.length})`}
                                    bsStyle='separator'
                                    iconLeft
                                >
                                    <div>
                                        {variantTags}
                                    </div>
                                    <br />
                                </ExpanderPanel>
                            ) : null}
                        </ExpanderPanel>
                    </div>
                </div>
            );
        }

        return (
            <>
                {inactiveList.length > 0 ? (
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{t("fotaone.fd.serviceData.bookableServices")}:</label>
                            </div>
                            {inactiveList}
                        </div>
                    </div>
                ) : null}
                <br />
            </>
        );
    };    

    return (
        <>
            <h5>{`${t("fotaone.fd.serviceData.digitalServices")}`}</h5>
            <div className='breakline margin-bottom-20' />
            
            {renderFeatureCount()}
            {renderActiveFeatureList()}
            {renderInactiveFeatureList()}
        </>
    )
});

export default VehicleDetailsDevice;
