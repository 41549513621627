import React, { useEffect, useMemo, useState } from 'react';
import { createPaginationArray } from './Pages.utils';
import PropTypes from 'prop-types';
import './Pages.css';
const defaultStep = 3;
/**
 *
 * @typedef PagesProps
 * @type {object}
 * @property {()=>any} onPageChange
 * @property {number} size
 * @property {number} step
 * @property {number} activePage
 */

/**
 *
 * @param {PagesProps} props
 * @returns
 */
export default function Pages({ onPageChange, size, step = defaultStep, activePage = 1 }) {
    const [active, setActive] = useState(activePage);
    const handleActivePageChange = (clickedActive) => {
        const integerActive = parseInt(clickedActive);
        setActive(integerActive);
        onPageChange?.(parseInt(integerActive));
    };

    useEffect(() => {
        setActive(activePage);
    }, [activePage]);
    if (size <= 0) size = 1;

    // First number to show after the initial one
    const startNumber = 2;
    // Shows leading and trailing page numbers to navigate
    const showingNumbers = step * 2 + 1;
    // It's the index of the active item plus the step plus first item
    const projectedLastItemIndex = active + step + 1;
    // Show start dot if active button is greater than the value of the step plus the start number
    const isStartDotRequired = active > step + startNumber;
    // Show end dot if the size is greater than the amount of number to show and if the size is greater than the projected last item
    const isEndDotRequired = size > showingNumbers && size > projectedLastItemIndex;

    const isSizeGreaterThanDisplayedAmount = size > showingNumbers + startNumber;
    /* 
      1 ... 3 4 5 [6] 7 8 9 ... 200
      [6] - Active
      3 4 5 - Step
      200 - size 
      10 - projectedLastItemIndex 
  */
    // Builds the array of buttons to display
    const showingNumberList = useMemo(() => {
        // If size greater than displayed amount of buttons
        // Then: Display the buttons starting in the first number after the initial button
        // Otherwise: Display the buttons starting for the first number (1) until the last number is less or equal than the size
        // Clear all the number less than 0
        return createPaginationArray(
            isSizeGreaterThanDisplayedAmount,
            showingNumbers,
            size,
            projectedLastItemIndex,
            active,
            step,
            startNumber,
        );
    }, [isSizeGreaterThanDisplayedAmount, showingNumbers, size, projectedLastItemIndex, active, step]);
    return (
        <nav
            className='pagination-wrapper pagination-wrapper--center'
            aria-label='Page navigation'
            data-testid='pages_navigation'
        >
            <ul className='pagination margin-0' data-testid='pages_list'>
                {/* Show back arrow if not in the first page */}
                {active > 1 && (
                    <li>
                        <span onClick={() => handleActivePageChange(active - 1)} aria-label='Prev'>
                            <span className='rioglyph rioglyph-chevron-left' aria-hidden='true' />
                        </span>
                    </li>
                )}
                {/* Checks if amount of avaiable pages is greater than amount of numbers to show   */}
                {isSizeGreaterThanDisplayedAmount && (
                    <li id={'page_1'} className={`page-item ${active === 1 && 'active'}`}>
                        <span onClick={(e) => handleActivePageChange(e.currentTarget.textContent)}>
                            <span>1</span>
                        </span>
                    </li>
                )}
                {isStartDotRequired && (
                    <li>
                        <span onClick={() => handleActivePageChange(active - 1)}>
                            <span className='rioglyph rioglyph-option-horizontal' />
                        </span>
                    </li>
                )}
                {showingNumberList.length > 0 &&
                    showingNumberList.map((button) => (
                        <li
                            key={button}
                            id={`page_${button}`}
                            className={`page-item ${active === button ? 'bg-light' : ''}`}
                            onClick={(e) => handleActivePageChange(e.currentTarget.textContent)}
                        >
                            <span>{button}</span>
                        </li>
                    ))}
                {isEndDotRequired && (
                    <li>
                        <span onClick={() => handleActivePageChange(active + 1)}>
                            <span className='rioglyph rioglyph-option-horizontal' />
                        </span>
                    </li>
                )}
                {isSizeGreaterThanDisplayedAmount && (
                    <li className={`page-item ${active === size && 'bg-light'}`}>
                        <span onClick={(e) => handleActivePageChange(e.currentTarget.textContent)}>
                            <span>{size}</span>
                        </span>
                    </li>
                )}
                {/* Show next arrow if not in the last page */}
                {active < size && (
                    <li>
                        <span onClick={() => handleActivePageChange(active + 1)} aria-label='Next'>
                            <span className='rioglyph rioglyph-chevron-right' aria-hidden='true' />
                        </span>
                    </li>
                )}
            </ul>
        </nav>
    );
}

Pages.propTypes = {
    onPageChange: PropTypes.func,
    size: PropTypes.number,
    step: PropTypes.number,
    activePage: PropTypes.number,
};
