import React, { useMemo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStores } from '@stores/context.jsx';
import { observer } from 'mobx-react-lite';
import { ServiceRoles, ServiceRoleDescription } from '@helpers/constants.jsx';
import './Navbar.css';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import ApplicationHeader from '@rio-cloud/rio-uikit/lib/es/ApplicationHeader';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useTranslation } from 'react-i18next';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog.jsx';

const NavBar = (props) => {
    const { t, i18n } = useTranslation();
    const { authStore, serviceStore } = useStores();
    const [allowToCopyToken, setAllowToCopyToken] = useState(false);

    useEffect(() => {
        for (let i = 0; i < authStore.roles.length; i++) {
            if (authStore.roles[i].includes('_RW')) {
                setAllowToCopyToken(true);
                break;
            }
        }
    }, [authStore]);

    const handleServiceMenuSelection = (menu) => {
        serviceStore.setActiveServiceMenu(menu);
        serviceStore.setServiceMenuDialogOpen(true);
    };

    const AccountMenuComponent = !props.auth && (
        <ActionBarItem id='accountMenu'>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph-user' />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={authStore.user?.profile.email}>
                <div className='container'>
                    {authStore.roles.length > 1 ? (
                        <>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h5>{t('fotaone.auth.account.roles')}:</h5>
                                    {authStore.roles.map((role) =>
                                        ServiceRoleDescription[role] ? (
                                            <span key={role}>
                                                {ServiceRoleDescription[role]} <br />
                                            </span>
                                        ) : (
                                            <React.Fragment key={role} />
                                        ),
                                    )}
                                </div>
                            </div>
                            <hr />
                        </>
                    ) : null}
                    <div className='text-center'>
                        <button
                            type='button'
                            className={'btn btn-primary btn-action'}
                            onClick={authStore.logout}
                        >
                            <span className={'rioglyph rioglyph-off'} />
                            <span>{t('fotaone.auth.logout')}</span>
                        </button>
                    </div>
                    {allowToCopyToken ? (
                        <>
                            <hr />
                            <div className='text-center'>
                                <OverlayTrigger
                                    placement='left'
                                    overlay={
                                        <Tooltip id='tooltip' className='left'>
                                            {t('fotaone.auth.token.warning')}
                                        </Tooltip>
                                    }
                                >
                                    <button
                                        type='button'
                                        className={'btn btn-danger btn-action'}
                                        onClick={copyToken}
                                    >
                                        <span className={'rioglyph rioglyph-key'} />
                                        <span>{t('fotaone.auth.token.copy')}</span>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </>
                    ) : null}
                </div>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );

    const LanguageComponent = !props.auth && (
        <ActionBarItem id='languageMenu'>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph-language' />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover title={`${t('fotaone.language.current')}: ${i18n?.resolvedLanguage?.toUpperCase()}`}>
                <div className='container'>
                    <div className='col-md-4'>
                        <button
                            type='button'
                            className={i18n.resolvedLanguage === 'en' ? 'btn btn-primary btn-action text-color-black text-medium pointer-events-none' : 'btn btn-primary btn-action text-color-dark hover-text-color-primary'}
                            onClick={() => i18n.changeLanguage('en')}
                        >
                            <span>{`${getUnicodeFlagIcon('gb')} ${t('fotaone.language.locales.en-GB')}`}</span>
                        </button>
                    </div>
                    <div className='col-md-4'>
                        <button
                            type='button'
                            className={i18n.resolvedLanguage === 'de' ? 'btn btn-primary btn-action text-color-black text-medium pointer-events-none' : 'btn btn-primary btn-action text-color-dark hover-text-color-primary'}
                            onClick={() => i18n.changeLanguage('de')}
                        >
                            <span>{`${getUnicodeFlagIcon('de')} ${t('fotaone.language.locales.de-DE')}`}</span>
                        </button>
                    </div>
                    <div className='col-md-4'>
                        <button
                            type='button'
                            className={i18n.resolvedLanguage === 'pt' ? 'btn btn-primary btn-action text-color-black text-medium pointer-events-none' : 'btn btn-primary btn-action text-color-dark hover-text-color-primary'}
                            onClick={() => i18n.changeLanguage('pt')}
                        >
                            <span>{`${getUnicodeFlagIcon('pt')} ${t('fotaone.language.locales.pt-PT')}`}</span>
                        </button>
                    </div>
                </div>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );

    const ServiceInfo = !props.auth && (
        <ActionBarItem id='serviceInfo'>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph-info-sign' />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover className='ServicePopover'>
                <ActionBarItem.List>
                    <ActionBarItem.ListItem
                        icon='rioglyph rioglyph-heart'
                        onClick={() => {
                            handleServiceMenuSelection('Welcome');
                        }}
                    >
                        {`${t("fotaone.general.welcome")}`}
                    </ActionBarItem.ListItem>
                    <ActionBarItem.ListItem
                        icon='rioglyph rioglyph-detail-view-info'
                        onClick={() => {
                            handleServiceMenuSelection('Services');
                        }}
                    >
                        {`${t("fotaone.fd.serviceData.service.many")}`}
                    </ActionBarItem.ListItem>
                    <ActionBarItem.ListItem
                        icon='rioglyph rioglyph-chat'
                        onClick={() => {
                            handleServiceMenuSelection('Feedback');
                        }}
                    >
                        {`${t("fotaone.general.feedback")}`}
                    </ActionBarItem.ListItem>
                    <ActionBarItem.ListItem
                        icon='rioglyph rioglyph-support'
                        onClick={() => {
                            handleServiceMenuSelection('Support');
                        }}
                    >
                        {`${t("fotaone.general.support")}`}
                    </ActionBarItem.ListItem>
                </ActionBarItem.List>
            </ActionBarItem.Popover>
            {serviceStore.ServiceMenuDialog ? <ServiceMenuDialog/> : null}
        </ActionBarItem>
    );

    function copyToken() {
        navigator.clipboard
            .writeText(authStore.user.id_token)
            .then(() => {
                Notification.success(t('fotaone.notification.success.copyToken'));
            })
            .catch(() => {
                Notification.error(t('fotaone.notification.error.copyToken'));
            });
    }

    const verifyEnv = () => {
        let env = '';
        let showEnv = false;

        if (window.location.href.indexOf('localhost') !== -1) {
            env = 'LOCAL';
            showEnv = true;
        } else if (window.location.href.indexOf('services-dev') !== -1) {
            env = 'DEV';
            showEnv = true;
        }

        return (
            <>
                {!showEnv ? (
                    <div className='text-center'>FOTA Admin</div>
                ) : (
                    <div className='text-left'>
                        FOTA Admin <span className='label label-danger label-condensed'>{env}</span>
                    </div>
                )}
            </>
        );
    };

    const navItems = useMemo(() => {
        const items = [];

        if (props.auth) return items;

        const mapUpdateAllowed = authStore.roles.includes(ServiceRoles.MapUpdate) || authStore.roles.includes(ServiceRoles.MapUpdateRead);
        if (mapUpdateAllowed) {
            items.push({
                key: 'maps',
                route: (
                    <NavLink to='/maps' data-testid='maps'>
                        Map Updates
                    </NavLink>
                ),
            });
        }

        const onlineTrafficAllowed = authStore.roles.includes(ServiceRoles.OnlineTraffic) || authStore.roles.includes(ServiceRoles.OnlineTrafficRead);
        if (onlineTrafficAllowed) {
            items.push({
                key: 'online-traffic',
                route: (
                    <NavLink to='/online-traffic' data-testid='online-traffic'>
                        Online Traffic
                    </NavLink>
                ),
            });
        }

        const featureDashboardAllowed = authStore.roles.includes(ServiceRoles.FeatureDashboard) || authStore.roles.includes(ServiceRoles.FeatureDashboardRead);
        if (featureDashboardAllowed){
            items.push({
                key: 'feature-dashboard',
                route: (
                  <NavLink to={'/feature-dashboard'} data-testid={'feature-dashboard'}>
                      Feature Dashboard
                  </NavLink>
                )
            });
        }

        const remoteMessageAllowed =
          authStore.roles.includes(ServiceRoles.RemoteMessage) ||
          authStore.roles.includes(ServiceRoles.RemoteMessageRead);
        if (remoteMessageAllowed){
            items.push({
                key: 'remote-message',
                route: (
                  <NavLink to={'/remote-message'} data-testid={'remote-message'}>
                      Remote Message
                  </NavLink>
                )
            });
        }
        return items;
    }, [authStore, props.auth]);
    return (
        <ApplicationHeader
            appNavigatorClassName='appNavigatorClassName'
            label={verifyEnv()}
            homeRoute={<NavLink to='/' />}
            navItems={navItems}
            actionBarItems={[ServiceInfo, LanguageComponent, AccountMenuComponent]}
        />
    );
};
export default observer(NavBar);
