import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import VehicleTable from '@components/mapupdate/vehicles/overview/table/VehicleTable.jsx';
import VehicleDetailSidebar from '@components/mapupdate/vehicles/overview/sidebar/VehicleDetailSidebar.jsx';
import { useStores } from '@stores/context.jsx';
import { FilterStore } from '@stores/filter.jsx';
import { PaginationStore } from '@stores/pagination';
import { useParams } from 'react-router-dom';
import { cloneObject } from '@helpers/object';
import { filterData, muFilterDataKeys } from '@components/mapupdate/vehicles/overview/Vehicle.constants';
import { validateEnvironmentLabelIfDev } from '@helpers/utils.jsx';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';

const sortField = {
    VCM: 'vcm',
    VIN: 'vin',
    ASSETID: 'device_id',
    DEVICEID: 'asset_id',
    FLEETID: 'account_id',
    SHORTVIN: 'asset_name',
};

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
    filters: cloneObject(validateEnvironmentLabelIfDev(filterData)),
    query: '',
    sortBy: sortField.VIN,
    sortDir: SortDirection.DESCENDING,
});

export default observer(() => {
    const { id: vehicleId } = useParams();
    const { serviceStore } = useStores();
    const queryParams = new URLSearchParams(location.search);
    const searchQueryParam = queryParams.get('query');
    const groupId = queryParams.get('group');
    const regionParamFilter = queryParams.get('regions');
    const tagsParamFilter = queryParams.get('tags');
    const environmentParamFilter = queryParams.get('environment');
    const regionFilter = regionParamFilter ? regionParamFilter.split(',') : [];
    const tagsFilter = tagsParamFilter ? tagsParamFilter.split(',') : [];
    const environmentFilter = environmentParamFilter ? environmentParamFilter.split(',') : [];
    useEffect(() => {
        if (searchQueryParam) {
            filterStore.setQuery(searchQueryParam);
        }
        if (regionFilter.length > 0) {
            filterStore.filters.forEach((item) => {
                if (item.key === muFilterDataKeys.FotaRegionFilter) {
                    item.result = regionFilter;
                }
            });
        }
        if (tagsFilter.length > 0) {
            filterStore.filters.forEach((item) => {
                if (item.key === muFilterDataKeys.FotaTestFleetFilter) {
                    item.result = tagsFilter;
                }
            });
        }
        if (environmentFilter.length > 0) {
            filterStore.filters.forEach((item) => {
                if (item.key === muFilterDataKeys.Environment) {
                    item.result = environmentFilter;
                }
            });
        }
        // When unmounting the page:
        return () => {
            filterStore.resetToDefaults();
            paginationStore.resetToDefaults();
        };
    }, [searchQueryParam, serviceStore]);
    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Header>
                <FotaHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className='bg-muted'>
                <FotaSidebar />
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Sidebar className='right'>
                {vehicleId && <VehicleDetailSidebar vehicleId={vehicleId} />}
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer />
                {serviceStore.serviceMenuDialogOpen &&
                    <ServiceMenuDialog/>
                }
                <VehicleTable
                    groupId={groupId}
                    selectedRow={vehicleId}
                    paginationStore={paginationStore}
                    filterStore={filterStore}
                />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
});
