import React, { useEffect } from 'react';
import FotaHeader from '@components/Navbar/Navbar.jsx';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import RolloutDetails from './sidebar/RolloutDetails.jsx';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { Services } from '@stores/service.js';
import RolloutTable from './table/RolloutTable.jsx';
import { mapRolloutTableData, rolloutStatusMap } from '../../mapupdateHelper/constants.jsx';
import { FilterStore } from '@stores/filter.jsx';
import { PaginationStore } from '@stores/pagination.js';
import { cloneObject } from '@helpers/object.js';
import { filterData } from './Rollout.constants.js';
import { useParams } from 'react-router-dom';
import { campaignKpiStatus } from '../campaigns/Campaign.constants.jsx';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
    filters: cloneObject(filterData),
    query: '',
    sortBy: '',
    sortDir: SortDirection.DESCENDING,
});

export default observer(() => {
    const { serviceStore, mapRolloutStore } = useStores();
    const { id: rolloutId } = useParams();
    const queryParams = new URLSearchParams(location.search);
    const rolloutStateFilter = queryParams.get('rolloutState');
    const searchQueryParam = queryParams.get('query');
    const filter = filterData;
    const setFilterFromUrl = (rolloutStateFilter) => {
        switch (rolloutStateFilter) {
            case campaignKpiStatus.STARTED:
                filter[0].result = [
                    rolloutStatusMap.MapUpdateInitiatedState,
                    rolloutStatusMap.MapUpdatesOfferedState,
                    rolloutStatusMap.DownloadInitiatedState,
                    rolloutStatusMap.DownloadInProgressState,
                    rolloutStatusMap.VcmDownloadCompletedState,
                    rolloutStatusMap.DownloadsCompleteState,
                    rolloutStatusMap.MapUpdatesPatchingState,
                    rolloutStatusMap.MapUpdatesPatchedState,
                    rolloutStatusMap.AppUpdateInitiatedState,
                ];
                break;
            case campaignKpiStatus.QUEUED:
                filter[0].result = [rolloutStatusMap.InitialRolloutState];
                break;
            case campaignKpiStatus.SUCCEEDED:
                filter[0].result = [rolloutStatusMap.RolloutDoneState];
                break;
            case campaignKpiStatus.FAILED:
                filter[0].result = [rolloutStatusMap.RolloutFailedRepeatedlyState];
                break;
            case campaignKpiStatus.WARNING:
                filter[0].result = [
                    rolloutStatusMap.MapUpdatesRejectedState,
                    rolloutStatusMap.SuspendedRolloutState,
                    rolloutStatusMap.RolloutFailedState,
                ];
                break;
            case campaignKpiStatus.CANCELLED:
                filter[0].result = [rolloutStatusMap.RolloutCanceledState];
                break;
        }
        filterStore.setFilters(filter);
    };
    if (rolloutStateFilter) {
        setFilterFromUrl(rolloutStateFilter);
    }
    if (searchQueryParam) {
        filterStore.setQuery(searchQueryParam);
    }
    useEffect(() => {
        // When unmounting the page:
        return () => {
            filterStore.resetToDefaults();
            paginationStore.resetToDefaults();
        };
    }, [serviceStore]);
    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Header>
                <FotaHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className='bg-muted'>
                <FotaSidebar />
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Sidebar className='right'>
                {rolloutId && serviceStore.currentService === Services.MapUpdate && (
                    <RolloutDetails
                        rolloutId={rolloutId}
                        store={mapRolloutStore}
                        paginationStore={new PaginationStore()}
                    />
                )}
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer />
                {serviceStore.serviceMenuDialogOpen &&
                    <ServiceMenuDialog/>
                }
                {serviceStore.currentService === Services.MapUpdate && (
                    <RolloutTable
                        store={mapRolloutStore}
                        columnsData={mapRolloutTableData}
                        selectedRow={rolloutId}
                        paginationStore={paginationStore}
                        filterStore={filterStore}
                    />
                )}
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
});
