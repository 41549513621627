import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import React, { useEffect } from 'react';
import { Services } from '@stores/service.js';
import { Navigate, Route, Routes } from 'react-router';
import VehiclesMessagePage from '@pages/RemoteMessage/VehiclesMessagePage.jsx';
import NoMatch from '@pages/NoMatch.jsx';
import MessagesPage from '@pages/RemoteMessage/MessagesPage.jsx';
import MessageTemplatePage from '@pages/RemoteMessage/MessageTemplatePage.jsx';
import MyMessagesPage from '@pages/RemoteMessage/MyMessagesPage.jsx';
import ToApprovePage from '@pages/RemoteMessage/ToApprovePage.jsx';

export default observer(() => {
  const { serviceStore } = useStores();

  useEffect(() => {
    serviceStore.setCurrentService(Services.RemoteMessage);
  });

  return (
    <Routes>
      <Route path='/' element={<Navigate to={`${Services.RemoteMessage}/vehicles`} />} />
      <Route path='vehicles' element={<VehiclesMessagePage/>} >
        <Route path=':id' element={<VehiclesMessagePage/>}/>
      </Route>
      <Route path='messages' element={<MessagesPage />}>
        <Route path=':id' element={<MessagesPage />} />
      </Route>
      <Route path='templates' element={<MessageTemplatePage />} />
      <Route path='myMessages' element={<MyMessagesPage />}>
        <Route path=':id' element={<MyMessagesPage />} />
      </Route>
      <Route path='toApprove' element={<ToApprovePage />}>
        <Route path=':id' element={<ToApprovePage />} />
      </Route>
      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
});