import { useStores } from '@stores/context.jsx';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

const VehicleDetailsGeneral = observer(() => {
    const { remoteMessageStore } = useStores();
    const { t } = useTranslation();
    return (
        remoteMessageStore.vehicleDetails && (
            <>
                <h5>{`${t("fotaone.general.general")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.shortname")}`}</label>
                            <span className='text-primary'>
                                {get(remoteMessageStore.vehicleDetails, ['assets','0','name'])}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.vinShort")}`}</label>
                            <span className='text-primary'>
                                {get(remoteMessageStore.vehicleDetails, ['vin'])}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.vehicleData.vehicleId")}`}</label>
                            <span className='text-primary'>
                                {get(remoteMessageStore.vehicleDetails, ['assets','0','id'])}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    );
});

export default VehicleDetailsGeneral;
