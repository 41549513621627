import { filterOptionsTypes } from '@common/Filter/Filter.constants.js';
import { rolloutStatusMap } from '../../mapupdateHelper/constants.jsx';

const getStatusOptions = (statusMap) => {
    return Object.entries(statusMap).map(([statusKey, statusValue]) => {
        return {
            key: statusValue,
            label: statusValue,
            value: statusKey,
        };
    });
};

export const filterDataKeys = {
    Status: 'status',
    HasNameAndVin: 'noNameAndVin',
};
/**
 *
 */
export const filterData = [
    {
        key: 'status',
        label: 'fotaone.mu.rolloutData.rolloutStatus',
        databaseKey: 'rollout_state_type',
        type: filterOptionsTypes.CHECKBOX,
        options: getStatusOptions(rolloutStatusMap),
        result: [
            rolloutStatusMap.InitialRolloutState,
            rolloutStatusMap.ActivationPendingState,
            rolloutStatusMap.MapUpdateInitiatedState,
            rolloutStatusMap.MapUpdatesOfferedState,
            rolloutStatusMap.DownloadInitiatedState,
            rolloutStatusMap.DownloadInProgressState,
            rolloutStatusMap.VcmDownloadCompletedState,
            rolloutStatusMap.DownloadsCompleteState,
            rolloutStatusMap.MapUpdatesPatchingState,
            rolloutStatusMap.MapUpdatesPatchedState,
            rolloutStatusMap.MapUpdatesRejectedState,
            rolloutStatusMap.RolloutDoneState,
            rolloutStatusMap.RolloutFailedRepeatedlyState,
            rolloutStatusMap.SuspendedRolloutState,
            rolloutStatusMap.AppUpdateInitiatedState,
        ],
    },
    {
        key: 'noNameAndVin',
        label: 'fotaone.mu.rolloutData.nameAndVin',
        databaseKey: null,
        options: [{ key: 'noNameAndVin', label: 'fotaone.mu.rolloutData.noNameAndVin', value: 'noNameAndVin' }],
        type: filterOptionsTypes.CHECKBOX,
        result: [],
    },
];
