import React, { useCallback, useEffect, useState } from 'react';
import { Observer, observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import MultipleRollout from '../dialog/MultipleRollout.jsx';
import Table from '@common/Table/Table.jsx';
import Pagination from '@common/Pagination/Pagination.jsx';

import { useStores } from '@stores/context.jsx';
import { debounce } from 'lodash';
import Filter from '@common/Filter/Filter.jsx';
import FilterButton from '@common/Buttons/FilterButton.jsx';
import { cloneObject } from '@helpers/object.js';
import { setQueryParamToUrl } from '../../../mapupdateHelper/utils.jsx';
import { useTranslation } from 'react-i18next';

export default observer((props) => {
    const { serviceStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showFilter, setShowFilter] = useState(false);
    const { paginationStore, filterStore, store, columnsData, selectedRow } = props;
    const limit = paginationStore?.limit;
    const activePage = paginationStore?.activePage;
    const offset = paginationStore?.offset;
    const filters = filterStore?.filters;
    const query = filterStore?.query;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;

    const hasCampaignFilter = new URLSearchParams(location.search).get('hasCampaign'); // True || False
    const campaignId = new URLSearchParams(location.search).get('campaignId');
    const searchRollouts = useCallback(
        debounce(async (params) => {
            const rollouts = await store.getRolloutsGraphQL(params);
            paginationStore.setItemsLength(store.rolloutsTotal);
            return rollouts;
        }, 200),
        [],
    );

    useEffect(() => {
        searchRollouts({
            query,
            sortBy,
            sortDir,
            limit,
            filters,
            offset,
            hasCampaignFilter,
            campaignId,
        });
    }, [
        query,
        sortBy,
        sortDir,
        limit,
        activePage,
        filters,
        store,
        searchRollouts,
        offset,
        store.shouldReload,
        location.search,
        selectedRow
    ]);

    const handleSortChange = (sortBy, sortDir) => {
        filterStore.setSortBy(sortBy);
        filterStore.setSortDir(sortDir);
    };

    const handleRowClick = (row) => {
        navigate(`${serviceStore.currentService}/rollouts/${row.id}`);
    };

    const handleSearchValueChange = (search) => {
        setQueryParamToUrl('query', search);
        filterStore.setQuery(search);
        paginationStore.setActivePage(1);
    };

    const handleFilterState = (state) => {
        setShowFilter(state);
    };
    const handleFilterConfirmation = (newData) => {
        filterStore.setFilters(cloneObject(newData));
    };

    const render = () => {
        return (
            <div>
                <Observer>
                    {() => (
                        <Filter
                            filters={filters}
                            title={`${t("fotaone.mu.rolloutData.filterRollouts")}`}
                            showFilter={showFilter}
                            onHide={() => handleFilterState(false)}
                            onConfirm={(data) => handleFilterConfirmation(data)}
                            id={'rollout-filter-modal'}
                        />
                    )}
                </Observer>
                <Table
                    toolbarElementLeft={
                        <FilterButton id={'filter-map-update'} onShowFilter={() => handleFilterState(true)} />
                    }
                    toolbarElement={<MultipleRollout />}
                    showSearch={true}
                    handleSearchValueChange={handleSearchValueChange}
                    handleSortChange={handleSortChange}
                    onRowClick={handleRowClick}
                    searchValue={query}
                    data={store.rolloutsList || []}
                    columnDetails={columnsData.columnDetails}
                    defaultColumnOrder={columnsData.defaultColumnOrder}
                    columns={columnsData.columns}
                    columnOrder={columnsData.columnsOrder}
                    hiddenColumns={columnsData.hiddenColumns}
                    columnLabels={columnsData.columnLabels}
                    disabledColumns={columnsData.disabledColumns}
                    loading={store.rolloutsLoading}
                    rowKey='id'
                    selectedRow={selectedRow}
                />
                <Pagination paginationStore={paginationStore} />
            </div>
        );
    };

    return render();
});
