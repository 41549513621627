import React from 'react';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import RadioButton from '@rio-cloud/rio-uikit/lib/es/RadioButton';
import { filterOptionsTypes } from './Filter.constants';
import { useTranslation } from 'react-i18next';

function FilterBody(props) {
    const { t } = useTranslation();
    const {
        defaultFilters = [],
        onChange = () => {
            /* */
        },
    } = props;

    const handleRadioSelect = (filterKey, selectedOptionKey) => {
        const currentFilterIndex = defaultFilters.findIndex((filter) => {
            return filter.key === filterKey;
        });

        if (currentFilterIndex !== -1) {
            defaultFilters[currentFilterIndex].result = [selectedOptionKey];
        }
        onChange?.([...defaultFilters]);
    };

    const handleCheckboxSelect = (filterKey, selectedOptionKey) => {
        const currentFilterIndex = defaultFilters.findIndex((filter) => {
            return filter.key === filterKey;
        });
        if (currentFilterIndex !== -1) {
            defaultFilters[currentFilterIndex].result = defaultFilters[currentFilterIndex].result.includes(
                selectedOptionKey,
            )
                ? defaultFilters[currentFilterIndex].result.filter((item) => item !== selectedOptionKey)
                : [...defaultFilters[currentFilterIndex].result, selectedOptionKey];
        }
        onChange?.([...defaultFilters]);
    };

    const isItemChecked = (filterKey, key) => {
        let isChecked = false;
        if (filterKey) {
            const localFilter = defaultFilters.find((item) => item.key === filterKey);
            if (localFilter) {
                isChecked = localFilter.result.some((item) => item === key);
            }
        }
        return isChecked;
    };

    const selectAllCheckboxes = (filterKey, selectAll) => {
        const currentFilterIndex = defaultFilters.findIndex((filter) => filter.key === filterKey);
        if (currentFilterIndex !== -1) {
            const updatedFilter = { ...defaultFilters[currentFilterIndex] };
            updatedFilter.result = selectAll ? updatedFilter.options.map(option => option.key) : [];
            defaultFilters[currentFilterIndex] = updatedFilter;
            onChange?.([...defaultFilters]);
        }
    };

    const handleSelectAllClick = (filter) => {
        const allChecked = filter.options.every(option => isItemChecked(filter.key, option.key));
        selectAllCheckboxes(filter.key, !allChecked);
    };

    return (
        <>
            {defaultFilters.map((filter, index) => (
                <div key={`${filter.key}_${index}`}>
                    <h5> {`${t(filter.label)}`}</h5>
                    <Checkbox
                        className='margin-bottom-5'
                        onClick={() => handleSelectAllClick(filter)} 
                        indeterminate={!filter.options.every(option => isItemChecked(filter.key, option.key))}
                        checked={filter.options.every(option => isItemChecked(filter.key, option.key))}
                    >
                        <span id={`${filter.key}_selectAll`}>
                            {`${t("fotaone.general.table.selectAll")}`}
                        </span>
                    </Checkbox>
                    <ul>
                        {filter.options.map((option) => (
                            <React.Fragment key={`${option.key}_${index}`}>
                                {filter.type === filterOptionsTypes.RADIO && (
                                    <RadioButton
                                        id={`${filter.key}_${option.key}`}
                                        value={`${t(option.key)}`}
                                        onChange={() => handleRadioSelect(filter.key, option.key)}
                                        checked={isItemChecked(filter.key, option.key)}
                                    >
                                        {`${t(option.label)}`}
                                    </RadioButton>
                                )}
                                {filter.type === filterOptionsTypes.CHECKBOX && (
                                    <Checkbox
                                        id={`${filter.key}_${option.key}`}
                                        key={`${option.key}_${index}`}
                                        value={option.key}
                                        onChange={() => handleCheckboxSelect(filter.key, option.key)}
                                        checked={isItemChecked(filter.key, option.key)}
                                        className={'region-select-data'}
                                        label={`${t(option.label)}`}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
            ))}
        </>
    );
}

export default FilterBody;
