import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';
import NoData from '@rio-cloud/rio-uikit/NoData';

const VehicleDetailsMessagesStats = observer(() => {
    const { remoteMessageStore } = useStores();
    const [sentData, setSentData] = useState(0);
    const [failedData, setFailedData] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        getStatistics();
      }, [remoteMessageStore.vehicleDetails]);

    const getStatistics = () => {
        if (remoteMessageStore.vehicleDetails?.device_messages) {
            let sentCount = 0;
            let failedCount = 0;
            let totalCount = 0;
            if (remoteMessageStore.vehicleDetails?.device_messages) {
                remoteMessageStore.vehicleDetails?.device_messages?.forEach(message => {
                    totalCount++;
                    if (message.status === "SENT") {
                        sentCount++;
                    } else if (message.status === "SENDING_FAILED") {
                        failedCount++;
                    }
                });
            }
            setSentData(sentCount);
            setFailedData(failedCount);
            setTotalData(totalCount);
        }
    }

    return (
        remoteMessageStore.vehicleDetails && (
            <>
                <h5>{`${t("fotaone.rm.messageData.messageStats.messageStatistics")}`}</h5>
                <div className="breakline margin-bottom-20" />
                <div className="row">
                    <div className="col-md-4">
                        <div className="margin-bottom-10">
                            <label className="display-block">{`${t("fotaone.rm.messageData.messageStats.sentMessages")}`}</label>
                            {sentData ? 
                                <>
                                    <span class="rioglyph rioglyph-send text-color-success text-size-h4"/>
                                    <span className='margin-left-5 text-primary text-size-18'>
                                        {sentData}
                                    </span>
                                </>
                            :
                                <NoData text={`${t("fotaone.general.table.noData")}`} className='text-size-16' />
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="margin-bottom-10">
                            <label className="display-block">{`${t("fotaone.rm.messageData.messageStats.failedMessages")}`}</label>
                            {failedData ? 
                                <>
                                    <span class="rioglyph rioglyph-warning-sign text-color-warning text-size-h4"/>
                                    <span className='margin-left-5 text-primary text-size-18'>
                                        {failedData}
                                    </span>
                                </>
                            :
                                <NoData text={`${t("fotaone.general.table.noData")}`} className='text-size-16' />
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="margin-bottom-10">
                            <label className="display-block">{`${t("fotaone.rm.messageData.messageStats.totalMessages")}`}</label>
                            <span class="rioglyph rioglyph rioglyph-envelope text-color-default text-size-h4"/>
                            <span className='margin-left-5 text-primary text-size-18'>
                                {totalData}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        )
    );
});

export default VehicleDetailsMessagesStats;
