import React from 'react';
import { useStores } from '@stores/context.jsx';
import Logo from '@assets/img/man-logo.png';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import { useTranslation } from 'react-i18next';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import './Auth.css';

const Auth = () => {
    const { authStore } = useStores();
    const { t, i18n } = useTranslation();
    const year = new Date().getFullYear();
    const language = i18n.resolvedLanguage ? i18n.resolvedLanguage : "gb";

    return ( 
        <div className='display-flex height-100pct justify-content-center align-items-center'>
            <div className='login'>
                <div className='logo'>
                    <img alt='MAN Logo' src={Logo} />
                </div>
                <div className="panel panel-default panel-body login-box shadow-smooth padding-25">
                    <div className="text-center text-color-darker margin-top-15">
                        <div className="text-size-12">
                            <div className="text-size-200pct padding-bottom-10 text-capitalize text-medium">MAN FOTA Admin</div>
                        </div>
                        <br/>
                        <div>
                            <div className='row justify-content-end'>
                                <div className='col-md-2'/>
                                <div className='col-md-8'>
                                    <button
                                        type='button'
                                        className="btn btn-primary btn-lg btn-block text-size-18" 
                                        onClick={authStore.login}
                                    >
                                        {t('fotaone.auth.login')}
                                    </button>
                                </div>
                                <div className='col-md-2 text-right'>
                                    <ButtonDropdown
                                        id='language-selection'
                                        bsSize='lg'
                                        title={<span className='rioglyph rioglyph-language' aria-hidden='true' />}
                                        items={[
                                            {
                                                value: 
                                                    <div className='row'>
                                                        <div className='text-left col-md-8'>
                                                            {t("fotaone.language.current")}:
                                                        </div>
                                                        <div className='text-right col-md-4'>
                                                            {i18n?.resolvedLanguage?.toUpperCase()}
                                                            {i18n.resolvedLanguage === "en" ? `${getUnicodeFlagIcon("gb")}`:`${getUnicodeFlagIcon(language)}` }
                                                        </div>
                                                    </div>,
                                                header: true,
                                            },
                                            {
                                                divider: true,
                                            },
                                            {
                                                value: 
                                                    <button
                                                        type='button'
                                                        className={i18n.resolvedLanguage === 'en'?'btn btn-primary btn-action text-color-black text-medium pointer-events-none':'btn btn-primary btn-action text-color-dark hover-text-color-primary'}
                                                    >
                                                        <span>{`${getUnicodeFlagIcon("gb")} ${t("fotaone.language.locales.en-GB")}`}</span>
                                                    </button>,
                                                onSelect: language => i18n.changeLanguage('en')
                                            },
                                            {
                                                value: 
                                                    <button
                                                        type='button'
                                                        className={i18n.resolvedLanguage === 'de'?'btn btn-primary btn-action text-color-black text-medium pointer-events-none':'btn btn-primary btn-action text-color-dark hover-text-color-primary'}
                                                    >
                                                        <span>{`${getUnicodeFlagIcon("de")} ${t("fotaone.language.locales.de-DE")}`}</span>
                                                    </button>,
                                                onSelect: language => i18n.changeLanguage('de')
                                            },
                                            {
                                                value: 
                                                    <button
                                                        type='button'
                                                        className={i18n.resolvedLanguage === 'pt'?'btn btn-primary btn-action text-color-black text-medium pointer-events-none':'btn btn-primary btn-action text-color-dark hover-text-color-primary'}
                                                    >
                                                        <span>{`${getUnicodeFlagIcon("pt")} ${t("fotaone.language.locales.pt-PT")}`}</span>
                                                    </button>,
                                                onSelect: language => i18n.changeLanguage('pt')
                                            },
                                        ]}
                                        iconOnly
                                        dropup
                                        dropdownClassName='overflow-auto padding-0'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='text-center text-color-gray'>
                    &copy; {year} <a href='https://man.eu'>man.eu</a> – {t('fotaone.auth.loginLegal')}
                </p>
            </div>
        </div>
    );
};

export default Auth;