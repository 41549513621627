import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { getTestFleetStatusFromAssets } from '@helpers/constants.jsx';
import { getVehicleStatus, getManNowStatus } from '../Vehicle.utils';
import Badge from '@common/Badge/Badge';
import DeviceOverrideActions from './OverrideActions';
import { useTranslation } from 'react-i18next';

const VehicleDetailsHeader = (props) => {
    const { vin, row } = props;
    const { t } = useTranslation();
    const vehicleState = getVehicleStatus(row);
    const { color, state, title } = getManNowStatus(vehicleState);
    const isMultipleAssets = useMemo(() => row?.assets?.length > 1, [row]);
    const { isTestFleet } = getTestFleetStatusFromAssets(row?.assets || []);
    const  deploymentEnvironment  = row.environment ? row.environment : null;
    return (
        <>
            <div className='display-flex align-items-center'>
                <DeviceOverrideActions vin={vin} row={row} style={{ marginRight: 'auto' }} />

                <div className='display-flex justify-content-around align-items-center'>
                    <Badge state={state} title={title} color={color} style={{ marginRight: '5px' }} />
                    {isMultipleAssets && (
                        <Badge title={`${t("fotaone.general.tagData.multipleAssets")}`} state={`${t("fotaone.general.tagData.multipleAssets")}`} color={'badge-primary'} style={{ marginRight: '5px' }} />
                    )}
                    {isTestFleet && <Badge title={`${t("fotaone.general.tagData.testfleet")}`} state={`${t("fotaone.general.tagData.testfleet")}`} color={'badge-info'} style={{ marginRight: '5px' }}/>}
                    {deploymentEnvironment && <Badge title={`${t("fotaone.general.tagData.environment")}`} state={deploymentEnvironment[0].toUpperCase() + deploymentEnvironment.slice(1)} color={'badge-info'} />}
                </div>
            </div>
        </>
    );
};

export default observer(VehicleDetailsHeader);
