import { observer } from 'mobx-react-lite';
import StatsWidget from '@rio-cloud/rio-uikit/lib/es/StatsWidget';
import StatsWidgetNumber from '@rio-cloud/rio-uikit/lib/es/StatsWidgetNumber';
import StatsWidgets from '@rio-cloud/rio-uikit/lib/es/StatsWidgets';
import StatsWidgetSpacer from '@rio-cloud/rio-uikit/lib/es/StatsWidgetSpacer';
import { useStores } from '@stores/context.jsx';
import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

export default observer(() => {
  const { featureDashboardStore } = useStores();
  const { t } = useTranslation();
  const getDigitalServicesKpi = useCallback(
    debounce(async() => {
      await featureDashboardStore.getFeatureMetrics();
    }, 200),[],
  );

  useEffect(() => {
    getDigitalServicesKpi();
  }, []);
  return (
    <div className='display-grid gap-20'>
      <div className='display-grid gap-20 grid-cols-2 margin-bottom-20'>
        <StatsWidgets>
          <StatsWidget>
            <StatsWidget.Header>
              <div className={'text-bold text-size-h4'}>{`${t("fotaone.fd.serviceData.category")}`}</div>
            </StatsWidget.Header>
            <StatsWidget.Body>
              <StatsWidgetNumber
                  data-testid='featuresComfort'
                  className='text-color-secondary'
                  value={featureDashboardStore.featureComfortTotal}
                  label={`${t("fotaone.fd.featureData.category.comfort")}`}
              />
              <StatsWidgetSpacer />
              <StatsWidgetNumber
                  data-testid='featuresEfficiency'
                  className='text-color-primary'
                  value={featureDashboardStore.featureEfficiencyTotal}
                  label={`${t("fotaone.fd.featureData.category.efficiency")}`}
              />
              <StatsWidgetSpacer />
              <StatsWidgetNumber
                  data-testid='featuresSafety'
                  className='text-color-success'
                  value={featureDashboardStore.featureSafetyTotal}
                  label={`${t("fotaone.fd.featureData.category.safety")}`}
              />
            </StatsWidget.Body>
          </StatsWidget>
        </StatsWidgets>
        <StatsWidgets className={'margin-top-0'}>
          <StatsWidget>
            <StatsWidget.Header>
              <div className={'text-bold text-size-h4'}>{`${t("fotaone.fd.serviceData.service.many")}`}</div>
            </StatsWidget.Header>
            <StatsWidget.Body>
              <StatsWidgetNumber
                  data-testid='featuresCounter'
                  className='text-color-primary'
                  value={featureDashboardStore.featureListTotal}
                  label={`${t("fotaone.fd.featureData.feature.many")}`}
              />
              <StatsWidgetSpacer />
              <StatsWidgetNumber
                  data-testid='featuresLanguage'
                  className='text-color-rating-5'
                  value={featureDashboardStore.featureLanguageTotal}
                  label={`${t("fotaone.language.languages")}`}
              />
              <StatsWidgetSpacer />
              <StatsWidgetNumber
                  data-testid='featuresItemsTotal'
                  className='text-color-secondary'
                  value={featureDashboardStore.featureItemsTotal}
                  label={`${t("fotaone.mu.rolloutData.state.total")}`}
              />
            </StatsWidget.Body>
          </StatsWidget>
        </StatsWidgets>
      </div>
    </div>
  );
});