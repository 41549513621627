import {
    activationsFotaStatus,
    activationsManNowStatus,
    environments,
    assetParser,
    multipleAssetsParser,
    tagsRenderer,
    testFleetParser,
    deploymentEnvironmentParser,
} from '@helpers/constants.jsx';
import { filterOptionsTypes } from '@common/Filter/Filter.constants.js';
import {
    activationFotaStatusRenderer,
    activationManNowStatusRenderer,
    formatActivationDateRenderer,
    deactivationReasonRenderer,
    serialNumberRenderer,
} from './MainTable/MainTable.renderers';
import {
    activationFotaStatusParser,
    activationManNowStatusParser,
    formatActivationDateParser,
    deactivationReasonParser,
} from './MainTable/MainTable.parsers';

export const filterDataKeys = {
    ManNow: 'man-now',
    FotaStatus: 'fota-status',
    TestFleet: 'test-fleet',
    Environment: 'environment',
};

export const overrideStatus = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
};
export const devicesType = {
    Vcm: 'VCM',
};

export const filterData = [
    {
        key: filterDataKeys.ManNow,
        label: 'fotaone.ot.activationData.manNowStatus',
        databaseKey: 'is_active',
        type: filterOptionsTypes.CHECKBOX,
        options: [
            {
                key: activationsManNowStatus.Active,
                label: 'fotaone.general.vehicleData.state.active',
                value: true,
            },
            {
                key: activationsManNowStatus.Inactive,
                label: 'fotaone.general.vehicleData.state.inactive',
                value: false,
            },
            {
                key: activationsManNowStatus.Never,
                label: 'fotaone.general.vehicleData.state.neverActivated',
                value: null,
            },
        ],
        result: [],
    },
    {
        key: filterDataKeys.FotaStatus,
        label: 'fotaone.ot.activationData.fotaStatus',
        databaseKey: 'fota_admin_override',
        type: filterOptionsTypes.CHECKBOX,
        options: [
            {
                key: activationsFotaStatus.Active,
                label: 'fotaone.general.vehicleData.state.active',
                value: true,
            },
            {
                key: activationsFotaStatus.Inactive,
                label: 'fotaone.general.vehicleData.state.inactive',
                value: false,
            },
            {
                key: activationsFotaStatus.Never,
                label: 'fotaone.general.vehicleData.state.neverActivated',
                value: null,
            },
        ],
        result: [],
    },
    {
        key: filterDataKeys.TestFleet,
        label: 'fotaone.general.tagData.tags',
        databaseKey: 'test_Fleet_Selection',
        type: filterOptionsTypes.CHECKBOX,
        options: [
            {
                key: 'testfleet',
                label: 'fotaone.general.tagData.testfleet',
                value: true,
            },
        ],
        result: [],
    },
    {
        key: filterDataKeys.Environment,
        label: 'fotaone.general.environmentData.deployEnvironment',
        type: filterOptionsTypes.CHECKBOX,
        options: [
            {
                key: environments.Dev,
                label: 'fotaone.general.environmentData.dev',
            },
            {
                key: environments.Canary,
                label: 'fotaone.general.environmentData.canary',
            },
            {
                key: environments.Prod,
                label: 'fotaone.general.environmentData.prod',
            },
        ],
        result: [],
    },
];

export const vehicleMainTable = {
    defaultColumnOrder: [
        'name',
        'vin',
        'devices.0.formatted_serial_no',
        'man_now_status',
        'deactivation_reason',
        'man_now_change_date',
        'fota_override_status',
        'fota_override_change_date',
        'first_coverage_request_at',
        'taglist',
    ],
    columnOrder: [
        'name',
        'vin',
        'devices.0.formatted_serial_no',
        'man_now_status',
        'deactivation_reason',
        'man_now_change_date',
        'fota_override_status',
        'fota_override_change_date',
        'first_coverage_request_at',
        'taglist',
    ],
    columnDetails: {
        name: {},
        vin: {},
        'devices.0.formatted_serial_no': {},
        man_now_status: {},
        deactivation_reason: {},
        man_now_change_date: {},
        fota_override_status: {},
        fota_override_change_date: {},
        first_coverage_request_at: {},
        taglist: {},
    },
    columns: [
        {
            title: 'fotaone.general.vehicleData.shortname',
            key: 'name',
            sortable: true,
        },
        {
            title: 'fotaone.general.vehicleData.vinShort',
            key: 'vin',
            sortable: true,
        },
        {
            title: 'fotaone.general.vehicleData.serialnumber',
            key: 'devices.0.formatted_serial_no',
            renderer: serialNumberRenderer,
        },
        {
            title: 'fotaone.ot.activationData.manNowStatus',
            key: 'man_now_status',
            parser: activationManNowStatusParser,
            renderer: activationManNowStatusRenderer,
        },
        {
            title: 'fotaone.ot.activationData.deactivationReason',
            key: 'deactivation_reason',
            parser: deactivationReasonParser,
            renderer: deactivationReasonRenderer,
        },
        {
            title: 'fotaone.ot.activationData.manNowChanged',
            key: 'man_now_change_date',
            parser: formatActivationDateParser,
            renderer: formatActivationDateRenderer,
        },
        {
            title: 'fotaone.ot.activationData.fotaStatus',
            key: 'fota_override_status',
            parser: activationFotaStatusParser,
            renderer: activationFotaStatusRenderer,
        },
        {
            title: 'fotaone.ot.activationData.fotaChanged',
            key: 'fota_override_change_date',
            parser: formatActivationDateParser,
            renderer: formatActivationDateRenderer,
        },
        {
            title: 'fotaone.ot.activationData.firstCoverageRequestAt',
            key: 'first_coverage_request_at',
            parser: formatActivationDateParser,
            renderer: formatActivationDateRenderer,
        },
        {
            title: 'fotaone.general.tagData.tags',
            key: 'tags',
            exportable: false,
            renderer: tagsRenderer,
        },
        {
            title: 'fotaone.general.vehicleData.asset.many',
            key: 'assets',
            parser: assetParser,
        },
        {
            title: 'fotaone.general.vehicleData.isMultipleAssets',
            key: 'multiple-assets',
            parser: multipleAssetsParser,
        },
        {
            title: 'fotaone.general.vehicleData.isTestfleet',
            key: 'test-fleet',
            parser: testFleetParser,
        },
        {
            title: 'fotaone.general.environmentData.deployEnvironment',
            key: 'environment',
            parser: deploymentEnvironmentParser,
        },
    ],
    disabledColumns: [],
    hiddenColumns: ['assets', 'multiple-assets', 'test-fleet', 'environment'],
};

export const deactivationReasonEnum = {
    EXPIRED_SUBSCRIPTION_MANNOW: 'fotaone.ot.activationData.expiredSubscriptionManNow',
    EXPIRED_FREE_TRIAL: 'fotaone.ot.activationData.expiredSubscriptionFreeTrial',
};
