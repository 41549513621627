import React from 'react';
import Loader from './Loader';

const FullPageLoader = () => (
    <div className='display-flex justify-content-center align-items-center height-100vh'>
        <Loader />
    </div>
);

export default FullPageLoader;
