import Table from '@common/Table/Table.jsx';
import { get } from 'lodash';
import React from 'react';
import { useStores } from '@stores/context.jsx';
import { vehicleTableSidebar } from '../../../../Onlinetraffic/Vehicle/Sidebar/Sidebar.constants.js';
import { useTranslation } from 'react-i18next';

const VehicleDetailsAssets = () => {
    const { assetStore } = useStores();
    const { t } = useTranslation();
    return (
        <>
            <h5>{`${t("fotaone.general.vehicleData.asset.many")}`}</h5>
            <div className='breakline margin-bottom-20' />
            <Table
                data={get(assetStore.assetDetails, ['vehicles', '0', 'assets'])}
                columnDetails={vehicleTableSidebar.columnDetails}
                columns={vehicleTableSidebar.columns}
                columnOrder={vehicleTableSidebar.columnsOrder}
                defaultColumnOrder={vehicleTableSidebar.defaultColumnOrder}
            />
        </>
    );
};

export default VehicleDetailsAssets;
