import { client as onlineTrafficProxyAxiosClient } from '@api/onlinetraffic-proxy-client.js';
import { client as mapUpdateProxyAxiosClient } from '@api/mapupdate-proxy-client.js';
import { environments } from '@helpers/constants.jsx';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { messageProxyConnectionErrorMessage } from './constants.jsx';

export async function getOTDevicesDeploymentEnvironment(shouldAlert) {

  if (window.location.href.indexOf('services-dev') !== -1 || window.location.href.indexOf('localhost') !== -1) {
    return { devDevices: [''], canaryDevices: [''] };
  }

  return await onlineTrafficProxyAxiosClient.get('/devices')
    .then(function(res) {
      if (res.data) {
        return res.data;
      }
      return { devDevices: [''], canaryDevices: [''] };
    })
    .catch(function(err) {
      if (shouldAlert) {
        Notification.warning(messageProxyConnectionErrorMessage('Online-Traffic-Proxy'));
      }
      return { devDevices: [''], canaryDevices: [''] };
    });
}

export async function getMUDevicesDeploymentEnvironment(shouldAlert) {

  if (window.location.href.indexOf('services-dev') !== -1 || window.location.href.indexOf('localhost') !== -1) {
    return { devDevices: [''], canaryDevices: [''] };
  }

  return await mapUpdateProxyAxiosClient.get('/devices')
    .then(function(res) {
      if (res.data) {
        return res.data;
      }
      return { devDevices: [''], canaryDevices: [''] };
    })
    .catch(function(err) {
      if (shouldAlert) {
        Notification.warning(messageProxyConnectionErrorMessage('Map-Update-Proxy'));
      }

      return { devDevices: [''], canaryDevices: [''] };
    });
}

export const generateDeviceEnvironmentMap = (vehicleDeviceEnvironmentList) => {
  const vehicleEnvironmentMap = new Map();
  if (vehicleDeviceEnvironmentList.devDevices[0] === '') {
    vehicleEnvironmentMap.set('no_data_dev', environments.Dev);
  } else {
    vehicleDeviceEnvironmentList.devDevices.forEach((deviceId) => {
      vehicleEnvironmentMap.set(deviceId, environments.Dev);
    });
  }

  if (vehicleDeviceEnvironmentList.canaryDevices[0] === '') {
    vehicleEnvironmentMap.set('no_data_canary', environments.Canary);
  } else {
    vehicleDeviceEnvironmentList.canaryDevices.forEach((deviceId) => {
      vehicleEnvironmentMap.set(deviceId, environments.Canary);
    });
  }

  return vehicleEnvironmentMap;
};

export const enrichVehicleDataWithDeploymentEnvironment = (data, vehicleDeviceEnvironmentMap) => {

  data.vehicles.map((vehicle) => {
    const deviceIdEnvironment = vehicleDeviceEnvironmentMap.get(vehicle.devices?.[0]?.device_id);
    if (deviceIdEnvironment !== undefined) {
      vehicle.environment = deviceIdEnvironment;
    }
  });

  return data;
};