import { gql } from '@apollo/client';
import { action, makeObservable, observable } from 'mobx';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { client as featureDashboardGraphqlClient } from '@api/featuredashboard-graphql';
import { client as featureDashboardRestApiClient } from '@api/featuredashboard-client';
import  i18n  from 'i18next';

let instance; // singleton instance

const GET_FEATURES_DESCRIPTIONS = gql`
  query GetFeatureDescriptions {
    fd {
      features(order_by: {category: asc}) {
        category
        code
        name
        image
        feature_descriptions {
          benefits
          description
          feature_code
          id
          locale
          url
          subtitle
          short_description
          note
        }
      }
    }
  }
`;

const GET_FEATURES_KPI = gql`
  query GetFeaturesKpi {
    fd {
      features(distinct_on: category) {
        category
      }
      featuresComfort_aggregate: features_aggregate(where: {category: {_eq: "Comfort"}}) {
        aggregate {
          count
        }
      }
      featuresEfficiency_aggregate: features_aggregate(where: {category: {_eq: "Efficiency"}}) {
        aggregate {
          count
        }
      }
      featuresSafety_aggregate: features_aggregate(where: {category: {_eq: "Safety"}}) {
        aggregate {
          count
        }
      }
      featuresCounter_aggregate: features_aggregate {
        aggregate {
          count
        }
      }
      featuresLanguage_aggregate: feature_descriptions_aggregate {
        aggregate {
          count(columns: locale, distinct: true)
        }
      }
      featuresItemsTotal_aggregate: feature_descriptions_aggregate {
        aggregate {
          count
        }
      }
    }  
  }
`;

const GET_FEATURE_DETAILS =  gql`
  query GetFeatureDetailsQuery($feature_id: String) {
    fd {
      feature_descriptions(where: {id: {_eq: $feature_id}}) {
        benefits
        description
        feature_code
        id
        locale
        note
        short_description
        subtitle
        url
        feature {
          category
          code
          name
          image
        }
    }
  }
}
`;

const GET_FD_VEHICLES =  gql
`query searchVehicles($limit: Int, $offset: Int = 0, $query: String = "%", $orderBy: [fd_devices_order_by!] = {}, $filter: [fd_devices_bool_exp!] = {}) {
  fd {
    devices(limit: $limit, offset: $offset, order_by: $orderBy, where: {_and: [{vin: {_is_null: false}}, {assets: {id: {_is_null: false}}}, {_or: [{vin: {_ilike: $query}}, {assets: {name: {_ilike: $query}}}, {serial_number: {_ilike: $query}}], _and: $filter}], assets: {activations_aggregate: {count: {predicate: {_gt: 0}}}}}) {
      id
      vin
      serial_number
      type
      assets {
        id
        name
        account_id
        activations_aggregate(where: {is_active: {_eq: true}}) {
          aggregate {
            count
          }
        }
        activations(order_by: {feature: {name: asc}}) {
          id
          is_active
          feature_variant
          feature {
            name
            code
          }
        }
      }
    }
    devices_aggregate(where: {_and: [{vin: {_is_null: false}}, {assets: {id: {_is_null: false}}}, {_or: [{vin: {_ilike: $query}}, {assets: {name: {_ilike: $query}}}, {serial_number: {_ilike: $query}}], _and: $filter}], assets: {activations_aggregate: {count: {predicate: {_gt: 0}}}}}) {
      aggregate {
        count
      }
    }
  }
}`;

const GET_FD_VEHICLE_DETAILS =  gql
`query GET_VEHICLE_DETAILS($device_id: uuid!, $vin: String) {
  fd {
    devices(where: {id: {_eq: $device_id}}) {
      id
      vin
      serial_number
      type
      assets {
        id
        name
        account_id
        activations_aggregate(where: {is_active: {_eq: true}}) {
          aggregate {
            count
          }
        }
        activations(order_by: {feature: {name: asc}}) {
          id
          is_active
          type
          feature_variant
          feature {
            name
            category
            code
            feature_descriptions(where: {_or: [{locale: {_eq: "en-GB"}}, {locale: {_eq: "de-DE"}}, {locale: {_eq: "pt-PT"}}]}) {
              description
              locale
              short_description
            }
          }
          subscription_end_date
          subscription_start_date
        }
      }
      feature_flags {
        flag_name
      }
    }
    booking_requests(where: {vin: {_eq: $vin}}) {
      feature_code
      id
    }
  }
}`;

export class FeatureDashboardStore {
  featureLoading= false;
  featureKpiLoading= false;
  featureFlagLoading= false;
  featureDetailsLoading = false;
  featureIconLoading= false;
  vehiclesLoading= false;
  vehicleDetailsLoading= false;
  featureDescriptionsList= [];
  bookingRequestList = [];
  currentFeatureDetails = {};
  deviceDetails = {};
  featureListTotal= 0;
  featureComfortTotal= 0;
  featureEfficiencyTotal= 0;
  featureSafetyTotal= 0;
  featureLanguageTotal= 0;
  featureItemsTotal= 0;
  vehicleTotal = 0;
  vehicleList = null;

  constructor() {
    makeObservable(this, {
      featureLoading: observable,
      featureKpiLoading: observable,
      featureFlagLoading: observable,
      featureDescriptionsList: observable,
      featureListTotal: observable,
      featureComfortTotal: observable,
      featureEfficiencyTotal: observable,
      featureSafetyTotal: observable,
      featureLanguageTotal: observable,
      featureItemsTotal: observable,
      currentFeatureDetails: observable,
      featureDetailsLoading: observable,
      featureIconLoading: observable,
      vehiclesLoading: observable,
      vehicleDetailsLoading: observable,
      vehicleList: observable,
      vehicleTotal: observable,
      deviceDetails: observable,
      bookingRequestList: observable,

      getFeatureDescriptions: action,
      getFeatureMetrics: action,
      getFeatureDetails: action,
      getVehicles: action,
      getDetailsById: action,
      updateFeatureFlag: action,
    });
  }

  async getFeatureDescriptions() {
    try {
      this.setFeatureLoading(true);
      const res = await featureDashboardGraphqlClient.query({
        query: GET_FEATURES_DESCRIPTIONS,
        fetchPolicy: 'no-cache',
      });
      const { features } = res.data.fd;
      this.featureDescriptionsList = features;
      return this.featureDescriptionsList;
    } catch (err) {
      if (err.response) {
        Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
      } else {
        Notification.error(`${i18n.t('fotaone.notification.error.feature.fetchFeatureDesc')}`);
      }
    } finally {
      this.setFeatureLoading(false);
    }
  }

  async getFeatureMetrics() {
    try {
      this.setFeatureKpiLoading(true);
      const res = await featureDashboardGraphqlClient.query({
        query: GET_FEATURES_KPI,
        fetchPolicy: 'no-cache',
      });
      const {
        featuresComfort_aggregate: { aggregate: { count: featuresComfort } },
        featuresEfficiency_aggregate: { aggregate: { count: featuresEfficiency } },
        featuresSafety_aggregate: { aggregate: { count: featuresSafety } },
        featuresCounter_aggregate: { aggregate: { count: featuresCounter } },
        featuresLanguage_aggregate: { aggregate: { count: featuresLanguage } },
        featuresItemsTotal_aggregate: { aggregate: { count: featuresItems } },
      } = res.data.fd;
      this.featureComfortTotal = featuresComfort;
      this.featureEfficiencyTotal = featuresEfficiency;
      this.featureSafetyTotal = featuresSafety;
      this.featureListTotal = featuresCounter;
      this.featureLanguageTotal = featuresLanguage;
      this.featureItemsTotal = featuresItems;
    } catch (err) {
      if (err.response) {
        Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
      } else {
        Notification.error(`${i18n.t('fotaone.notification.error.feature.fetchFeatureMetrics')}`);
      }
    } finally {
      this.setFeatureKpiLoading(false);
    }
  }

  async getFeatureDetails(featureId){
    try {
      this.setFeatureDetailsLoading(true);
      const variables = {
        feature_id: featureId,
      };
      const res = await featureDashboardGraphqlClient.query({
        query: GET_FEATURE_DETAILS,
        variables,
        fetchPolicy: 'no-cache',
      });
      const {
        benefits,
        description,
        id,
        locale,
        note,
        short_description,
        subtitle,
        url,
        feature,
      } = res.data.fd.feature_descriptions[0];

      this.currentFeatureDetails.name = feature.name;
      this.currentFeatureDetails.subtitle = subtitle;
      this.currentFeatureDetails.feature_code = feature.code;
      this.currentFeatureDetails.category = feature.category;
      this.currentFeatureDetails.description_id = id;
      this.currentFeatureDetails.benefits = benefits.split('\n')
      this.currentFeatureDetails.local = locale;
      this.currentFeatureDetails.description = description;
      this.currentFeatureDetails.short_description = short_description;
      this.currentFeatureDetails.url = url;
      this.currentFeatureDetails.note = note;
      this.currentFeatureDetails.icon = feature.image;
    } catch (err) {
      if (err.response) {
        Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
      } else {
        Notification.error(`${i18n.t('fotaone.notification.error.feature.fetchFeatureDetails')}`);
      }
    } finally {
      this.setFeatureDetailsLoading(false);
    }

    return null;
  }

  async uploadIcon(data) {
    const {featureCode, iconData} = data;
    try {
      this.setFeatureIconLoading(true);
      const res = await featureDashboardRestApiClient.updateFeatureIcon(featureCode, iconData);
      Notification.success(`${i18n.t('fotaone.notification.success.feature.uploadFeatureIcon')}`);
      return res;
    } catch (err) {
      if (err.respone) {
        Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
      } else {
        Notification.error(`${i18n.t('fotaone.notification.error.feature.uploadFeatureIcon')}`);
      }
    } finally {
      this.setFeatureIconLoading(false);
    }
  }

  async getVehicles(searchParams = {}) {
    this.setVehiclesLoading(true);
    const { query, sortBy, sortDir, limit, offset, groupNameFilter, filters } = searchParams;
    try {
      const variables = {
          query: `%${query === undefined ? '' : query}%`,
          limit,
          offset,
          filter: {},
      };

      if (sortBy) {
          variables.orderBy = {};
          setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
      }

      const res = await featureDashboardGraphqlClient.query({
          query: GET_FD_VEHICLES,
          variables,
          fetchPolicy: 'no-cache',
      });

      const {
          devices,
          devices_aggregate: { aggregate: { count: total } },
      } = res.data.fd;

      this.vehicleList = devices;
      this.vehicleTotal = total;
      this.setVehiclesLoading(false);
      return this.vehicleList;
    } catch (err) {
      Notification.error(`${i18n.t('fotaone.notification.error.assets.fetchVehicles')}`);
    }
  }

  async getDetailsById(deviceId, vin) {
    try {
      this.setVehicleDetailsLoading(true);
      const variables = { device_id: deviceId, vin: vin };
      const query = GET_FD_VEHICLE_DETAILS;
      const res = await featureDashboardGraphqlClient.query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      });
      this.deviceDetails = res.data.fd.devices[0];
      this.bookingRequestList = res.data.fd.booking_requests;
    } catch (err) {
      if (err.response) {
        Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
      } else {
        Notification.error(`${i18n.t('fotaone.notification.error.assets.fetchVehicleDetails')}`);
      }
    } finally {
      this.setVehicleDetailsLoading(false);
    }
}

async updateFeatureFlag(data) {
  const { deviceId, flagType, value} = data;
  const payloadFeatureFlag = {
    device_id: deviceId,
    flag: {
      name: flagType,
      value: value
    }
  };
  try {
    this.setFeatureFlagLoading(true);
    const res = await featureDashboardRestApiClient.updateFeatureFlag(null, payloadFeatureFlag);
    Notification.success(`${i18n.t('fotaone.notification.success.featureFlag.updateFlag')}`);
    return res;
  } catch (err) {
    Notification.error(`${i18n.t('fotaone.notification.error.featureFlag.updateFlag')}`);
    throw err;
  } finally {
    this.setFeatureFlagLoading(false);
  }
}

  setVehiclesLoading(state) {
    this.vehiclesLoading = Boolean(state);
  }

  setVehicleDetailsLoading(state) {
    this.vehicleDetailsLoading = Boolean(state);
  }

  setFeatureFlagLoading(state) {
    this.featureFlagLoading = Boolean(state);
  }

  setFeatureLoading(state) {
    this.featureLoading = Boolean(state);
  }

  setFeatureKpiLoading(state) {
    this.featureKpiLoading = Boolean(state);
  }

  setFeatureDetailsLoading(state) {
    this.featureDetailsLoading = Boolean(state);
  }

  setFeatureIconLoading(state) {
    this.featureIconLoading = Boolean(state);
  }

  static instance() {
    if (!instance) {
      instance = new FeatureDashboardStore();
    }
    return instance;
  }

}