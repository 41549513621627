import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const MapUpdateMenu = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='text-size-h2 text-thin'>
                Map Update
            </div>
            <Divider />
            <p>
                {t('fotaone.general.serviceMenu.mapUpdateSection.serviceDesc')}
            </p>
            <div className='text-size-h5 margin-bottom-5'>
                <span className='rioglyph rioglyph-truck margin-right-10' />
                <strong>{t('fotaone.general.vehicleData.vehicle.many')}</strong>
            </div>
            <ul>
                <li>
                    <p>
                        <strong>{t('fotaone.general.overview')}</strong>: {' '}{t('fotaone.general.serviceMenu.mapUpdateSection.vehiclesDesc')}
                    </p>

                </li>
                <li>
                    <p>
                        <strong>{t('fotaone.mu.groupData.group.many')}</strong>: {' '}{t('fotaone.general.serviceMenu.mapUpdateSection.groupsDesc')}
                    </p>
                </li>
                <li>
                    <p>
                        <strong>{t('fotaone.mu.installedMapsPage.installedMaps')}</strong>: {' '}{t('fotaone.general.serviceMenu.mapUpdateSection.installedMapsDesc')}
                    </p>
                </li>
            </ul>
            <div className='text-size-h5 margin-bottom-5'>
                <span className='rioglyph rioglyph-files margin-right-10' />
                <strong>{t('fotaone.mu.fileData.map.many')}</strong>
            </div>
            <ul>
                <li>
                    <p>
                        <strong>{t('fotaone.mu.fileData.file.many')}</strong>: {' '}{t('fotaone.general.serviceMenu.mapUpdateSection.fileDesc')}
                    </p>
                </li>
                <li>
                    <p>
                        <strong>{t('fotaone.mu.fileData.archive')}</strong>: {' '}{t('fotaone.general.serviceMenu.mapUpdateSection.archiveDesc')}
                    </p>
                </li>
            </ul>
            <div className='text-size-h5 margin-bottom-5'>
                <span className='rioglyph rioglyph-delivery-on-track margin-right-10' />
                <strong>{t('fotaone.mu.rolloutData.rollout.many')}</strong>
            </div>
            <ul>
                <li>
                    <p>
                        <strong>{t('fotaone.general.overview')}</strong>: {' '}{t('fotaone.general.serviceMenu.mapUpdateSection.rolloutsDesc')}
                    </p>
                </li>
                <li>
                    <p>
                        <strong>{t('fotaone.mu.campaignData.campaign.many')}</strong>: {' '}{t('fotaone.general.serviceMenu.mapUpdateSection.campaignsDesc')}
                    </p>
                </li>
            </ul>
        </>
    );
};

export default observer(MapUpdateMenu);