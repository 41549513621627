import React, { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import MapUploadDialog from '../Dialogs/MapUploadDialog';
import RolloutDialog from '../Dialogs/RolloutDialog';
import Table from '@common/Table/Table';
import Pagination from '@common/Pagination/Pagination';

import { useStores } from '@stores/context.jsx';
import { Services } from '@stores/service.js';

import { useNavigate } from 'react-router-dom';
import { mapFileTableData } from '../mapupdate/mapupdateHelper/constants.jsx';
import { setQueryParamToUrl } from '../mapupdate/mapupdateHelper/utils.jsx';
import { useTranslation } from 'react-i18next';

export default observer((props) => {
    const { filesStore, uiStore, mapRolloutStore, serviceStore } = useStores();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ dialogType, setDialogType ] = useState('');
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const { paginationStore, filterStore, showSearch, actionButtons, className } = props;
    const limit = paginationStore?.limit;
    const activePage = paginationStore?.activePage;
    const offset = paginationStore?.offset;
    const query = filterStore?.query;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;
    const searchQueryParam = queryParams.get('query');

    const searchFiles = useCallback(
        debounce(async (params) => {
            const response = await filesStore.getFilesGraphql(params);
            paginationStore.setItemsLength(filesStore.filesTotal);
            return response;
        }, 300),
        [],
    );

    useEffect(() => {
        if (searchQueryParam) {
            filterStore.setQuery(searchQueryParam);
        }

        searchFiles({
            query,
            sortBy,
            sortDir,
            limit,
            offset,
        });

        return () => {
            uiStore.setShowRolloutDialog(false);
        };
    }, [
        query,
        sortBy,
        sortDir,
        limit,
        activePage,
        uiStore,
        searchFiles,
        offset,
        filterStore.shouldReload,
        searchQueryParam,
    ]);

    const handleSearchValueChange = (search) => {
        setQueryParamToUrl('query', search);
        filterStore.setQuery(search);
        paginationStore.setActivePage(1);
    };
    const handleSortChange = (sortBy, sortDir) => {
        //TODO Sort here is not possible because the displayed data has concatenetd fields, must think in a solution after
        // filterStore.setSortBy(sortBy);
        // filterStore.setSortDir(sortDir);
    };

    const handleRowClick = (row) => {
        if (props.isStep) {
            props.onChange({
                selectedMap: row,
            });
            return;
        }
        navigate(`${serviceStore.currentService}/files/${row.id}`);
    };

    const downloadFileAction = async (row) => {
        try {
            const { downloadUrl } = await filesStore.getFile(row.file_metadata.file_id);
            window.location.href = downloadUrl;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${t("fotaone.notification.error.files.failedDownload")}`);
            }
        }
    };

    const deleteFileAction = async (row) => {
        setDialogType('delete')
        setDialogOpen(true)
        filesStore.setFile(row);
    };

    const editFileAction = (row) => {
        filesStore.setEditFile(row);
        filesStore.setFileDialogOpen(true);
    };

    const archiveFileAction = async (row) => {
        setDialogType('archive')
        setDialogOpen(true)
        filesStore.setFile(row);
    };

    const renderActions = (row) => (
        <div className='text-right'>
            <span
                onClick={(e) => e.stopPropagation()}
                data-comment='workaround for ButtonDropdown stopPropagation not working'
            >
                <ButtonDropdown
                    title={<span className='rioglyph rioglyph-option-vertical' aria-hidden />}
                    items={[
                        {
                            value: (
                                <div>
                                    <span className='rioglyph rioglyph-play-circle margin-right-10' />
                                    <span>{`${t("fotaone.mu.rolloutData.startRollout")}`}</span>
                                </div>
                            ),
                            onSelect: () => {
                                uiStore.setShowRolloutDialog(true);
                                mapRolloutStore.setRolloutData(row);
                            },
                        },
                        {
                            value: (
                                <div>
                                    <span className='rioglyph rioglyph-download margin-right-10' />
                                    <span>{`${t("fotaone.mu.fileData.downloadFile")}`}</span>
                                </div>
                            ),
                            onSelect: () => downloadFileAction(row),
                        },
                        {
                            value: (
                                <div>
                                    <span className='rioglyph rioglyph-parcel margin-right-10' />
                                    <span>{`${t("fotaone.mu.fileData.moveArchive")}`}</span>
                                </div>
                            ),
                            onSelect: () => archiveFileAction(row),
                            disabled: !row.can_be_archived,
                        },
                        {
                            value: (
                                <div>
                                    <span className='rioglyph rioglyph-files margin-right-10' />
                                    <span>{`${t("fotaone.mu.fileData.editFile")}`}</span>
                                </div>
                            ),
                            onSelect: () => editFileAction(row),
                            disabled: !row.can_be_deleted,
                        },
                        {
                            value: (
                                <div>
                                    <span className='rioglyph rioglyph-trash margin-right-10' />
                                    <span>{`${t("fotaone.mu.fileData.deleteFile")}`}</span>
                                </div>
                            ),
                            onSelect: () => deleteFileAction(row),
                            disabled: !row.can_be_deleted,
                        },
                    ]}
                    bsStyle='link'
                    preventDefault
                    stopPropagation
                    iconOnly
                />
            </span>
        </div>
    );

    const getUploadDialog = () => {
        if (serviceStore.currentService === Services.MapUpdate) {
            return <MapUploadDialog />;
        }
    };

    return (
        <div className={`${className} map-file-table`}>
            <Table
                toolbarElement={getUploadDialog()}
                showSearch={showSearch || props.isStep}
                handleSearchValueChange={handleSearchValueChange}
                handleSortChange={handleSortChange}
                searchValue={query}
                data={filesStore.listToTable}
                columnDetails={mapFileTableData.columnDetails}
                defaultColumnOrder={mapFileTableData.defaultColumnOrder}
                columns={mapFileTableData.columns}
                columnOrder={mapFileTableData.columnsOrder}
                hiddenColumns={mapFileTableData.hiddenColumns}
                columnLabels={mapFileTableData.columnLabels}
                disabledColumns={mapFileTableData.disabledColumns}
                actionItemRenderer={actionButtons ? renderActions : actionButtons}
                actionColumn='buttons'
                loading={filesStore.fileState.isLoading}
                onRowClick={handleRowClick}
                selectedRow={props.isStep && props.data.selectedMap ? props.data.selectedMap.id : props.selectedRow}
                rowKey='id'
            />
            {!props.isStep && (
                <RolloutDialog show={uiStore.showRolloutDialog} data={mapRolloutStore.rolloutDialogData || []} />
            )}
            <Pagination paginationStore={paginationStore} />
            {
                dialogType &&
                    <ConfirmationDialog
                        show={dialogOpen}
                        title={dialogType==='delete'?`${t("fotaone.mu.fileData.deleteFile")}`:`${t("fotaone.mu.fileData.moveArchive")}`}
                        content={dialogType==='delete'
                            ?`${t("fotaone.mu.fileData.deleteWarning")} ${filesStore.file.display_name}, ${t("fotaone.mu.fileData.version")}: ${filesStore.file.version_number}`
                            :`${t("fotaone.mu.fileData.archiveWarning")} ${filesStore.file.display_name}, ${t("fotaone.mu.fileData.version")}: ${filesStore.file.version_number}`}
                        bsSize={Dialog.SIZE_SM}
                        onClickConfirm={async () => {
                            try {
                                if(dialogType==='delete'){
                                    await filesStore.deleteFile(filesStore.file.id);
                                }else{
                                    await filesStore.archiveFile(filesStore.file.id);
                                }
                            }finally{
                                filesStore.getFilesGraphql();
                            }
                            setDialogOpen(false)
                        }}
                        onClickCancel={() => {
                            setDialogOpen(false)
                        }}
                        cancelButtonText={`${t("fotaone.general.abort")}`}
                        confirmButtonText={`${t("fotaone.general.yes")}`}
                        useOverflow
                    />
            }
        </div>
    );
});
