import React, { useEffect } from 'react';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import VehicleMainTable from '@components/Onlinetraffic/Vehicle/MainTable/VehicleMainTable';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import { cloneObject } from '@helpers/object';
import { useStores } from '@stores/context.jsx';
import { FilterStore } from '@stores/filter.jsx';
import { PaginationStore } from '@stores/pagination';
import {filterData, filterDataKeys} from '@components/Onlinetraffic/Vehicle/Vehicle.constants';
import VehicleSidebar from '@components/Onlinetraffic/Vehicle/Sidebar/Sidebar';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { validateEnvironmentLabelIfDev } from '@helpers/utils.jsx';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
    filters: cloneObject(validateEnvironmentLabelIfDev(filterData)),
    query: '',
    // sortBy: 'activation.source_active',
    sortBy: 'vin',
    sortDir: SortDirection.ASCENDING,
});
export default observer(() => {
    const { id: vin } = useParams();
    const { serviceStore } = useStores();
    const queryParams = new URLSearchParams(location.search);
    const searchQueryParam = queryParams.get('query');
    const manStatusParamFilter = queryParams.get('manNow');
    const fotaStatusParamFilter = queryParams.get('fotaStatus');
    const tagsParamFilter = queryParams.get('tags');
    const environmentParamFilter = queryParams.get('environment');
    const manStatusFilter = manStatusParamFilter ? manStatusParamFilter.split(',') : [];
    const fotaStatusFilter = fotaStatusParamFilter ? fotaStatusParamFilter.split(',') : [];
    const tagsFilter = tagsParamFilter ? tagsParamFilter.split(',') : [];
    const environmentFilter = environmentParamFilter ? environmentParamFilter.split(',') : [];

    useEffect(() => {
        if (searchQueryParam) {
            filterStore.setQuery(searchQueryParam);
        }
        if (manStatusParamFilter?.length > 0) {
            filterStore.filters.forEach((item) => {
                if (item.key === filterDataKeys.ManNow) {
                    item.result = manStatusFilter;
                }
            });
        }

        if (fotaStatusParamFilter?.length > 0) {
            filterStore.filters.forEach((item) => {
                if (item.key === filterDataKeys.FotaStatus) {
                    item.result = fotaStatusFilter;
                }
            });
        }

        if (tagsParamFilter?.length > 0) {
            filterStore.filters.forEach((item) => {
                if (item.key === filterDataKeys.TestFleet) {
                    item.result = tagsFilter;
                }
            });
        }

        if (environmentParamFilter?.length > 0) {
            filterStore.filters.forEach((item) => {
                if (item.key === filterDataKeys.Environment) {
                    item.result = environmentFilter;
                }
            });
        }

        // When unmounting the page:
        return () => {
            filterStore.resetToDefaults();
            paginationStore.resetToDefaults();
        };
    }, [serviceStore]);
    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Header>
                <FotaHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Sidebar className='bg-muted'>
                <FotaSidebar />
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Sidebar className='right'>
                {vin && <VehicleSidebar vin={vin} />}
            </ApplicationLayout.Sidebar>
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer />
                {serviceStore.serviceMenuDialogOpen &&
                    <ServiceMenuDialog/>
                }
                <VehicleMainTable selectedRow={vin} paginationStore={paginationStore} filterStore={filterStore} />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
});
