import React from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
export default ({ className }) => (
    <div
        className={`display-flex justify-content-center align-items-center w-100  position-absolute ${
            className ? className : ''
        }`}
    >
        <Spinner isDoubleSized isFullSized />
    </div>
);
