import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import DetailsGeneral from './DetailsGeneral.jsx'
import DetailsDevice from './DetailsDevice.jsx'
import DetailsMessages from './DetailsMessages.jsx'
import DetailsMessagesStats from './DetailsMessagesStats.jsx'

const MessageDetails = observer((props) => {
    const { remoteMessageStore } = useStores();
    const { deviceId } = props;

    useEffect(() => {
        const fetchData = async () => {
            await remoteMessageStore.getVehicleDetails(deviceId);
        };
        fetchData();
    }, [deviceId]);

    return (
        <div className='message-details'>
            <div className='details'>
                <DetailsGeneral />
                <DetailsDevice />
                <DetailsMessagesStats />
                <DetailsMessages />
            </div>
        </div>
    );
});

export default MessageDetails;