import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import FotaHeader from '@components/Navbar/Navbar';
import FotaSidebar from '@components/Sidebar/Sidebar';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import MessageTemplateTable from '@components/RemoteMessage/Messages/Template/Table/MessageTemplateTable.jsx';
import { useStores } from '@stores/context.jsx';

const MessageTemplatePage = () => {
  const { serviceStore } = useStores();

  useEffect(() => {
  }, [serviceStore]);

  return (
    <ApplicationLayout className={'MyServiceLayout'}>
      <ApplicationLayout.Header>
        <FotaHeader />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar className='bg-muted'>
        <FotaSidebar files={true} />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className='responsive'>
        <NotificationsContainer />
        {serviceStore.serviceMenuDialogOpen &&
          <ServiceMenuDialog/>
        }
        <MessageTemplateTable />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
};

export default observer(MessageTemplatePage);