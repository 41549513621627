import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import ReleaseNotes from '@rio-cloud/rio-uikit/lib/es/ReleaseNotes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const WhatsNewMenu = (props) => {
    const { t } = useTranslation();

    const releaseNotes = {
        'en-GB': {
            '1.0.0': {
                date: 'DD.MM.YYYY',
                content: (
                    <div className='padding-left-15'>
                        <CustomState headline='Stay tuned' fullWidth image={<img className='max-width-500' src='https://cdn.rio.cloud/svg/dashboard/dashboard_coming_soon.svg' alt='comingSoon' />}/>
                    </div>
                ),
            },
        },
    };

    return (
        <>
            <div className='text-size-h2 text-thin'>
                {t("fotaone.general.whatsnew")}
            </div>
            <Divider />
            <ReleaseNotes releaseNotes={releaseNotes['en-GB']} />;
        </>
    );
};

export default observer(WhatsNewMenu);