import { observable, action, makeObservable } from 'mobx';

let instance; // singleton instance

export const Services = {
    MapUpdate: '/maps',
    OnlineTraffic: '/online-traffic',
    FeatureDashboard: '/feature-dashboard',
    RemoteMessage: '/remote-message',
};

export class ServiceStore {
    currentService = Services.MapUpdate;

    serviceMenuDialogOpen = false;
    activeServiceMenu = '';

    constructor() {
        makeObservable(this, {
            currentService: observable,
            serviceMenuDialogOpen: observable,
            activeServiceMenu: observable,

            setCurrentService: action,
            setServiceMenuDialogOpen: action,
            setActiveServiceMenu: action,
        });
    }

    setCurrentService(current) {
        this.currentService = current;
    }

    setServiceMenuDialogOpen(state) {
        this.serviceMenuDialogOpen = Boolean(state);
    }

    setActiveServiceMenu(selectedMenu) {
        this.activeServiceMenu = selectedMenu;
    }

    static instance() {
        if (!instance) {
            instance = new ServiceStore();
        }
        return instance;
    }
}
