import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { AuthStore } from '@stores/auth';
import config from '@src/config';

let client;

const init = () => {
    const authStore = AuthStore.instance();
    const httpLink = createHttpLink({
        uri: config.MAPUPDATE_GRAPHQL_ENDPOINT,
    });
    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem('Authorization');
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token,
                'x-hasura-role': authStore.roles.includes('MU_RW') ? 'MU_RW' : 'MU_RO',
            },
        };
    });

    // auto-logout on auth failure
    const errorLink = onError(({ networkError }) => {
        if (networkError && networkError.statusCode === 401) {
            authStore.logout();
        }
    });

    client = new ApolloClient({
        link: from([authLink, errorLink, httpLink]),
        cache: new InMemoryCache(),
    });
    return client;
};

export { client, init };
