import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStores } from '@stores/context.jsx';
import { Services } from '@stores/service.js';
import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar.jsx';
import Pagination from '@common/Pagination/Pagination';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants.js';
import Table from '@common/Table/Table.jsx';
import './RolloutDetails.css';
import {
    eventStatusData,
    fileTableStatusData,
    FINAL_ROLLOUT_STATES,
    RESTART_ALLOWED_STATES,
    statusPercentage,
} from '../../../mapupdateHelper/constants.jsx';
import StatusBar from '@rio-cloud/rio-uikit/lib/es/StatusBar';
import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

export default observer((props) => {
    const { store, rolloutId, paginationStore } = props;
    const { serviceStore, authStore } = useStores();
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ dialogType, setDialogType ] = useState('');
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const intervalTime = 3000;

    const eventsLimit = paginationStore?.limit;
    const eventsOffset = paginationStore?.offset;
    paginationStore.setItemsLength(store.rolloutDetails.eventsTotal);

    const getRolloutDetails = useCallback(() => {
        store.updateRolloutDetails(rolloutId, { limit: eventsLimit, offset: eventsOffset });
        paginationStore.setItemsLength(store.rolloutDetails.eventsTotal);
    }, [store, rolloutId, eventsLimit, eventsOffset]);

    const refreshRolloutDetails = useCallback(() => {
        if (!store.rolloutDetailsRefreshingIsLoading) {
            store.refreshRolloutDetails(rolloutId, { limit: eventsLimit, offset: eventsOffset });
            paginationStore.setItemsLength(store.rolloutDetails.eventsTotal);
        }
    }, [store, rolloutId, eventsLimit, eventsOffset]);

    const setRolloutId = useCallback(
        (id) => {
            store.setID(id);
        },
        [store],
    );

    useEffect(() => {
        let detailsInterval;

        if (rolloutId !== store.rolloutDetails?.id) {
            setRolloutId(params?.id);
            store.resetRolloutDetails();
            getRolloutDetails();
        }
        if (rolloutId) {
            detailsInterval = setInterval(refreshRolloutDetails, intervalTime);
        }

        return () => {
            clearInterval(detailsInterval);
        };
    }, [getRolloutDetails, params, rolloutId, refreshRolloutDetails, setRolloutId, store, eventsLimit, eventsOffset]);

    const resumeRollout = () => {
        store.resumeRollout(rolloutId);
    };

    const restartRollout = () => {
        setDialogType('restart')
        setDialogOpen(true)
    };
    
    const cancelRollout = () => {
        setDialogType('cancel')
        setDialogOpen(true)
    };

    const renderStatusbar = () => {
        if (store.rolloutDetails.status === 'CANCELED' || store.rolloutDetails.status === 'FAILED') {
            return (
                <StatusBar
                    icon={{
                        name: 'rioglyph rioglyph-warning-sign',
                        weight: 'light',
                        color: 'text-danger',
                    }}
                    label={{
                        value: returnPercentage(true),
                        weight: 'light',
                        color: 'text-danger',
                    }}
                    progress={[
                        {
                            percentage: Number(returnPercentage(false)),
                            color: 'progress-bar-danger',
                            tooltip: <span>{returnPercentage(true)}</span>,
                        },
                    ]}
                    size='small'
                    useProgressDivider={true}
                />
            );
        } else if (store.rolloutDetails.status === 'COMPLETED') {
            return (
                <StatusBar
                    icon={{
                        name: 'rioglyph rioglyph-ok-sign',
                        weight: 'light',
                        color: 'text-success',
                    }}
                    label={{
                        value: returnPercentage(true),
                        weight: 'light',
                        color: 'text-success',
                    }}
                    progress={[
                        {
                            percentage: Number(returnPercentage(false)),
                            color: 'progress-bar-success',
                            tooltip: <span>{returnPercentage(true)}</span>,
                        },
                    ]}
                    size='small'
                    useProgressDivider={true}
                />
            );
        } else {
            return (
                <StatusBar
                    icon={{
                        name: 'rioglyph rioglyph-transfer',
                        weight: 'light',
                        color: 'text-primary',
                    }}
                    label={{
                        value: returnPercentage(true),
                        weight: 'light',
                        color: 'text-primary',
                    }}
                    progress={[
                        {
                            percentage: Number(returnPercentage(false)),
                            color: 'progress-bar-primary',
                            tooltip: <span>{returnPercentage(true)}</span>,
                        },
                    ]}
                    size='small'
                    useProgressDivider={true}
                />
            );
        }
    };

    const parseMapFiles = () => {
        return store.rolloutDetails?.packages
            ? store.rolloutDetails.packages.map((m) => {
                  const obj = {
                      ...m.metadata,
                      all_names: `${m.metadata.filename} - ${m.metadata.displayName}`,
                      approval_state: m.approvalState,
                  };
                  if (store.rolloutDetails.progress.remaining.includes(m.id)) {
                      obj.status = 'PENDING';
                  } else if (store.rolloutDetails.progress.completed.includes(m.id)) {
                      obj.status = 'COMPLETED';
                  } else if (store.rolloutDetails.progress.current?.includes(m.id)) {
                      obj.status = 'IN_PROGRESS';
                  } else if (store.rolloutDetails.progress.alreadyPresent?.includes(m.id)) {
                      obj.status = 'ALREADY_PRESENT';
                  } else {
                      obj.status = 'UNKNOWN';
                  }
                  return obj;
              })
            : [];
    };

    const renderTable = () => {
        const transformedData = parseMapFiles();
        return (
            <div>
                <h5>{`${t("fotaone.mu.fileData.file.many")}`}</h5>
                <div className='breakline margin-bottom-20 margin-bottom-10' />
                <Table
                    showSearch={false}
                    data={transformedData || []}
                    columnDetails={fileTableStatusData.columnDetails}
                    defaultColumnOrder={fileTableStatusData.defaultColumnOrder}
                    columns={fileTableStatusData.columns}
                    columnsOrder={fileTableStatusData.columnsOrder}
                />
            </div>
        );
    };

    const renderEvents = () => {
        const events = store.rolloutDetails.events || [];
        return (
            <div>
                <h5>{`${t("fotaone.mu.rolloutData.event.many")}`}</h5>
                <div className='breakline margin-bottom-20 margin-bottom-10' />
                <Table
                    showSearch={false}
                    data={events}
                    columnDetails={eventStatusData.columnDetails}
                    defaultColumnOrder={eventStatusData.defaultColumnOrder}
                    columns={eventStatusData.columns}
                    columnsOrder={eventStatusData.columnsOrder}
                />
                <Pagination paginationStore={paginationStore} />
            </div>
        );
    };

    const returnPercentage = (percentSign) => {
        if (store.rolloutDetails.status === 'SUSPENDED') {
            return statusPercentage[store.rolloutDetails.rolloutMetadata.nextStatus] + (percentSign ? '%' : '');
        }
        return statusPercentage[store.rolloutDetails.status] + (percentSign ? '%' : '');
    };

    const onClose = () => {
        navigate(`${serviceStore.currentService}/rollouts`);
    };

    const handleVehicleIdClick = () => {
        navigate(`${serviceStore.currentService}/vehicles/${store.rolloutDetails.vehicleId}`);
    };

    const restartButtonDisabledState = () => {
        // Checks if we are allowed to use the Restart Rollout Button
        if (!authStore.hasWriteAccess(Services.MapUpdate)) {
            // button stays deactivated
            return true;
        }

        // The Rollout State does not allow a Restart
        if (!RESTART_ALLOWED_STATES.includes(store.rolloutDetails.status)) {
            // button stays deactivated
            return true;
        }

        // Restart button can be activated so we activate the button:
        return false;
    };

    return (
        <>
            <DetailsSideBar
                title={`${t("fotaone.mu.rolloutData.rolloutDetails")}`}
                onClose={onClose}
                width={600}
                blurEffect={
                    store.rolloutDetailsUpdatingIsLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide
                }
                fly
            >
                <div className='display-flex align-items-center'>
                    <div className='margin-right-auto'>
                        <button
                            type='button'
                            className='btn btn-primary btn-outline margin-right-5'
                            disabled={restartButtonDisabledState()}
                            onClick={() => restartRollout()}
                            id='rollout-restart-button'
                            data-testid='rollout-restart-button'
                        >
                            <span className='rioglyph rioglyph-play-circle' aria-hidden='true' />
                            <span>{`${t("fotaone.mu.rolloutData.restartRollout")}`}</span>
                        </button>
                    </div>

                    <div>
                        <span className='label label-default justify-content-around' style={{ marginRight: '5px' }}>
                            {store.rolloutDetailsRefreshingIsLoading ? (
                                <>
                                    <span className='rioglyph rioglyph-refresh spinning' /> {`${t("fotaone.mu.rolloutData.updatingData")}`}
                                </>
                            ) : (
                                <>
                                    <span className='rioglyph rioglyph-ok' /> {`${t("fotaone.mu.rolloutData.dataLoaded")}`}
                                </>
                            )}
                        </span>
                    </div>
                </div>

                <div>
                    <h5>{`${t("fotaone.general.general")}`}</h5>
                    <div className='breakline margin-bottom-20' />
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.rolloutData.rolloutId")}`}</label>
                                <p className='text-primary'>{store.rolloutDetails.id} </p>
                            </div>
                            <div className='margin-bottom-10'>
                                <label className='display-block margin-bottom-10'>{`${t("fotaone.general.vehicleData.shortname")}`}</label>
                                <span className='text-primary' onClick={() => handleVehicleIdClick()}>
                                    <a className='margin-top-10'>{store.rolloutDetails.name}</a>
                                </span>
                                {store.rolloutDetails.isTestFleet && (
                                    <>
                                        {' '}
                                        <br />(<span className='text-primary'>{store.rolloutDetails.name} </span>
                                        <span
                                            className='label label-condensed margin-right-2 label-info'
                                            title='Test Vehicle'
                                        >
                                            {`${t("fotaone.general.tagData.testfleet")}`}
                                        </span>
                                        )
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.general.vehicleData.vinShort")}`}</label>
                                <p className='text-primary'>{store.rolloutDetails.vin}</p>
                            </div>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>{`${t("fotaone.mu.rolloutData.interactiveMode")}`}</label>
                                <p className='text-primary'>
                                    {typeof store.rolloutDetails.suspendable !== 'undefined'
                                        ? store.rolloutDetails.suspendable
                                            ? `${t("fotaone.general.enabled")}`
                                            : `${t("fotaone.general.disabled")}`
                                        : `${t("fotaone.general.notApplicable")}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {serviceStore.currentService === Services.MapUpdate && renderTable()}
                <div>
                    <h5>{`${t("fotaone.general.status")}`}</h5>
                    <div className='breakline margin-bottom-20 ' />
                    <div className='margin-bottom-10 row'>
                        <div className='margin-bottom-10 col-lg-6'>
                            <label className='display-block'>{`${t("fotaone.mu.rolloutData.currentState")}`}</label>
                            <p className='text-primary'>{store.rolloutDetails.status}</p>
                            {store.rolloutDetails.retriable && (
                                <span className='label label-condensed margin-left-4 label-info' title='Retrieable'>
                                    {`${t("fotaone.mu.rolloutData.retriable")}`}
                                </span>
                            )}
                        </div>
                        <div className='margin-bottom-10 col-lg-6'>
                            <label className='display-block'>{`${t("fotaone.mu.rolloutData.manualRestarts")}`}</label>
                            <p className='text-primary'>{store.rolloutDetails.manualRestarts}</p>
                        </div>
                        {store.rolloutDetails.suspendable !== false && store.rolloutDetails.status === 'SUSPENDED' && (
                            <div className='margin-bottom-10 col-lg-6'>
                                <label className='display-block'>{`${t("fotaone.mu.rolloutData.nextState")}`}</label>
                                <p className='text-primary'>
                                    {store.rolloutDetails.rolloutMetadata
                                        ? store.rolloutDetails.rolloutMetadata.nextStatus
                                        : `${t("fotaone.general.notApplicable")}`}
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                {store.rolloutDetails.suspendable !== false && store.rolloutDetails.status === 'SUSPENDED' && (
                    <div>
                        <button type='button' className='btn btn-primary' onClick={() => resumeRollout()}>
                            <span className='rioglyph rioglyph-angle-double-right margin-right-10' />
                            <span>{`${t("fotaone.mu.rolloutData.continueNextStep")}`}</span>
                        </button>
                    </div>
                )}
                <div className='margin-bottom-10 margin-top-20'>
                    <h5>{`${t("fotaone.mu.rolloutData.progress")}`}</h5>
                    <div className='breakline margin-bottom-20 margin-bottom-10' />
                    {renderStatusbar()}
                </div>
                {FINAL_ROLLOUT_STATES.indexOf(store.rolloutDetails.status) < 0 && (
                    <div className='margin-bottom-10 margin-top-20 '>
                        <button type='button' className='btn btn-danger' onClick={() => cancelRollout()}>
                            <span className='rioglyph rioglyph-error-sign margin-right-10' />
                            <span>{`${t("fotaone.mu.rolloutData.cancelRollout")}`}</span>
                        </button>
                    </div>
                )}
                {renderEvents()}
            </DetailsSideBar>
            {
                store.rolloutDetails &&
                    <ConfirmationDialog
                        show={dialogOpen}
                        title={dialogType==='restart'?`${t("fotaone.mu.rolloutData.restartRollout")}`:`${t("fotaone.mu.rolloutData.cancelRollout")}`}
                        content={dialogType==='restart'
                            ?<>{t("fotaone.mu.rolloutData.restartRolloutWarning")}<br/>{t("fotaone.general.name")}: {store.rolloutDetails.name}<br/>ID: {store.rolloutDetails.id}</>
                            :<>{t("fotaone.mu.rolloutData.cancelRolloutWarning")}<br/>{t("fotaone.general.name")}: {store.rolloutDetails.name}<br/>ID: {store.rolloutDetails.id}</>}
                        bsSize={Dialog.SIZE_SM}
                        onClickConfirm={async () => {
                            if(dialogType==='restart'){
                                await store.restartRollout(rolloutId);
                            }else{
                                await store.cancelRollout(rolloutId);
                            }
                            setDialogOpen(false)
                        }}
                        onClickCancel={() => {
                            setDialogOpen(false)
                        }}
                        cancelButtonText={`${t("fotaone.general.abort")}`}
                        confirmButtonText={`${t("fotaone.general.yes")}`}
                        useOverflow
                    />
            }
        </>
    );
});
