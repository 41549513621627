import { activationBadgeRenderer, assetBadgeRenderer, lastActivationDateRenderer } from './Sidebar.renderers';

/*
 * Table definition used in Sidebar in VehicleDetails.
 */
export const vehicleTableSidebar = {
    defaultColumnOrder: [
        'asset_id',
        'source_active',
        'last_change_date',
        'activations.activated_at',
        'activations.deactivated_at',
        'activations.activation_source',
        'activations.first_activated_at',
        'activations.source_active',
    ],
    columnsOrder: [
        'name',
        'vin',
        'serial_number',
        'account_id',
        'activations.activated_at',
        'activations.deactivated_at',
        'activations.activation_source',
        'activations.first_activated_at',
        'activations.source_active',
    ],
    columnDetails: {
        name: {},
        vin: {},
        serial_number: {},
        account_id: {},
        'activations.activated_at': {},
        'activations.deactivated_at': {},
        'activations.activation_source': {},
        'activations.first_activated_at': {},
        'activations.source_active': {},
    },
    columns: [
        {
            title: 'fotaone.general.vehicleData.assetId',
            key: 'asset_id',
            badge: assetBadgeRenderer,
        },
        {
            title: 'fotaone.general.status',
            renderer: activationBadgeRenderer,
        },
        {
            title: 'fotaone.general.vehicleData.assetLastChanged',
            key: 'last_change_date',
            renderer: lastActivationDateRenderer,
        },
    ],
};
