import {observer} from 'mobx-react-lite';
import Bar from '@rio-cloud/rio-uikit/lib/es/Bar';
import BarChart from '@rio-cloud/rio-uikit/lib/es/BarChart';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import Legend from '@rio-cloud/rio-uikit/lib/es/Legend';
import {useStores} from '@stores/context.jsx';
import {PureComponent, useCallback, useEffect, useState} from 'react';
import moment from 'moment/moment.js';
import {debounce} from 'lodash';
import { useTranslation } from 'react-i18next';

const SessionCountsByDateRangeKpi = () => {
    const {vehicleStore} = useStores();
    const [sessionKpi, setSessionKpi] = useState([]);
    const [startDate, setStartDate] = useState('01/01/2010');
    const [endDate, setEndDate] = useState(moment());
    const [visibleCountries, setVisibleCountries] = useState({});
    const sessionCountsPerCountry = useCallback(debounce(async () => {
        const sessionData = await vehicleStore.getSessionCountsPerCountry(moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
        setSessionKpi(sessionData);
        return sessionData;
    }, 0), [startDate, endDate],);
    const { t } = useTranslation();

    useEffect(() => {
        sessionCountsPerCountry();
    }, [vehicleStore, startDate, endDate]);

    useEffect(() => {
        const countriesVisibility = {};
        sessionKpi?.forEach(item => {
            countriesVisibility[item.start_country] = true;
        });
        setVisibleCountries(countriesVisibility);
    }, [sessionKpi]);

    const handleCountryVisibilityChange = (country) => {
        setVisibleCountries({
            ...visibleCountries,
            [country]: !visibleCountries[country]
        });
    };

    const filteredData = sessionKpi 
        ? sessionKpi?.map(function (obj){
            obj[`${t("fotaone.ot.analyticData.activated")}`] = obj['Activated'];
            obj[`${t("fotaone.ot.analyticData.deactivated")}`] = obj['Deactivated'];
        }) && sessionKpi?.filter(item => visibleCountries[item.start_country])
        : [];

    const handleStartDateChange = selectedDate => setStartDate(selectedDate);
    const handleEndDateChange = selectedDate => setEndDate(selectedDate);

    class CustomizedAxisTick extends PureComponent {
        render() {
            const {x, y, payload} = this.props;

            return (<g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={0}
                    dy={10}
                    textAnchor="end"
                    fill="#666"
                    transform="rotate(-35)"
                    style={{ fontSize: 10, fontWeight: 'bold' }}
                >
                    {payload.value}
                </text>
            </g>);
        }
    }


    return (
        <div className='panel panel-default panel-body margin-bottom-0' style={{height: 650}}>
            <div className='display-flex gap-10 max-width-600 margin-bottom-10'>
                <div className='flex-1-1-0'>
                    <label>{`${t("fotaone.general.timeData.from")}`}</label>
                    <DatePicker
                        inputProps={{placeholder: `${t("fotaone.general.timeData.selectDate")}`}}
                        timeFormat={false}
                        value={startDate}
                        onChange={handleStartDateChange}
                        dateFormat={'DD/MM/YYYY'}
                    />
                </div>
                <div className='flex-1-1-0'>
                    <label>{`${t("fotaone.general.timeData.to")}`}</label>
                    <DatePicker
                        inputProps={{placeholder: `${t("fotaone.general.timeData.selectDate")}`}}
                        timeFormat={false}
                        value={endDate}
                        onChange={handleEndDateChange}
                        dateFormat={'DD/MM/YYYY'}
                        alignRight
                    />
                </div>
            </div>
            <div style={{height: 430}}>
                <BarChart
                    data={filteredData}
                    dataKey='start_country'
                    legend={<Legend
                        layout='horizontal'
                        verticalAlign='top'
                        align='right'
                    />}
                    showGrid
                    gridOptions={{vertical: false}}
                    xAxisOptions={{
                        dataKey: 'start_country', tick: <CustomizedAxisTick/>
                    }}
                    yAxisOptions={{}}
                    margin={{bottom: 30}}
                    bars={[<Bar
                        key='activated'
                        dataKey={`${t("fotaone.ot.analyticData.activated")}`}
                        color='color-rating-5'
                    />, <Bar
                        key='deactivated'
                        dataKey={`${t("fotaone.ot.analyticData.deactivated")}`}
                        color='color-rating-3'
                    />,]}
                />
            </div>
            <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 'calc(100vw - 50px)'}}>
                {sessionKpi?.map(item => (
                    <div style={{width: 110}}>
                        <Checkbox
                            key={item.start_country}
                            checked={visibleCountries[item.start_country]}
                            onChange={() => handleCountryVisibilityChange(item.start_country)}
                            inline
                        >
                            {item.start_country}
                        </Checkbox>
                    </div>
                ))}
            </div>
    </div>);
};

export default observer(SessionCountsByDateRangeKpi)