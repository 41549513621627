import React from 'react';
import { regionSelectData } from './constants.jsx';
import { useTranslation } from 'react-i18next';

export function getRegion(id) {
    let regionLabel = 'N/A';
    regionSelectData.map((region) => {
        if (Object.keys(region)[0] === id) {
            regionLabel = region[id];
        }
        return null;
    });
    return regionLabel;
}
export const getDefaultRegion = () => {
    return regionSelectData[0];
};
export const getDefaultRegionValue = () => {
    return Object.keys(getDefaultRegion())[0];
};

export function renderRegions() {
    const { t } = useTranslation();
    return regionSelectData.map((obj) => {
        return (
            <option value={Object.keys(obj)} key={Object.keys(obj)}>
                {t(obj[Object.keys(obj)])}
            </option>
        );
    });
}
