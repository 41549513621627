import React, { useEffect } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import Unauthorized from '@components/Auth/Unauthorized';
import FotaHeader from '@components/Navbar/Navbar';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';

export default observer(() => {
    const { serviceStore } = useStores();

    useEffect(() => {

    }, [serviceStore.serviceMenuDialogOpen]);

    return (
        <ApplicationLayout className='MyServiceLayout'>
            <ApplicationLayout.Header>
                <FotaHeader />
            </ApplicationLayout.Header>
            <ApplicationLayout.Body className='auth'>
                {serviceStore.serviceMenuDialogOpen &&
                    <ServiceMenuDialog/>
                }
                <Unauthorized />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
});
