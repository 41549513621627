 import { observable, action, makeObservable } from 'mobx';
import { client as mapUpdateApiClient } from '@api/mapupdate-client';
import { client as mapUpdateGraphqlClient } from '@api/mapupdate-graphql';
import { gql } from '@apollo/client';
import { setWith, isEmpty } from 'lodash';
import { initialRolloutStatusFilter, rolloutStatusFinal } from '@helpers/constants';
import { cloneObject } from '@helpers/object';
import { filterDataKeys } from '@components/mapupdate/rollouts/overview/Rollout.constants.js';
import { v4 as uuidv4 } from 'uuid';
 import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import  i18n  from 'i18next';

let instance; // singleton instance
const rolloutDialogDataMock = {
    vehicle: null,
    assetId: null,
    filename: '',
    fileSize: 0,
    versionNumber: '',
    interactiveMode: false,
};
const ROLLOUTS_FIELDS = `
    id
    id_text
    campaign_id
    is_active
    is_suspendable
    created_at
    manual_restarts
    rollout_state {
      rollout_state_type
      timeout_at
    }
    rollout_packages{
        map_file {
            display_name
        }
    }
    vehicle {
        id_text
        name
        vin
        devices {
            formatted_serial_no
        }
        assets {
            testfleet {
              account_id
            }
        }
    }
`;

const ROLLOUTS_INIT_FILTER = `
    {
        _or: [
            { id_text: { _ilike: $query } }
            { vehicle: { id_text: { _ilike: $query } } }
            { vehicle: { vin: { _ilike: $query } } }
            { vehicle: { name: { _ilike: $query } } }
            { vehicle: { devices: { formatted_serial_no: { _ilike: $query } } } }
            { campaign_id_text: { _ilike:  $query } }
            { campaign: { campaign_name: { _ilike:  $query } }}
            { rollout_packages: { map_file: { display_name: { _ilike: $query } } } }
        ]
        _and: $filter
    }
`;

const SEARCH_ROLLOUTS = gql`
    query rolloutsOverview(
        $limit: Int = 10
        $offset: Int = 0
        $query: String = "%%"
        $orderBy: [mu_rollouts_order_by!] = {created_at: desc}
        $filter: [mu_rollouts_bool_exp!] = {}
    ) {
        mu {
            rollouts(
                limit: $limit
                offset: $offset
                where: {
                    _and: [
                        ${ROLLOUTS_INIT_FILTER}
                    ]
                }
                order_by: $orderBy
            ) {
                ${ROLLOUTS_FIELDS}
            }
            rollouts_aggregate(
                where: {
                    _and: [
                        ${ROLLOUTS_INIT_FILTER}
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const SEARCH_ROLLOUTS_FROM_CAMPAIGN_ID = gql`
    query rolloutsOverview(
        $limit: Int = 10
        $offset: Int = 0
        $query: String = "%%"
        $orderBy: [mu_rollouts_order_by!] = {}
        $filter: [mu_rollouts_bool_exp!] = {}
        $campaignId: String = "%%"
    ) {
        mu {
            rollouts(
                limit: $limit
                offset: $offset
                where: {
                    _and: [
                        { campaign_id_text: { _ilike:  $campaignId } }
                        ${ROLLOUTS_INIT_FILTER}
                    ]
                }
                order_by: $orderBy
            ) {
                ${ROLLOUTS_FIELDS}
            }
            rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id_text: { _ilike:  $campaignId } }
                        ${ROLLOUTS_INIT_FILTER}
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

const SEARCH_ROLLOUTS_FROM_CAMPAIGNS = gql`
    query rolloutsOverview(
        $limit: Int = 10
        $offset: Int = 0
        $query: String = "%%"
        $orderBy: [mu_rollouts_order_by!] = {}
        $filter: [mu_rollouts_bool_exp!] = {}
        $hasNoCampaign: Boolean
    ) {
        mu {
            rollouts(
                limit: $limit
                offset: $offset
                where: {
                    _and: [
                        { campaign_id: { _is_null:  $hasNoCampaign } }
                        ${ROLLOUTS_INIT_FILTER}
                    ]
                }
                order_by: $orderBy
            ) {
                ${ROLLOUTS_FIELDS}
            }
            rollouts_aggregate(
                where: {
                    _and: [
                        { campaign_id: { _is_null:  $hasNoCampaign } }
                        ${ROLLOUTS_INIT_FILTER}
                    ]
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    }
`;

// Search for the ongoing (active) rollout for each asset to show in the rollout preview (exclude final states)
// Suggestion: use the rollouts_queue view, hasura configs needed
const SEARCH_ASSETS_WITH_ACTIVE_ROLLOUTS = gql`
    query GetActiveRolloutsFromAssetIds(
        $vehicleIds: [uuid!] = ""
        $rolloutCondition: [String!] = [${Object.keys(rolloutStatusFinal)
            .map((item) => `"${item}"`)
            .join(',')}]
    ) {
        mu {
            rollouts_overview(where: { rollout_state_type: { _nin: $rolloutCondition }, vehicle_id: { _in: $vehicleIds } }) {
                id
                vehicle_id
                vin
                name
                is_test_fleet
                rollout_state_type
            }
        }
    }
`;

const GET_ROLLOUT_EVENTS = gql`
    query GetRolloutEvents(
        $limit: Int = 50
        $offset: Int = 0
        $rollout_id: uuid!
        $time_reference: timestamp!
    ) {
        mu {
            rollout_events(
                limit: $limit
                offset: $offset
                where: { 
                    _and: [ 
                        { rollout_id: { _eq: $rollout_id }}
                        { created_at: { _gt: $time_reference }}
                    ]
                }, 
                order_by: {created_at: desc}
            ) {
                id
                payload
                created_at
                subject
            }
            rollout_events_aggregate(
                where: { 
                    _and: [ 
                        { rollout_id: { _eq: $rollout_id }}
                        { created_at: { _gt: $time_reference }}
                    ]
                },
                order_by: {created_at: desc}
            ) {	
                aggregate {
                    count
                }
            }
        }
    }
`;

const defaultRolloutsListFilter = {
    status: initialRolloutStatusFilter,
};
export class MapRolloutStore {
    id = null;
    rolloutsListFilter = defaultRolloutsListFilter;

    rolloutsLoading = false;
    rolloutDetailsRefreshingIsLoading = false;
    rolloutDetailsUpdatingIsLoading = false;
    rolloutsList = [];
    rolloutsTotal = 0;
    rolloutDetails = {};
    activeVehicles = []; // assets with active rollouts
    shouldReload = 0;

    rolloutDialogData = cloneObject(rolloutDialogDataMock);
    multipleRolloutDialogData = {
        group: null,
        vehicle: null,
        vehicleIds: [],
        filesList: [],
        activeIds: [],
        interactiveMode: false,
        cancelRetry: false,
        distinctMapRollouts: false,
    };

    constructor() {
        makeObservable(this, {
            activeVehicles: observable,
            rolloutsListFilter: observable,
            rolloutsLoading: observable,
            rolloutDetailsRefreshingIsLoading: observable,
            rolloutDetailsUpdatingIsLoading: observable,
            rolloutsList: observable,
            rolloutDetails: observable,
            rolloutDialogData: observable,
            multipleRolloutDialogData: observable,
            getRolloutsGraphQL: action,
            getRolloutDetails: action,
            getAssetsWithActiveRollouts: action,
            createRollout: action,
            resumeRollout: action,
            cancelRollout: action,
            setRolloutDetails: action,
            setDefaultRolloutData: action,
            setMultipleRolloutData: action,
            setMultipleDefaultRolloutData: action,
            setRolloutsLoading: action,
        });
    }
    triggerReload() {
        // randomized value to trigger a useEffect in a component.
        this.shouldReload = uuidv4();
    }

    setRolloutDetails(details) {
        this.rolloutDetails = {
            ...this.rolloutDetails,
            ...details,
        };
    }

    setID(id) {
        this.id = id;
    }

    resetRolloutDetails() {
        this.rolloutDetails = {};
    }

    setRolloutDetailsRefreshingIsLoading(state) {
        this.rolloutDetailsRefreshingIsLoading = Boolean(state);
    }

    setRolloutDetailsUpdatingIsLoading(state) {
        this.rolloutDetailsUpdatingIsLoading = Boolean(state);
    }

    setRolloutsLoading(state) {
        this.rolloutsLoading = Boolean(state);
    }

    async getAssetsWithActiveRollouts(vehicleIds) {
        try {
            const variables = {
                vehicleIds,
            };
            const res = await mapUpdateGraphqlClient.query({
                query: SEARCH_ASSETS_WITH_ACTIVE_ROLLOUTS,
                variables,
                fetchPolicy: 'no-cache',
            });
            this.activeVehicles = res.data.mu.rollouts_overview;
            return res.data.mu.rollouts_overview;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.rollouts.fetchAssetsActiveRollout")}`);
            }
        }
    }

    async getRolloutsGraphQL(searchParams = {}) {
        const { query, sortBy, sortDir, limit, offset, filters, hasCampaignFilter, campaignId } = searchParams;
        try {
            const variables = {
                query: `%${query}%`,
                limit,
                offset,
                filter: {},
                hasNoCampaign: !hasCampaignFilter,
                campaignId: `%${campaignId}%`,
            };

            if (sortBy) {
                variables.orderBy = {};
                setWith(variables.orderBy, sortBy.split('.'), sortDir, Object);
            }

            variables.filter = this.getVariablesFilters(filters);
            this.setRolloutsLoading(true);
            let gqlQuery = SEARCH_ROLLOUTS;
            if (hasCampaignFilter) {
                gqlQuery = SEARCH_ROLLOUTS_FROM_CAMPAIGNS;
            } else if (campaignId) {
                gqlQuery = SEARCH_ROLLOUTS_FROM_CAMPAIGN_ID;
            }

            const res = await mapUpdateGraphqlClient.query({
                query: gqlQuery,
                variables,
                fetchPolicy: 'no-cache',
            });

            const {
                rollouts,
                rollouts_aggregate: { aggregate: { count: total } },
            } = res.data.mu;
            this.rolloutsList = rollouts;
            this.rolloutsTotal = total;

            return this.rolloutsList;
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.rollouts.fetchRollouts")}`);
            }
        } finally {
            this.setRolloutsLoading(false);
        }
    }
    /**
     *
     * @param {*} filters
     * @returns {{_and: Array<any>}}
     */
    getVariablesFilters(filters) {
        const variablesFilter = {
            _and: [],
        };
        if (Array.isArray(filters)) {
            filters.forEach((filter) => {
                const { result, options, databaseKey } = filter;
                const fieldFilter = {
                    _or: [],
                };
                switch (
                    filter.key //NOSONAR
                ) {
                    case filterDataKeys.Status: {
                        const valuesToAdd = this.getArrayFilters(databaseKey, result, options);
                        // we add the filter information nested to the fieldfilter
                        // in order to account for the changed data structure
                        fieldFilter._or.push({ rollout_state: valuesToAdd[0] });
                        break;
                    }
                    case filterDataKeys.HasNameAndVin: {
                        if(result.length === 0) {
                            const valuesToAdd =
                              {_and: [{vehicle: {name: {_neq: "null"}}}, {vehicle: {vin: {_neq: "null"}}}]}
                            variablesFilter._and.push(valuesToAdd);
                        }
                        break;
                    }
                    default:
                        break;
                }
                if (fieldFilter._or.length > 0) variablesFilter._and.push(fieldFilter);
            });
        }
        return variablesFilter;
    }

    /**
     *
     * @param {*} fieldName
     * @param {*} result
     * @param {{[x:string]:{ _in: Array<string>}}} options
     * @returns
     */
    getArrayFilters(fieldName, result, options) {
        const response = [];
        if (!isEmpty(fieldName) && Array.isArray(result) && Array.isArray(options)) {
            const values = result.reduce((acc, item) => {
                const option = options.find((op) => op?.key === item);
                if (option) return [...acc, option.value];
                else return acc;
            }, []);
            response.push({ [fieldName]: { _in: values } });
        }
        return response;
    }

    async createRollout({ isMultipleRollout, startImmediately }) {
        let payloadRollout = {};

        if (isMultipleRollout) {
            const { activeIds, interactiveMode, group, vehicleIds, cancelRetry, distinctMapRollouts } =
                this.multipleRolloutDialogData;
            payloadRollout = {
                vehicleIds,
                mapIds: activeIds,
                suspendable: interactiveMode,
                retriable: !cancelRetry,
                groupId: group ? group.id : null,
                startImmediately,
                distinctMapRollouts,
            };
        } else {
            const { vehicleIds, id, interactiveMode } = this.rolloutDialogData;
            payloadRollout = {
                vehicleIds,
                mapIds: [id],
                suspendable: interactiveMode,
                startImmediately,
            };
        }

        try {
            const res = await mapUpdateApiClient.createRollout(null, payloadRollout);
            this.triggerReload();
            this.setDefaultRolloutData();
            isMultipleRollout && this.getRolloutsGraphQL();

            if (res.status === 201) {
                Notification.success(`${i18n.t("fotaone.notification.success.rollouts.startRollout")}`);
            } else {
                Notification.warning(`${i18n.t("fotaone.notification.warning.rollouts.startRollout")}`);
            }
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(
                    `${i18n.t("fotaone.notification.error.rollouts.startRollout")} ${payloadRollout.assetIds.join(' ')}.`,
                );
            }
        }
    }

    async getRolloutDetails(id) {
        const response = {
            details: null,
            timestampRef: null,
        };
        try {
            const httpResponse = await mapUpdateApiClient.getRollout(id);
            const timestampRef = httpResponse.data.createdAt;
            response.details = httpResponse.data;
            response.timestampRef = timestampRef;
        } catch (err) {
            Notification.error(`${i18n.t("fotaone.notification.error.rollouts.fetchRolloutDetails")}`);
        }
        return response;
    }

    async getRolloutEvents(searchParameters) {
        const { id, afterTimestamp, limit, offset } = searchParameters;

        const variables = {
            limit: !limit ? 50 : limit,
            offset: !offset ? 0 : offset,
            rollout_id: id,
            time_reference: afterTimestamp,
        };

        try {
            const res = await mapUpdateGraphqlClient.query({
                query: GET_ROLLOUT_EVENTS,
                variables,
                fetchPolicy: 'no-cache',
            });

            // FIXME: Remove the second events, needed because it is the expected structure upstream
            return {
                events: {
                    events:
                        res.data.mu.rollout_events.length !== 0
                            ? res.data.mu.rollout_events.map((event) => ({
                                  timestamp: event.created_at,
                                  subject: event.subject,
                                  payload: JSON.stringify(event.payload),
                              }))
                            : [],
                },
                eventsTotal: res.data.mu.rollout_events_aggregate.aggregate.count,
            };
        } catch (err) {
            Notification.error(`${i18n.t("fotaone.notification.error.rollouts.fetchRolloutEvents")}`);
        }
    }

    async getRolloutDetailsData(id, eventSearch = {}) {
        const data = {
            details: null,
            events: null,
        };
        const { details, timestampRef } = await this.getRolloutDetails(id);
        data.details = details;
        if (timestampRef) {
            const { events, eventsTotal } = await this.getRolloutEvents({
                id: id,
                afterTimestamp: timestampRef,
                limit: eventSearch?.limit,
                offset: eventSearch?.offset,
            });
            data.events = events;
            data.eventsTotal = eventsTotal;
        }
        return data;
    }

    async refreshRolloutDetails(id, eventSearch) {
        this.setRolloutDetailsRefreshingIsLoading(true);
        const data = await this.getRolloutDetailsData(id, eventSearch);
        if (this.id === this.rolloutDetails?.id) {
            if (data.details) {
                this.setRolloutDetails({
                    ...this.rolloutDetails,
                    ...data?.details,
                    events: data?.events?.events,
                    eventsTotal: data?.eventsTotal,
                });
            }
        }
        this.setRolloutDetailsRefreshingIsLoading(false);
    }

    async updateRolloutDetails(id, eventSearch) {
        this.setRolloutDetailsUpdatingIsLoading(true);
        const data = await this.getRolloutDetailsData(id, eventSearch);
        if (data.details) {
            this.setRolloutDetails({
                ...this.rolloutDetails,
                ...data?.details,
                events: data?.events?.events,
                eventsTotal: data?.eventsTotal,
            });
        }
        this.setRolloutDetailsUpdatingIsLoading(false);
    }

    filterRolloutEvents(events, timestampRef) {
        const recentEvents = events.filter((event) => event.timestamp >= timestampRef);

        return {
            events: recentEvents ? recentEvents : events,
        };
    }

    async resumeRollout(id) {
        try {
            await mapUpdateApiClient.resumeRollout(id);
            this.getRolloutsGraphQL();
            this.getRolloutDetails(id);
            Notification.success(`${i18n.t("fotaone.notification.success.rollouts.resumedRollout")}`);
        } catch (err) {
            Notification.error(`${i18n.t("fotaone.notification.error.rollouts.resumedRollout")} ${id}`);
        }
    }

    async cancelRollout(id) {
        try {
            await mapUpdateApiClient.cancelRollout(id);
            this.getRolloutDetails(id);
            Notification.success(`${i18n.t("fotaone.notification.success.rollouts.canceledRollout")}`);
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.rollouts.canceledRollout")} (${id}).`);
            }
        }
    }

    async restartRollout(id) {
        try {
            await mapUpdateApiClient.restartRollout(id);
            this.getRolloutDetails(id);
            this.triggerReload();
            Notification.success(`${i18n.t("fotaone.notification.success.rollouts.restartedRollout")}`);
        } catch (err) {
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error(`${i18n.t("fotaone.notification.error.rollouts.restartedRollout")} (${id}).`);
            }
        }
    }

    setRolloutData(data) {
        this.rolloutDialogData = {
            ...this.rolloutDialogData,
            ...data,
        };
    }

    setMultipleRolloutData(data) {
        this.multipleRolloutDialogData = {
            ...this.multipleRolloutDialogData,
            ...data,
        };
    }

    setDefaultRolloutData() {
        this.rolloutDialogData = cloneObject(rolloutDialogDataMock);
    }

    setMultipleDefaultRolloutData() {
        this.multipleRolloutDialogData = {
            vehicle: null,
            vehicleIds: [],
            filesList: [],
            activeIds: [],
            interactiveMode: false,
        };
    }

    setRolloutsQuery(query) {
        this.rolloutsListFilter.query = query;
    }

    setRolloutsLimit(limit) {
        this.rolloutsListFilter.limit = limit;
    }

    setRolloutsOffset(offset) {
        this.rolloutsListFilter.offset = offset;
    }

    setRolloutsStatusFilter(statusFilter) {
        this.rolloutsListFilter.status = statusFilter;
    }

    setRolloutsSortBy(sortBy) {
        this.rolloutsListFilter.sortBy = sortBy;
    }

    setRolloutsSortDirection(sortDir) {
        this.rolloutsListFilter.sortDir = sortDir;
    }

    static instance() {
        if (!instance) {
            instance = new MapRolloutStore();
        }
        return instance;
    }
}
