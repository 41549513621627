import React from 'react';

import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';

import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants.js';
import Details from './Details';
import { useTranslation } from 'react-i18next';

const VehicleSidebar = observer((props) => {
    const { serviceStore, vehicleStore } = useStores();
    const { vin } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const close = async () => {
        navigate(`${serviceStore.currentService}/vehicles${location.search}`);
    };
    return (
        <DetailsSideBar
            title={`${t("fotaone.general.vehicleData.vehicleDetails")}`}
            onClose={close}
            width={600}
            blurEffect={vehicleStore.detailsState.isLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide}
            fly
        >
            <Details vin={vin} />
        </DetailsSideBar>
    );
});

export default VehicleSidebar;
