import React, { useEffect, useState } from 'react';
import StepLayout from './StepLayout';
import GroupTable from '../../mapupdate/vehicles/groups/GroupTable';
import VehicleSearchTable from '../../mapupdate/vehicles/overview/table/VehicleSearchTable.jsx';
import { Observer, observer } from 'mobx-react-lite';
import { PaginationStore } from '@stores/pagination.js';
import { FilterStore } from '@stores/filter.jsx';
import { useStores } from '@stores/context.jsx';
import { getTestFleetStatusFromAssets } from '@helpers/constants.jsx';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const TargetSelection = observer((props) => {
    const { assetStore } = useStores();
    const { t } = useTranslation();
    const [vehicleActive, setVehicleActive] = useState(isEmpty(props.data.group));
    const [groupActive, setGroupActive] = useState(!isEmpty(props.data.group));
    const [testFleetOnly, setTestFleetOnly] = useState(false);
    const [warningLabel, setWarningLabel] = useState('');
    const [selectedLabel, setSelectedLabel] = useState(
        props.data.group ? `${t("fotaone.mu.groupData.selectedGroup")} ${props.data.group.name} (ID: ${props.data.group.id})` : '',
    );

    const onUpdateGroup = async (row) => {
        props.onChange({
            group: row,
            vehicleIds: [],
        });

        // these are now vehicles.
        // Todo: Refactor everything, instead assetList, vehicleList.
        const assetList = await assetStore.getAssetsGraphql({ groupIdFilter: row.id });
        const vehicleIds = assetList.map(({ id }) => id);
        setSelectedLabel(`${t("fotaone.mu.groupData.selectedGroup")} ${row.name} (ID: ${row.id})`);
        props.onChange({
            group: row,
            vehicleIds,
        });
    };

    useEffect(() => {
        checkForUnreleasedFiles();
        if (props.data.group) {
            onUpdateGroup(props.data.group);
        }
    }, [vehicleActive, groupActive]);

    const onUpdateVehicle = (row) => {
        setSelectedLabel(`${t("fotaone.general.vehicleData.selectedVehicle")} ${row.name} (ID: ${row.id})`);
        const vehicleIds = [row.id];
        const { isTestFleet } = getTestFleetStatusFromAssets(row.assets);
        const testfleet = [isTestFleet];
        props.onChange({
            vehicle: true,
            vehicleIds,
            testfleet,
        });
    };

    const onSetVehicleActive = () => {
        setVehicleActive(true);
        setGroupActive(false);
    };
    const onSetGroupActive = () => {
        setVehicleActive(false);
        setGroupActive(true);
    };
    const checkForUnreleasedFiles = () => {
        if (props.data.filesList) {
            const array = props.data.filesList;
            const arrayLength = props.data.filesList.length;
            let fileCounter = 0;
            let i;
            for (i = 0; i < arrayLength; i++) {
                if (array[i].approval_state === 'UNRELEASED') {
                    fileCounter++;
                }
            }
            if (fileCounter >= 1) {
                setTestFleetOnly(true);
                setWarningLabel(
                    `${t("fotaone.mu.rolloutData.unreleasedFileWarning")}`,
                );
            }
        } else {
            if (props.data.approval_state === 'UNRELEASED') {
                setTestFleetOnly(true);
                setWarningLabel(
                    `${t("fotaone.mu.rolloutData.unreleasedFileWarning")}`,
                );
            }
        }
    };

    const renderWithGroups = () => {
        return (
            <StepLayout title={`${t("fotaone.mu.rolloutData.selectTarget")}`}>
                <p className='text-color-success'>{selectedLabel}</p>
                {warningLabel ? (
                    <div className='text-color-warning warning-wrapper'>
                        <span className='rioglyph rioglyph-warning-sign warning-sign' />
                        {warningLabel}
                    </div>
                ) : (
                    ''
                )}
                <br />
                <div>
                    <div className='row'>
                        <ul className='nav nav-tabs' id='myTab' role='tablist'>
                            <li className={vehicleActive ? 'nav-item active' : 'nav-item'}>
                                <a
                                    className='nav-link'
                                    id='vehicle'
                                    data-toggle='tab'
                                    role='tab'
                                    onClick={onSetVehicleActive}
                                >
                                    {`${t("fotaone.general.vehicleData.vehicle.one")}`}
                                </a>
                            </li>
                            <li className={groupActive ? 'nav-item active' : 'nav-item'}>
                                <a
                                    className='nav-link'
                                    id='group'
                                    data-toggle='tab'
                                    role='tab'
                                    onClick={onSetGroupActive}
                                >
                                    {`${t("fotaone.mu.groupData.group.one")}`}
                                </a>
                            </li>
                        </ul>
                        <div className='tab-content' id='myTabContent'>
                            {vehicleActive && (
                                <div id='vehicle' role='tabpanel' aria-labelledby='vehicle'>
                                    <VehicleSearchTable
                                        rowClicked={onUpdateVehicle}
                                        testFleetOnly={testFleetOnly}
                                        paginationStore={new PaginationStore()}
                                        filterStore={new FilterStore()}
                                        className={`${props.className} vehicle-table`}
                                    />
                                </div>
                            )}
                            {groupActive && (
                                <div id='group' role='tabpanel' aria-labelledby='group'>
                                    <Observer>
                                        {() => (
                                            <GroupTable
                                                rowClicked={onUpdateGroup}
                                                rolloutGroups={true}
                                                paginationStore={new PaginationStore()}
                                                filterStore={new FilterStore()}
                                            />
                                        )}
                                    </Observer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </StepLayout>
        );
    };

    const renderWithoutGroup = () => {
        return (
            <StepLayout title={`${t("fotaone.mu.rolloutData.selectTarget")}`}>
                <p className='text-color-success'>{selectedLabel}</p>
                {warningLabel ? (
                    <div className='text-color-warning warning-wrapper'>
                        <span className='rioglyph rioglyph-warning-sign warning-sign' />
                        {warningLabel}
                    </div>
                ) : (
                    ''
                )}
                <br />
                <div>
                    <div
                        className={vehicleActive ? 'tab-pane show active' : 'tab-pane'}
                        id='vehicle'
                        role='tabpanel'
                        aria-labelledby='vehicle'
                    >
                        <div>
                            <div>
                                <VehicleSearchTable
                                    rowClicked={onUpdateVehicle}
                                    testFleetOnly={testFleetOnly}
                                    paginationStore={new PaginationStore()}
                                    filterStore={new FilterStore()}
                                    className={`${props.className} vehicle-table`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </StepLayout>
        );
    };

    if (props.multipleRollout) {
        return renderWithGroups();
    } else {
        return renderWithoutGroup();
    }
});

export default TargetSelection;
