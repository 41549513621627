import { observable, makeObservable } from 'mobx';
import { client as restClient } from '@api/activation-client';
import { ForbiddenError } from '@helpers/errors/error.forbidden';
/* eslint-disable no-unused-vars */
import StateMachineStore from './stateMachine'; //NOSONAR
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
/* eslint-enable no-unused-vars */
import i18n from 'i18next';

let instance; // singleton instance

export class ActivationStore {
    stateMachine = {};

    /**
     *
     * @param {StateMachineStore} stateMachine
     */
    constructor(stateMachine) {
        makeObservable(this, {
            stateMachine: observable,
        });

        this.stateMachine = stateMachine;
    }

    async activate(vehicleId) {
        try {
            this.stateMachine.setIsLoadingState();
            await restClient.put(`/activations/activate/${vehicleId}`);
            Notification.success(
                vehicleId
                    ? `${i18n.t('fotaone.notification.success.assets.activationRequest')} ${i18n.t(
                          'fotaone.general.vehicleData.vehicleId'
                      )}: ${vehicleId}`
                    : `${i18n.t('fotaone.notification.success.assets.activationRequest')}`
            );
            this.stateMachine.setLoadedState();
        } catch (err) {
            this.handleError(err, vehicleId);
            this.stateMachine.setErrorState();
        }
    }

    async deactivate(vehicleId) {
        try {
            this.stateMachine.setIsLoadingState();
            await restClient.put(`/activations/deactivate/${vehicleId}`);
            Notification.success(
                vehicleId
                    ? `${i18n.t('fotaone.notification.success.assets.deactivationRequest')} ${i18n.t(
                          'fotaone.general.vehicleData.vehicleId'
                      )}: ${vehicleId}`
                    : `${i18n.t('fotaone.notification.success.assets.deactivationRequest')}`
            );
            this.stateMachine.setLoadedState();
        } catch (err) {
            this.handleError(err, vehicleId);
            this.stateMachine.setErrorState();
        }
    }
    async reset(vehicleId) {
        try {
            this.stateMachine.setIsLoadingState();
            await restClient.put(`/activations/reset/${vehicleId}`);
            Notification.success(
                vehicleId
                    ? `${i18n.t('fotaone.notification.success.assets.resetRequest')} ${i18n.t(
                          'fotaone.general.vehicleData.vehicleId'
                      )}: ${vehicleId}`
                    : `${i18n.t('fotaone.notification.success.assets.resetRequest')}`
            );
            this.stateMachine.setLoadedState();
        } catch (err) {
            this.handleError(err, vehicleId);
            this.stateMachine.setErrorState();
        }
    }

    /**
     *
     * @param {Error} error
     * @param {string} vehicleId
     */
    handleError(error, vehicleId) {
        if (error instanceof ForbiddenError) {
            Notification.error(
                `${i18n.t('fotaone.notification.error.assets.overrideRequest')} ${i18n.t(
                    'fotaone.general.vehicleData.vehicleId'
                )}: ${vehicleId}`
            );
        } else {
            if (error.response) {
                Notification.error(`${error.response.statusText.toUpperCase()} - ${error}`);
            } else {
                Notification.error(
                    `${i18n.t('fotaone.notification.error.assets.overrideRequest')} ${i18n.t(
                        'fotaone.general.vehicleData.vehicleId'
                    )}: ${vehicleId}`
                );
            }
        }
    }

    static instance(stateMachine) {
        if (!instance) {
            instance = new ActivationStore(stateMachine);
        }
        return instance;
    }
}
