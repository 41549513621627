import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { get } from 'lodash';
import prettyBytes from 'pretty-bytes';
import { formatDate } from '@helpers/date.js';
import { blockedMmtVersions } from '../../../mapupdateHelper/constants.jsx';
import { useTranslation } from 'react-i18next';

const VehicleDetailsMapState = observer(() => {
    const { assetStore } = useStores();
    const { t } = useTranslation();
    const mmtHeadUnitVersion = get(assetStore?.assetDetails, [
        'vehicles',
        '0',
        'map_state',
        '0',
        'software_version_head_unit',
    ]);

    return (
        get(assetStore.assetDetails, ['vehicles', '0', 'map_state'], []).length > 0 && (
            <>
                <h5>{`${t("fotaone.mu.mapState.mapState")}`}</h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.general.status")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, [
                                    'vehicles',
                                    '0',
                                    'status_request_progress',
                                    '0',
                                    'update_status',
                                ])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.mapState.statusRequestedAt")}`}</label>
                            <span className='text-primary'>
                                {formatDate(
                                    get(assetStore.assetDetails, [
                                        'vehicles',
                                        '0',
                                        'status_request_progress',
                                        '0',
                                        'requested_at',
                                    ]),
                                )}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.mapState.softwareVersionNavCore")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, [
                                    'vehicles',
                                    '0',
                                    'map_state',
                                    '0',
                                    'software_version_nav_core',
                                ])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.mapState.availableStorage")}`}</label>
                            <span className='text-primary'>
                                {prettyBytes(
                                    Number(
                                        get(
                                            assetStore.assetDetails,
                                            ['vehicles', '0', 'map_state', '0', 'available_storage_size_megabytes'],
                                            0,
                                        ) * 1000000,
                                    ),
                                    { locale: 'de' },
                                )}
                            </span>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.regionData.region.one")}`}</label>
                            <span className='text-primary'>
                                {get(assetStore.assetDetails, ['vehicles', '0', 'map_state', '0', 'region'])}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.mapState.statusChangedAt")}`}</label>
                            <span className='text-primary'>
                                {formatDate(
                                    get(assetStore.assetDetails, [
                                        'vehicles',
                                        '0',
                                        'request_status_progress',
                                        '0',
                                        'updated_at',
                                    ]),
                                )}
                            </span>
                        </div>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.mu.mapState.softwareVersionHeadUnit")}`}</label>
                                {blockedMmtVersions.indexOf(mmtHeadUnitVersion) < 0 ? (
                                        <div className='margin-bottom-10'>
                                            <div className="label label-success text-size-12">{mmtHeadUnitVersion}</div>
                                        </div>
                                    ):(
                                        <div className='margin-bottom-10'>
                                            <div className="label label-danger text-size-12">
                                                <span className="rioglyph rioglyph-warning-sign text-size-16 margin-right-10"/>
                                                {mmtHeadUnitVersion}
                                            </div>
                                            <div className='margin-bottom-10 text-size-10'>
                                                {`${t("fotaone.mu.mapState.headUnitNoMapUpdate")}`}
                                            </div>
                                        </div>
                                    )
                                }
                        </div>
                    </div>
                </div>
            </>
        )
    );
});

export default VehicleDetailsMapState;
