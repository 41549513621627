import { observer } from 'mobx-react-lite';
import AspectRatioPlaceholder from '@rio-cloud/rio-uikit/lib/es/AspectRatioPlaceholder';
import ImagePreloader from '@rio-cloud/rio-uikit/lib/es/ImagePreloader';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const WelcomeMenu = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='text-size-h2 text-thin'>
                {t("fotaone.general.welcomeTo")} FOTA Admin
            </div>
            <Divider />
            <div className="margin-bottom-25">
              <ImagePreloader
                src='https://content.mannow.rio.cloud/assets/dist/carousel/MAN-Now-Efficiency.png'>
                  {({ status, image }) => {
                      if (status === ImagePreloader.STATUS_LOADED) {
                          return(
                              <>
                                  <div style={{position: 'absolute', top: '22%', left: '30%'}}>
                                      <div className='text-size-h3 text-normal'>
                                          {`${t("fotaone.general.welcomeTo")}`}
                                      </div>
                                      <div className='text-size-h2 text-medium'>
                                          FOTA ADMIN
                                      </div>
                                  </div>
                                  <img src={image.src} className='img-responsive ani-fade-in' alt='mainImage'/>
                              </>
                          )
                      }
                      if (status === ImagePreloader.STATUS_FAILED) {
                          return null;
                      }
                      return (
                          <AspectRatioPlaceholder width={1000} height={562}>
                              <div className='display-grid place-items-center positon-absolute inset-0'>
                                  <Spinner isInverse={false} />
                              </div>
                          </AspectRatioPlaceholder>
                      );
                  }}
              </ImagePreloader>
            </div>
            <div className='margin-bottom-15'>
              {`${t("fotaone.general.serviceMenu.welcomeSection.introductionText")}`}
            </div>
            <ul>
                <li>
                  <strong className='text-size-h5'>Map Update (MU)</strong>: {' '}
                  {t("fotaone.general.serviceMenu.mapUpdateSection.serviceShortDesc")}
                </li>
                <li>
                  <strong className='text-size-h5'>Online Traffic (OT)</strong>: {' '}
                  {t("fotaone.general.serviceMenu.onlineTrafficSection.serviceShortDesc")}
                </li>
                <li><strong className='text-size-h5'>Feature Dashboard (FD)</strong>: {' '}
                  {t("fotaone.general.serviceMenu.featureDashboardSection.serviceShortDesc")}
                </li>
                <li><strong className='text-size-h5'>Remote Message (RM)</strong>: {' '}
                  {t("fotaone.general.serviceMenu.remoteMessageSection.serviceShortDesc")}
                </li>
            </ul>
            <p >
                {t("fotaone.general.serviceMenu.welcomeSection.furtherInfoText")}
            </p>
        </>
    );
};

export default observer(WelcomeMenu);