import axios from 'axios';
import config from '@src/config.js';
import { configureAxiosClient } from '@api/api-utils.js';

let client;

const init = () => {
  client = axios.create({ baseURL:  config.FEATUREDASHBOARDPROXY_API_URL});
  return configureAxiosClient(client);
};

export { client, init }