import React from 'react';

/**
 *
 * @param {{color:string,state:string,title:string}} props
 * @returns
 */
export default function Badge(props) {
    const { color, state, title, ...layoutProps } = props;
    return (
        <span className={color ? `badge ${color}` : ''} title={title} {...layoutProps}>
            {state}
        </span>
    );
}
