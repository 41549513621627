import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';
import DetailsGeneral from './DetailsGeneral.jsx'
import DetailsDevice from './DetailsDevice.jsx'
import DetailsFeatures from './DetailsFeatures.jsx'
import DetailsFeatureFlag from './DetailsFeatureFlag.jsx'

const VehicleDetails = observer((props) => {
    const { featureDashboardStore } = useStores();
    const { deviceId, vin } = props;

    useEffect(() => {
        const fetchData = async () => {
            await featureDashboardStore.getDetailsById(deviceId, vin);
        };
        fetchData();
    }, [deviceId]);

    return (
        <div className='vehicle-feature-details'>
                <div className='details'>
                    <DetailsGeneral />
                    <DetailsDevice />
                    <DetailsFeatures />
                    <DetailsFeatureFlag />
            </div>
        </div>
    );
});

export default VehicleDetails;