import { useState } from "react";
import { useStores } from '@stores/context.jsx';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Services } from '@stores/service.js';
import Collapse from '@rio-cloud/rio-uikit/lib/es/Collapse';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

const MessageDetailsMessage = observer(() => {
    const { remoteMessageStore, authStore } = useStores();
    const [showCollapse, setShowCollapse] = useState(false);
    const { t } = useTranslation();

    const messageIsLocked = !authStore.hasAccess(Services.RemoteMessage);

    return (
        remoteMessageStore.messageDetails && (
            <div data-testid="message-details-message-section">
                <h5
                    className="cursor-pointer"
                    onClick={() => messageIsLocked ? null : setShowCollapse(state => !state)}
                >
                    <span className="rioglyph rioglyph-chat text-size-20 margin-right-10"/>
                    <span className="margin-right-5">{`${t("fotaone.rm.messageData.message.one")}`}</span>
                    {
                        messageIsLocked ? (
                            <OverlayTrigger
                                placement='right'
                                overlay={
                                    <Tooltip id='tooltip' allowOnTouch>
                                        <span>{`${t("fotaone.auth.permissionInsufficient")}`}</span>
                                    </Tooltip>
                                }
                            >
                                <span className="rioglyph rioglyph-lock"/>
                            </OverlayTrigger>
                        ):(
                            <span
                                className={
                                    showCollapse
                                    ? "rioglyph rioglyph-chevron-down"
                                    : "rioglyph rioglyph-chevron-right"
                                }
                            />
                        )
                    }
                </h5>
                <div className="breakline margin-bottom-20" />
                <Collapse open={messageIsLocked ? false : showCollapse} unmountOnExit>
                    <div>
                        <div className="margin-bottom-10">
                            <label className="display-block">{`${t("fotaone.rm.title")}`}</label>
                            <span className="text-primary">{get(remoteMessageStore.messageDetails, ['title'])}</span>
                        </div>
                        <div className="margin-bottom-10">
                            <label className="display-block">{`${t("fotaone.rm.content")}`}</label>
                            <span className="text-primary">{get(remoteMessageStore.messageDetails, ['content'])}</span>
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    );
});

export default MessageDetailsMessage;
