import React, { useState } from 'react';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import TableToolbar from '@rio-cloud/rio-uikit/lib/es/TableToolbar';
import TableSettingsDialog from '@rio-cloud/rio-uikit/lib/es/TableSettingsDialog';
import { useTranslation } from 'react-i18next';

function TableToolbarComponent({
    showSearch,
    toolbarElement,
    toolbarElementLeft,
    showTableSettings,
    searchValue,
    ...props
}) {
    const { t } = useTranslation();
    const [showTableSettingsDialog, setShowTableSettingsDialog] = useState(false);

    const {
        columnLabels,
        defaultColumnOrder,
        disabledColumns,
        setColumnOrder,
        setHiddenColumns,
        setColumnDetails,
        columnDetails,
        columnOrder,
        hiddenColumns,
    } = props;

    const handleColumnChange = (order, hidden, details = columnDetails) => {
        setColumnOrder(order);
        setHiddenColumns(hidden);
        setColumnDetails(details);
    };

    const toggleTableSettingsDialog = () => {
        setShowTableSettingsDialog(!setShowTableSettingsDialog);
    };

    const handleColumnDetailsChange = (column, columnsDetails) => {
        const updatedColumnsDetails = { ...columnDetails };
        updatedColumnsDetails[column] = columnsDetails;
        setColumnDetails(updatedColumnsDetails);
    };

    return (
        <TableToolbar key='toolbar'>
            {showSearch && (
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-left'>
                        <div className='table-toolbar-column'>
                            <TableSearch
                                value={searchValue}
                                onChange={props.handleSearchValueChange}
                                placeholder={`${t("fotaone.general.table.tableSearch")}`}
                            />
                        </div>
                        {toolbarElementLeft}
                    </div>
                    <div className='table-toolbar-group-right'>
                        {toolbarElement}
                        <div className='table-toolbar-column margin-left-10'>
                            {showTableSettings && (
                                <button
                                    type='button'
                                    className='btn btn-default btn-icon-only'
                                    onClick={() => setShowTableSettingsDialog(!showTableSettingsDialog)}
                                >
                                    <span className='rioglyph rioglyph-cog' />
                                </button>
                            )}
                        </div>
                        {showTableSettings && (
                            <TableSettingsDialog
                                show={showTableSettingsDialog}
                                title={`${t("fotaone.general.table.tableSettings")}`}
                                onHide={toggleTableSettingsDialog}
                                onColumnChange={handleColumnChange}
                                defaultColumnOrder={defaultColumnOrder}
                                defaultHiddenColumns={[]}
                                columnOrder={columnOrder}
                                hiddenColumns={hiddenColumns}
                                columnLabels={columnLabels}
                                disabledColumns={disabledColumns}
                                closeButtonText={`${t("fotaone.general.table.close")}`}
                                resetButtonText={`${t("fotaone.general.table.resetToDefault")}`}
                                searchPlaceholder={`${t("fotaone.general.table.columnSearch")}`}
                                notFoundMessage={`${t("fotaone.general.table.noColumnFound")}`}
                                columnsDetails={columnDetails}
                                autoLabel={'Auto'}
                                onColumnDetailsChange={handleColumnDetailsChange}
                                immediateChange
                            />
                        )}
                    </div>
                </div>
            )}
        </TableToolbar>
    );
}

export default TableToolbarComponent;
