import { action, makeObservable, observable } from 'mobx';
import fileDownload from 'js-file-download';
/* eslint-disable no-unused-vars */
import StateMachineStore from './stateMachine'; //NOSONAR
import { get, set, isNil } from 'lodash';
import { cloneObject, flattenObj } from '@helpers/object';
import { vehicleMainTable } from '@components/Onlinetraffic/Vehicle/Vehicle.constants';
import { getDateAndHour } from '@helpers/date';
import { Services } from './service.js';
import { assetTableData, installedMapsTableData } from '@components/mapupdate/vehicles/overview/Vehicle.constants.jsx';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';

let instance; // singleton instance
export const SupportedFileTypes = {
    CSV: 'csv',
};

/**
 * @typedef ExportAsFileParams
 * @type {object}
 * @property {string} type
 * @property {string} feature
 * @property {string} [page] - Optional page parameter
 * @property {Array<{key:string,title:string }>} headers
 * @property {string} query
 * @property {Array<any>} filters
 * @property {string} sortBy
 * @property {string} sortDir
 */

export class ExportsStore {
    stateMachine = {};
    vehicleStore = {};
    assetStore = {};
    /**
     *
     * @param {StateMachineStore} stateMachine
     * @param {VehicleStore} vehicleStore
     * @param {AssetStore} assetStore
     */
    constructor(stateMachine, vehicleStore, assetStore) {
        makeObservable(this, {
            exportAsFile: action,
            stateMachine: observable,
        });

        this.stateMachine = stateMachine;
        this.vehicleStore = vehicleStore;
        this.assetStore = assetStore;
    }

    /**
     *
     * @param {ExportAsFileParams} params
     */
    async exportAsFile(params) {
        const { type, feature, page, headers, query, filters, sortBy, sortDir } = params;
        this.stateMachine.setIsLoadingState();
        try {
            const data = await this.getData({ feature, page, headers, query, filters, sortBy, sortDir });
            const { file, fileName } = this.getFile({ type, data, headers, feature });
            fileDownload(file, fileName);
            this.stateMachine.setLoadedState();
        } catch (err) {
            this.stateMachine.setErrorState();
            if (err.response) {
                Notification.error(`${err.response.data.message.toUpperCase()} - ${err}`);
            } else {
                Notification.error('Could not export the data.');
            }
        }
    }

    async getData(params) {
        const { feature, page, headers, query, filters, sortBy, sortDir } = params;
        let data = null;
        switch (feature) {
            case Services.OnlineTraffic: {
                const { list } = await this.getOnlineTrafficData({ query, filters, sortBy, sortDir });
                data = this.parseListToCsvFormat(list, cloneObject(vehicleMainTable.columns), headers);
                break;
            }
            case Services.MapUpdate: {
                if (page === 'installedMapPage') {
                    const installedMapData = await this.getInstalledMapsData({query, filters, sortBy, sortDir});
                    data = this.parseListToCsvFormat(installedMapData, cloneObject(installedMapsTableData.columns), headers);
                   break;
                }
                const mapUpdateData = await this.getMapUpdateData({ query, filters, sortBy, sortDir });
                data = this.parseListToCsvFormat(mapUpdateData, cloneObject(assetTableData.columns), headers);
                break;
            }
            default:
                break;
        }
        if (isNil(data)) throw new Error('No data found');
        return data;
    }

    async getMapUpdateData(params) {
        const { query, filters, sortBy, sortDir } = params;

        const mapUpdateData = await this.assetStore.getAssetsGraphql({
            query,
            filters,
            sortBy,
            sortDir,
            limit: null,
            offset: null,
        });

        return mapUpdateData;
    }

    async getOnlineTrafficData(params) {
        const { query, filters, sortBy, sortDir } = params;
        const onlineTrafficData = await this.vehicleStore.searchVehicles({
            query,
            filters,
            sortBy,
            sortDir,
            limit: null,
            offset: null,
        });
        return onlineTrafficData;
    }

    async getInstalledMapsData(params) {
        const { query, sortBy, sortDir } = params;
        const installedMaps =  await this.assetStore.getVehiclesInstalledMapsGraphql({
            query,
            sortBy,
            sortDir,
            limit: null,
            offset: null,
        });
        return installedMaps
    }

    parseExtensionColumnsToCsvFormat(data) {
        const { rowData, currentColumn, columns, columnsToErase, headers, csvData } = data;
        const value = get(rowData, currentColumn.key);
        const extensionColumns = currentColumn.extensionColumns(value, rowData);

        Object.values(extensionColumns).forEach((extensionColumn) => {
            if (!headers.some((header) => header.key === extensionColumn.key)) {
                headers.push({ key: extensionColumn.key, title: extensionColumn.title });
            }
            set(csvData, extensionColumn.key, extensionColumn.content);
        });

        if (headers.some((header) => header.key === currentColumn.key)) {
            const index = headers.findIndex((value) => (value ? currentColumn.key === value.key : false));
            headers.splice(index, 1);
        }

        if (
            columns.some((value) => value.key === currentColumn.key) &&
            columnsToErase.some((value) => value.key === currentColumn.key)
        ) {
            columnsToErase.push(currentColumn);
        }
    }

    withColumnParserToCsvFormat(item, column, parsedObject) {
        const value = get(item, column.key);
        const { state } = column.parser(value, item, column.key);
        set(parsedObject, column.key, state);
    }

    parseItemToCsvFormat(item, columns, columnsToErase, headers) {
        const flatObj = flattenObj(item, undefined);
        const parsedObject = cloneObject(flatObj);

        columns.forEach((column) => {
            if (
                !(
                    column.exportable == null ||
                    column.exportable ||
                    columnsToErase.some((value) => value.key === column.key)
                )
            ) {
                columnsToErase.push(column);
                const index = headers.findIndex((element) => (element ? column.key === element.key : false));
                headers.splice(index, 1);
                return;
            }

            if (column.parser) {
                this.withColumnParserToCsvFormat(item, column, parsedObject);
            }

            if (column.extensionColumns && headers) {
                this.parseExtensionColumnsToCsvFormat({
                    rowData: item,
                    currentColumn: column,
                    columns: columns,
                    columnsToErase: columnsToErase,
                    headers: headers,
                    csvData: parsedObject,
                });
            }
        });

        return {
            parsedObject: parsedObject,
            columnsToErase: columnsToErase,
        };
    }

    parseListToCsvFormat(list, columns, headers = undefined) {
        const columnsToErase = [];

        if (list && Array.isArray(list) && columns && Array.isArray(columns)) {
            const parsedList = list.map((item) => {
                const result = this.parseItemToCsvFormat(item, columns, columnsToErase, headers);
                return result.parsedObject;
            });

            columnsToErase.forEach((column) => {
                const index = columns.findIndex((value) => (value ? column.key === value.key : false));
                columns.splice(index, 1);
            });

            return parsedList;
        } else {
            return [];
        }
    }

    getFile(params) {
        const { type, data, headers, feature } = params;
        const response = {
            file: null,
            fileName: '',
        };
        switch (
            type //NOSONAR
        ) {
            case SupportedFileTypes.CSV:
                response.file = this.convertToCSV(data, headers);
                response.fileName = this.getFileName(type, feature);
                break;
            default:
                break;
        }
        return response;
    }

    /**
     *
     * @param {Array<{[key:string]: string | boolean | number}>} json
     * @param {Array<{key:string, title: string}>} header
     * @param {';'} separator
     * @returns {string}
     */
    convertToCSV(json, header, separator = ';') {
        let csv = '';
        if (Array.isArray(json) && Array.isArray(header) && header.length > 0) {
            csv += `${header
                .map((h) => (h.alternateExportTitle ? h.alternateExportTitle : h.title))
                .join(separator)}\r\n`;
            for (const line of json) {
                const values = header.map((h) => line[h.key]);
                csv += `${values.join(separator)}\r\n`;
            }
        }
        return csv;
    }
    getFileName(type, feature) {
        let fileName = '';
        const date = getDateAndHour();
        switch (
            type //NOSONAR
        ) {
            case SupportedFileTypes.CSV:
                fileName = `${feature.replace('/', '')}_${date}.csv`;
                break;
            default:
                break;
        }

        return fileName;
    }
    static instance(stateMachine, vehicleStore, assetStore) {
        if (!instance) {
            instance = new ExportsStore(stateMachine, vehicleStore, assetStore);
        }
        return instance;
    }
}
