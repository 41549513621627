import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import { useTranslation } from 'react-i18next';

const ProgressBarLabels = (props) => {
  const { status } = props;
  const { t } = useTranslation();

  const labels = {
    draft: {
      icon: <i className="rioglyph rioglyph-envelope" />,
      label: `${t("fotaone.rm.messageData.messageStatus.DRAFT")}`,
    },
    pending_approval: {
      icon: (
        <>
          <i className="rioglyph rioglyph-thumbs-up" />
          <i className="rioglyph rioglyph-thumbs-down" />
        </>
      ),
      label: `${t("fotaone.rm.messageData.messageStatus.PENDING_APPROVAL")}`,
    },
    approved: {
      icon: <i className="rioglyph rioglyph-thumbs-up" />,
      label: `${t("fotaone.rm.messageData.messageStatus.APPROVED")}`,
    },
    rejected: {
      icon: <i className="rioglyph rioglyph-thumbs-down" />,
      label: `${t("fotaone.rm.messageData.messageStatus.REJECTED")}`,
    },
    sent: {
      icon: <i className="rioglyph rioglyph rioglyph-send" />,
      label: `${t("fotaone.rm.messageData.messageStatus.SENT")}`,
    },
    sending_failed: {
      icon: <i className="rioglyph rioglyph-warning-sign" />,
      label: `${t("fotaone.rm.messageData.messageStatus.SENDING_FAILED")}`,
    },
  };

  const {
    draft,
    pending_approval,
    approved,
    rejected,
    sent,
    sending_failed,
  } = labels;

  switch (status) {
    case "DRAFT":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={0}
          disableFollowingPages={0}
          className={"pointer-events-none"}
          labels={[draft, pending_approval, sent]}
        />
      );
    case "APPROVED":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={1}
          disableFollowingPages={1}
          className={"pointer-events-none"}
          labels={[draft, approved, sent]}
        />
      );
    case "REJECTED":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={1}
          disableFollowingPages={1}
          className={"pointer-events-none"}
          labels={[draft, rejected]}
        />
      );
    case "SENT":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={2}
          disableFollowingPages={2}
          className={"pointer-events-none"}
          labels={[draft, approved, sent]}
        />
      );
    case "PENDING_APPROVAL":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={1}
          disableFollowingPages={1}
          className={"pointer-events-none"}
          labels={[draft, pending_approval, sent]}
        />
      );
    case "SENDING_FAILED":
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={3}
          disableFollowingPages={3}
          className={"pointer-events-none"}
          labels={[draft, approved, sent, sending_failed]}
        />
      );
    default:
      return (
        <SteppedProgressBar
          variant={SteppedProgressBar.VARIANT_CIRCLE}
          selectedStepNumber={0}
          disableFollowingPages={0}
          className={"pointer-events-none"}
          labels={[draft, pending_approval, sent]}
        />
      );
  }
};

export default ProgressBarLabels;
