import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import { useTranslation } from 'react-i18next';
import Divider from '@rio-cloud/rio-uikit/lib/es/Divider';

const FeedbackReceived = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-size-h2 text-thin">
        {t('fotaone.general.serviceMenu.feedbackSection.headline')}
      </div>
      <Divider />
      <CustomState
        headline={
          <span className="text-bold text-color-darkest">
            {t('fotaone.general.serviceMenu.feedbackSection.feedbackReceived.replyHeader')}
          </span>
        }
        message={t('fotaone.general.serviceMenu.feedbackSection.feedbackReceived.replyContent')}
        icons={[
          {
            name: 'rioglyph rioglyph-ok-sign',
            className: 'text-size-400pct',
            color: 'text-color-highlight',
          },
        ]}
      />
    </>
  );
};

export default FeedbackReceived;