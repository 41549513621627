import { useEffect, useState, useCallback } from 'react';
import SteppedProgressBar from '@rio-cloud/rio-uikit/lib/es/SteppedProgressBar';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import { debounce } from 'lodash';
import { useStores } from '@stores/context.jsx';
import {
    getDefaultLanguageValue,
    getDefaultSeverityValue,
    getDefaultTypeValue,
    renderLanguage,
    renderSeverity,
    severityRender,
    renderType,
    typeRender,
    serialNumberRenderer,
} from '@components/RemoteMessage/RemoteMessageHelper/utils.jsx';
import { useTranslation } from 'react-i18next';
import VehiclesMessageTable from '../../RemoteMessage/Vehicles/VehiclesMessageTable';

const CreateMessageDialog = () => {
    const { remoteMessageStore, filterStore, paginationStore, authStore } = useStores();
    const [selectedStep, setSelectedStep] = useState(0);
    const [disableFollowingPages, setDisableFollowingPages] = useState();
    const { t } = useTranslation();
    const [messageTitle, setMessageTitle] = useState(remoteMessageStore.editMyMessage ? remoteMessageStore.editMyMessage.title : '');
    const [messageType, setMessageType] = useState(getDefaultTypeValue());
    const [messageSeverity, setMessageSeverity] = useState(getDefaultSeverityValue());
    const [messageLanguage, setMessageLanguage] = useState(getDefaultLanguageValue());
    const [messageContent, setMessageContent] = useState('');
    const [messageKbaNumber, setMessageKbaNumber] = useState('');
    const [messageId, setMessageId] = useState('');
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState({});
    const query = filterStore?.query;
    const activePage = paginationStore?.activePage;
    const filters = filterStore?.filters;
    const limit = paginationStore?.limit;
    const offset = paginationStore?.offset;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;
    const profileEmail = authStore.user?.profile.email;

    const getVehicles = useCallback(
        debounce(async (params) => {
            await remoteMessageStore.getVehicles(params);
            paginationStore.setItemsLength(remoteMessageStore.vehicleTotal);
        }, 200),
        [],
    );
    
    useEffect(() => {
        getVehicles({
            query,
            limit,
            offset,
            filters,
        });
    }, [
            query,
            limit,
            activePage,
            getVehicles,
            offset,
            filters,
            remoteMessageStore,
    ]);

    const onHide = () => {
        remoteMessageStore.setEditMyMessage(null);
        remoteMessageStore.setMessageDialogOpen(false);
        setMessageTitle('');
        setMessageType(getDefaultTypeValue());
        setMessageSeverity(getDefaultSeverityValue());
        setMessageLanguage(getDefaultLanguageValue());
        setMessageContent('');
        setMessageKbaNumber('');
        setSelectedStep(0);
        setLoading(false);
        setTarget({});
    };

    const handlePageNext = () => {
        setSelectedStep(selectedStep + 1);
        setDisableFollowingPages(3);
    };

    const handlePagePrevious = () => setSelectedStep(selectedStep - 1);

    const handleSelectedChanged = selectedKey => setSelectedStep(selectedKey);
    const handleDisableFollowingPages = pageNumber => setDisableFollowingPages(pageNumber - 1);

    const saveDraft = async (toApprove) => {
        setLoading(true);
        const messageData = {
            title: messageTitle,
            content: messageContent,
            severity: messageSeverity,
            type: messageType,
            kba_number: messageKbaNumber,
            language_code: messageLanguage,
            devices: target.id ? [target.id] : [],
        };
        try {
            let response;
            let approvalResponse = {};
            if (remoteMessageStore.editMyMessage) {
                messageData.id = messageId;
                response = await remoteMessageStore.updateMessage(messageData);
            } else {
                response = await remoteMessageStore.createMessage(messageData);
            }
            if (response?.status === 201) {
                if (response.data.id) {
                    if (toApprove) {
                        approvalResponse = await remoteMessageStore.requestApproval({
                            messageId: response.data.id, query, sortBy, sortDir, limit, offset, profileEmail
                        });
                        if (approvalResponse?.status !== 201) {
                            setLoading(false);
                            return;
                        }
                    }
                }
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            onHide();
        }
    };    

    const requestApproval = () =>{
        const toApprove = true;
        saveDraft(toApprove);
    }

    const onUpdateTarget = (target) =>{
        setTarget(target.target)
    }

    const pageContent = [
        <MessageCreation 
            key='firstStep'
            pageNumber={1}
            onClickNext={handlePageNext}
            saveDraft={saveDraft}
            onDisableFollowingPages={handleDisableFollowingPages}
            setMessageTitle={setMessageTitle}
            setMessageType={setMessageType}
            setMessageSeverity={setMessageSeverity}
            setMessageLanguage={setMessageLanguage}
            setMessageContent={setMessageContent}
            setMessageKbaNumber={setMessageKbaNumber}
            setMessageId={setMessageId}
            messageTitle={messageTitle}
            messageType={messageType}
            messageSeverity={messageSeverity}
            messageKbaNumber={messageKbaNumber}
            messageLanguage={messageLanguage}
            messageContent={messageContent}
            loading={loading}
            t={t}
        />,
        <TargetSelection
            key='secondStep'
            pageNumber={2}
            onClickNext={handlePageNext}
            onClickPrevious={handlePagePrevious}
            saveDraft={saveDraft}
            onUpdateTarget={onUpdateTarget}
            query={query}
            target={target}
            loading={loading}
            t={t}
        />,
        <Overview
            key='thirdStep'
            pageNumber={3}
            onClickPrevious={handlePagePrevious}
            requestApproval={requestApproval}
            saveDraft={saveDraft}
            target={target}
            messageTitle={messageTitle}
            messageType={messageType}
            messageSeverity={messageSeverity}
            messageKbaNumber={messageKbaNumber}
            messageLanguage={messageLanguage}
            messageContent={messageContent}
            loading={loading}
            t={t}
        />,
    ];

    const renderBody = () => {
        return (
            <div className='steppedProgressBarExample'>
                <div className='margin-bottom-25'>
                    <SteppedProgressBar
                        variant={SteppedProgressBar.VARIANT_CIRCLE}
                        selectedStepNumber={selectedStep}
                        onSelectedChanged={handleSelectedChanged}
                        disableFollowingPages={disableFollowingPages}
                        mobileBreakpoint={false}
                        labels={[
                            {
                                icon: <i className='rioglyph rioglyph-envelope' />,
                                label: remoteMessageStore.editMyMessage ?
                                    t('fotaone.rm.messageData.createMessage.editMessageLabel') :
                                    t('fotaone.rm.messageData.createMessage.createMessageLabel'),
                            },
                            {
                                icon: <i className='rioglyph rioglyph-truck' />,
                                label: t('fotaone.rm.messageData.createMessage.selectTarget'),
                            },
                            {
                                icon: <i className='rioglyph rioglyph-ok' />,
                                label: t('fotaone.general.overview'),
                            },
                        ]}
                    />
                </div> 
                {pageContent[selectedStep]}
            </div>
        );
    };

    return (
        <div className='table-toolbar-column pull-right'>
          <button type='button' className='btn btn-primary' onClick={()=>{remoteMessageStore.setMessageDialogOpen(true)}} >
              {t('fotaone.rm.messageData.createMessage.createMessageLabel')}
          </button>
          <Dialog
            show={remoteMessageStore.messageDialogOpen}
            title={remoteMessageStore.editMyMessage ?
                t('fotaone.rm.messageData.createMessage.editMessageLabel') :
                t('fotaone.rm.messageData.createMessage.createMessageLabel')
            }
            body={renderBody()}
            onHide={onHide}
            showCloseButton={true}
          />
        </div>
      );
};

export default CreateMessageDialog;

const MessageCreation = props => {
    const { remoteMessageStore } = useStores();
    const { pageNumber, onClickNext, saveDraft, onDisableFollowingPages } = props;
    const { messageTitle, messageType, messageSeverity, messageKbaNumber, messageLanguage, messageContent } = props;
    const { setMessageTitle, setMessageType, setMessageSeverity, setMessageLanguage, setMessageContent, setMessageKbaNumber, setMessageId, loading, t } = props;

    useEffect(() => {
        if (remoteMessageStore.editMyMessage) {
            setMessageTitle(remoteMessageStore.editMyMessage.title);
            setMessageType(remoteMessageStore.editMyMessage.type);
            setMessageSeverity(remoteMessageStore.editMyMessage.severity);
            setMessageKbaNumber(remoteMessageStore.editMyMessage.kba_number);
            setMessageLanguage(remoteMessageStore.editMyMessage.language_code);
            setMessageContent(remoteMessageStore.editMyMessage.content);
            setMessageId(remoteMessageStore.editMyMessage.id);
        }
    }, [remoteMessageStore.editMyMessage]);

    if (!messageTitle ||
        !messageType ||
        !messageSeverity ||
        !messageLanguage ||
        !messageContent) {
            onDisableFollowingPages(pageNumber);
    }

    return (
        <div className='panel panel-default panel-body padding-25'>
            <div className='row equal-height'>
                <div className='col-xs-12 col-md-12 margin-bottom-15'>
                    <form>
                        <fieldset>
                            <div className={`form-group ${messageTitle ? 'has-success has-feedback' : 'has-error has-feedback'}`}>
                                <label htmlFor='inputMessageTitle'>
                                    {t('fotaone.rm.templateData.title')}
                                </label>
                                <ClearableInput
                                    placeholder={t('fotaone.rm.title')}
                                    id='inputMessageTitle'
                                    type='text'
                                    maxLength={25}
                                    value={messageTitle}
                                    onChange={event => setMessageTitle(event)}
                                />
                                <span className={`form-control-feedback rioglyph ${messageTitle ? 'rioglyph-ok' : 'rioglyph-error-sign'}`}/>
                                <span className="help-block input-group-addon input-group-addon-label">
                                    {`${messageTitle.length}/25`}
                                </span>
                            </div>
                            <div className='row'>
                                <div className='form-group col-xs-4 col-md-4'>
                                    <label htmlFor='messageTypeSelect'>
                                        {t('fotaone.rm.templateData.createTemplate.type')}
                                    </label>
                                    <select
                                        className='form-control'
                                        id='messageTypeSelect'
                                        defaultValue={messageType}
                                        value={messageType}
                                        onChange={(event) => setMessageType(event.target.value)}
                                    >
                                        {renderType()}
                                    </select>
                                </div>
                                <div className='form-group col-xs-4 col-md-4'>
                                    <label htmlFor='messageSeveritySelect'>
                                        {t('fotaone.rm.severityState.severity')}
                                    </label>
                                    <select
                                        className='form-control'
                                        id='messageSeveritySelect'
                                        defaultValue={messageSeverity}
                                        value={messageSeverity}
                                        onChange={(event) => setMessageSeverity(event.target.value)}
                                    >
                                        {renderSeverity()}
                                    </select>
                                </div>
                                <div className='form-group col-xs-4 col-md-4'>
                                    <label htmlFor='messageLanguageSelect'>
                                        {t('fotaone.rm.templateData.createTemplate.language')}
                                    </label>
                                    <select
                                        className='form-control'
                                        id='messageLanguageSelect'
                                        defaultValue={`${t(messageLanguage)}`}
                                        value={`${t(messageLanguage)}`}
                                        onChange={(event) => setMessageLanguage(event.target.value)}
                                    >
                                        {renderLanguage()}
                                    </select>
                                </div>
                            </div>
                            {messageType === 'RECALL' &&
                            <div className='row'>
                                <div className='form-group col-xs-4 col-md-4'>
                                    <div className={`form-group ${messageKbaNumber ? 'has-success has-feedback' : 'has-error has-feedback'}`}>
                                        <label htmlFor='inputMessageTitle'>KBA</label>
                                        <ClearableInput
                                            placeholder={t('fotaone.rm.kbaNumber')}
                                            id='inputKbaNumber'
                                            type='text'
                                            maxLength={6}
                                            value={messageKbaNumber}
                                            onChange={event => setMessageKbaNumber(event)}
                                        />
                                        <span className={`form-control-feedback rioglyph ${messageKbaNumber ? 'rioglyph-ok' : 'rioglyph-error-sign'}`}/>
                                        <span className="help-block input-group-addon input-group-addon-label">
                                            {`${messageKbaNumber.length}/6`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className={`form-group ${messageContent ? 'has-success has-feedback' : 'has-error has-feedback'}`}>
                                <label htmlFor='areaMessageContent'>
                                    {t('fotaone.rm.templateData.content')}
                                </label>
                                <textarea
                                    className='form-control'
                                    rows='3'
                                    id='areaMessageContent'
                                    placeholder={t('fotaone.rm.content')}
                                    value={messageContent}
                                    maxLength={2000}
                                    onChange={(event) => setMessageContent(event.target.value)}
                                >
                                </textarea>
                                <span className={`form-control-feedback rioglyph ${messageContent ? 'rioglyph-ok' : 'rioglyph-error-sign'}`}/>
                                <span className="help-block input-group-addon input-group-addon-label">
                                    {`${messageContent.length}/2000`}
                                </span>
                            </div>
                            <br/>
                            <div className='breakline' />
                            <div className='flex-1-1'>
                                <div className="display-flex justify-content-end align-items-start margin-top-10">
                                    <Button
                                        onClick={saveDraft}
                                        className='btn btn-default margin-5'
                                        id='saveDraftButton'
                                        disabled={
                                            !messageTitle ||
                                            !messageType ||
                                            !messageSeverity ||
                                            !messageLanguage ||
                                            !messageContent ||
                                            loading
                                        }
                                    >
                                        {remoteMessageStore.editMyMessage ?
                                            t('fotaone.rm.messageData.createMessage.saveChanges') :
                                            t('fotaone.rm.messageData.createMessage.saveAsDraft')
                                        }
                                    </Button>
                                    <Button
                                        onClick={onClickNext}
                                        onHover
                                        className='btn btn-primary margin-5'
                                        id='nextButton'
                                        disabled={
                                        !messageTitle ||
                                        !messageType ||
                                        !messageSeverity ||
                                        !messageLanguage ||
                                        !messageContent ||
                                        loading
                                        }
                                    >
                                        {t('fotaone.rm.messageData.createMessage.targetSelection')}
                                    </Button>
                                </div>
                                {
                                    loading &&
                                    <div className='text-center'>
                                        <br/>
                                        <span className="rioglyph rioglyph-spinner spinning"/>
                                        {t('fotaone.general.loading')}
                                    </div>
                                }
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    );
};

const TargetSelection = props => {
    const { remoteMessageStore, paginationStore, filterStore } = useStores();
    const { onClickNext, onClickPrevious, saveDraft, query, target, onUpdateTarget, loading, t } = props;

    return(
        <div className='panel panel-default panel-body padding-25'>
            <VehiclesMessageTable
                selectedRow={target.id}
                paginationStore={paginationStore}
                filterStore={filterStore}
                query={query}
                onChange={onUpdateTarget}
            />
            <div className='breakline' />
            <div className='flex-1-1'>
                <div className="display-flex justify-content-end align-items-start margin-top-10">
                    <Button 
                        className='btn btn-default margin-right-50'
                        onClick={onClickPrevious}
                        id='previousButton' 
                        disabled={loading}
                    >
                        <span className='rioglyph rioglyph-chevron-left' />
                        <span>{t('fotaone.general.previous')}</span>
                    </Button>
                    <Button
                        onClick={saveDraft}
                        className='btn btn-default margin-right-5'
                        id='saveDraftButton'
                        disabled={loading}
                    >
                        {remoteMessageStore.editMyMessage ?
                            t('fotaone.rm.messageData.createMessage.saveChanges') :
                            t('fotaone.rm.messageData.createMessage.saveAsDraft')
                        }
                    </Button>
                    <Button
                        onClick={onClickNext}
                        className='btn btn-primary'
                        id='nextButton'
                        disabled={loading || Object.keys(target).length === 0}
                    >
                        {t('fotaone.general.overview')}
                    </Button>
                </div>
                {
                    loading &&
                    <div className='text-center'>
                        <br/>
                        <span className="rioglyph rioglyph-spinner spinning"/>
                        {t('fotaone.general.loading')}
                    </div>
                }
            </div>
        </div>
    )
};

const Overview = props => {
    const { remoteMessageStore} = useStores();
    const { onClickPrevious, saveDraft, requestApproval, messageTitle, messageType, messageSeverity, messageKbaNumber, messageLanguage, messageContent, target, loading, t} = props;

    return (
        <div className='panel panel-default panel-body padding-25'>
            <div className='text-size-h4'>
                <span className="rioglyph rioglyph-envelope margin-right-5"/>
                {t('fotaone.rm.messageData.createMessage.messageInformation')}
            </div>
            <div className='row margin-top-10'>
                <div className='col-md-4'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>
                            {t('fotaone.rm.templateData.createTemplate.type')}
                        </label>
                        <span className='text-primary'>
                            {typeRender(messageType)}
                        </span>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>
                            {`${t("fotaone.rm.severityState.severity")}`}
                        </label>
                        <span className='text-primary'>
                            {severityRender(messageSeverity)}
                        </span>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>
                            {t('fotaone.rm.templateData.createTemplate.language')}
                        </label>
                        <span className='text-primary'>
                            {`${t(messageLanguage)}`}
                        </span>
                    </div>
                </div>
            </div>
            <div className='row margin-top-10'>
                <div className={messageKbaNumber===''?'col-md-12':'col-md-8'}>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>
                            {t('fotaone.rm.templateData.title')}
                        </label>
                        <span className='text-primary'>
                            {messageTitle}
                        </span>
                    </div>
                </div>
            
                {messageKbaNumber === '' ?
                    <></> :
                    <div className='col-md-4'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>
                                {t('fotaone.rm.kbaNumber')}
                        </label>
                            <span className='text-primary'>
                                {messageKbaNumber}
                            </span>
                        </div>
                    </div>
                }
            </div>
            <div className='row margin-top-10'>
                <div className='col-md-12'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>
                            {t('fotaone.rm.templateData.content')}
                        </label>
                        <span className='text-primary'>
                            {messageContent}
                        </span>
                    </div>
                </div>
            </div>
            <br/>
            <div className='text-size-h4'>
                <span className="rioglyph rioglyph-truck margin-right-5"/>
                {t('fotaone.rm.messageData.createMessage.targetInformation')}
            </div>
            <div className='row margin-top-10'>
                <div className='col-md-6'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>
                            {t('fotaone.general.vehicleData.vinShort')}
                        </label>
                        <span className='text-primary'>
                            {target.vin}
                        </span>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='margin-bottom-10'>
                        <label className='display-block'>
                            {t('fotaone.general.vehicleData.serialnumber')}
                        </label>
                        <span className='text-primary'>
                            {serialNumberRenderer(null, target)}
                        </span>
                    </div>
                </div>
            </div>
            <div className='breakline margin-top-20' />
            <div className='flex-1-1'>
                <div className="display-flex justify-content-end align-items-start margin-top-10">
                    <Button className='btn btn-default margin-right-50' onClick={onClickPrevious} id='previousButton'>
                        <span className='rioglyph rioglyph-chevron-left' />
                        <span>{t('fotaone.general.previous')}</span>
                    </Button>
                    <Button
                        onClick={saveDraft}
                        className='btn btn-default margin-right-5'
                        id='saveDraftButton'
                    >
                        {t('fotaone.rm.messageData.createMessage.save')}
                    </Button>
                    <Button
                        onClick={requestApproval}
                        className='btn btn-primary'
                        id='requestApprovalButton'
                    >
                        {t('fotaone.rm.messageData.messageAction.requestApproval')}
                    </Button>
                </div>
                {
                    loading &&
                    <div className='text-center'>
                        <br/>
                        <span className="rioglyph rioglyph-spinner spinning"/>
                        {t('fotaone.general.loading')}
                    </div>
                }
            </div>
        </div>
    );
};
