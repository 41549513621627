import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';
import { Services } from '@stores/service';
import SplitDialog from '@rio-cloud/rio-uikit/lib/es/SplitDialog';
import ListMenu from '@rio-cloud/rio-uikit/lib/es/ListMenu';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import WelcomeMenu from './SupportMenuContent/WelcomeMenu';
import WhatsNewMenu from './SupportMenuContent/WhatsNewMenu';
import FeedbackMenu from './SupportMenuContent/FeedbackMenu';
import SupportMenu from './SupportMenuContent/SupportMenu';
import ServicesMenu from './SupportMenuContent/ServicesMenu';
import MapUpdateMenu from './SupportMenuContent/MapUpdateMenu';
import OnlineTrafficMenu from './SupportMenuContent/OnlineTrafficMenu';
import FeatureDashboardMenu from './SupportMenuContent/FeatureDashboardMenu';
import RemoteMessageMenu from './SupportMenuContent/RemoteMessageMenu';

export const ServiceMenuDialog = observer((props) => {
    const { serviceStore, authStore } = useStores();
    const { t } = useTranslation();

    const [activeMenuItem, setActiveMenuItem] = useState(serviceStore.activeServiceMenu);

    useEffect(() => {}, [activeMenuItem]);

    const onHide = () => {
        serviceStore.setServiceMenuDialogOpen(false);
    };

    const handleMenuItemClick = (selectedMenu) => {
        serviceStore.setActiveServiceMenu(selectedMenu);
        setActiveMenuItem(selectedMenu);
    };

    const menuItems = [
        {
            group: 'FOTA Admin',
            navItems: [
                {
                    key: 'Welcome',
                    item: (
                        <span
                            className={activeMenuItem === 'Welcome' ? 'active' : ''}
                            onClick={() => {
                                handleMenuItemClick('Welcome');
                            }}>
                            {t("fotaone.general.welcome")}
                        </span>
                    ),
                },
                {
                    key: 'WhatsNew',
                    item: (
                        <span
                            className={activeMenuItem === 'WhatsNew' ? 'active' : ''}
                            onClick={() => {
                                handleMenuItemClick('WhatsNew');
                            }}>
                            {`${t("fotaone.general.whatsnew")}`}
                        </span>
                    ),
                },
            ],
        },
        {
            navItems: [
                {
                    key: 'Feedback',
                    item: (
                        <span
                            className={activeMenuItem === 'Feedback' ? 'active' : ''}
                            onClick={() => {
                                handleMenuItemClick('Feedback');
                            }}>
                            {t("fotaone.general.feedback")}
                        </span>
                    ),
                },
                {
                    key: 'Support',
                    item: (
                        <span
                            className={activeMenuItem === 'Support' ? 'active' : ''}
                            onClick={() => {
                                handleMenuItemClick('Support');
                            }}>
                            {t("fotaone.general.support")}
                        </span>
                    ),
                },
            ],
        },
        {
            group: 'Services',
            navItems: [
                {
                    key: 'Services',
                    item: (
                        <span
                            className={activeMenuItem === 'Services' ? 'active' : ''}
                            onClick={() => {
                                handleMenuItemClick('Services');
                            }}>
                            {t("fotaone.general.overview")}
                        </span>
                    ),
                },
                {
                    key: 'MapUpdate',
                    disabled: !authStore.hasWriteAccess(Services.MapUpdate),
                    item: (
                        <span
                            className={`${activeMenuItem === 'MapUpdate' ? 'active' : ''}`}
                            onClick={() => {
                                authStore.hasWriteAccess(Services.MapUpdate)
                                ? handleMenuItemClick('MapUpdate')
                                : null
                            }}>
                            {'Map Update (MU) '}
                            {!authStore.hasWriteAccess(Services.MapUpdate) ?
                                <OverlayTrigger
                                    key={"right"}
                                    placement={"right"}
                                    overlay={
                                        <Tooltip id='tooltip' allowOnTouch>
                                            {t("fotaone.auth.permissionInsufficient")}
                                        </Tooltip>
                                    }
                                >
                                    <span className="rioglyph rioglyph-info-sign"/>
                                </OverlayTrigger>
                            : null}
                        </span>
                    ),
                },
                {
                    key: 'OnlineTraffic',
                    disabled: !authStore.hasWriteAccess(Services.OnlineTraffic),
                    item: (
                        <span
                            className={activeMenuItem === 'OnlineTraffic' ? 'active' : ''}
                            onClick={() => {
                                authStore.hasWriteAccess(Services.OnlineTraffic)
                                ? handleMenuItemClick('OnlineTraffic')
                                : null
                            }}>
                            {'Online Traffic (OT) '}
                            {!authStore.hasWriteAccess(Services.OnlineTraffic) ?
                                <OverlayTrigger
                                    key={"right"}
                                    placement={"right"}
                                    overlay={
                                        <Tooltip id='tooltip' allowOnTouch>
                                            {t("fotaone.auth.permissionInsufficient")}
                                        </Tooltip>
                                    }
                                >
                                    <span className="rioglyph rioglyph-info-sign"/>
                                </OverlayTrigger>
                            : null}
                        </span>
                    ),
                },
                {
                    key: 'FeatureDashboard',
                    disabled: !authStore.hasWriteAccess(Services.FeatureDashboard),
                    item: (
                        <span
                            className={activeMenuItem === 'FeatureDashboard' ? 'active' : ''}
                            onClick={() => {
                                authStore.hasWriteAccess(Services.FeatureDashboard)
                                ? handleMenuItemClick('FeatureDashboard')
                                : null
                            }}>
                            {'Feature Dashboard (FD) '}
                            {!authStore.hasWriteAccess(Services.FeatureDashboard) ?
                                <OverlayTrigger
                                    key={"right"}
                                    placement={"right"}
                                    overlay={
                                        <Tooltip id='tooltip' allowOnTouch>
                                            {t("fotaone.auth.permissionInsufficient")}
                                        </Tooltip>
                                    }
                                >
                                    <span className="rioglyph rioglyph-info-sign"/>
                                </OverlayTrigger>
                            : null}
                        </span>
                    ),
                },
                {
                    key: 'RemoteMessage',
                    disabled: !authStore.hasWriteAccess(Services.RemoteMessage),
                    item:  (
                        <span
                            className={activeMenuItem === 'RemoteMessage' ? 'active' : ''}
                            onClick={() => {
                                authStore.hasWriteAccess(Services.RemoteMessage)
                                ? handleMenuItemClick('RemoteMessage')
                                : null
                            }}>
                            {'Remote Message (RM) '}
                            {!authStore.hasWriteAccess(Services.RemoteMessage) ?
                                <OverlayTrigger
                                    key={"right"}
                                    placement={"right"}
                                    overlay={
                                        <Tooltip id='tooltip' allowOnTouch>
                                            {t("fotaone.auth.permissionInsufficient")}
                                        </Tooltip>
                                    }
                                >
                                    <span className="rioglyph rioglyph-info-sign"/>
                                </OverlayTrigger>
                            : null}
                        </span>
                    ),
                },
            ],
        },
    ];

    const renderLeftBody = () => {
        return (
            <ListMenu menuItems={menuItems} groupClassName='padding-left-0' />
        );
    };

    const renderRightBody = () => {
        switch (activeMenuItem) {
            case 'Welcome':
                return <WelcomeMenu/>
            case 'WhatsNew':
                return <WhatsNewMenu/>
            case 'Feedback':
                return <FeedbackMenu/>
            case 'Services':
                return <ServicesMenu/>
            case 'MapUpdate':
                return <MapUpdateMenu/>
            case 'OnlineTraffic':
                return <OnlineTrafficMenu/>
            case 'FeatureDashboard':
                return <FeatureDashboardMenu/>
            case 'RemoteMessage':
                return <RemoteMessageMenu/>
            case 'Support':
                return <SupportMenu/>;
            default:
                return (
                    <ErrorState
                        headline='Oh snap! Something went wrong.'
                        buttons={[
                            {
                                text: <span>Try again</span>,
                                className: '',
                                onClick: () => handleMenuItemClick('Welcome'),
                            },
                        ]}
                    />
                );
        }
    };

    return (
        <div className='table-toolbar-column pull-right'>
            <SplitDialog
                show={serviceStore.serviceMenuDialogOpen}
                title={'FOTA Admin Services'}
                onClose={onHide}
                leftContent={renderLeftBody()}
                rightContent={renderRightBody()}
                bsSize='xl'
                useOverflow
            />
        </div>
    );
});

export default ServiceMenuDialog;
