import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import NoMatch from '@pages/NoMatch';
import { useStores } from '@stores/context';
import { observer } from 'mobx-react-lite';
import { Services } from '@stores/service.js';
import VehiclesActivationsPage from '@pages/FeatureDashboard/VehiclesActivationsPage.jsx';
import ServicesPage from '@pages/FeatureDashboard/ServicesPage.jsx';

export default observer(() => {
  const { serviceStore } = useStores();

  useEffect(() => {
    serviceStore.setCurrentService(Services.FeatureDashboard);
  });

  return (
    <Routes>
      <Route path='/' element={<Navigate to={`${Services.FeatureDashboard}/vehicles`} />} />
      <Route path='vehicles' element={<VehiclesActivationsPage />} >
        <Route path=':id' element={<VehiclesActivationsPage />} />
      </Route>
      <Route path='services' element={<ServicesPage />} >
        <Route path=':id' element={<ServicesPage />} />
      </Route>
      <Route path='*' element={<NoMatch />} />
    </Routes>
  );
});
