import { languageRenderer } from '@components/FeatureDashboard/FeatureDashboardHelper/utils.jsx';
import {
  renderMessageStatus,
  renderSentAt,
  severityRender,
  typeRender,
  serialNumberRenderer,
  messageCounterRenderer,
} from '@components/RemoteMessage/RemoteMessageHelper/utils.jsx';

export const messageTemplateTableData = {
    defaultColumnOrder: [
        'title',
        'type',
        'severity',
        'content',
        'language_code'
    ],
    columnsOrder: [
        'title',
        'type',
        'severity',
        'content',
        'language_code'
    ],
    columnDetails: {
        'title': {},
        'type': {},
        'severity': {},
        'content': {},
        'language_code': {}
    },
    columnLabels: {
        'title': 'fotaone.rm.templateData.title',
        'type': 'fotaone.rm.templateData.createTemplate.type',
        'severity': 'fotaone.rm.severityState.severity',
        'content': 'fotaone.rm.templateData.content',
        'language_code': 'fotaone.rm.templateData.createTemplate.language'
    },
    columns: [
      {
            title: 'fotaone.rm.templateData.title',
            key: 'title',
            sortable: false,
      }, {
            title: 'fotaone.rm.templateData.createTemplate.type',
            key: 'type',
            renderer: typeRender,
            sortable: false,
        }, {
            title: 'fotaone.rm.severityState.severity',
            key: 'severity',
            renderer: severityRender,
            sortable: false,
        }, {
            title: 'fotaone.rm.templateData.content',
            key: 'content',
            sortable: false,
        }, {
            title: 'fotaone.rm.templateData.createTemplate.language',
            key: 'language_code',
            renderer: languageRenderer,
            sortable: false,
        }
    ],
    disabledColumns: [],
    hiddenColumns: [],
};

export const typeData = [
    { TECH_INFO: 'Technical Information' },
    { RECALL: 'Recall' },
    { SERVICE_INFO: 'Service Information' }
];

export const severityData = [
    { HIGH: 'HIGH' },
    { MEDIUM: 'MEDIUM' },
    { LOW: 'LOW' }
];

export const vehiclesTableData = {
    defaultColumnOrder: [
        'device.name',
        'device.vin',
        'id',
      ],
      columnsOrder: [
        'device.name',
        'device.vin',
        'id',
      ],
      columnDetails: {
        'device.name': {},
        'device.vin': {},
        'id': {},
      },
      columnLabels: {
        'device.name': 'Shortname',
        'device.vin': 'VIN',
        'id': 'Device ID',
      },
      columns: [
        {
          title: 'fotaone.general.vehicleData.shortname',
          key: 'assets.0.name',
          sortable: true,
        },
        {
          title: 'fotaone.general.vehicleData.vinShort',
          key: 'vin',
          sortable: true,
        },
        {
          title: 'fotaone.general.vehicleData.serialnumber',
          key: 'serial_number',
          renderer: serialNumberRenderer,
          sortable: true,
        },
        {
          title: "fotaone.rm.messageData.message.many",
          key: 'device_messages_aggregate.aggregate.count',
          renderer: messageCounterRenderer,
          sortable: true,
        },
      ],
      disabledColumns: [],
      hiddenColumns: [],
    };

export const vehiclesSelectionTableData = {
    defaultColumnOrder: [
        'device.name',
        'device.vin',
        'id',
      ],
      columnsOrder: [
        'device.name',
        'device.vin',
        'id',
      ],
      columnDetails: {
        'device.name': {},
        'device.vin': {},
        'id': {},
      },
      columnLabels: {
        'device.name': 'Shortname',
        'device.vin': 'VIN',
        'id': 'Device ID',
      },
      columns: [
        {
          title: 'fotaone.general.vehicleData.shortname',
          key: 'assets.0.name',
          sortable: true,
        },
        {
          title: 'fotaone.general.vehicleData.vinShort',
          key: 'vin',
          sortable: true,
        },
        {
          title: 'fotaone.general.vehicleData.serialnumber',
          key: 'serial_number',
          renderer: serialNumberRenderer,
          sortable: true,
        },
      ],
      disabledColumns: [],
      hiddenColumns: [],
    };

export const vehicleDetailsMessagesTableData = {
    defaultColumnOrder: [
        'message.title', 
        'message.type',
        'status',
        'message.device_message_events[0].created_at'
    ],
    columnsOrder: [
        'message.title',
        'message.type',
        'status',
        'message.device_message_events[0].created_at'
    ],
    columnDetails: {
        'message.title': {},
        'message.type': {},
        'status': {},
        'message.device_message_events[0].created_at': {},
    },
    columnLabels: {
        'message.title': 'Title',
        'message.type': 'Type',
        'status': 'Status',
        'message.device_message_events[0].created_at': 'Sent at',
    },
    columns: [
        {
            title: 'fotaone.rm.title',
            key: 'message.title',
            sortable: false,
        }, {
            title: 'fotaone.rm.messageData.messageType.type',
            key: 'message.type',
            renderer: typeRender,
            sortable: false,
        }, {
            title: 'fotaone.rm.messageData.messageStatus.status',
            key: 'status',
            renderer: renderMessageStatus,
            sortable: false,
        }, {
            title: 'fotaone.rm.eventData.eventCreatedAt',
            key: 'message.device_message_events[0].created_at',
            sortable: false,
            renderer: renderSentAt,
        }
    ],
    disabledColumns: [],
    hiddenColumns: [],
};

export const messagesTableData = {
    defaultColumnOrder: [
        'id',
        'severity',
        'title', 'type',
        'device_messages[0].status',
        'device_message_events[0].created_at'
    ],
    columnsOrder: [
        'id',
        'severity',
        'title',
        'type',
        'device_messages[0].status',
        'device_message_events[0].created_at'
    ],
    columnDetails: {
        'id': {},
        'severity': {},
        'title': {},
        'type': {},
        'device_messages[0].status': {},
        'device_message_events[0].created_at': {},
    },
    columnLabels: {
        'id': 'Message Id',
        'severity': 'Severity',
        'title': 'Title',
        'type': 'Type',
        'device_messages[0].status': 'Status',
        'device_message_events[0].created_at': 'Sent at',
    },
    columns: [
        {
            title: 'fotaone.rm.messageData.messageId',
            key: 'id',
            sortable: false,
        }, {
            title: 'fotaone.rm.severityState.severity',
            key: 'severity',
            renderer: severityRender,
            sortable: false,
        }, {
            title: 'fotaone.rm.title',
            key: 'title',
            sortable: false,
        }, {
            title: 'fotaone.rm.messageData.messageType.type',
            key: 'type',
            renderer: typeRender,
            sortable: false,
        }, {
            title: 'fotaone.rm.messageData.messageStatus.status',
            key: 'device_messages[0].status',
            renderer: renderMessageStatus,
            sortable: false,
        }, {
            title: 'fotaone.rm.eventData.eventCreatedAt',
            key: 'device_message_events[0].created_at',
            sortable: false,
            renderer: renderSentAt,
        }
    ],
    disabledColumns: [],
    hiddenColumns: [],
};

export const myMessagesTableData = {
    defaultColumnOrder: [
        'id',
        'severity',
        'title',
        'type',
        'status',
    ],
    columnsOrder: [
        'id',
        'severity',
        'title',
        'type',
        'status',
    ],
    columnDetails: {
        'id': {},
        'severity': {},
        'title': {},
        'type': {},
        'status': {},
    },
    columnLabels: {
        'id': 'Message Id',
        'severity': 'Severity',
        'title': 'Title',
        'type': 'Type',
        'status': 'Status',
    },
    columns: [
        {
            title: 'fotaone.rm.messageData.messageId',
            key: 'id',
            sortable: false,
        }, {
            title: 'fotaone.rm.severityState.severity',
            key: 'severity',
            renderer: severityRender,
            sortable: false,
        }, {
            title: 'fotaone.rm.title',
            key: 'title',
            sortable: false,
        }, {
            title: 'fotaone.rm.messageData.messageType.type',
            key: 'type',
            renderer: typeRender,
            sortable: false,
        }, {
            title: 'fotaone.rm.messageData.messageStatus.status',
            key: 'status',
            renderer: renderMessageStatus,
            sortable: false,
        }
    ],
    disabledColumns: [],
    hiddenColumns: [],
};

export const messagesApprovalTableData = {
    defaultColumnOrder: [
        'id',
        'severity',
        'title',
        'type',
        'device_message_events[0].created_by',
        'status',
    ],
    columnsOrder: [
        'id',
        'severity',
        'title',
        'type',
        'device_message_events[0].created_by',
        'status',
    ],
    columnDetails: {
        'id': {},
        'severity': {},
        'title': {},
        'type': {},
        'device_message_events[0].created_by':{},
        'status': {},
    },
    columnLabels: {
        'id': 'Message Id',
        'severity': 'Severity',
        'title': 'Title',
        'type': 'Type',
        'device_message_events[0].created_by':'Created by',
        'status': 'Status',
    },
    columns: [
        {
            title: 'fotaone.rm.messageData.messageId',
            key: 'id',
            sortable: false,
        }, {
            title: 'fotaone.rm.severityState.severity',
            key: 'severity',
            renderer: severityRender,
            sortable: false,
        }, {
            title: 'fotaone.rm.title',
            key: 'title',
            sortable: false,
        }, {
            title: 'fotaone.rm.messageData.messageType.type',
            key: 'type',
            renderer: typeRender,
            sortable: false,
        }, {
            title: 'Requested By',
            key: 'device_message_events[0].created_by',
            sortable: false,
        }, {
            title: 'fotaone.rm.messageData.messageStatus.status',
            key: 'status',
            renderer: renderMessageStatus,
            sortable: false,
        }
    ],
    disabledColumns: [],
    hiddenColumns: [],
};

export const statusMap = {
    DRAFT: { color: 'text-color-primary', icon: 'rioglyph rioglyph-envelope' },
    REJECTED: { color: 'text-color-danger', icon: 'rioglyph rioglyph-thumbs-down' },
    APPROVED: { color: 'text-color-success', icon: 'rioglyph rioglyph-thumbs-up' },
    PENDING_APPROVAL: { color: 'text-color-info', icon: 'rioglyph rioglyph-hour-glass' },
    SENDING_FAILED: { color: 'text-color-warning', icon: 'rioglyph rioglyph-warning-sign' },
    SENT: { color: 'text-color-success', icon: 'rioglyph rioglyph-send' },
    null: { color: 'text-color-darkest', icon: 'rioglyph rioglyph-flash' },
};

