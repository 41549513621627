import { useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import Table from '@common/Table/Table.jsx';
import { useStores } from '@stores/context.jsx';
import Pagination from '@components/Common/Pagination/Pagination.jsx';
import { setQueryParamToUrl } from '@components/mapupdate/mapupdateHelper/utils.jsx';
import { vehiclesTableData, vehiclesSelectionTableData } from '@components/RemoteMessage/RemoteMessageHelper/constants.jsx';

const VehiclesMessageTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceStore, remoteMessageStore } = useStores();
  const { paginationStore, filterStore } = props;
  const [tableData, setTableData] = useState(vehiclesTableData);

  const limit = paginationStore?.limit;
  const activePage = paginationStore?.activePage;
  const offset = paginationStore?.offset;
  const query = filterStore?.query;
  const filters = filterStore?.filters;

  const getVehicles = useCallback(
    debounce(async (params) => {
      await remoteMessageStore.getVehicles(params);
      paginationStore.setItemsLength(remoteMessageStore.vehicleTotal);
      if(location.pathname === '/remote-message/myMessages'){
        setTableData(vehiclesSelectionTableData)
      }else{
        setTableData(vehiclesTableData)
      }
    }, 200),
    [],
  );
  useEffect(() => {
    getVehicles({
        query,
        limit,
        offset,
        filters,
    });
  }, [
      query,
      limit,
      activePage,
      location.search,
      getVehicles,
      offset,
      filters,
  ]);

  const rowClicked = (row) => {
    if(location.pathname === '/remote-message/myMessages'){
      props.onChange({
        target: row
      });
    }else{
      navigate(`${serviceStore.currentService}/vehicles/${row.id}${location.search}`)
    }
  };

  const handleSearchValueChange = (search) => {
    setQueryParamToUrl('query', search);
    filterStore.setQuery(search);
    paginationStore.setActivePage(1);
  };

  const handleSortChange = (_sortBy, _sortDir) => {
    filterStore.setSortBy(_sortBy);
    filterStore.setSortDir(_sortDir);
  };

  return (
    <div className='app-file-table'>
      <Table
          onRowClick={rowClicked}
          rowKey='id'
          selectedRow={props.selectedRow}
          showSearch={true}
          handleSearchValueChange={handleSearchValueChange}
          handleSortChange={handleSortChange}
          searchValue={query}
          data={remoteMessageStore.vehicleList || []}
          columnDetails={tableData.columnDetails}
          defaultColumnOrder={tableData.defaultColumnOrder}
          columns={tableData.columns}
          columnOrder={tableData.columnsOrder}
          hiddenColumns={tableData.hiddenColumns}
          columnLabels={tableData.columnLabels}
          disabledColumns={tableData.disabledColumns}
          actionColumn='buttons'
          loading={remoteMessageStore?.vehiclesLoading}
        />
        <Pagination paginationStore={paginationStore} />
    </div>
  );
};

export default observer(VehiclesMessageTable);