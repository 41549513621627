import { observable, action, makeObservable } from 'mobx';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';

let instance; // singleton instance

/**
 * @typedef DefaultValues
 * @type {object}
 * @property {Array<any>} filters
 * @property {string} query
 * @property {string} sortBy
 * @property {any} sortDir
 */
export const defaultValues = {
    filters: [],
    query: '',
    sortBy: '',
    sortDir: SortDirection.DESCENDING,
};
export class FilterStore {
    defaultValues;
    filters = defaultValues.filters;
    query = defaultValues.query;
    sortBy = defaultValues.sortBy;
    sortDir = defaultValues.sortDir;

    constructor(params = defaultValues) {
        makeObservable(this, {
            filters: observable,
            query: observable,
            sortBy: observable,
            sortDir: observable,
            setFilters: action,
            setQuery: action,
            setSortBy: action,
            setSortDir: action,
            setDefaults: action,
            resetToDefaults: action,
        });

        this.defaultValues = params;
        Object.assign(this, params);
    }
    setDefaults(value) {
        this.defaultValues = value;
    }

    setFilters(filters) {
        this.filters = filters;
    }

    setQuery(query) {
        this.query = query;
    }

    setSortBy(sortBy) {
        this.sortBy = sortBy;
    }

    setSortDir(sortDir) {
        this.sortDir = sortDir;
    }

    resetToDefaults() {
        Object.assign(this, this.defaultValues);
    }

    static instance() {
        if (!instance) {
            instance = new FilterStore();
        }
        return instance;
    }
}
