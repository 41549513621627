import React from 'react';
import Details from './Details.jsx';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import DetailsSideBar from '@common/DetailsSideBar/DetailsSideBar.jsx';
import { EBlurEffectStates } from '@common/DetailsSideBar/DetailsSideBar.constants.js';
import { useTranslation } from 'react-i18next';

const VehicleFeatureSidebar = observer((props) => {
    const { serviceStore, featureDashboardStore } = useStores();
    const { deviceId, vin} = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const onClose = async () => {
        navigate(`${serviceStore.currentService}/vehicles${location.search}`);
    };
    return (
        <DetailsSideBar
            title={`${t("fotaone.general.vehicleData.vehicleDetails")}`}
            onClose={onClose}
            width={600}
            blurEffect={featureDashboardStore.vehicleDetailsLoading === true ? EBlurEffectStates.Show : EBlurEffectStates.Hide}
            fly
        >
            <Details deviceId={deviceId} vin={vin}/>
        </DetailsSideBar>
    );
});

export default VehicleFeatureSidebar;
