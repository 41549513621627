import { isNumber } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

/**
 * @type {PaginationStore}
 */
let instance; // singleton instance

/**
 * @typedef DefaultValues
 * @type {object}
 * @property {number} limit
 * @property {number} offset
 * @property {number} size
 */
export const defaultValues = {
    limit: 50,
    activePage: 1,
    itemsLength: 0,
};

export class PaginationStore {
    defaultValues;
    limit = defaultValues.limit;
    activePage = defaultValues.activePage;
    itemsLength = defaultValues.itemsLength;

    get size() {
        if (this.itemsLength && this.limit) {
            return Math.ceil(this.itemsLength / this.limit);
        } else {
            return 0;
        }
    }
    get offset() {
        if (this.activePage && this.limit) {
            return (this.activePage - 1) * this.limit;
        } else {
            return 0;
        }
    }

    /**
     *
     * @param {DefaultValues} params
     */
    constructor(params = defaultValues) {
        makeObservable(this, {
            limit: observable,
            activePage: observable,
            itemsLength: observable,

            offset: computed,
            size: computed,

            setLimit: action,
            setActivePage: action,
            setItemsLength: action,

            resetToDefaults: action,
        });

        this.defaultValues = params;
        Object.assign(this, params);
    }
    resetToDefaults() {
        Object.assign(this, this.defaultValues);
    }

    setLimit(limit) {
        if (isNumber(limit)) {
            this.limit = limit;
        } else {
            const castToNumber = Number(limit);
            if (isNumber(castToNumber) && !isNaN(castToNumber)) {
                this.limit = castToNumber;
            }
        }
    }
    setActivePage(activePage) {
        if (isNumber(activePage)) {
            this.activePage = activePage;
        } else {
            const castToNumber = Number(activePage);
            if (isNumber(castToNumber) && !isNaN(castToNumber)) {
                this.activePage = castToNumber;
            }
        }
    }
    setItemsLength(itemsLength) {
        if (isNumber(itemsLength)) {
            this.itemsLength = itemsLength;
        } else {
            const castToNumber = Number(itemsLength);
            if (isNumber(castToNumber) && !isNaN(castToNumber)) {
                this.itemsLength = castToNumber;
            }
        }
    }

    /**
     *
     * @returns {PaginationStore} Returns a singleton instance of PaginationStore
     */
    static instance() {
        if (!instance) {
            instance = new PaginationStore();
        }
        return instance;
    }
}
