import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@stores/context.jsx';
import Table from '../../../../Common/Table/Table.jsx';
import CreateCampaignDialog from '../dialog/CreateCampaignDialog.jsx';
import { debounce } from 'lodash';
import { campaignTableData } from '../Campaign.constants.jsx';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import { regionSelectData, DEFAULT_LIMIT } from '../../../mapupdateHelper/constants.jsx';
import { setQueryParamToUrl } from '../../../mapupdateHelper/utils.jsx';
import { useTranslation } from 'react-i18next';

export default observer((props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { campaignStore, serviceStore } = useStores();
    const { showSearch } = props;
    const { filterStore } = props;
    const queryParams = new URLSearchParams(location.search);
    let query = filterStore?.query;
    const sortBy = filterStore?.sortBy;
    const sortDir = filterStore?.sortDir;
    const [shouldLoadMore, setShouldLoadMore] = useState([]);
    const [regionsWithLimits, setRegionsWithLimits] = useState(
        regionSelectData.map((entry) => ({ [Object.keys(entry)]: DEFAULT_LIMIT })),
    );
    const searchQueryParam = queryParams.get('query');

    function handleLoadMore(regionKey) {
        let updatedLoadLimit = 0;

        const updatedRegionsWithLimits = regionsWithLimits.map((entry) => {
            const mapRegionKey = Object.keys(entry);

            let loadLimitResult = 0;
            // rome-ignore lint: TODO it should be solved in a different way
            if (mapRegionKey == regionKey) {
                loadLimitResult = entry[mapRegionKey] + DEFAULT_LIMIT;
                updatedLoadLimit = entry[mapRegionKey] + DEFAULT_LIMIT;
            } else {
                loadLimitResult = entry[mapRegionKey];
            }

            return { [mapRegionKey]: loadLimitResult };
        });

        setRegionsWithLimits(updatedRegionsWithLimits);

        campaignStore.getCampaignsByRegion(updatedRegionsWithLimits, { query, sortBy, sortDir });

        setShouldLoadMore(
            shouldLoadMore.map((entry) => {
                // rome-ignore lint: TODO it should be solved in a different way
                if (Object.keys(entry) == regionKey) {
                    const total = campaignStore.campaignRegionListTotal.filter(
                        // rome-ignore lint: TODO it should be solved in a different way
                        (campaign) => Object.keys(campaign)[0] == regionKey,
                    )[0][regionKey];
                    const shouldLoad = total > updatedLoadLimit;
                    return { [Object.keys(entry)]: shouldLoad };
                }
                return entry;
            }),
        );
    }

    const handleSortChange = (sortBy, sortDir) => {
        filterStore.setSortBy(sortBy);
        filterStore.setSortDir(sortDir);
    };

    const handleRowClick = (row) => {
        navigate(`${serviceStore.currentService}/campaigns/${row.id}`);
    };

    const handleSearchValueChange = (search) => {
        setQueryParamToUrl('query', search);
        filterStore.setQuery(search);
    };

    const loadCampaignByRegion = useCallback(
        debounce(async (params) => {
            const campaign = await campaignStore.getCampaignsByRegion(regionsWithLimits, params);

            // The Load More flag array has already been initialized
            if (shouldLoadMore.length === 0) {
                setShouldLoadMore(
                    campaignStore.campaignRegionListTotal.map((entry) => ({
                        [Object.keys(entry)]: entry[Object.keys(entry)] > DEFAULT_LIMIT,
                    })),
                );
            } else {
                shouldLoadMore.map((entry) => {
                    const total = campaignStore.campaignRegionListTotal.filter(
                        (campaign) => Object.keys(campaign)[0] === Object.keys(entry)[0],
                    )[Object.keys(entry)];
                    const someResult = regionsWithLimits.filter(
                        (region) => Object.keys(region)[0] === Object.keys(entry)[0],
                    )[Object.keys(entry)];
                    const shouldLoad = total > someResult;
                    return { [Object.keys(entry)]: shouldLoad };
                });
            }

            return campaign;
        }, 200),
        [],
    );

    const searchCampaign = useCallback(
        debounce(async (params) => {
            const campaign = await campaignStore.getCampaigns(params);
            return campaign;
        }, 200),
        [],
    );

    useEffect(() => {
        if (searchQueryParam) {
            query = searchQueryParam;
            filterStore.setQuery(searchQueryParam);
        }

        if (query === '') {
            loadCampaignByRegion({ query, sortBy, sortDir });
        } else {
            searchCampaign({ query, sortBy, sortDir });
        }
    }, [
        query,
        sortBy,
        sortDir,
        campaignStore,
        campaignStore.shouldReload,
        searchCampaign,
        loadCampaignByRegion,
        searchQueryParam,
    ]);

    return (
        <>
            {showSearch && (
              <div className={'table-toolbar'}>
                <div className='table-toolbar-container'>
                    <div className='table-toolbar-group-left'>
                        <div className='table-toolbar-column'>
                            <TableSearch
                                value={query}
                                onChange={handleSearchValueChange}
                                placeholder={`${t("fotaone.general.table.tableSearch")}`}
                                className={'width-300'}
                            />
                        </div>
                    </div>
                    <div className='table-toolbar-group-right'>
                        <CreateCampaignDialog />
                        <div className={'table-toolbar-column margin-left-10'} />
                    </div>
                </div>
              </div>
            )}
            {query !== '' && (
                <Table
                    handleSortChange={handleSortChange}
                    onRowClick={handleRowClick}
                    data={campaignStore.campaignList || []}
                    searchValue={query}
                    columnDetails={campaignTableData.columnDetails}
                    defaultColumnOrder={campaignTableData.defaultColumnOrder}
                    columns={campaignTableData.columns}
                    columnOrder={campaignTableData.columnsOrder}
                    hiddenColumns={campaignTableData.hiddenColumns}
                    columnLabels={campaignTableData.columnLabels}
                    disabledColumns={campaignTableData.disabledColumns}
                    loading={campaignStore.campaignLoading}
                    rowKey='id'
                    selectedRow={props.selectedRow}
                />
            )}
            {query === '' &&
                regionSelectData
                    .filter((k) => k)
                    .map((region) => {
                        const regionKey = Object.keys(region)[0];
                        return (
                            <ExpanderPanel
                                key={`${t(regionKey)}`}
                                title={`${t(region[regionKey])}`}
                                bsStyle={'default'}
                                bodyClassName={'padding-20'}
                                className={' margin-0'}
                            >
                                <>
                                    <Table
                                        showSearch={false}
                                        filterStore={filterStore}
                                        handleSortChange={handleSortChange}
                                        onRowClick={handleRowClick}
                                        data={
                                            campaignStore.campaignList // TODO: Cleanup this mess!!!!
                                                .filter((entry) => Object.keys(entry)[0] === regionKey)
                                                .map((entry) => entry[regionKey])[0] || []
                                        }
                                        columnDetails={campaignTableData.columnDetails}
                                        defaultColumnOrder={campaignTableData.defaultColumnOrder}
                                        columns={campaignTableData.columns}
                                        columnOrder={campaignTableData.columnsOrder}
                                        hiddenColumns={campaignTableData.hiddenColumns}
                                        columnLabels={campaignTableData.columnLabels}
                                        disabledColumns={campaignTableData.disabledColumns}
                                        loading={campaignStore.campaignLoading}
                                        rowKey='id'
                                        selectedRow={props.selectedRow}
                                    />
                                    {shouldLoadMore
                                        // rome-ignore lint: TODO it should be solved in a different way
                                        .filter((entry) => Object.keys(entry) == regionKey)
                                        .map((entry) => entry[regionKey])[0] && (
                                        <div key={regionKey} className='padding-25 text-center'>
                                            <button
                                                type='button'
                                                className='btn btn-default'
                                                onClick={() => handleLoadMore(regionKey)}
                                            >
                                                <span className='rioglyph rioglyph-plus' />
                                                <span>{`${t("fotaone.general.table.loadMore")}`}</span>
                                            </button>
                                        </div>
                                    )}
                                </>
                            </ExpanderPanel>
                        );
                    })}
        </>
    );
});
