import { observable, action, makeObservable } from 'mobx';

let instance; // singleton instance

export class UIStore {
    showRolloutDialog = false;

    constructor() {
        makeObservable(this, {
            showRolloutDialog: observable,
            setShowRolloutDialog: action,
            showSidebar: observable,
            setShowSidebar: action,
            showMultipleRolloutDialog: observable,
            setShowMultipleRolloutDialog: action,
        });
    }

    setShowRolloutDialog(value) {
        this.showRolloutDialog = Boolean(value);
    }

    showMultipleRolloutDialog = false;
    setShowMultipleRolloutDialog(value) {
        this.showMultipleRolloutDialog = Boolean(value);
    }

    showSidebar = true;
    setShowSidebar(value) {
        this.showSidebar = Boolean(value);
    }

    static instance() {
        if (!instance) {
            instance = new UIStore();
        }
        return instance;
    }
}
