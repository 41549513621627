import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import FotaHeader from '@components/Navbar/Navbar.jsx';
import FotaSidebar from '@components/Sidebar/Sidebar.jsx';
import ServiceMenuDialog from '@components/Dialogs/SupportMenu/ServiceMenuDialog';
import { useStores } from '@stores/context.jsx';
import { PaginationStore } from '@stores/pagination.js';
import { FilterStore } from '@stores/filter.jsx';
import MessagesTable from '@components/RemoteMessage/Messages/Overview/Table/MessagesTable.jsx';
import MessageDetailSidebar from '@components/RemoteMessage/Messages/Overview/Sidebar/MessageDetailSidebar.jsx';

const paginationStore = new PaginationStore();
const filterStore = new FilterStore({
  query: '',
});

export default observer(() => {
  const { id: messageId } = useParams();
  const { serviceStore } = useStores();
  const queryParams = new URLSearchParams(location.search);
  const searchQueryParam = queryParams.get('query');

  useEffect(() => {
    if (searchQueryParam) {
        filterStore.setQuery(searchQueryParam);
    }
    return () => {
      filterStore.resetToDefaults();
      paginationStore.resetToDefaults();
    };
  }, [searchQueryParam, serviceStore]);

  return (
    <ApplicationLayout className={'MyServiceLayout'}>
      <ApplicationLayout.Header>
        <FotaHeader />
      </ApplicationLayout.Header>
      <ApplicationLayout.Sidebar className='bg-muted'>
        <FotaSidebar />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Sidebar className='right'>
        {messageId && <MessageDetailSidebar messageId={messageId} />}
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className='responsive'>
        <NotificationsContainer />
        {serviceStore.serviceMenuDialogOpen &&
          <ServiceMenuDialog/>
        }
        <MessagesTable
          selectedRow={messageId}
          paginationStore={paginationStore}
          filterStore={filterStore}
        />
      </ApplicationLayout.Body>
    </ApplicationLayout>
  );
});