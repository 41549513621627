import {
    getActivationDate,
    getFotaStatus,
    getManNowStatus,
    getVehicleStatus,
    getDeactivationReason,
} from '../Vehicle.utils';

export const activationManNowStatusParser = (_, row) => {
    const vehicleState = getVehicleStatus(row);
    const { state } = getManNowStatus(vehicleState);
    return { state };
};

export const activationFotaStatusParser = (_, row) => {
    const { activations_override } = row;
    const { state } = getFotaStatus(activations_override);
    return { state };
};

export const formatActivationDateParser = (_, row, key) => {
    const response = { state: '' };
    response.state = getActivationDate(_, row, key);
    return response;
};

export const deactivationReasonParser = (_, row) => {
    const response = { state: '' };
    response.state = getDeactivationReason(row);
    return response;
};
