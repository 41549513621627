import React from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import { useNavigate } from 'react-router-dom';

export const ProgressBar = (props) => {
    const {
        title, //string;
        totalCount, //number;
        statuses, //Array<{ name: string, count: number, color: string, clickUrl: string, animated: boolean}>;
        showLabel, //boolean;
        size, //small | normal | large;
        testId, //string;
        currentService, //string;
    } = props;
    const navigate = useNavigate();

    function getWidth(count) {
        const width = (count / totalCount) * 100;
        return `${width}%`;
    }

    return (
        <div className={'margin-bottom-20'} data-testid={testId}>
            <div className={'progress-bar-title margin-bottom-5 text-bold text-size-14'}>
                <span className={'total-count'}>{totalCount}</span> {title}
            </div>
            <div className={'bs-component'}>
                <div className='progress' style={{ height: size === 'large' ? 15 : size === 'small' ? 6 : 10 }}>
                    {statuses.map(
                        (status) =>
                            status.count > 0 && (
                                <OverlayTrigger
                                    key={status.name}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id='tooltip' allowOnTouch>
                                            <p className={'text-capitalize-first-word'}>{status.name}</p>
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        data-testid={status.test_id}
                                        onClick={() =>
                                            status.clickUrl ? navigate(`${currentService}${status.clickUrl}`) : null
                                        }
                                        className={`progress-bar progress-bar-${status.color} progress-divider ${
                                            status.clickUrl ? 'cursor-pointer' : 'cursor-default'
                                        } ${status.animated ? 'progress-bar-striped progress-bar-animated' : ''}`}
                                        style={{ width: getWidth(status.count), height: size === 'large' ? 15 : 10 }}
                                    >
                                        {showLabel && (
                                            <span
                                                className={'text-bold text-size-11'}
                                                style={{ position: 'relative', bottom: 2 }}
                                            >
                                                {status.count}
                                            </span>
                                        )}
                                    </div>
                                </OverlayTrigger>
                            ),
                    )}
                </div>
            </div>
        </div>
    );
};
