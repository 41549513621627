import { useState } from 'react';
import { useStores } from '@stores/context.jsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ProgressBarLabels from "../RemoteMessageHelper/ProgressBarLabels.jsx";
import Collapse from '@rio-cloud/rio-uikit/lib/es/Collapse';

const MessageDetailsProgress = observer(() => {
    const { remoteMessageStore } = useStores();
    const { t } = useTranslation();
    const [showCollapse, setShowCollapse] = useState(true);

    const renderStatus = () => {
        return(
            remoteMessageStore.messageDetails?.status === 'APPROVED' ? 
            (
                <ProgressBarLabels status={remoteMessageStore.messageDetails?.device_messages[0]?.status} />
            ):(
                <ProgressBarLabels status={remoteMessageStore.messageDetails?.status} />
            )
        )
    };

    return (
        remoteMessageStore.messageDetails && (
            <div data-testid="message-details-progress-section">
                <h5
                    className="cursor-pointer"
                    onClick={() => setShowCollapse(state => !state)}
                >
                    <span className="rioglyph rioglyph-route-view text-size-20 margin-right-10"/>
                    <span className="margin-right-5">{`${t("fotaone.mu.rolloutData.progress")}`}</span>
                    <span
                        className={
                            showCollapse
                            ? "rioglyph rioglyph-chevron-down"
                            : "rioglyph rioglyph-chevron-right"
                        }
                    />
                </h5>
                <div className="breakline margin-bottom-20" />

                <Collapse open={showCollapse} unmountOnExit>
                    {renderStatus()}
                </Collapse>
            </div>
        )
    );
});

export default MessageDetailsProgress;
