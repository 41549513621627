import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores/context.jsx';
import DetailsGeneral from '../../../Common/DetailsGeneral.jsx'
import DetailsTarget from '../../../Common/DetailsTarget.jsx'
import DetailsMessage from '../../../Common/DetailsMessage.jsx'
import DetailsEvents from '../../../Common/DetailsEvents.jsx'

const MessageDetails = observer((props) => {
    const { remoteMessageStore } = useStores();
    const { messageId } = props;

    useEffect(() => {
        const fetchData = async () => {
            await remoteMessageStore.getMessageDetails(messageId);
        };
        fetchData();
    }, [messageId]);

    return (
        <div className='message-details'>
            <div className='details'>
                <DetailsGeneral />
                <DetailsTarget />
                <DetailsMessage />
                <DetailsEvents />
            </div>
        </div>
    );
});

export default MessageDetails;