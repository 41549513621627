import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useStores } from '@stores/context.jsx';
import { useTranslation } from 'react-i18next';

const MessageDetailsTarget = observer(() => {
    const { serviceStore, remoteMessageStore } = useStores();
    const { t } = useTranslation();
    const deviceId = get(remoteMessageStore.messageDetails, ['device_messages','0','device_id']);
    const deviceVin = get(remoteMessageStore.messageDetails, ['device_messages','0','device','vin']);
    const deviceType = get(remoteMessageStore.messageDetails, ['device_messages','0','device', 'type']);
    const deviceSerial = get(remoteMessageStore.messageDetails, ['device_messages','0','device', 'serial_number']);

    return (
        remoteMessageStore.messageDetails && (
            <>
                <h5>
                    <span className="rioglyph rioglyph-truck-baseline text-size-20 margin-right-10"/>
                    {`${t("fotaone.general.vehicleData.vehicle.one")}`}
                </h5>
                <div className='breakline margin-bottom-20' />
                {deviceId && deviceVin && deviceType && deviceSerial ?
                <>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>
                                  {`${t("fotaone.general.vehicleData.deviceId")}`}
                                </label>
                                <span className='text-primary'>
                                    <a href={`${serviceStore.currentService}/vehicles/${deviceId}${location.search}`}>{deviceId}</a>
                                </span>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>
                                  {`${t("fotaone.general.vehicleData.vinShort")}`}
                                </label>
                                <span className='text-primary'>
                                    {deviceVin}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='margin-bottom-10'>
                                <label className='display-block'>
                                  {`${t("fotaone.general.vehicleData.serialnumber")}`}
                                </label>
                                <span className='text-primary'>
                                    {`${deviceType?.toUpperCase()}:${deviceSerial}`}
                                </span>
                                &nbsp;
                                <span className='margin-left-10 label label-muted label-filled label-condensed'>
                                    <a
                                        className='text-color-info'
                                        href={deviceType==="CM4"
                                        ?   
                                        `https://ota.admin-eu.rio.cloud/#devices?serial-number-prefix=cm4_${deviceSerial}&deviceSelected=true`
                                        :
                                        `https://ota.admin-eu.rio.cloud/#devices?serial-number-prefix=${deviceSerial}&deviceSelected=true`
                                        }
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        {`${t("fotaone.general.vehicleData.mdmDevice")}`}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </>:<>
                    <div className='row'>
                        <div className='col-md-12 text-center'>
                            <div className='margin-bottom-10'>
                                <p>
                                    <span className="rioglyph rioglyph-warning-sign text-color-danger pulsing text-size-20 margin-right-10"/>
                                    {`${t("fotaone.notification.warning.devices.noDevicesSelected")}`}
                                </p>
                            </div>
                        </div>
                    </div>
                </>}
            </>
        )
    );
});

export default MessageDetailsTarget;
