import { useEffect, useState } from 'react';
import { useStores } from '@stores/context.jsx';
import { Services } from '@stores/service';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';

const VehicleDetailsFeatureFlag = observer(() => {
    const { authStore, featureDashboardStore } = useStores();
    const [disabledSwitch, setDisabledSwitch] = useState(authStore.hasWriteAccess(Services.FeatureDashboard)? false : true);
    const [bookingRequestOptionSwitch, setBookingRequestOption] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        getOptionsState();
    }, [featureDashboardStore.deviceDetails]);

    const getOptionsState = () => {
        const deviceDetails = featureDashboardStore.deviceDetails;
        if (deviceDetails) {
            const featureFlags = deviceDetails.feature_flags || [];
            setBookingRequestOption(featureFlags.some(flag => flag.flag_name === 'show_booking_request_button'));
        }
    }

    const handleSwitchChange = (type) => {
        const deviceId = get(featureDashboardStore.deviceDetails, ['id']);
        let flagType;
        let value;
        let setOptionFunction;
        let previousValue;
        switch (type) {
            case 'bookingRequestOption':
                flagType = 'show_booking_request_button';
                previousValue = bookingRequestOptionSwitch;
                value = !bookingRequestOptionSwitch;
                setOptionFunction = setBookingRequestOption;
                break;
            default:
                console.error(`'${type}' is unknown.`);
                return;
        }
        setOptionFunction(value);
        featureDashboardStore.updateFeatureFlag({ deviceId, flagType, value })
            .catch((error) => {
                setOptionFunction(previousValue);
            });
    }

    return ( 
        featureDashboardStore.deviceDetails && (
            <div className='margin-bottom-20'>
                <h5>
                    {`${t("fotaone.fd.featureData.featureSwitch.featureOptions")}:`}
                    {featureDashboardStore.featureFlagLoading?
                        <i className='rioglyph rioglyph-spinner spinning text-color-primary' />
                    :<></>}
                </h5>
                <div className='breakline margin-bottom-20' />
                <div className='row'>
                    <div className='col-md-7'>
                        <div className='margin-bottom-10'>
                            <label className='display-block'>{`${t("fotaone.fd.featureData.featureSwitch.requestFeatureSwitch")}:`}</label>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <div className='margin-bottom-10'>
                            <div>
                                <Switch
                                    onChange={() => handleSwitchChange("bookingRequestOption")}
                                    checked={bookingRequestOptionSwitch}
                                    enabledText={`${t("fotaone.general.yes")}`}
                                    disabledText={`${t("fotaone.general.no")}`}
                                    labelPosition='right'
                                    disabled={disabledSwitch}
                                >
                                    <span className='text-primary'>{bookingRequestOptionSwitch ? `${t("fotaone.fd.featureData.featureSwitch.optionActive")}.` : `${t("fotaone.fd.featureData.featureSwitch.optionInactive")}.`}</span>  
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
});

export default VehicleDetailsFeatureFlag;
