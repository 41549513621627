import axios from 'axios';
import config from '@src/config';
import { configureAxiosClient } from './api-utils';

let client;

const init = () => {
    client = axios.create({ baseURL: config.ONLINETRAFFIC_API_URL });
    return configureAxiosClient(client);
};

export { client, init };
